import React, { MouseEvent } from 'react';

interface Props {
  imgurl?: string;
  imgalt?: string;
  title: string;
  items?: any;
  imgClass?: string;
  textClass?: string;
  produnctimgmainclass?: string;
  handleClick?: () => void;
}

const ProductImageCard: React.FC<Props> = ({
  imgurl = '../images/Front.svg',
  imgalt = 'product-image',
  imgClass = 'img-fluid',
  textClass = 'text-break mb-1',
  title = '',
  items = [],
  handleClick,
  produnctimgmainclass = ''
}) => {
  imgurl = imgurl == "" ? "../images/Front.svg" : imgurl
  return (
    <div onClick={handleClick && handleClick} className={`cursor-pointer ${produnctimgmainclass ?? ' h-100'}`}>
      <div className="p-4 px-5" title={imgalt}>
        <img src={imgurl} alt={imgalt} className={imgClass} />
      </div>
      <p className={textClass} title=''>{title}</p>
      {items.map((value: string) => (
        <p key={value} className={textClass} >{value}</p>
      ))}
    </div>
  )
}

export default ProductImageCard;