import React, { useState, useEffect } from 'react'
import Layout from '../../pages/Shipment/Layout';
import { useNavigate } from 'react-router-dom'
import { getrepair } from '../../routes/ApiEndPoints';
import { APICALL } from '../../services/ApiServices';
import CommonShipmentServices from '../../services/CommonShipmentServices';
import { RepairConstants } from '../../pages/Shipment/Constants/ShipmentConstants';
import TableStructure from '../atoms/TableStructure';
import { t } from '../../pages/Translations/TranslationUtils';
import Popup from '../../utils/popup';
import { ScannerMolecule } from '../molecules/ScannerMolecule';

const ManageRepairOrganism: React.FC = () => {
    const navigate = useNavigate();
    const localdata = CommonShipmentServices.getPostData();
    const user_id = Number(localStorage.getItem("user_id"));
    const [repairData, setRepairData] = useState({})
    const [popup, setPopup] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchrepairdetails = async () => {

            const response = await APICALL.service(getrepair, 'POST', localdata);

            if (response.status === 200 && response.data != null) {
                const data = response.data?.repairdata?.filter((item: any) => {
                    return item?.renewal_record?.status === 'active'
                })
                if (data.length > 0) {
                    setPopup(true)
                }
                setRepairData(data)
            }
        }
        fetchrepairdetails()
    }, []);


    const handleEdit = async (value: any) => {
        navigate(`/shipment/repair/?edit=${value.repair_id}`)
    }

    const handleScan = async (value: any) => {
        const scan_value: any = Object.values(repairData).find((item: any) => item.serial_number == value.trim())
        if (scan_value) {
            navigate(`/shipment/repair/?edit=${scan_value?.repair_id}`)
        } else {
            setError(true)
        }
    }

    return (
        <Layout pagename={t('Repair')} logo={true} timer={false}>
            <div className="flex-1 overflow-auto">
                <TableStructure
                    isAction
                    data={repairData}
                    headers={RepairConstants.TABLE_HEADERS}
                    values={RepairConstants.TABLE_VALUES}
                    handleEdit={handleEdit}
                />
            </div>
            {popup && <Popup modalSize="xl" title={t("Scan renewal record")} cancel={() => setPopup(false)} body={<ScannerMolecule handleClick={handleScan} error={error} />} />}
        </Layout >
    )
}

export default ManageRepairOrganism;
