import React, { useState } from 'react';
import {UserContext } from './Contextlib';
import { User } from '../@types/user';
// import { RefreshToken } from '../@types/refresh_token'

const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
export default UserProvider;
