import React, { useEffect, useState } from "react";
import { fetchList, getSortReports, getUsernames } from "../../../routes/ApiEndPoints";
import { APICALL } from "../../../services/ApiServices";
import {
  ConfigConstants,
  sortCategories,
} from "../../../pages/Shipment/Constants/ShipmentConstants";
import CommonServices from "../../../services/CommonServices";
import { t } from "../../../pages/Translations/TranslationUtils";
import CustomFilterHeaderForReports from "../../molecules/CustomFilterHeaderForReports";
import { useNavigate } from "react-router-dom";
import ActionButtonGroup from "../../molecules/ActionButtonGroup";
import SortCategoriesMolecule from "../../molecules/SortCategoriesMolecule";
import WindowHeightComponent from "../../../commonComponents/WindowHeightComponent";

const ViewSortReportsOrganism = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    brandPartners: [],
    users: [],
    data: [],
    shipments: []
  });

  const [selectedFilterData, setSelectedFilterData] = useState({
    brand_partner_id: [],
    user_id: [],
    shipment_id: [],
    from_date: null,
    to_date: null,
    filter: "all"
  });

  useEffect(() => {
    fetchData();
    fetchSortData();
  }, []);

  useEffect(() => {
    if (selectedFilterData.filter != 'all') {
      fetchSortData('shipments');
    }
  }, [selectedFilterData.brand_partner_id]);

  const fetchData = async () => {
    try {
      let postData = {
        method: "POST",
        data: {
          activestatus: [true, false]
        }
      };
      let fetchedUsernames = await APICALL.service(getUsernames, "POST", postData, true);
      if (fetchedUsernames.status === 200) {
        let configreqdata = {
          method: "POST",
          data: {
            list: [ConfigConstants.BRANDPARTNER],
          },
        };
        const configres = await APICALL.service(fetchList, "POST", configreqdata, true);
        if (configres.status === 200) {
          setState((prevState) => ({
            ...prevState,
            users: CommonServices.sortAlphabattically(fetchedUsernames?.data ?? [], "full_name"),
            brandPartners: CommonServices.sortAlphabattically(configres?.data?.BrandPartner),
          }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSortData = async (filter?: any) => {
    try {
      const postdata: any = { ...selectedFilterData };
      if (filter === 'shipments') {
        postdata.filter = 'shipments'
      }
      if (postdata.from_date) {
        postdata.from_date = await CommonServices.getTimezoneOffset(postdata.from_date);
      }
      if (postdata.to_date) {
        postdata.to_date = await CommonServices.getTimezoneOffset(postdata.to_date);
      }
      const response = await APICALL.service(getSortReports, "POST", postdata);
      if (response.status === 200) {
        if (filter === 'shipments') {
          setState((prevState) => ({
            ...prevState,
            shipments: CommonServices.sortAlphabattically(response.shipmentdata)
          }));
          if (response.shipmentdata?.length) {
            const filteredShipments = selectedFilterData.shipment_id.filter((item: any) =>
              response.shipmentdata.some((shipment: any) => shipment.shipment_id === item.value)
            );
            setSelectedFilterData((prev) => ({ ...prev, filter: '', shipment_id: filteredShipments ?? [] }))
          } else {
            setSelectedFilterData((prev) => ({ ...prev, filter: '', shipment_id: [] }))
          }
        } else {
          setState((prevState) => ({
            ...prevState,
            data: response.data,
            shipments: selectedFilterData.filter === 'all' 
              ? CommonServices.sortAlphabattically(response.shipmentdata) 
              : state.shipments
          }));
          setSelectedFilterData((prev) => ({ ...prev, filter: '' }))
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getSelectOptions = (data: any, id: any, label: any) => {
    const convertedData: any = Object.values(data).map((item: any) => ({
      value: item[id],
      label: item[label],
    }));
    return convertedData;
  };

  const handleSelectedFilter = (value: any, key: string) => {
    setSelectedFilterData((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleClearFilter = () => {
    setSelectedFilterData((prevValues) => ({
      ...prevValues,
      brand_partner_id: [],
      user_id: [],
      from_date: null,
      to_date: null,
    }));
  };
  // Define an array of filter objects with properties for rendering filters
  const filters: any = [
    { type: "multiSelect", label: t("Brand partner"), key: "brand_partner_id", selected: selectedFilterData.brand_partner_id, options: getSelectOptions(state.brandPartners, "brand_partner_id", "title") },
    { type: "multiSelect", label: t("Shipments"), key: "shipment_id", selected: selectedFilterData.shipment_id, options: getSelectOptions(state.shipments, "shipment_id", "title") },
    { type: "multiSelect", label: t("Users"), key: "user_id", selected: selectedFilterData.user_id, options: getSelectOptions(state.users, "user_id", "full_name") },
    { type: "calender", label: t("From date"), key: "from_date", selected: selectedFilterData.from_date, "max_date": selectedFilterData.to_date },
    { type: "calender", label: t("To date"), key: "to_date", selected: selectedFilterData.to_date, "min_date": selectedFilterData.from_date },
  ];

  return (
    <div className="row">
      <div className="col-11 ms-auto px-4">
        <WindowHeightComponent>
          <div className="d-flex overflow-auto flex-column h-100">
            <div className="py-xxl-4 py-xl-3 py-lg-3 page-title">
              {t("Sort overview")}
            </div>
            <CustomFilterHeaderForReports
              filters={filters}
              handleSelectedFilter={handleSelectedFilter}
              // clearFilter={handleClearFilter}
              applyFilter={() => fetchSortData()}
            />
            <div className="flex-1 overflow-auto">
              {<div className="mb-3">
                <SortCategoriesMolecule
                  sortCategories={sortCategories}
                  apiData={state.data}
                  timer={true}
                  disablePlusMinus={true}
                />
              </div>}
            </div>
            <ActionButtonGroup
              backTitle={t("Back")}
              handleBackClick={() => navigate('/reports')}
            />
          </div>
        </WindowHeightComponent>
      </div>
    </div>
  );
};

export default ViewSortReportsOrganism;
