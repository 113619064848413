import React, { ReactNode, useEffect, useState } from "react";
import Shipmentheader from "./Shipmentheader";
import WindowHeightComponent from "../../commonComponents/WindowHeightComponent";
import { useLocation } from "react-router-dom";
import { useUserContext } from "../../routes/Contextlib";
import Popup from "../../utils/popup";
import { t } from "../Translations/TranslationUtils";

type LayoutProps = {
  children: ReactNode;
  pagename: string;
  logo: any;
  timer: boolean;
  comments?: any;
  hold?: any;
  stage?: string;
  ongoing?: boolean;
  totaltime?: number;
  setTimerRunning?: any
};

const Layout: React.FC<LayoutProps> = ({
  logo,
  children,
  pagename,
  timer,
  comments,
  hold,
  stage,
  ongoing,
  totaltime,
  setTimerRunning
}) => {
  const { user } = useUserContext();
  const [showTrackTimePopup, setShowTrackTimePopup] = useState(false);

  useEffect(() => {
    if (user && localStorage.getItem("trackTime") == null) {
      if (user?.trackTime.includes("no")) {
        localStorage.setItem("trackTime", "no")
      } else if (user?.trackTime.includes("maybe")) {
        setShowTrackTimePopup(true)
      } else {
        localStorage.setItem("trackTime", "yes")
      }
    }
  }, [localStorage.getItem("trackTime")])

  return (
    <div className="row">
      <div className="col-md-11 ms-auto px-4">
        <WindowHeightComponent>
          <div className="d-flex flex-1 flex-column overflow-auto h-100">
            <Shipmentheader
              logo={logo}
              pagename={pagename}
              timer={timer}
              comments={comments}
              hold={hold}
              stage={stage}
              ongoing={ongoing}
              totaltime={totaltime}
              setTimerRunning={setTimerRunning}
            />
            <div className="flex-1 overflow-auto mt-2 d-flex flex-column h-100">
              <>{children}</>
            </div>
          </div>
        </WindowHeightComponent >
        {showTrackTimePopup && (
          <Popup
            body={t('Enable time tracking?')}
            modalSize="lg"
            closeButton={false}
            onHideOption={false}
            submit={() => { localStorage.setItem("trackTime", "yes"), setShowTrackTimePopup(false) }}
            cancel={() => { localStorage.setItem("trackTime", "no"), setShowTrackTimePopup(false) }}
            yestext={t('Yes')}
            notext={t('No')}
          />
        )
        }
      </div>
    </div>
  );
};

export default Layout;
