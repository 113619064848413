import React, { useEffect } from "react";
import {getRefreshToken} from '../routes/ApiEndPoints';
import EncryptDecryptService from "./EncryptDecryptService";
// import { useRefreshTokenContext } from '../routes/Contextlib';
export async function GenerateRefreshToken(): Promise<any> {
  const body: any = {
    api_key: process.env.REACT_APP_SERVICE_API,
  };
  let token: string = "";

  const tokenData: any = localStorage.getItem("tokenExpiry");

  if (tokenData) {
    const decyptedToken = JSON.parse(
      EncryptDecryptService.decryptData(tokenData)
    );

    const utcTimestamp = decyptedToken?.data?.expiry;
    const utcDate = new Date(utcTimestamp);
    const currentDate = new Date();

    if (!tokenData || currentDate > utcDate) {
      const newToken = await getNewToken(body);
      return newToken;
    } else {
      return decyptedToken?.data?.token;
    }
  } else {
    const newToken = await getNewToken(body);
    return newToken;
  }
}

const getNewToken = async (body: any) => {
  const response = await fetch(getRefreshToken + "/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  const data = await response.json();
  const encryptedResponse = EncryptDecryptService.encryptData(
    JSON.stringify({ data })
  );

  localStorage.setItem("tokenExpiry", encryptedResponse);

  if (data.token != "" || data.token != null) {
    const token = data.token;
    return token;
  }
};
