import React from 'react'

const Delete = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="21.51" height="27.625" viewBox="0 0 21.51 27.625">
                <path id="delete" d="M1.511,24.532V6.115H19.928V24.532a2.983,2.983,0,0,1-.9,2.194,2.907,2.907,0,0,1-2.123.9H4.6a2.983,2.983,0,0,1-2.194-.9A2.989,2.989,0,0,1,1.511,24.532Zm20-23.021V4.6H0V1.511H5.4L6.906,0h7.7l1.511,1.511Z" fill="rgba(84,89,96,0.35)" />
            </svg>
        </>
    )
}

export default Delete