import { deleteFiles, fetchFiles, uploadFiles } from "../routes/ApiEndPoints";
import { APICALL as AXIOSCALL } from "./AxiosServices";
import { APICALL } from "./ApiServices";
import { S3_BUCKET_NAME, s3, S3_FOLDER } from "../config/aws-config";

const S3Services = {
  uploadFilesToS3: async function (formdata: any) {
    let result: any;
    try {
      if (formdata) {
        let response: any = await AXIOSCALL.service(
          uploadFiles,
          "POST",
          formdata,
          1
        );
        result = await response;
      }
    } catch (error) {
      result = await error;
    }
    return result;
  },

  deleteFromS3: async function (postData: any) {
    let result: any;
    try {
      const response = await APICALL.service(deleteFiles, "POST", postData);
      result = await response;
    } catch (error) {
      console.log(error);
      result = await error;
    }
    return result;
  },

  fetchFilesFromS3: async function (pathString: any) {
    try {
      const postData = {
        path: pathString,
      };
      const response = await APICALL.service(fetchFiles, "POST", postData);

      return response;
    } catch (error) {
      console.log(error);
    }
  },

  uploadToS3: async (fileList: any, pathstring: string) => {
    const totalfiles = fileList.length;
    let filesdata: any = [];
    if (fileList) {
      for (let i = 0; i < totalfiles; i++) {
        const file = fileList[i];
        const timestamp = Math.floor(new Date().getTime() / 1000);
        const randomSuffix = Math.floor(Math.random() * 1000) + 1;
        const fileExtension = file.name.split('.').pop();
        const newFileName = `${timestamp}${randomSuffix}.${fileExtension}`;
        const params = {
          Bucket: S3_BUCKET_NAME,
          Key: S3_FOLDER + pathstring + newFileName,
          Body: file,
          ContentType: file.type,
          ACL: 'public-read',
        };
        // Wrap the S3 upload operation in a Promise
        const uploadPromise = () => {
          return new Promise((resolve, reject) => {
            s3.upload(params, (err: any, data: any) => {
              if (err) {
                console.error('Error uploading image:', err);
                reject(err);
              } else {
                filesdata.push(data.Location);
                resolve(data.Location);
              }
            });
          });
        };

        try {
          const uploadedLocation = await uploadPromise();
        } catch (error) {
          // Handle any errors from the S3 upload here
        }
      }
    }
    return filesdata;
  }
};

export default S3Services;
