import React from "react";
import IconData from "../../static/IconData";
import SortCounter from "./SortCounterMolecule";
import { t } from "../../pages/Translations/TranslationUtils";

type Props = {
  item?: any;
  time?: any;
  timer?: boolean;
  item_id?: any;
  handleChange: (newCount: number, type: string) => void;
  errors?: any;
  pendingItems?: any;
  upperLimit?: any;
};

const StainTreatmentPopupBody: React.FC<Props> = ({
  item,
  timer,
  time,
  item_id,
  handleChange,
  errors,
  pendingItems,
  upperLimit,
}) => {
  return (
    <div className="col-lg-12 mb-3">
      <div className="d-flex align-items-center justify-content-around gap-5">
        {pendingItems === 0 && (
          <div className="d-flex align-items-center justify-content-center ">
            <div className="pe-3  mb-3">{IconData.Carticon}</div>
            <SortCounter
              count={item}
              onCountChange={(newCount) => handleChange(newCount, "item")}
              upperLimit={upperLimit}
              timer={true}
            />
            <div className="ps-3  mb-3">{t('Items')}</div>
          </div>
        )}
        {timer && (
          <div className="d-flex align-items-center justify-content-center">
            <div className="pe-3 mb-3">{IconData.timer}</div>
            <SortCounter
              count={time}
              onCountChange={(newCount) => handleChange(newCount, "time")}
              timer={true}
            />
            <div className="ps-3 mb-3">{t('Minutes')}</div>
          </div>
        )}
      </div>
      {errors && <span>{errors}</span>}
    </div>
  );
};

export default StainTreatmentPopupBody;
