import React from "react";
import PrePublishOrganism from "../../../components/organisms/PrePublish/PrePublishOrganism";
import AccessControlWorkFlow from "../../../services/AccessControlWorkFlow";
import AccessControl from "../../../services/AccessControl";

const PrePublish = () => {
  return (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={[
        {
          permission: "workflow",
          read: true,
        },
      ]}
    >
    <AccessControlWorkFlow stage_name="publishing" renderNoAccess={true}>
      <PrePublishOrganism />
      </AccessControlWorkFlow>
    </AccessControl>
  );
};

export default PrePublish;
