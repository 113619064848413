import React from "react";

export const FolderIcon = () => {
  return (
    <>
      <svg
        id="folder_G_"
        data-name="folder (G)"
        xmlns="http://www.w3.org/2000/svg"
        width="38.5"
        height="32.577"
        viewBox="0 0 38.5 32.577"
      >
        <g id="Group_7384" data-name="Group 7384" transform="translate(0 0)">
          <g id="Group_7383" data-name="Group 7383">
            <path
              id="Path_30853"
              data-name="Path 30853"
              d="M38.5,11.106V27.394a5.214,5.214,0,0,1-5.183,5.183H5.183A4.988,4.988,0,0,1,1.527,31.05,4.988,4.988,0,0,1,0,27.394V5.183A4.988,4.988,0,0,1,1.527,1.527,4.988,4.988,0,0,1,5.183,0h7.4a4.988,4.988,0,0,1,3.656,1.527,4.988,4.988,0,0,1,1.527,3.656v.74H33.318A4.988,4.988,0,0,1,36.973,7.45,4.988,4.988,0,0,1,38.5,11.106Z"
              transform="translate(0 0)"
              fill="#545960"
            />
          </g>
        </g>
      </svg>
    </>
  );
};
