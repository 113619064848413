import React from 'react'

const QualityControl = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="74.993" height="70" viewBox="0 0 74.993 75.033">
        <g id="Group_48582" data-name="Group 48582" transform="translate(-4.711 0.501)">
          <path id="Subtraction_733" data-name="Subtraction 733" d="M12816.782-115.486H12792.9v-43.745a1.335,1.335,0,0,0-1.332-1.335,1.335,1.335,0,0,0-1.332,1.335v3.531l-4.122,3.828-9.256-12.149,13.5-13.481a8.612,8.612,0,0,1,6.134-2.551h6.764a1.425,1.425,0,0,1,1.412,1.229,9.135,9.135,0,0,0,9.027,7.882,9.169,9.169,0,0,0,9.043-7.882,1.4,1.4,0,0,1,1.412-1.226h6.747a8.726,8.726,0,0,1,6.134,2.548l13.5,13.481-9.256,12.149-4.118-3.828v-3.535a1.335,1.335,0,0,0-1.335-1.332,1.336,1.336,0,0,0-1.336,1.332v20.92a14.37,14.37,0,0,0-7.976-2.918c-.254-.013-.512-.02-.766-.02a14.435,14.435,0,0,0-14.411,13.688,14.48,14.48,0,0,0,5.456,12.076Zm2.747-45.667v6.563l4.614,3.225,4.615-3.225v-6.563Z" transform="translate(-12771.48 180.051)" fill="#989b9f" stroke="rgba(0,0,0,0)" strokeWidth="1" />
          <g id="Group_48549" data-name="Group 48549" transform="translate(40.601 38.064) rotate(3)">
            <path id="Path_56406" data-name="Path 56406" d="M11.683,0a11.683,11.683,0,1,0,7.6,20.558l3.574,3.5-.122.126a1.066,1.066,0,0,0,.023,1.5l6.416,6.226a1.066,1.066,0,0,0,1.5-.022l2.316-2.387a1.064,1.064,0,0,0-.023-1.5l-6.416-6.226a1.066,1.066,0,0,0-1.5.022l-.142.146-3.646-3.573A11.684,11.684,0,0,0,11.683,0Zm0,3.294a8.389,8.389,0,1,1-8.389,8.389A8.389,8.389,0,0,1,11.683,3.294Zm0,2.489a.905.905,0,1,1,0,1.81,4.089,4.089,0,0,0-4.091,4.091.905.905,0,0,1-1.81,0,5.9,5.9,0,0,1,5.9-5.9Z" transform="translate(2.508 2.427)" fill="#989b9f" fillRule="evenodd" />
          </g>
        </g>
      </svg>
    </>
  )
}

export default QualityControl

