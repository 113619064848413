import CategorySortOrganism from "../../../components/organisms/CategorySortOrganism/CategorySortOrganism";
import AccessControl from "../../../services/AccessControl";
import AccessControlWorkFlow from "../../../services/AccessControlWorkFlow";

const CategorySort = () => {
  return (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={[
        {
          permission: "workflow",
          read: true,
        },
      ]}
    >
      <AccessControlWorkFlow stage_name="category_sort" renderNoAccess={true}>
        <>
          <CategorySortOrganism />
        </>
      </AccessControlWorkFlow>
    </AccessControl>
  );
};
export default CategorySort;
