import { UTILITYFN } from '../utility/Utility';
import { useEffect, useReducer, useRef, useState } from 'react';
import styles from "../../brandpartner.module.css"
import LabelField from '../../../../components/atoms/LabelField';
import { GenerateRefreshToken } from '../../../../services/RefreshTokenService';
import InputTextfield from '../../../../components/atoms/InputTextField';
import { AddWidget } from '../../../../static/icons/AddWidget';
import Close from '../../../../static/icons/Close';
import EncryptDecryptService from '../../../../services/EncryptDecryptService';
import { APICALL } from '../../../../services/ApiServices';
import { fetchConfigData } from '../../../../routes/ApiEndPoints';
import ActionButtonGroup from '../../../../components/molecules/ActionButtonGroup';
import { t } from '../../../Translations/TranslationUtils';
import { useNavigate } from 'react-router-dom';
import SelectedSizesAndDimensions from '../../../../components/molecules/SelectedSizesAndDimensions';
import customAlert from '../../../../components/atoms/CustomAlert';
import AddConfigToBrandPartner from '../../../../components/organisms/AddConfigToBrandPartner';
import FormValidation from '../../../../services/FormValidation';
import CommonServices from '../../../../services/CommonServices';

interface Props {
  draftSaved: boolean;
  saveAsDraft: string;
  saveAndNext: string;
  backbutton: string;
  arrowIosBackFill?: string;
  arrowIosBackFill2?: string;
  toggleAlertMessage: () => void;
  setAlertMessage: (message: string) => void;
  formPageState: any;
  dispatchFormPageState: any;
  setPageDirection: (page: number) => void;
}

interface FetchedDimension {
  [key: number]: any
}

const dimensionDataState = {
  dimensions: [],
};

const dimensionFieldState = {
  dimensions: { errorState: false, label: "Name", errorLabel: t("Please enter a valid data!") },
};

function FourthTab(props: Props) {
  const {
    draftSaved,
    saveAndNext,
    saveAsDraft,
    backbutton,
    arrowIosBackFill,
    arrowIosBackFill2,
    toggleAlertMessage,
    setAlertMessage,
    formPageState,
    dispatchFormPageState,
    setPageDirection
  } = props
  const [state, dispatch] = useReducer(formReducer, dimensionDataState);
  const [fetcheddimension, setFetchedDimension] = useState<FetchedDimension>([]);
  const [selecteddimension, setSelectedDimension] = useState(null);
  const [addnew, setAddNew] = useState(false);
  const [errorState, setErrorState] = useState(dimensionFieldState);
  const addDimension = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  function DispatchErrorChanges(newState: any) {
    setErrorState(newState);
  }

  async function validateFormData(event: React.FormEvent<HTMLFormElement>, draft?: boolean) {
    event.preventDefault();
    let updatedFormPageState = { ...formPageState };
    let newErrorState = { ...dimensionFieldState };

    const { requiredStatus, requiredMessage } = validateRequiredField();

    if (!draft && requiredStatus) {
      newErrorState.dimensions.errorState = true;
      customAlert("error", t(requiredMessage), 6000);
      updatedFormPageState.pageFour.validationStatus = false;
      dispatchFormPageState({ ...formPageState, ...updatedFormPageState });
    } else {
      let token = await GenerateRefreshToken();
      const bp_id = sessionStorage.getItem("brand_partner_id");
      const response = await fetch(
        process.env.REACT_APP_MASTER_DATA_URL +
        "api/master/create-brand-partner",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            "bp_dimension": state.dimensions,
            "brand_partner_id": bp_id
          }),
        }
      );
      if (response?.status === 200) {
        if (draft) {
          navigate('/brand-partner/manage')
        }
        newErrorState.dimensions.errorState = false;
        updatedFormPageState.pageFour.validationStatus = true;
        dispatchFormPageState({ ...formPageState, ...updatedFormPageState });

        sessionStorage.setItem('dimensionsState', JSON.stringify(state));
        UTILITYFN.resetUserSessionDataforMoveOut();
        UTILITYFN.setCurrentPageData(props, 5);
      }
    }
    DispatchErrorChanges(newErrorState);
  }

  function validateRequiredField() {
    let errorResponse;
    if (state.dimensions.length == 0) {
      errorResponse = {
        requiredStatus: true,
        requiredMessage: t("No dimension is selected")
      };
    } else {
      let invalidLabels = state.dimensions.filter((dimension: any) => FormValidation.nameValidation(dimension?.label) != '');

      errorResponse = {
        requiredStatus: invalidLabels.length > 0,
        requiredMessage: invalidLabels.length > 0 ? t("Some labels are empty or exceeding 255 characters") : ""
      };
    }

    return errorResponse;
  }

  function formReducer(prevState: any, newsState: any) {
    return { ...prevState, ...newsState };
  }

  window.addEventListener('beforeunload', () => {
    sessionStorage.clear()
  });

  useEffect(() => {
    let newErrorState = { ...dimensionFieldState };
    newErrorState.dimensions.errorState = false;
    DispatchErrorChanges(newErrorState);
    if (sessionStorage.getItem('dimensionsState')) {
      let d = { ...JSON.parse(sessionStorage.getItem('dimensionsState') || '') };
      dispatch({ ...JSON.parse(sessionStorage.getItem('dimensionsState') || '') });
    } else {
      sessionStorage.setItem('dimensionsState', JSON.stringify(state));
    }

    fetchDimensions();
  }, []);

  useEffect(() => {
    if (addnew) {
      window.scrollTo({
        top: addDimension.current?.offsetTop,
        behavior: 'smooth',
      });
    }
  }, [addnew]);

  const fetchDimensions = async () => {
    const postData = {
      // Encrypting the request data
      method: "POST",
      data: EncryptDecryptService.encryptData(JSON.stringify({
        "type": "model",
        "name": "Dimension",
        "status": true
      })),
    };
    const response = await APICALL.service(fetchConfigData, 'POST', postData);
    let decrypteddata = JSON.parse(EncryptDecryptService.decryptData(response));
    if (decrypteddata?.status !== 200) {
      throw new Error("Request failed");
    }
    setFetchedDimension(decrypteddata.data);
  }

  const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>, dimension_id: number) => {
    const updatedFetchedDimensions = Object.values(fetcheddimension).map((dimension: any) => {
      if (dimension.dimension_id === dimension_id) {
        return { ...dimension, label: e.target.value };
      }
      return dimension;
    });

    const updatedStateDimensions = state.dimensions.map((dimension: any) => {
      if (dimension.dimension_id === dimension_id) {
        return { ...dimension, label: e.target.value };
      }
      return dimension;
    });

    setFetchedDimension(updatedFetchedDimensions);
    dispatch({ ...state, dimensions: updatedStateDimensions });
  }

  const handleDimensionClick = (dimension: any) => {
    const isSizeAlreadyPresent = state.dimensions.find((s: any) => s.dimension_id === dimension.dimension_id);
    setSelectedDimension(dimension.dimension_id);
    if (isSizeAlreadyPresent == undefined) {
      dispatch({ ...state, dimensions: [...state.dimensions, { ...dimension, label: dimension.title }] });
    }
    let newErrorState = { ...dimensionFieldState };
    newErrorState.dimensions.errorState = false;
    DispatchErrorChanges(newErrorState);
  }

  const addNewDimension = (dimension: any) => {
    setAddNew(false);
    fetchDimensions();
    dispatch({ ...state, dimensions: [...state.dimensions, { ...dimension, label: dimension.title }] });
  }

  const removeDimension = (dimension_id: number) => {
    const updatedDimensions = state.dimensions?.filter((dimension: any) => dimension.dimension_id !== dimension_id);
    dispatch({ ...state, dimensions: updatedDimensions });
  };

  return (
    <>
      <form className="contract-meter-form flex-1 overflow-auto d-flex flex-column h-100" onSubmit={(e) => validateFormData(e)}>
        <div className="flex-1 overflow-auto">
          <SelectedSizesAndDimensions
            title={t("Selected dimensions")}
            selectedItems={state?.dimensions}
            onRemoveItem={removeDimension}
            onLabelChange={handleLabelChange}
          />
          <div className="d-flex justify-content-center">
            <img style={{ width: "200px" }} alt="" title="" src="../../success.svg" />
          </div>
          <div className="border rounded p-4 mb-xxl-4 mb-3">
            <LabelField
              title={t("Select dimension")}
              mandatory={true}
              className={` ${styles.label} mb-3`}
            />
            <div className="d-flex flex-wrap">
              {Object.entries(fetcheddimension).map(([key, value]) => (
                <div key={key} className={`mb-3 position-relative p-4 py-3 rounded  ${(selecteddimension === value.dimension_id) ? styles.activebrandpartnerselectBorder : ''} ${styles.bpsize_btn}`}>
                  <div className="position-relative d-flex flex-column h-100">
                    <div title={value.title} className={`${styles.color_btn} d-flex align-items-center justify-content-center rounded text-break h-100  w-100 ${state.dimensions?.some((s: any) => s.dimension_id === value.dimension_id)
                      ? styles.activebrandpartnerselect : ''} `}
                      onClick={() => handleDimensionClick(value)}
                    >
                      {value.title ?? ""}
                    </div>
                    {state.dimensions?.some((d: any) => d.dimension_id === value.dimension_id) &&
                      <div className={`${styles.selected_close_btn} position-absolute cursor-pointer top-0`} onClick={() => removeDimension(value.dimension_id)}>
                        <Close />
                      </div>}
                    {selecteddimension === value.dimension_id &&
                      <div className="position-relative">
                        <InputTextfield
                          className="border rounded mt-3 text-gray text-truncate"
                          value={
                            state.dimensions?.find((d: any) => d.dimension_id === value.dimension_id)?.label ??
                            value.title ??
                            ''
                          }
                          handleChange={(e) => handleLabelChange(e, value.dimension_id)}
                          handleClick={(event) => event.stopPropagation()}
                          customStyle={{ width: '100%', textAlign: 'center' }}
                        />
                      </div>
                    }
                  </div>
                </div>
              ))}
              <div className={`${styles.add_btn} rounded me-5 mb-3 p-4 py-3  cursor-pointer`}
                onClick={() => setAddNew(true)}
              >
                <AddWidget />
              </div>
            </div>
          </div>
          {addnew && <div className="border rounded p-4 mb-5" ref={addDimension}>
            <span className="float-end cursor-pointer"
              onClick={() => setAddNew(false)}
            >
              <Close />
            </span>
            <LabelField
              title={t("Add dimension")}
              mandatory={false}
              className={`${styles.label}`}
            />
            <AddConfigToBrandPartner
              addNew={addNewDimension}
              model={'Dimension'}
            />
          </div>}
        </div>
        <ActionButtonGroup
          backTitle={t(backbutton)}
          saveAsDraftTitle={draftSaved ? "" : t(saveAsDraft)}
          saveAndNextTitle={t(saveAndNext)}
          handleBackClick={() => UTILITYFN.setCurrentPageData(props, 3)}
          handleSaveAsDraftClick={(e: any) => validateFormData(e, true)}
          handleSaveAndNextClick={(e: any) => validateFormData(e)}
        />
      </form>
    </>
  );
}

export default FourthTab;
