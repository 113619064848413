import React, { useEffect, useState } from 'react';
import FirstTab from './components/FirstTab';
import SecondTab from './components/SecondTab';
import ThirdTab from './components/ThirdTab';
import FourthTab from './components/FourthTab';
import FifthTab from './components/FifthTab';
import SixthTab from './components/SixthTab';
import SeventhTab from './components/SeventhTab';
import {
  CommonData,
  formPageInformation,
  TabBarsConstData
} from './components/constants/brandPartnerConst';
import ErrorAlert from './components/ErrorAlert';
import TabBars from './components/TabBars';
import customAlert from '../../components/atoms/CustomAlert';
import EighthTab from './components/EighthTab';
import AccessControl from '../../services/AccessControl';
import { t } from '../Translations/TranslationUtils';
import WindowHeightComponent from '../../commonComponents/WindowHeightComponent';
import NinthTab from './components/NinthTab';

function BrandPartner() {
  const searchParams = new URLSearchParams(location.search);
  const viewId = Number(searchParams.get("view"));
  const editId = viewId != 0 ? viewId : Number(searchParams.get("edit"));
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [formPageState, setFormPageState] = useState({ ...formPageInformation });
  const [draftSaved, setDraftSaved] = useState(false);
  const setPageDirection = (page: number) => {
    if (activeError) toggleAlertMessage();
    updateVisitedPageStatus(page);
    setCurrentPage(page);
  };
  const [errorText, setErrorText] = useState('');
  const [activeError, setActiveError] = useState(false);
  const [requiredPermissions, setRequiredPermissions] = useState<any>([])
  useEffect(() => {
    updateVisitedPageStatus(1);
  }, []);

  useEffect(() => {
    // Need to render the function again on active change
  }, [activeError, errorText]);

  useEffect(() => {
    // Need to render the function again on formPageState change
  }, []);

  function updateVisitedPageStatus(page: number) {
    switch (page) {
      case 1:
        dispatchFormPageState({ ...formPageState, pageOne: { ...formPageState.pageOne, visited: true } });
        break;
      case 2:
        dispatchFormPageState({ ...formPageState, pageTwo: { ...formPageState.pageTwo, visited: true } });
        break;
      case 3:
        dispatchFormPageState({ ...formPageState, pageThree: { ...formPageState.pageThree, visited: true } });
        break;
      case 4:
        dispatchFormPageState({ ...formPageState, pageFour: { ...formPageState.pageFour, visited: true } });
        break;
      case 5:
        dispatchFormPageState({ ...formPageState, pageFive: { ...formPageState.pageFive, visited: true } });
        break;
      case 6:
        dispatchFormPageState({ ...formPageState, pageSix: { ...formPageState.pageSix, visited: true } });
        break;
      case 7:
        dispatchFormPageState({ ...formPageState, pageSeven: { ...formPageState.pageSeven, visited: true } });
        break;
    }
  }

  function toggleAlertMessage() {
    setActiveError(!activeError);
  }

  function setAlertMessage(message: string) {
    if (activeError) toggleAlertMessage();
    setErrorText(message);
  }
  //Flash message should be shown if page is not validated when trying to access next page
  const showErrorMessage = () => {
    const storageMessage = localStorage.getItem("errorAlertMessage");

    if (storageMessage) {
      localStorage.removeItem("errorAlertMessage");
      customAlert("error", t(storageMessage), 6000);
    }
  }

  function dispatchFormPageState(formData: any) {
    setFormPageState(formData);
  }

  useEffect(() => {
    sessionStorage.clear();
    setFormPageState({
      ...formPageState,
      pageOne: { ...formPageState.pageOne, validationStatus: (editId ? true : false) },
      pageTwo: { ...formPageState.pageTwo, validationStatus: (editId ? true : false) },
      pageThree: { ...formPageState.pageThree, validationStatus: (editId ? true : false) },
      pageFour: { ...formPageState.pageFour, validationStatus: (editId ? true : false) },
      pageFive: { ...formPageState.pageFive, validationStatus: (editId ? true : false) },
      pageSix: { ...formPageState.pageSix, validationStatus: (editId ? true : false) },
      pageSeven: { ...formPageState.pageSeven, validationStatus: (editId ? true : false) },
      pageEight: { ...formPageState.pageEight, validationStatus: (editId ? true : false) },
    });
  }, []);
  const setCurrentPageData = () => {
    switch (currentPage) {
      case 1:
        return <FirstTab {...CommonData} formPageState={formPageState} dispatchFormPageState={dispatchFormPageState} setPageDirection={setPageDirection} draftSaved={draftSaved} setDraftSaved={setDraftSaved}/>;
      case 2:
        return <SecondTab {...CommonData} formPageState={formPageState} dispatchFormPageState={dispatchFormPageState} setPageDirection={setPageDirection} draftSaved={draftSaved} />;
      case 3:
        return <ThirdTab {...CommonData} toggleAlertMessage={toggleAlertMessage} formPageState={formPageState} dispatchFormPageState={dispatchFormPageState} setAlertMessage={setAlertMessage} setPageDirection={setPageDirection} draftSaved={draftSaved} />;
      case 4:
        return <FourthTab {...CommonData} toggleAlertMessage={toggleAlertMessage} formPageState={formPageState} dispatchFormPageState={dispatchFormPageState} setAlertMessage={setAlertMessage} setPageDirection={setPageDirection} draftSaved={draftSaved} />;
      case 5:
        return <FifthTab {...CommonData} formPageState={formPageState} toggleAlertMessage={toggleAlertMessage} setAlertMessage={setAlertMessage} dispatchFormPageState={dispatchFormPageState} setPageDirection={setPageDirection} draftSaved={draftSaved} />;
      case 6:
        return <SixthTab {...CommonData} formPageState={formPageState} setPageDirection={setPageDirection} draftSaved={draftSaved} dispatchFormPageState={dispatchFormPageState} />;
      case 7:
        return <SeventhTab {...CommonData} toggleAlertMessage={toggleAlertMessage} formPageState={formPageState} dispatchFormPageState={dispatchFormPageState} setAlertMessage={setAlertMessage} setPageDirection={setPageDirection} draftSaved={draftSaved} />;
      case 8:
        return <EighthTab {...CommonData} toggleAlertMessage={toggleAlertMessage} formPageState={formPageState} dispatchFormPageState={dispatchFormPageState} setAlertMessage={setAlertMessage} setPageDirection={setPageDirection} draftSaved={draftSaved} />;
      case 9:
        return <NinthTab {...CommonData} setPageDirection={setPageDirection} draftSaved={draftSaved} />;
      default:
        return <FirstTab {...CommonData} formPageState={formPageState} dispatchFormPageState={dispatchFormPageState} setPageDirection={setPageDirection} draftSaved={draftSaved} setDraftSaved={setDraftSaved} />;
    }
  };

  useEffect(() => {
    let permissions = [
      {
        permission: "brand partner",
        create: true,
        update: true
      }
    ]
    if (editId) {
      permissions[0].create = false;
    } else {
      permissions[0].update = false;
    }
    setRequiredPermissions(permissions)
  }, [editId])

  return (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={requiredPermissions}
    >
      <div className="row">
        <div className="col-11 px-4 ms-auto">
          <WindowHeightComponent>
            <div className="d-flex h-100 overflow-auto flex-column">
              <div className="py-xxl-4 py-xl-3 py-lg-3 page-title">{t(`${viewId !== 0 ? "View" : (editId !== 0 ? "Edit" : "Create")} brand partner`)}</div>

              <div className="flex-1 h-100 d-flex flex-column overflow-auto">
                <div className="d-flex">
                  {viewId == 0 &&
                    <>
                      <TabBars {...TabBarsConstData.stepOne} {...formPageState} showErrorMessage={showErrorMessage} currentPage={currentPage} setPageDirection={setPageDirection} />
                      <TabBars {...TabBarsConstData.stepTwo} {...formPageState} showErrorMessage={showErrorMessage} currentPage={currentPage} setPageDirection={setPageDirection} />
                      <TabBars {...TabBarsConstData.stepThree} {...formPageState} showErrorMessage={showErrorMessage} currentPage={currentPage} setPageDirection={setPageDirection} />
                      <TabBars {...TabBarsConstData.stepFour} {...formPageState} showErrorMessage={showErrorMessage} currentPage={currentPage} setPageDirection={setPageDirection} />
                      <TabBars {...TabBarsConstData.stepFive} {...formPageState} showErrorMessage={showErrorMessage} currentPage={currentPage} setPageDirection={setPageDirection} />
                      <TabBars {...TabBarsConstData.stepSix} {...formPageState} showErrorMessage={showErrorMessage} currentPage={currentPage} setPageDirection={setPageDirection} />
                      <TabBars {...TabBarsConstData.stepSeven} {...formPageState} showErrorMessage={showErrorMessage} currentPage={currentPage} setPageDirection={setPageDirection} />
                      <TabBars {...TabBarsConstData.stepEight} {...formPageState} showErrorMessage={showErrorMessage} currentPage={currentPage} setPageDirection={setPageDirection} />
                    </>
                  }
                  <TabBars {...TabBarsConstData.stepNine} {...formPageState} showErrorMessage={showErrorMessage} currentPage={currentPage} setPageDirection={setPageDirection} />
                </div>
                <ErrorAlert errorText={errorText} toggleAlertMessage={toggleAlertMessage} activeError={activeError} />
                <div className="border rounded px-4 py-2 pt-4 mt-3 flex-1 overflow-auto d-flex flex-column">
                  {setCurrentPageData()}
                </div>
              </div>
            </div>
          </WindowHeightComponent>
        </div>
      </div>
    </AccessControl>
  );
}

export default BrandPartner;
