import React from 'react'

const Archive = () => {
  return (
    <>
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24.378 28.714">
          <path id="Archive_G_" data-name="Archive (G)" d="M5364.378,492.714H5340V474.322a1.109,1.109,0,0,1,1.108-1.107h7.995a1.105,1.105,0,0,1,.81.354l0,0,2.139,2.334.58.635a1.737,1.737,0,0,0,.163.146l0,0,.044.036.039.036a.656.656,0,0,0,.076.066.467.467,0,0,0,.066.038l.007,0,.041.022.02.012a1.881,1.881,0,0,0,.242.137,2.083,2.083,0,0,0,.847.179h9.532a.665.665,0,0,1,.664.664v14.824Zm-14.627-6.575a.406.406,0,0,0-.288.694l2.438,2.438a.408.408,0,0,0,.575,0l2.439-2.438a.406.406,0,0,0,.119-.288.407.407,0,0,0-.695-.288L5352.6,488V483.3a.406.406,0,1,0-.813,0V488l-1.745-1.745A.4.4,0,0,0,5349.751,486.139Zm-2.031-5.688a.407.407,0,1,0,0,.813h8.939a.407.407,0,0,0,0-.813Zm12.768-5.216h-6.3a1.107,1.107,0,0,1-.45-.1h0l-.005,0-.005,0a1.084,1.084,0,0,1-.359-.261l-.665-.726-2.049-2.24a2.108,2.108,0,0,0-1.548-.681h-5.216V464h16.6v11.234Zm-8.792-4.91a.495.495,0,1,0,0,.99h7a.495.495,0,0,0,0-.99Zm-2.646-1.9a.495.495,0,1,0,0,.99h9.65a.495.495,0,0,0,0-.99Zm0-2.413a.5.5,0,1,0,0,.991h9.65a.5.5,0,0,0,0-.991Z" transform="translate(-5340 -464)" fill="rgba(84,89,96,0.35)" />
        </svg>
      </span>
    </>
  )
}

export default Archive

