import React from "react";
import { QuickAccessObject } from "../../utils/QuickAccessObject";
import IconWithLabel from "../atoms/IconWithLabel";
import styles from "./organisms.module.css"
import IconData from "../../static/IconData";
import AccessControl from "../../services/AccessControl";

interface Props {
  icon?: React.ReactNode;
  iconName?: String;
  className?: String;
  handleClick?: () => void;
}

const QuickAccessBody: React.FC<Props> = ({ icon, iconName, className }) => {

  const handleClick = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <div className="text-start">
      {Object.values(QuickAccessObject).map((item: any) => {
        return (
          <div className="border-bottom">
            <AccessControl requiredPermissions={item.requiredPermissions} strict={item.strict ?? false}>
              <div className="ps-4 mt-xxl-2 mt-lg-1 mb-xxl-4 mb-xl-3 mb-lg-2">
                <div className="text-gray">
                  <p className={`${styles.quickaccesslabel}`}>{item.name}</p>
                </div>
                <div className={`d-flex  ${styles.quickaccessicon} flex-wrap mb-3 gap-3`}>
                  {item.subcategories.map((item1: any) => {
                    return (
                      <AccessControl requiredPermissions={item1.requiredPermissions}>
                        <div className={`${styles.quickaccessblock} me-4`}>
                          <IconWithLabel
                            classname1="text-center cursor-pointer d-flex flex-column"
                            iconLabel={item1.name}
                            svgData={IconData[item1.icon]}
                            handleClick={() => handleClick(item1.url)}
                          />
                        </div>
                      </AccessControl>
                    );
                  })}
                </div>
              </div>
            </AccessControl>
          </div>
        );
      })}
    </div>
  );
};
export default QuickAccessBody;
