import React from 'react'

const Manageicon = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="44.707" height="50" viewBox="0 0 44.707 45.791">
        <g id="__TEMP__SVG__" transform="translate(23.051 535.667)">
          <path id="Path_56333" data-name="Path 56333" d="M39.34,25.486l4.837,3.762a1.136,1.136,0,0,1,.322,1.5l-4.622,7.954a.941.941,0,0,1-1.4.43l-5.7-2.257a21.654,21.654,0,0,1-3.87,2.257l-.86,6.02a1.026,1.026,0,0,1-1.182.967H17.734a1.106,1.106,0,0,1-1.182-.967l-.86-6.02a17.6,17.6,0,0,1-3.87-2.257l-5.7,2.257a1.047,1.047,0,0,1-1.4-.43L.106,30.753a1.136,1.136,0,0,1,.322-1.5l4.837-3.762q-.107-1.29-.107-2.257t.107-2.257L.428,17.21a1.136,1.136,0,0,1-.322-1.5L4.728,7.75a.971.971,0,0,1,1.4-.43l5.7,2.257a21.654,21.654,0,0,1,3.87-2.257l.86-6.02A1.106,1.106,0,0,1,17.734.333h9.137a1.026,1.026,0,0,1,1.182.967l.86,6.02a17.6,17.6,0,0,1,3.87,2.257l5.7-2.257a1.047,1.047,0,0,1,1.4.43L44.5,15.7a1.136,1.136,0,0,1-.322,1.5L39.34,20.972a13.958,13.958,0,0,1,.215,2.257A13.958,13.958,0,0,1,39.34,25.486ZM22.3,31.291a7.7,7.7,0,0,0,5.643-2.365,7.765,7.765,0,0,0,2.365-5.7,7.765,7.765,0,0,0-2.365-5.7,7.916,7.916,0,0,0-11.287,0,7.765,7.765,0,0,0-2.365,5.7,7.765,7.765,0,0,0,2.365,5.7A7.7,7.7,0,0,0,22.3,31.291Z" transform="translate(-23 -536)" fill="#545960" />
        </g>
      </svg>
    </>
  )
}

export default Manageicon

