import React from "react";
import TimeTrackerOverviewOrganism from "../../../components/organisms/TimeTracker/TimeTrackerOverviewOrganism";
import AccessControl from "../../../services/AccessControl";
const TimeTrackerOverview = () => {
  return (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={[
        {
          permission: "time tracker overview",
          read: true,
        },
      ]}
    >
      <TimeTrackerOverviewOrganism />
    </AccessControl>
  );
};

export default TimeTrackerOverview;
