import React, { useState } from "react";
import LabelField from "../atoms/LabelField";
import styles from "./organisms.module.css";
import Button from "../atoms/Button";
import ErrorMessage from "../atoms/ErrorMessage";
import FormValidation from "../../services/FormValidation";
import { APICALL } from "../../services/ApiServices";
import { createConfigData } from "../../routes/ApiEndPoints";
import { t } from "../../pages/Translations/TranslationUtils";
import { CommonConstants } from "../../pages/Shipment/Constants/ShipmentConstants";
import { InputWithLabel } from "../molecules/InputWithLabel";
import MultiSelectField from "../atoms/MultiSelectField";
type Props = {
  addNewSize?: (size: any) => void;
  category_id?: number;
  category_title?: string;
  model: string;
  categoryOptions?: any;
  addNew?: (value: any) => void;
  handleCategorySelect?: (value: any) => void;
};

const AddConfigToBrandPartner: React.FC<Props> = (props) => {
  const [input, setInput] = useState({
    value: "",
    error: "",
    selecterror: ""
  });

  const validateInput = (value: string): boolean => {
    let error = FormValidation.nameValidation(value);

    let selecterror = props.categoryOptions ? FormValidation.nameValidation(props?.category_id?.toString()) : "";

    setInput({ ...input, error: error, selecterror: selecterror });
    return error == "" && selecterror == "";
  };

  const handleSubmit = async () => {
    try {
      let validate = validateInput(input.value);

      if (validate) {
        let model = props.model;
        let postData: any = {
          // Encrypting the request data
          method: "POST",
          data: {
            model: model,
            fields: { title: [input.value] },
            subfields: [[props.category_id]]
          },
        };

        const response = await APICALL.service(
          createConfigData,
          "POST",
          postData,
          true
        );

        if (response.status === 200) {
          if (props.addNewSize) {
            let responsedata = response.data;
            const propsdata = {
              ...responsedata,
              category_id: props.category_id,
              category_title: props.category_title,
            };
            props.addNewSize(propsdata);
          }
          props.addNew && props.addNew(response.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="row mt-5">
        {props.categoryOptions && (
          <div className="col-6">
            <LabelField
              title={t("Category")}
              mandatory={true}
              className={`${styles.label}`}
            />
            <MultiSelectField
              isClearable={false}
              placeholder={t("Category")}
              options={props.categoryOptions}
              handleChange={props.handleCategorySelect}
              isSearchable
              customStyle={
                input.selecterror !== ""
                  ? { border: "1px solid red", borderRadius: "5px", width: "100%" }
                  : { width: "100%" }
              }
              handleClick={() => setInput((prev) => ({ ...prev, selecterror: "" }))}
            />
            {input?.selecterror !== "" && (
              <ErrorMessage
                errorMessage={t(input.selecterror)}
                className="text-danger"
              />
            )}
          </div>
        )}
        <div className="col-6">
          <div>
            <LabelField
              title={t("Title")}
              mandatory={true}
              className={`${styles.label}`}
            />
          </div>
          <InputWithLabel
            className={`border rounded w-100 text-gray px-3 ${input.error !== "" ? "border-danger" : ""} `}
            value={input.value}
            name={"title"}
            handleChange={(e) => setInput({ ...input, value: e.target.value })}
            handleClick={(e) => { e.stopPropagation(); setInput((prev) => ({ ...prev, error: "" })); }}
            error={input.error}
          />
        </div>
        <div className="col d-flex align-items-end justify-content-end">
          <Button
            className={`${styles.submit_btn_size} shadow-none text-decoration-none`}
            title={t(CommonConstants.SAVE)}
            handleClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default AddConfigToBrandPartner;
