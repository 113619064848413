import React from "react";
import { useProfilePicture } from "./ProfilePictureContext";
import { t } from "../pages/Translations/TranslationUtils";
interface ProfileImageProps {
    profilepictureurl: string;
}
const ProfileImage: React.FC<ProfileImageProps> = ({profilepictureurl}) => {
    const {profilePictureUrl, updateProfilePicture}  = useProfilePicture();
    let url:any =  (profilepictureurl != '')
                    ? profilepictureurl
                    : localStorage.getItem('profilepicture')
    updateProfilePicture(url)
    return(
        <>
            <img alt={t("PROFILE")} className="profile-icon rounded-pill" src={profilePictureUrl} /> 
        </>
    )

}

export default ProfileImage;

