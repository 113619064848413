const FormValidation = {
  nameValidation: function (value: any, length = 255, fieldtitle = 'This') {
    //For required text fields
    if (value?.trim() == "" || value == null) {
      return `${fieldtitle} field is required`;
    } else if (value?.trim()?.length > length) {
      return `${fieldtitle} field length exceeds ${length} characters`;
    } else {
      return "";
    }
  },
  textValidation: function (value: any, length = 255) {
    //For not required fields
    if (value?.trim()?.length > length) {
      return `The field length exceeds ${length} characters`;
    } else {
      return "";
    }
  },
  zipcodeValidation: function (value: any) {
    //For not required fields
    if (value?.trim()?.length > 20) {
      return "The field length exceeds 20 characters";
    } else {
      return "";
    }
  },
  numberValidation: function (value: any) {
    //only for range atom
    if (value == "") {
      return "This field is required";
    } else if (value > 0 && value <= 100) {
      return "";
    } else {
      return "The field accepts only (1 - 100) numbers";
    }
  },
  isNumberValidation: function (value: any, min: any, max: any) {
    const onlyNumbersRegex = /^[0-9]+$/;

    if (!onlyNumbersRegex.test(value)) {
      return "Invalid input format";
    } else if (Number(value) < Number(min)) {
      return `Minimum value is ${min}`;
    } else if (Number(value) > Number(max)) {
      return `maximum value is ${max}`;
    } else {
      return "";
    }
  },
  isDecimalValidation: function (value: any, min: any, max: any) {
    const decimalNumberRegex = /^\d*\.?\d*$/;

    if (!decimalNumberRegex.test(value)) {
      return "Invalid input format";
    } else if (value < min) {
      return `Minimum value is ${min}`;
    } else if (value > max) {
      return `maximum value is ${max}`;
    } else {
      return "";
    }
  },
  textareaValidation: function (value: any) {
    if (value?.trim() == "") {
      return "This field is required";
    } else {
      return "";
    }
  },
  checkboxValidation: function (value: any) {
    if (value == "") {
      return "This field is required";
    } else {
      return "";
    }
  },
  radioValidation: function (value: any) {
    if (value == "") {
      return "This field is required";
    } else {
      return "";
    }
  },
  emptyValidation: function (value: any) {
    if (value == "" || value == null) {
      return "This field is required";
    } else {
      return "";
    }
  },
  selectValidation: function (value: any) {
    if (value == null || value.length == 0) {
      return "This field is required";
    } else {
      return "";
    }
  },
  multiselectValidation: function (value: any) {
    // For required fields
    if (value.length == 0) {
      return "This field is required";
    } else {
      return "";
    }
  },
  fileValidation: function (value: any) {    
    //For not required fields
    if (value == null || value == '') {
      return "This field is required";
    } else {
      return "";
    }
  },
  dateValidation: function (date: string, regex = /^\d{2}\/\d{2}\/\d{4}$/, fieldtitle = 'Date') {
    if (date === '' && !date) {
      return `${fieldtitle} field is empty`;
    } else if (!regex.test(date)) {
      return `${fieldtitle} format is incorrect`
    } else {
      return ""
    }
  },
  isValidHexColor: (color: string) => {
    if (!color) return "";
		const hexColorRegex = /^#[0-9a-fA-F]{6}$/;
		return hexColorRegex.test(color) ? "" : "Invalid hex code"
	},
  hasDuplicates: (array: any) => {
    return new Set(array).size !== array.length;
  }
};
export default FormValidation;
