import RecordsOverviewOrganism from "../../../components/organisms/RecordOverview/RecordsOverviewOrganism";

const ViewRenewalRecord = () => {
  return (
    <RecordsOverviewOrganism 
      bpid={localStorage.getItem('bpid')}
      view = {true}
      returnurl="/reports/operations-overview"/>
  );
};

export default ViewRenewalRecord;
