import React, { ReactNode, MouseEvent } from 'react';

interface Props {
  title?: ReactNode;
  handleClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  disabled?: boolean;
  children?: ReactNode;
  id?:string;
  hoverText?: string;
  name?: string;
  type?: "button" | "submit" | "reset" | undefined;
  style?: any;
}

const Button: React.FC<Props> = ({ title, handleClick, id, className = '', disabled = false, hoverText = '', children, name, type = 'button', style}) => {
  return (
    <button type={type} title={hoverText} className={className} id ={id} onClick={handleClick} disabled={disabled} name={name} style={style}>
      {title}
      {children}
    </button>
  );
};

export default Button;
