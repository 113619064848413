import React, { useState } from "react";
import "./user.css";
import { useNavigate } from "react-router-dom";
import { FaSort } from "react-icons/fa";
import Popup from "../../utils/popup";
import { t } from "../Translations/TranslationUtils";
import Archive from "../../static/icons/Archive";
import Unarchive from "../../static/icons/Unarchive";
import Edit from "../../static/icons/Edit";
import Pagination from "../../services/Paginations";
import AccessControl from "../../services/AccessControl";
import { CommonConstants } from "../Shipment/Constants/ShipmentConstants";
import InputWithButton from "../../components/molecules/InputWithButton";
interface User {
  user_id: number;
  username: string;
  email: string;
  roles: [];
  status: boolean;
}

interface UserTableProps {
  users: User[];
  onDeleteUser: (userId: number, status: boolean) => void;
  state: any;
  setState: (newState: any) => void;
  fetchUsers: (filter?: any) => void;
}

const UserTable: React.FC<UserTableProps> = ({
  users,
  onDeleteUser,
  state,
  setState,
  fetchUsers,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [selectedUserStatus, setSelectedUserStatus] = useState<boolean | null>(
    null
  );
  const navigate = useNavigate();

  const handleDeleteUser = (userId: number, status: boolean) => {
    setSelectedUserId(userId);
    setSelectedUserStatus(status);
    setShowDeleteConfirmation(true);
  };

  const handlePaginationClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    const newOffset = (pageNumber - 1) * state.limit;
    setState((prevData: any) => ({
      ...prevData,
      offset: newOffset,
    }));
  };

  const handleSortClick = async () => {
    if (users.length !== 0) {
      setCurrentPage(1);
      setState((prevData: any) => ({ ...prevData, issorted: true, filter: state.filter === 'asc' ? 'desc' : 'asc' }));;
      await fetchUsers(state.filter === 'asc' ? 'desc' : 'asc');
    }
  };

  const confirmDeleteUser = async () => {
    if (selectedUserId && selectedUserStatus !== null) {
      await onDeleteUser(selectedUserId, selectedUserStatus);
      setCurrentPage(1);
      setState((prev: any) => ({
        ...prev,
        offset: 0,
        activeTab: selectedUserStatus ? "inactive" : "active",
      }));
    }
    setShowDeleteConfirmation(false);
    setSelectedUserId(null);
    setSelectedUserStatus(null);
  };

  const handleTabClick = (tab: "active" | "inactive") => {
    setCurrentPage(1);
    setState((prev: any) => ({ ...prev, offset: 0, activeTab: tab }));
  };

  const handleEdit = (e: number) => {
    const url = `/users/create/?edit=${e}`;
    navigate(url);
  };

  return (
    <div className="d-flex flex-column h-100 overflow-auto">
      <div className="">
        <div className="">
          <div className="user-table-actions">
            <div className="mb-3 float-end">
              <InputWithButton
                handleClick={(searchText) => {
                  setState((prev: any) => ({
                    ...prev,
                    offset: 0,
                    searchText: searchText,
                  }));
                  setCurrentPage(1);
                }}
                textFieldClass="form-control shadow-none"
                buttonClass="submit-btn shadow-none"
                noerror={true}
                buttonTitle={t("Search")}
              />
            </div>
          </div>
        </div>
        <div className="user-tabs my-3">
          <button
            className={`${state.activeTab === "active" ? "active" : ""
              } border-0 bg-transparent tab-btn me-4`}
            onClick={() => handleTabClick("active")}
          >
            {t("Active")}
          </button>
          <button
            className={`${state.activeTab === "inactive" ? "active" : ""
              } border-0 bg-transparent tab-btn`}
            onClick={() => handleTabClick("inactive")}
          >
            {t("Inactive")}
          </button>
        </div>
      </div>

      <div className="manage-users-table flex-1 overflow-auto">
        <table className="table">
          <thead>
            <tr>
              <th className="border-bottom-0">
                {t("Name")}
                <button
                  onClick={handleSortClick}
                  className="sort-btn border-0 bg-transparent text-gray"
                >
                  <FaSort />
                </button>
              </th>
              <th className="border-bottom-0">{t("Email")}</th>
              <th className="border-bottom-0">{t("Role")}</th>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "users",
                    update: true,
                    delete: true,
                  },
                ]}
                actions={true}
                strict={false}
              >
                <th className="border-bottom-0 text-center">{t("Actions")}</th>
              </AccessControl>
            </tr>
          </thead>
          <tbody>
            {users.length ? (
              users.map((user) => (
                <tr key={user.user_id}>
                  <td className="text-break">
                    {user.username[0].toUpperCase() + user.username.slice(1)}
                  </td>
                  <td className="text-break">{user.email}</td>
                  <td className="text-break">
                    {user.roles && user.roles.length > 0
                      ? user.roles
                        .filter((role: any) => role?.status == true)
                        .map(
                          (role: any) =>
                            role?.title.charAt(0).toUpperCase() +
                            role?.title.slice(1)
                        )
                        .join(", ")
                      : ""}
                  </td>
                  <AccessControl
                    requiredPermissions={[
                      {
                        permission: "users",
                        update: true,
                        delete: true,
                      },
                    ]}
                    actions={true}
                    strict={false}
                  >
                    <td className="text-break manage-users-action text-center">
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "users",
                            update: true,
                          },
                        ]}
                      >
                        <button
                          onClick={() => handleEdit(user.user_id)}
                          className="me-4 px-0 border-0 bg-transparent shadow-none"
                          data-toggle="tooltip"
                          title={t("Edit")}
                        >
                          <Edit />
                        </button>
                      </AccessControl>
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "users",
                            delete: true,
                          },
                        ]}
                      >
                        <button
                          onClick={() =>
                            handleDeleteUser(user.user_id, user.status)
                          }
                          className="me-4 px-0 border-0 bg-transparent shadow-none"
                          data-toggle="tooltip"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          title={user.status ? t("Deactivate") : t("Activate")}
                        >
                          {user.status ? <Archive /> : <Unarchive />}
                        </button>
                      </AccessControl>
                    </td>
                  </AccessControl>
                </tr>
              ))
            ) : (
              <td colSpan={4} className="text-center text-danger">
                {t(CommonConstants.NO_RESULTS_FOUND)}
              </td>
            )}
          </tbody>
        </table>
      </div>
      <div className="position-relative w-100 row mt-2 m-0">

        {state.totalLength > state.limit && state.totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={state.totalPages}
            handlePaginationClick={handlePaginationClick}
          />
        )}
        <div className="col-md-6 float-start p-0">
          <button
            className="back-btn shadow-none float-start text-decoration-none"
            onClick={() => navigate("/backend")}
          >
            {t(CommonConstants.BACK)}
          </button>
        </div>
      </div>

      {showDeleteConfirmation && (
        <Popup
          title={t("Confirmation")}
          body={t(
            `Are you sure you want to ${state.activeTab === "active" ? "deactivate" : "activate"
            } this user?`
          )}
          cancel={() => setShowDeleteConfirmation(false)}
          submit={confirmDeleteUser}
          yestext={t(CommonConstants.YES)}
          notext={t(CommonConstants.NO)}
        />
      )}
    </div>
  );
};

export default UserTable;
