import React from "react";
import styles from "../../pages/Shipment/shimpment.module.css";
import { useEffect, useReducer, useRef, useState } from "react";
import IconData from "../../static/IconData";
interface MerchRack {
  label: string;
  value: number;
}
interface MerchRackPopupBodyProps {
  fetchedMerchRack?: MerchRack;
  setState: any;
  setPopup?: any;
  saveAndScan?: any;
  state: any;
}

const MerchRackPopupBody: React.FC<MerchRackPopupBodyProps> = ({
  fetchedMerchRack,
  setState,
  state,
}) => {
  const handleMerchRackClick = (rackId: any) => {
    setState((prevFormData: any) => ({
      ...prevFormData,
      merch_rack: rackId.value,
    }));
  };

  return (
    <div className="d-flex flex-wrap ">
      {fetchedMerchRack &&
        Object.entries(fetchedMerchRack).map(([key, value]) => (
          <div key={key} className={`${styles.merchpopblock} p-2`}>
            <div
              title={value?.label}
              className={` border p-2 fs-5 rounded text-truncate cursor-pointer ${value.value == state.merch_rack ? styles.merchpopblockselected : ''}`}
              onClick={() => handleMerchRackClick(value)}
            >
              <div className="my-2">{IconData.merchrack}</div>
              <div className="text-truncate w-100">{value.label}</div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default MerchRackPopupBody;
