import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import styles from "./MediaLibrary.module.css"
interface ProgressBarProps {
  progress?: any
}

export const ProgressBars: React.FC<ProgressBarProps> = ({ progress }) => {
  return (
    <div className={`h-100 w-100 ${styles.progressbar}`}>
      <div className="row w-100">
        <div className="col-11 ms-auto">
          <div className="d-flex justify-content-center ">
            <div className="progress-container w-75">
              <ProgressBar now={progress} label={`${Math.round(progress)}%`} variant="danger" animated />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBars;