import React from 'react'

const Reportssmall = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="42.922" height="50" viewBox="0 0 42.922 49.459">
        <g id="Reports_R_" data-name="Reports (R)" transform="translate(0 0.001)">
          <path id="Subtraction_721" data-name="Subtraction 721" d="M19.365,43.623H0V0H33.562V25.55a13.217,13.217,0,0,0-2.1-.169c-.232,0-.475.008-.729.021a1.067,1.067,0,0,0,.229-.683,1.015,1.015,0,0,0-1.091-1.039H3.75a1.116,1.116,0,0,0-.8.336,1.061,1.061,0,0,0-.307.769,1.011,1.011,0,0,0,1.083,1.04H28.072a13.119,13.119,0,0,0-6.215,3.737H3.75a1.117,1.117,0,0,0-.8.336,1.061,1.061,0,0,0-.307.769,1.011,1.011,0,0,0,1.083,1.04H20.235a12.936,12.936,0,0,0-1.567,3.855.943.943,0,0,0-.445-.12H3.874a1.128,1.128,0,0,0-.807.34,1.058,1.058,0,0,0-.307.766,1.019,1.019,0,0,0,1.078,1.039H18.113a1.3,1.3,0,0,0,.254-.024c-.022.312-.03.631-.03.953a13.065,13.065,0,0,0,1.025,5.106Zm.7-25.824a1.128,1.128,0,0,0-.8.336,1.059,1.059,0,0,0-.3.77,1.013,1.013,0,0,0,1.069,1.039h9.735a1.127,1.127,0,0,0,.81-.344,1.028,1.028,0,0,0,.3-.762A1,1,0,0,0,29.8,17.8ZM9.4,13.517,4.827,18.094a7.243,7.243,0,0,0,4.651,1.713,6.632,6.632,0,0,0,4.493-1.713ZM9.1,6.11h0A6.918,6.918,0,0,0,4.634,8.071a6.656,6.656,0,0,0-.221,9.367l4.468-4.466.218-.324V6.11Zm6.944,4.68-5.778,2.182,4.355,4.466a6.762,6.762,0,0,0,1.423-6.648Zm4.023,1.128a1.128,1.128,0,0,0-.8.336,1.057,1.057,0,0,0-.3.769,1.013,1.013,0,0,0,1.069,1.039h9.735a1.127,1.127,0,0,0,.81-.344,1.028,1.028,0,0,0,.3-.762A1,1,0,0,0,29.8,11.918ZM9.845,6.11h0v6.316l5.88-2.285a6.251,6.251,0,0,0-1.415-2.07A6.924,6.924,0,0,0,9.845,6.11Zm10.194-.074A1.075,1.075,0,0,0,18.961,7.1a1.009,1.009,0,0,0,1.078,1.076h9.727a1.145,1.145,0,0,0,.81-.341,1.046,1.046,0,0,0,.3-.764A1.014,1.014,0,0,0,29.8,6.036Z" transform="translate(0 -0.001)" fill="#545960" />
          <g id="time" transform="translate(20.403 26.939)">
            <path id="Path_56119" data-name="Path 56119" d="M15.759,4.5A11.259,11.259,0,1,0,27.019,15.759,11.26,11.26,0,0,0,15.759,4.5Zm5.2,12.992h-5.2a.866.866,0,0,1-.866-.866v-7.8a.866.866,0,1,1,1.732,0v6.929h4.331a.866.866,0,1,1,0,1.732Z" transform="translate(-4.5 -4.5)" fill="#545960" />
          </g>
        </g>
      </svg>
    </>
  )
}

export default Reportssmall

