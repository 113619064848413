import React from 'react'
import IssueDetails from './IssueDetails';
import { t } from '../../../pages/Translations/TranslationUtils';
import CommonShipmentServices from '../../../services/CommonShipmentServices';
import Button from '../../atoms/Button';
import styles from "../../organisms/organisms.module.css"
import { CommonConstants, QaConstants } from '../../../pages/Shipment/Constants/ShipmentConstants';
import CommonServices from '../../../services/CommonServices';
interface Props {
    issues: any;
    data: any;
    handleClick: (status: boolean, tier: string, repair: boolean) => void;
    handleBack?: () => void;
}
const QaIssueOverview: React.FC<Props> = ({ issues, data, handleClick, handleBack }) => {
    return (
        <div className='py-4 pb-3  px-3 px-xxl-5 border rounded shadow h-100  d-flex flex-column'>
            <div className={`${styles.qaissuesoverview}`}>
                <h4 className='mb-3'><b>{t(CommonConstants.OVERVIEW)}</b></h4>
                {issues != null && issues != undefined && Object.keys(issues).map((key) => (
                    <React.Fragment key={key}>
                        <div className="border rounded mb-3">
                            {issues[key].issue_repaired ? (
                                issues[key].quality_status ? (
                                    <IssueDetails
                                        title={t(CommonConstants.ISSUE) + ' ' + CommonServices.convertNumberIntoTwoDigits(Number(key) + 1)}
                                        issue_size={CommonShipmentServices.getFilteredTitle(data['issue_size'] ?? [], 'issue_size_id', issues[key]?.quality_assurance_issue_info?.issue_size)}
                                        // please do not remove the below line of code
                                        // issue_location={CommonShipmentServices.getFilteredTitle(data['issue_location'], 'issue_location_id', issues[key].quality_assurance_issue_info.issue_location)}
                                        repair_assesment={CommonShipmentServices.getFilteredTitle(data['repair_assessment'] ?? [], 'repair_assessment_id', issues[key]?.quality_assurance_issue_info?.repair_assessment)}
                                        work_area={CommonShipmentServices.getFilteredTitle(data['work_area'] ?? [], 'work_area_id', issues[key]?.quality_assurance_issue_info?.work_area)}
                                    />) : (
                                    <div className='p-3'>
                                        <h4><b>{t(CommonConstants.ISSUE) + ' ' + CommonServices.convertNumberIntoTwoDigits(Number(key) + 1)}</b></h4>
                                        <p className={`${styles.qcvalue} mb-0 text-gray`}>{t(QaConstants.ISSUE_REJECTED_MESSAGE)}</p>
                                    </div>)
                            ) : (
                                <div className='p-3'>
                                    <h4><b>{t(CommonConstants.ISSUE) + ' ' + CommonServices.convertNumberIntoTwoDigits(Number(key) + 1)}</b></h4>
                                    <p className={`${styles.qcvalue} mb-0 text-gray`}>{t(QaConstants.ISSUE_FORWARDED_MESSAGE)}</p>
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                ))}
            </div>
            <div className='col-12 text-end mt-2'>
                <Button
                    className='back-btn float-start'
                    title={t("Back")}
                    handleClick={handleBack}
                />
                <Button
                    className='submit-btn float-end'
                    title={t(QaConstants.SEND_TO_HOLD_AND_SCAN_NEXT)}
                    handleClick={() => handleClick(false, '', true)}
                />
            </div>
        </div>
    )
}

export default QaIssueOverview