import React from 'react';
import { t } from '../../../Translations/TranslationUtils';

interface ErrorAlertProps {
  activeError: boolean;
  errorText: string;
  toggleAlertMessage: () => void;
}

const ErrorAlert: React.FC<ErrorAlertProps> = (props) => {
  return (
    <div>
      {props.activeError ? (
        <div className="my-3 alert alert-danger alert-dismissible" role="alert">
          <img alt="" style={{ width: "30px", marginRight: "15px" }} src="../../path.svg" />
          {props.errorText}
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label={t("Close")}
            onClick={props.toggleAlertMessage}
          >
            <span className="close_popup_landing" aria-hidden="true">&times;</span>
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default ErrorAlert;
