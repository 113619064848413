import React from "react";
import ShipmentReportsOrganism from "../../../components/organisms/ShipmentReportsOrganism";
import AccessControl from "../../../services/AccessControl";

const ShipmentReports = () => {
  return (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={[
        {
          permission: "shipment reports",
          read: true,
        },
      ]}
    >
      <ShipmentReportsOrganism />
    </AccessControl>
  );
};

export default ShipmentReports;
