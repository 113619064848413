import React, { ReactNode, useEffect, useState } from 'react'
import Popup from '../../../utils/popup';
import MerchRackPopupBody from '../../molecules/MerchRackPopupBody';
import DimensionPopupBody from '../../molecules/DimensionPopupBody';
import { t } from '../../../pages/Translations/TranslationUtils';
import { CommonConstants } from '../../../pages/Shipment/Constants/ShipmentConstants';
import styles from "../../organisms/organisms.module.css";
import SelectTierPopup from '../../molecules/TierPopup/SelectTierPopup';
import BarcodeBody from '../BarcodeBody';

interface RenewalRecordPopupsOrganismProps {
  state: any,
  setState: any,
  popuptype: any,
  configdata: any,
  popupSubmitHandler:any,
}
const RenewalRecordPopupsOrganism: React.FC<RenewalRecordPopupsOrganismProps> = ({
  state, setState, popuptype, configdata, popupSubmitHandler
}) => {  
  const [type, setPopupType] = useState<keyof typeof popup>(popuptype);
  const [popup, setPopup] = useState({
    product_tier: false,
    merch_rack: false,
    dimension: false,
    view_barcode: false
  });

  useEffect(() => {
    if(popuptype != '') {
      setPopupType(popuptype)
      setPopup((prev) => ({ ...prev, [popuptype]: true }))
    } 
  }, [popuptype])

  const fetchPopupBodyDetails = (poptype: string) => {    
    switch (poptype) {
      case 'merch_rack':
        return <MerchRackPopupBody
          fetchedMerchRack={configdata.bpdata.merch_rack}
          setState={setState}
          state={state}
        />
      case 'dimension':
        return <DimensionPopupBody
          fetcheddimension={configdata.bpdata.dimension}
          setState={setState}
          state={state}
          setPopup={setPopup}
          handleClick={handleSaveandCancel}
        />
      case 'product_tier':
        return <SelectTierPopup
          handleClick={handleHoldSubmit}
          tierlist={configdata.ProductTier} 
        />
      case 'view_barcode': 
        return <BarcodeBody
          handlePrint={handlePrint}
        />  
      default:
        return "";
    }
  }

  const handleHoldSubmit = async (tier: any) => {
    setState((prev:any) => ({ ...prev, product_tier: tier }));
    handleSaveandCancel('product_tier');
  };

  const handlePrint = async (data: any) => {
    handleSaveandCancel('view_barcode', false, data);
  };

  const handleSaveandCancel = (type: any, status:boolean = false, data?: any) => {
    type !== 'view_barcode' && setPopup((prev) => ({ ...prev, [type]: false }));
    popupSubmitHandler(type, status, data);
  }

  return (
    <>
      {popuptype != "" && popup[type] == true &&
        <Popup
          title={popuptype == 'product_tier' ? t("Select Tier") : ""}
          body={fetchPopupBodyDetails(popuptype)}
          modalSize={popuptype == 'merch_rack' ? "xl" : 'lg'}
          yestext={popuptype == 'merch_rack' ? t(CommonConstants.SAVE) : ""}
          notext={popuptype == 'view_barcode'
            ? (process.env.REACT_APP_SERVER === 'true' ? "" : t(CommonConstants.SKIP))
            : t(CommonConstants.CANCEL)}
          submit={() => popuptype == 'merch_rack' ? handleSaveandCancel(popuptype, true) : ""}
          cancel={() => handleSaveandCancel(type)}
          close={() => handleSaveandCancel(type, false, 'close')}
          bodyclassName={popuptype == 'dimension' ? styles.dimension_popup_body : ""}
        />
      }
    </>

  )
}
export default RenewalRecordPopupsOrganism;