import React from "react";
import CheckBoxField from "../atoms/CheckBoxField";
import { t } from "../../pages/Translations/TranslationUtils";
interface FilterValue {
  value: number;
  title: string;
  handle: string;
}
interface Props {
  selectedColumns?: string[]; // Change the type to an array of numbers
  setSelectedColumns?: (value: string[]) => void;
  filterValues?: FilterValue[];
}

const ShipmentReportsFilterBody: React.FC<Props> = (props: any) => {
  
  const handleCheck = (e: any) => {
    const value = Number(e.target.id) - 1; // Convert id to a number
    const selectedValue = props.filterValues[value].handle;

    // Individual checkbox clicked
    const isSelected = props.selectedColumns?.includes(selectedValue);

    if (isSelected) {
      // If the value is already selected, remove it
      props.setSelectedColumns((prevSelectedColumns: string[]) =>
        prevSelectedColumns.filter((value: string) => value != selectedValue)
      );
    } else {
      // If the value is not selected, add it
      props.setSelectedColumns((prevSelectedColumns: string[]) => [
        ...prevSelectedColumns,
        selectedValue,
      ]);
    }
  };

  const isAllSelected = props.filterValues.every((item: any) =>
    props.selectedColumns?.includes(item.handle)
  );

  const handlSelectAll = () => {
    if (isAllSelected) {
      // If all values are already selected, deselect all
      props.setSelectedColumns([]);
    } else {
      // Otherwise, select all values
      props.setSelectedColumns(props.filterValues.map((item: any) => item.handle));
    }
  };

  return (
    <div>
      <CheckBoxField
        label={t("Select all")}
        name="Select all"
        id={0}
        checked={isAllSelected}
        onCheck={() => handlSelectAll()}
        className={"px-3"}

      />
      <div className="row">
        {props.filterValues.map((item: any) => (
          <div className="col-6" key={item.vaue}>
            <CheckBoxField
              key={item.value}
              label={item.title}
              name={item.title}
              id={item.value}
              checked={props.selectedColumns?.includes(item.handle)}
              onCheck={(e) => handleCheck(e)}
              className={"px-3"}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShipmentReportsFilterBody;
