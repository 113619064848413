import CreateSortOrganism from "../../../components/organisms/CreateSortOrganism";
import AccessControl from "../../../services/AccessControl";
import AccessControlWorkFlow from "../../../services/AccessControlWorkFlow";

const CreateSort = () => {
  return (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={[
        {
          permission: "workflow",
          read: true,
        },
      ]}
    >
      <AccessControlWorkFlow stage_name="sort" renderNoAccess={true}>
        <>
          <CreateSortOrganism />
        </>
      </AccessControlWorkFlow>
    </AccessControl>
  );
};
export default CreateSort;
