import AWS from 'aws-sdk';


AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_DEFAULT_REGION,
  s3ForcePathStyle: process.env.REACT_APP_AWS_USE_PATH_STYLE_ENDPOINT === 'true'
});

export const s3 = new AWS.S3();

export const S3_BUCKET_NAME = process.env.REACT_APP_AWS_BUCKET ?? 'bleckmann-raven';

export const S3_FOLDER = process.env.REACT_APP_AWS_DEFAULT_FOLDER ?? '/';
