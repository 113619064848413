import React, { useEffect, useState } from "react";
import Createrole from "../static/icons/Createrole";
import Manageroles from "../static/icons/Manageroles";
import Createuser from "../static/icons/Createuser";
import Manageuser from "../static/icons/Manageuser";
import { Link, useNavigate } from "react-router-dom";
import customAlert from "../components/atoms/CustomAlert";
import AccessControl from "../services/AccessControl";
import IconWithLabel from "../components/atoms/IconWithLabel";
import { t } from "./Translations/TranslationUtils";
import { useUserContext } from "../routes/Contextlib";
import ActionButtonGroup from "../components/molecules/ActionButtonGroup";

export default function Backend() {
  const navigate = useNavigate();
  const { user, setUser } = useUserContext();
  const [userPermissions, setUserPermissions] = useState<any>()

  const checkStatus = (title: any) => {
    const truePerm = userPermissions?.find((item: any) => item.permission.toLowerCase().trim() === title)
    if(truePerm?.update === true || truePerm?.delete === true){
      return false
    }else{
      return true
    }
  }

  useEffect(() => {
    if(user){
      const userPerm: any = user.permissions;
      setUserPermissions(userPerm)
    }
  },[user != null])

  const sections = [
    { permission: "roles", create: true, title: "Create role", icon: <Createrole />, link: "/roles/create" },
    { permission: "roles", read: checkStatus("roles"), title: "Manage roles", icon: <Manageroles />, link: "/roles/manage" },
    { permission: "users", create: true, title: "Create user", icon: <Createuser />, link: "/users/create" },
    { permission: "users", read: checkStatus("users"), title: "Manage users", icon: <Manageuser />, link: "/users/manage" },
  ];

  useEffect(() => {
    const storageMessage = localStorage.getItem("successAlertMessage");
    if (storageMessage) {
      localStorage.removeItem("successAlertMessage");
      customAlert("success", t(storageMessage), 6000);
    }
  });
  
  return (
    <>
      <div className="row">
        <div className="col-md-11 ms-auto px-4 mb-4">
          {/* Page title */}
          <div className='py-xxl-4 py-xl-3 py-lg-3 page-title'>
            {t("User management")}
          </div>
          <div className="row gx-5 mb-4 mt-3">
            {sections.map((section, index) => (
              <AccessControl key={index} requiredPermissions={[section]}>
                <div className={`col-md-6 ${index % 2 === 0 ? 'pe-5' : 'ps-5'} mb-4`}>
                  <div className="link-border pb-3 ps-4">
                    {section.link ? (
                      <Link to={section.link} className="text-decoration-none page-links">
                        <span className="me-2">{section.icon}</span> {t(section.title)}
                      </Link>
                    ) : (
                      <span onClick={() => navigate(section.link)} className="cursor-pointer page-links">
                        <IconWithLabel
                          classname1="d-flex"
                          classname2="ms-3"
                          svgData={section.icon}
                          iconLabel={t(section.title)}
                        />
                      </span>
                    )}
                  </div>
                </div>
              </AccessControl>
            ))}
          </div>
          <ActionButtonGroup
            backTitle={t("Back")}
            handleBackClick={()=> navigate('/')}
          />
        </div>
      </div>
    </>
  );
}
