import React from 'react'

export const AddWidget = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 68 68">
        <path id="Add_widget_v02" data-name="Add widget_v02" d="M-162.326,15264h-55.349a6.333,6.333,0,0,1-6.326-6.324v-55.348a6.327,6.327,0,0,1,6.326-6.328h55.349a6.327,6.327,0,0,1,6.326,6.328v55.348A6.333,6.333,0,0,1-162.326,15264Zm-47.314-36.457A2.46,2.46,0,0,0-212.1,15230a2.457,2.457,0,0,0,2.456,2.455h17.188v17.188A2.457,2.457,0,0,0-190,15252.1a2.457,2.457,0,0,0,2.456-2.457v-17.187h17.182A2.457,2.457,0,0,0-167.9,15230a2.457,2.457,0,0,0-2.456-2.455l-17.182,0v-17.184A2.457,2.457,0,0,0-190,15207.9a2.457,2.457,0,0,0-2.456,2.457v17.184Z" transform="translate(224 -15196)" fill="#545960" />
      </svg>
    </>
  )
}
