import React from "react";
import ImageField from "../atoms/ImageField";
import IconWithLabel from "../atoms/IconWithLabel";
import IconData from "../../static/IconData";
import LabelField from "../atoms/LabelField";
import { t } from "../../pages/Translations/TranslationUtils";
import Popup from "../../utils/popup";
import BarcodeBody from "../organisms/BarcodeBody";
import ActionButtonGroup from "./ActionButtonGroup";
import { CommonConstants, DataEntryConstants } from "../../pages/Shipment/Constants/ShipmentConstants";
import { useNavigate } from "react-router-dom";

interface GenerateBarcodeMoleculeProps {
  barcodeUrl: any;
  handlePrint: (param: any) => void;
  setPopup: any;
  popup: any;
  dataentry?: boolean;
}
const GenerateBarcodeMolecule: React.FC<
  GenerateBarcodeMoleculeProps
> = ({ barcodeUrl, handlePrint, setPopup, popup, dataentry = false }) => {
  const navigate = useNavigate();

  const createAnotherDataentry = () => {
    const currentUrl = window.location.pathname;
    if (currentUrl === "/data-entry/create") {
      window.location.reload();
    } else {
      // Navigate to "/data-entry/create" if the current URL is different
      navigate("/data-entry/create");
    }
  };
  return (
    <>
      <div className="bg_color_raven_gray d-flex justify-content-center align-items-center flex-1 overflow-auto">
        <div className="text-center">
          <div className="p-5 bg-white">
            <p className="text-center ">
              <span className="py-2 page-sub-title text-gray">
                {t('We have successfully generated the barcode')}
              </span>
            </p>
            <div className="p-2">
              <ImageField
                className="w-10 shipment-barcode"
                value={barcodeUrl}
                altText={t("Barcode")}
              />{" "}
            </div>
            <LabelField title={t("Print barcode")} />
            <IconWithLabel
              hoverText={t("Print barcode")}
              svgData={IconData.PrinterIcon}
              handleClick={() => setPopup((prev: any) => ({ ...prev, viewBarcode: true }))}
              classname1="hoversvg"
            />
          </div>
        </div>
        {popup?.viewBarcode && (
          <Popup
            body={
              <BarcodeBody
                handlePrint={handlePrint}
              />
            }
            title={t("Print barcode")}
            cancel={() => { setPopup((prev: any) => ({ ...prev, viewBarcode: false })) }}
          />
        )}
      </div>
      {dataentry && <ActionButtonGroup
        backTitle={t(CommonConstants.BACK)}
        saveAndNextTitle={t(DataEntryConstants.SAVE_AND_NEXT)}
        handleBackClick={() => navigate(localStorage?.getItem('shipment_dashboard_url') ?? "/shipment-dashboard")}
        handleSaveAndNextClick={createAnotherDataentry}
        saveDisabled={false}
        backbuttoncss="back-btn"
        maindivcss="d-flex justify-content-between mt-2"
      />}
    </>
  );
};

export default GenerateBarcodeMolecule;
