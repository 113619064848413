import React, { useState, useEffect } from 'react';

interface CountdownTimerProps {
  initialTime: number; // Initial time in seconds
  onTimeout?: () => void; // Callback when the timer reaches 0
}

const TimerCountDown: React.FC<CountdownTimerProps> = ({ initialTime, onTimeout }) => {
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    if (time > 0) {
      const timer = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
        onTimeout && onTimeout(); // Call the callback when the timer reaches 0
    }
  }, [time, onTimeout]);

  // Calculate minutes and seconds
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  return (
    <div>
      <div>{minutes} : {seconds}</div>
    </div>
  );
};

export default TimerCountDown;
