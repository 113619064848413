import RepairOrganism from "../../../components/organisms/RepairOrganism";
import AccessControl from "../../../services/AccessControl";
import AccessControlWorkFlow from "../../../services/AccessControlWorkFlow";

const Repair = () => {
  //add your hooks here
  return (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={[
        {
          permission: "workflow",
          read: true,
        },
      ]}
    >
      <AccessControlWorkFlow stage_name="repair" renderNoAccess={true}>
        <RepairOrganism />
      </AccessControlWorkFlow>
    </AccessControl>
  );
};

export default Repair;
