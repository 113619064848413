import React, { useState, ReactNode } from "react";
import Button from "../atoms/Button";
import { t } from "../../pages/Translations/TranslationUtils";
import InputTextfield from "../atoms/InputTextField";
import LabelField from "../atoms/LabelField";
import { StageWiseTimeoutInputItemsProps } from "../../pages/BrandPartner/components/EighthTab";

type Props = {
  stageWiseTimeoutInputItems: StageWiseTimeoutInputItemsProps[];
  setStageWiseTimeoutInputItems: React.Dispatch<React.SetStateAction<StageWiseTimeoutInputItemsProps[]>>;
  timeoutErrors: { [key: string]: string };
  removeError: (name: string) => void;
};

const StageWiseTimeoutBody: React.FC<Props> = ({ stageWiseTimeoutInputItems, setStageWiseTimeoutInputItems, timeoutErrors, removeError }) => {

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    let newValue = e.target.value;

    newValue = newValue.replace(/^0+(?!\.|$)/, '') || '0';

    const isValidNumber = /^-?\d*(\.\d{0,1})?$/.test(newValue) && Number(newValue) <= 60;

    if (isValidNumber) {
      setStageWiseTimeoutInputItems((prevItems) =>
        prevItems.map((item) => {
          if (item.name1 === name) {
            return { ...item, value1: newValue };
          } else if (item.name2 === name) {
            return { ...item, value2: newValue };
          }
          return item;
        })
      );
    }
  };

  return (
    <table className="table table-hover">
      <thead>
        <tr className="border-0">
          <th className="border-0" style={{ width: "30%" }}>
            {t("Stage name")}
          </th>
          <th className="border-0" style={{ width: "30%" }}>
            {t("Show popup after")}
          </th>
          <th className="border-0" style={{ width: "30%" }}>
            {t("Abort activity and go to workflow overview after")}
          </th>
        </tr>
      </thead>
      <tbody>
        {stageWiseTimeoutInputItems.map((item, index) => (
          <>
            <tr className="border-0" key={index}>
              <td className="border-0">
                <LabelField title={item.label} mandatory/>
              </td>
              <td className="border-0">
                <InputTextfield
                  key={item.name1}
                  className="border rounded text-gray w-100 px-3 form-control"
                  placeholder={"00"}
                  name={item.name1}
                  id=""
                  value={item.value1}
                  handleChange={(e) => handleInputChange(e)}
                  handleClick={() => removeError(item.name1)}
                />
                {timeoutErrors[item.name1] && <span style={{ color: 'red' }}>{timeoutErrors[item.name1]}</span>}
              </td>
              <td className="border-0">
                <InputTextfield
                  key={item.name2}
                  className="border rounded text-gray w-100 px-3 form-control"
                  placeholder={"00"}
                  name={item.name2}
                  value={item.value2}
                  handleChange={(e) => handleInputChange(e)}
                  handleClick={() => removeError(item.name2)}
                />
                {timeoutErrors[item.name2] && <span style={{ color: 'red' }}>{timeoutErrors[item.name2]}</span>}
              </td>
            </tr>
          </>
        ))}
      </tbody>
    </table>
  );
};

export default StageWiseTimeoutBody;
