import React, { useRef, useEffect, useState, ChangeEvent } from 'react';
import { renderInput } from '../../services/InputUtils';
import { useNavigate } from 'react-router-dom';
import { getValidationLength, inputValidation } from '../../services/Validation/InputValidation';
import customAlert from '../../components/atoms/CustomAlert';
import CommonServices from '../../services/CommonServices';
import { AddWidget } from '../../static/icons/AddWidget';
import styles from "./masterdata.module.css"
import Close from '../../static/icons/Close';
import { createConfigData, fetchConfigData, fetchFormData, editConfigData } from '../../routes/ApiEndPoints';
import { APICALL } from '../../services/ApiServices';
import { t } from '../Translations/TranslationUtils';
import S3Services from '../../services/S3Services';
import { CommonConstants } from '../Shipment/Constants/ShipmentConstants';
import { ConfigDataHelpTexts } from '../../utils/constants/ConfigDataHelpTexts';
import AccessControl from '../../services/AccessControl';
import WindowHeightComponent from '../../commonComponents/WindowHeightComponent';

interface FormData {
  [key: string]: any;
}

const CreateMasterData: React.FC = () => {
  const [formInputs, setFormInputs] = useState<any[]>([]);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const form = urlParams.get("form");
  const id = urlParams.get("id");
  const search = urlParams.get("search");
  const [formData, setFormData] = useState<FormData>({});
  const [formErrors, setFormErrors] = useState({});
  const [state, setState] = useState({
    isSaving: false,
    statusChange: false,
    additionalInputsCount: 0
  })
  const [additionalInputs, setAdditionalInputs] = useState<any[]>([]);
  const [files, setSelectedFiles] = useState<any>({});

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field?: string
  ) => {
    if (e != null) {
      const { name, value } = e.target ?? e;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [field ?? name]: value,
      }));
    } else {
      field &&
        setFormData((prevFormData) => ({ ...prevFormData, [field]: null }));
    }
  };

  const handleMultiSelectChange = (selectedValues: number[], name: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: selectedValues,
    }));
  };

  useEffect(() => {
    fetchData(form);
  }, [form]);

  useEffect(() => {
    const storageMessage = localStorage.getItem("errorAlertMessage");
    if (storageMessage) {
      localStorage.removeItem("errorAlertMessage");
      customAlert("error", storageMessage, 6000);
    }
  }, [state.statusChange]);

  const fetchData = async (form: string | null) => {
    try {
      const postdata = {
        method: "POST",
        data: {
          form: form,
        },
      };
      const response = await APICALL.service(
        fetchFormData,
        "POST",
        postdata,
        true
      );

      if (response?.status !== 200) {
        throw new Error("Request failed");
      }
      setFormInputs(response.data);
      Object.values(response.data).forEach((value: any) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [value.name]: value.value,
        }));
      });

      if (id) {
        const postdata = {
          method: "POST",
          data: {
            type: "model",
            name: form && CommonServices.convertIntoModelName(form),
            id: id,
          },
        };
        const response = await APICALL.service(
          fetchConfigData,
          "POST",
          postdata,
          true
        );
        setFormData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validate = (formData: any) => {
    const error: any = [];
    const mergedArray = [
      ...formInputs,
      ...additionalInputs.flatMap((obj) => obj),
    ];
    let length: any = getValidationLength(form ?? "");

    Object.entries(formData).forEach(([key, value]) => {
      let input = mergedArray.filter((input: any) => input.name === key)[0];
      error[key] = input?.validation
        ? inputValidation(value, input.validation, key?.includes(length[0]) ? length[1] : 255)
        : "";
      setFormErrors((prevFormData) => ({ ...prevFormData, [key]: error[key] }));
    });
    if (Object.keys(error).length !== 0) {
      return Object.values(error).every((errorMessage) => errorMessage === "");
    }
    return false;
  };

  //To remove error
  const removeError = (fieldName: any) => {
    setFormErrors((prevFormData) => {
      if (!prevFormData) {
        return prevFormData;
      }
      return {
        ...prevFormData,
        [fieldName]: "", // Set the value of the field to an empty string
      };
    });
  };

  const addNew = () => {
    const duplicatedFormInputs = formInputs.map((input: any) => ({
      ...input,
      name: `${input.name}${state.additionalInputsCount + 1}`,
    }));
    setAdditionalInputs([...additionalInputs, duplicatedFormInputs]);
    setState((prevState: any) => ({
      ...prevState,
      additionalInputsCount: state.additionalInputsCount + 1
    }));
    Object.values(duplicatedFormInputs).forEach((value: any) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [value.name]: value.value,
      }));
    });
  };

  const removeField = (index: number) => {
    setAdditionalInputs((prevGroups) => {
      const removedInputs = prevGroups[index];
      const updatedGroups = prevGroups.filter((_, i) => i !== index);
      removedInputs.forEach((input: any) => {
        delete formData[input.name];
      });
      return updatedGroups;
    });
  };

  const groupVariables = (data: any) => {
    const groupedData: { [key: string]: any[] } = {};

    for (const key in data) {
      const matchedKey = RegExp(/(\D+)(\d*)$/).exec(key);
      if (matchedKey) {
        const groupName = matchedKey[1];
        const index = matchedKey[2];

        if (!groupedData[groupName]) {
          groupedData[groupName] = [];
        }

        groupedData[groupName][parseInt(index) || 0] = data[key];
      }
    }

    return groupedData;
  };

  const validateDuplicates = (arr: string[], isValidated: any): boolean => {     
    if (arr.length <= 1 || !isValidated) {
      return true;
    }
    const normalizedArray = arr.map(item => item.trim().toLowerCase());
    const uniqueSet = new Set(normalizedArray);
    const hasDuplicates = uniqueSet.size !== arr.length;
    if (hasDuplicates) {
        customAlert('error', t("Please remove duplicate titles"), 6000);
    }
    return !hasDuplicates;
  };

  const handleSubmit = async () => {
    let isValidated = validate(formData);
    const data = groupVariables(formData);
    let validatealltitles = validateDuplicates(data['title'], isValidated);    
    if (isValidated && validatealltitles) {
      setState((prevState: any) => ({
        ...prevState,
        isSaving: true
      }));
      const subObjectKeys: string[] = [];
      const formfields = {} as Record<string, any>;
      const path = "md/" + (form && CommonServices.capitalizeLabel(form)) + '/'

      Object.entries(data).forEach(([key, value]) => {
        if (Array.isArray(value[0])) {
          subObjectKeys.push(key);
        } else {
          formfields[key] = value;
        }
      });

      const subfields: Record<string, any> = {};
      subObjectKeys.forEach((key) => {
        subfields[key] = data[key];
      });


      let filesdet = groupVariables(files)
      CommonServices.toggleLoadingIcon('block')
      Object.entries(filesdet).forEach(async ([key, value]) => {
        if (value.length > 0) {
          formfields[key] = await S3Services.uploadToS3(Object.values(value), path)
          //    files[key] = formfields[key]
        }
      });
      CommonServices.toggleLoadingIcon('none')

      let filedata = Object.values(files)
      CommonServices.toggleLoadingIcon('block')
      if (filedata.length > 0) {
        let tst = await S3Services.uploadToS3(Object.values(files), path)
      }
      CommonServices.toggleLoadingIcon('none')

      try {
        if (id) {
          const postdata = {
            method: "POST",
            data: {
              model: form && CommonServices.convertIntoModelName(form),
              fields: formfields,
              subfields: Object.values(subfields)[0],
              id: id
            },
          };

          const response = await APICALL.service(
            editConfigData,
            "POST",
            postdata,
            true
          );
          if (response.status === 200) {
            let message: string = `${form
              ? CommonServices.capitalizeLabel(form)
              : ""
              } ${id ? t("updated") : t("created")} ${t("successfully")}`;
            localStorage.setItem("successAlertMessage", message);
            navigate(`/master-data/manage?type=${form}`);
          } else if (response.status === 409) {
            customAlert(
              'error',
              t('Title') + ': ' + Object.values(response.data).join(', ') + ' ' + t('already exists'),
              5000
            );
            setState((prevState: any) => ({
              ...prevState,
              isSaving: false
            }));
          } else {
            setState((prevState: any) => ({
              ...prevState,
              isSaving: false
            }));
          }
        } else {
          setState((prevState: any) => ({
            ...prevState,
            isSaving: false
          }));
          const postdata = {
            method: "POST",
            data: {
              model: form && CommonServices.convertIntoModelName(form),
              fields: formfields,
              subfields: Object.values(subfields)[0],
            }
          };
          const response = await APICALL.service(
            createConfigData,
            "POST",
            postdata,
            true
          );
          if (response?.status !== 200 && response.status !== 409) {
            throw new Error("Request failed");
          }
          if (response.status === 200) {

            let message: string = `${form
              ? CommonServices.capitalizeLabel(form)
              : ""
              } ${id ? "updated" : "created"} successfully`;
            localStorage.setItem("successAlertMessage", message);
            navigate(`/master-data/manage?type=${form}`);
          } else if (response.status === 409) {
            customAlert(
              'error',
              t('Title') + ': ' + Object.values(response.data).join(', ') + ' ' + t('already exists'),
              5000
            );
            setState((prevState: any) => ({
              ...prevState,
              isSaving: false
            }));
          } else {
            setState((prevState: any) => ({
              ...prevState,
              isSaving: false
            }));
          }
        }
      } catch (error) {
        setState((prevState: any) => ({
          ...prevState,
          isSaving: false,
          statusChange: !state.statusChange
        }));
        let message: string = `${t('Error while')} ${id ? t("updating") : t("creating")
          } ${form
            ? form
              .replace(/^cd_|_/g, (match: any) => (match === "_" ? " " : ""))
              .replace(/^\w/, (char: any) => char.toUpperCase())
            : ""
          }`;
        localStorage.setItem("errorAlertMessage", message);
        console.log(error);
      }
    }
  };

  const handleFileUpload = async (event: any) => {
    const fileList = event.target.files && event.target.files;

    if (fileList && fileList[0] != undefined) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [event.target.name]: URL.createObjectURL(fileList[0]),
      }));
      setSelectedFiles({
        ...files,
        [event.target.name]: fileList[0]
      });
      // reader.readAsDataURL(fileList[0]);
    }
  }

  const determineHandler = (type: any) => {
    if (type === "multi-select") {
      return handleMultiSelectChange;
    } else if (type == "file") {
      return handleFileUpload;
    } else {
      return handleInputChange;
    }
  }

  const handleBack = () => {
    if (search === 'true') {
      navigate(`/master-data/manage?type=${form}`);
    } else {
      navigate(`/master-data/manage`);
    }
  }

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "master data",
          create: true,
          update: true,
        },
      ]}
      renderNoAccess={true}
      strict={false}
      actions={true}
    >
      <div className="row">
        <div className="col-md-11 ms-auto px-4">
          <WindowHeightComponent>
            <div className="d-flex flex-column h-100 overflow-auto">
              <div className="py-xxl-4 py-xl-3 py-lg-3 page-title">
                {id ? t("Update") : t("Create")} {form?.replace(/_/g, " ")}
              </div>

              <div className="flex-1 overflow-auto">
                <div className="row m-0 create-form p-4 mb-4 position-relative">
                  <div className="col-10 m-auto">
                    {form && ConfigDataHelpTexts[form] !== '' && (
                      <span className='custom_astrick_before alignhelptext mb-3 fw-bold fst-italic'> {t(ConfigDataHelpTexts[form])}</span>
                    )}
                    <div className="row mt-2">
                      {Object.entries(formInputs).map(([key, value]) => (
                        <React.Fragment key={key}>
                          {renderInput(
                            removeError,
                            value,
                            determineHandler(value.type),
                            formData,
                            formErrors
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                    {additionalInputs.length > 0 && (
                      <div className="row">
                        {additionalInputs.map((value: any, index: number) => (
                          <div key={index} className="col-12">
                            <div className="row mx-0 border position-relative rounded py-4 p-3 mb-5">
                              <span
                                className={`${styles.close_btn}`}
                                onClick={() => removeField(index)}
                              >
                                <Close />
                              </span>
                              {Object.values(value).map((input: any) =>
                              (
                                renderInput(
                                  removeError,
                                  input,
                                  determineHandler(input.type),
                                  formData,
                                  formErrors
                                ))
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    {!id && (
                      <div className="d-flex justify-content-end">
                        <div
                          title={t("Add")}
                          className={`${styles.button_icon} cursor-pointer`}
                          onClick={addNew}
                        >
                          <AddWidget />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row m-0">
                <div className="col-md-6 float-start p-0">
                  <button
                    className="back-btn shadow-none float-start text-decoration-none"
                    onClick={handleBack}
                  >
                    {t(CommonConstants.BACK)}
                  </button>
                </div>
                <div className="col-md-6 float-end p-0">
                  <button
                    type="submit"
                    className="submit-btn shadow-none float-end"
                    disabled={state.isSaving}
                    onClick={handleSubmit}
                  >
                    {state.isSaving ? t("Saving...") : t("Save")}
                  </button>
                </div>
              </div>

            </div>
          </WindowHeightComponent>
        </div>
      </div>
    </AccessControl>
  );
};

export default CreateMasterData;
