import React, { useEffect, useState } from "react";
import { fetchList, getHoldRecords, rePublish } from "../../../routes/ApiEndPoints";
import { APICALL } from "../../../services/ApiServices";
import TableStructure from "../../atoms/TableStructure";
import {
  CommonConstants,
  ConfigConstants,
  HoldItemsConstants,
} from "../../../pages/Shipment/Constants/ShipmentConstants";
import CommonServices from "../../../services/CommonServices";
import CommonShipmentServices from "../../../services/CommonShipmentServices";
import Popup from "../../../utils/popup";
import { t } from "../../../pages/Translations/TranslationUtils";
import Pagination from "../../../services/Paginations";
import DownloadXlsx from "../../../services/DownloadXlsx";
import CustomFilterHeaderForReports from "../../molecules/CustomFilterHeaderForReports";
import { useUserContext } from "../../../routes/Contextlib";
import { useNavigate } from "react-router-dom";
import ActionButtonGroup from "../../molecules/ActionButtonGroup";
import MoveItemToAnyStage from "../MoveItemToAnyStage/MoveItemToAnyStage";
import IconWithLabel from "../../atoms/IconWithLabel";
import IconData from "../../../static/IconData";
import Button from "../../atoms/Button";
import WindowHeightComponent from "../../../commonComponents/WindowHeightComponent";
import BarcodeBody from "../BarcodeBody";
import FormValidation from "../../../services/FormValidation";
import customAlert from "../../atoms/CustomAlert";
import { initialvarstate } from "../../../pages/Shipment/DataEntry/State";

const ManageHoldItemsOrganism = () => {
  const [data, setData] = useState([]);
  const { user } = useUserContext();
  const navigate = useNavigate();
  const [disable, setDisable] = useState({
    disableButton: false,
  });
  const [printPopupData, setPrintPopupData] = useState({
    showPrintPopup: false,
    item: {},
    skuPrint: false,
  });
  const [republishData, setRepublishData] = useState({
    showRepublishPopup: false,
    item: {},
  });
  const [varState, setVarState] = useState(initialvarstate);
  const [state, setState] = useState({
    brandPartners: [],
    shipments: [],
    stages: [],
    productTiers: [],
    warehouseLocation: [],
    status: [
      { value: 1, label: t("Active") },
      { value: 2, label: t("Hold") },
      { value: 3, label: t("Published") },
      { value: 4, label: t("Rejected") },
    ],
    showPopup: false,
    movePopup: false,
    serialNumber: null,
    renewalid: null,
    stagename: "",
    totalRenewalRecordsLength: 0,
    currentpage: 1,
    totalPages: 1,
  });
  const [selectedFilterData, setSelectedFilterData] = useState({
    brand_partner_id: [],
    shipment_id: [],
    stage_id: [],
    tier: [],
    warehouse_location: [],
    status: [],
    search: "",
    offset: 0,
    limit: 10,
    orderby: "DESC",
    from_date: null,
    to_date: null,
    filter: "all",
  });

  const selectedColumns = [
    "serial_number",
    "brand_partner",
    "warehouse_location",
    "shipment_title",
    "tier_name",
    "stage_name",
    "status",
    "hold_by",
    "hold_on",
  ];

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (state.brandPartners?.length) {
      fetchHoldData();
    }
  }, [
    state.brandPartners,
    selectedFilterData.offset,
    selectedFilterData.orderby,
  ]);

  useEffect(() => {
    if (state.brandPartners?.length) {
      fetchHoldData("shipments");
    }
  }, [selectedFilterData.brand_partner_id]);

  const fetchData = async () => {
    try {
      let configreqdata = {
        method: "POST",
        data: {
          list: [
            ConfigConstants.PRODUCTTIER,
            ConfigConstants.BRANDPARTNER,
            ConfigConstants.ACTIVE_WAREHOUSELOCATION,
          ],
        },
      };
      const configres = await APICALL.service(
        fetchList,
        "POST",
        configreqdata,
        true
      );
      if (configres.status === 200) {
        setState((prevState) => ({
          ...prevState,
          warehouseLocation: CommonServices.sortAlphabattically(
            configres?.data?.WarehouseLocation
          ),
          productTiers: CommonServices.sortAlphabattically(
            configres?.data?.ProductTier
          ),
          brandPartners: CommonServices.sortAlphabattically(
            configres?.data?.BrandPartner
          ),
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchHoldData = async (filter?: any) => {
    try {
      setDisable((prev) => ({ ...prev, disableButton: true }));
      const postdata: any = { ...selectedFilterData };
      if (state.serialNumber) {
        postdata.serial_number =
          filter === "moveitemfromhold" ? "" : state.serialNumber;
        postdata.user_id = Number(localStorage?.getItem("user_id"));
      } else if (filter == "shipments") {
        postdata.filter = "shipments";
      } else if (filter === "apply") {
        postdata.offset = 0;
        postdata.limit = 10;
        setState((prev) => ({ ...prev, currentpage: 1 }));
      } else if (filter) {
        postdata.offset = 0;
        postdata.limit = filter;
      }
      if (postdata.from_date) {
        postdata.from_date = await CommonServices.getTimezoneOffset(
          postdata.from_date
        );
      }
      if (postdata.to_date) {
        postdata.to_date = await CommonServices.getTimezoneOffset(
          postdata.to_date
        );
      }
      const response = await APICALL.service(getHoldRecords, "POST", postdata);
      if (response.status === 200) {
        if (postdata.filter === "shipments") {
          setState((prevState) => ({
            ...prevState,
            shipments: CommonServices.sortAlphabattically(response?.shipments),
            serialNumber: null,
          }));
        } else {
          const updatedByIds = response?.data?.map((item: any) =>
            item?.updated_by ? Number(item.updated_by) : item?.updated_by
          );
          const fetchedUsernames = await CommonServices.fetchUsernamesByIds(
            updatedByIds
          );
          const totalPages = Math.ceil(
            response.totalRenewalRecordsLength / selectedFilterData.limit
          );
          if (selectedFilterData.filter === "all") {
            setState((prevState) => ({
              ...prevState,
              totalPages,
              shipments: CommonServices.sortAlphabattically(
                response?.shipments
              ),
              totalRenewalRecordsLength: response?.totalRenewalRecordsLength,
              stages: CommonServices.sortAlphabattically(
                response?.stages?.map((item: any) => ({
                  ...item,
                  stage_name: CommonServices.capitalizeLabel(
                    item?.stage_name ?? ""
                  ),
                })),
                "stage_name"
              ),
              serialNumber: null,
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              totalPages,
              totalRenewalRecordsLength: response?.totalRenewalRecordsLength,
              serialNumber: null,
            }));
          }
          // Filter items where "tier" is "active" and update stage_name
          const filteredData = response?.data.map((item: any) => {
            // Modify the stage_name property
            item.stage_name = CommonServices?.capitalizeLabel(
              item?.stages?.stage_name ?? ""
            );
            const warehouse_location = item.renewal_product_info
              ?.warehouse_location
              ? CommonShipmentServices.getFilteredTitle(
                state.warehouseLocation,
                "warehouse_location_id",
                item?.renewal_product_info?.warehouse_location
              )
              : "-";
            const tier_name = item.tier
              ? CommonShipmentServices.getFilteredTitle(
                state.productTiers,
                "product_tier_id",
                item.tier
              )
              : "-";
            const hold_on = item.updatedAt
              ? CommonServices.showDateBasedOnFormat(
                item.updatedAt,
                user?.date_format ?? "dd-MM-yyyy",
                true
              )
              : "-";
            const hold_by = item.updated_by
              ? fetchedUsernames[item.updated_by]
              : "-";
            const brand_partner = item.brand_partner_id
              ? CommonShipmentServices.getFilteredTitle(
                state.brandPartners,
                "brand_partner_id",
                item.brand_partner_id
              )
              : "-";
            const shipment_title = item.shipments?.title || "-";
            const icon = fetchIconData(item);
            const status = CommonServices.capitalizeLabel(item.status);
            return {
              ...item,
              icon,
              brand_partner: brand_partner[0],
              shipment_title,
              tier_name: tier_name[0],
              hold_on,
              warehouse_location: warehouse_location[0],
              hold_by,
              status,
            };
          });

          if (
            filter &&
            filter !== "shipments" &&
            filter !== "moveitemfromhold" &&
            filter !== "apply"
          ) {
            const selectedFields = filteredData.map((obj: any) =>
              selectedColumns.reduce((acc: any, column: string) => {
                acc[column] = obj[column];
                return acc;
              }, {})
            );
            DownloadXlsx(selectedFields, "hold_overview");
          } else {
            // Set the filtered data as the state
            setData(filteredData);
          }
          if (selectedFilterData.filter === "all") {
            setSelectedFilterData((prevData) => ({
              ...prevData,
              filter: "",
            }));
          } else if (filter === "apply") {
            setSelectedFilterData((prevData) => ({
              ...prevData,
              offset: 0,
              limit: 10,
            }));
          }
        }
      }
      setDisable((prev) => ({ ...prev, disableButton: false }));
    } catch (error) {
      setDisable((prev) => ({ ...prev, disableButton: false }));
      console.log(error);
    }
  };

  const handleView = async (value: any) => {
    localStorage.setItem("bpid", value.brand_partner_id);
    navigate(`/reports/view-renewal-record?edit=${value.renewal_id}`);
  };

  const fetchIconData = (item: any) => {
    // Update the status if conditions are met
    if (
      item.status === "rejected" &&
      item?.stages?.stage_name === "publishing"
    ) {
      item.status = "active";
    }

    // Define the button based on the item status
    let statusButton;
    switch (item.status) {
      case "active":
        statusButton = (
          <Button
            title={IconData.Openeye}
            hoverText={t("View")}
            className={
              "px-0 border-0 bg-transparent shadow-none manage-users-action me-4"
            }
            handleClick={() => handleView?.(item)}
          />
        );
        break;
      case "hold":
        statusButton = (
          <Button
            title={
              <IconWithLabel
                hoverText={t("Move item back to workflow")}
                svgData={IconData.MoveItemBackToWorkflow}
              />
            }
            className={
              "me-2 border-0 bg-transparent shadow-none manage-users-action"
            }
            handleClick={() => handlePopup(item)}
          />
        );
        break;
      case "rejected":
        statusButton = (
          <Button
            title={
              <IconWithLabel
                hoverText={t("Move item back to workflow")}
                svgData={IconData.MoveItemBackToWorkflow}
              />
            }
            className={
              "me-2 border-0 bg-transparent shadow-none manage-users-action"
            }
            handleClick={() => handlePopup(item)}
          />
        );
        break;
      case "published":
        statusButton = (
          <>
            <Button
              title={IconData.Openeye}
              hoverText={t("View")}
              className={
                "px-0 border-0 bg-transparent shadow-none manage-users-action printerIcon me-3"
              }
              handleClick={() => handleView?.(item)}
            />
            {(user?.roles?.some(
              (role: any) =>
                role.toLowerCase().trim() === process.env.REACT_APP_ADMIN || role.toLowerCase().trim() === process.env.REACT_APP_SUPERVISOR
            )) &&
              <Button
                title={IconData.Pre_publish}
                hoverText={t("Republish")}
                className={
                  "px-0 border-0 bg-transparent shadow-none manage-users-action printerIcon me-3"
                }
                handleClick={() => setRepublishPopup(item)}
              />
            }
          </>
        );
        break;
      default:
        statusButton = null;
    }

    // Return both the status button and the print barcode icon
    return (
      <>
        {statusButton}
        <Button
          title={IconData.PrinterIcon}
          hoverText={t("Print label")}
          className={
            "px-0 border-0 bg-transparent shadow-none manage-users-action printerIcon"
          }
          handleClick={() => setPrintPopup(item)}
        />
      </>
    );
  };

  const getSelectOptions = (data: any, id: any, label: any) => {
    const convertedData: any = Object.values(data).map((item: any) => ({
      value: item[id],
      label: item[label],
    }));
    return convertedData;
  };

  const handleSelectedFilter = (value: any, key: string) => {
    setSelectedFilterData((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };
  const handleClearFilter = () => {
    setSelectedFilterData((prevValues) => ({
      ...prevValues,
      brand_partner_id: [],
      shipment_id: [],
      stage_id: [],
      tier: [],
      warehouse_location: [],
      status: [],
      search: "",
      offset: 0,
      limit: 10,
      orderby: "DESC",
      from_date: null,
      to_date: null,
      filter: "all",
    }));
  };
  // Define an array of filter objects with properties for rendering filters
  const filters: any = [
    {
      type: "multiSelect",
      label: t("Brand partner"),
      key: "brand_partner_id",
      selected: selectedFilterData.brand_partner_id,
      options: getSelectOptions(
        state.brandPartners,
        "brand_partner_id",
        "title"
      ),
    },
    {
      type: "multiSelect",
      label: t("Shipments"),
      key: "shipment_id",
      selected: selectedFilterData.shipment_id,
      options: getSelectOptions(state.shipments, "shipment_id", "title"),
    },
    {
      type: "multiSelect",
      label: t("Stage"),
      key: "stage_id",
      selected: selectedFilterData.stage_id,
      options: getSelectOptions(state.stages, "stage_id", "stage_name"),
    },
    {
      type: "multiSelect",
      label: t("Product tier"),
      key: "tier",
      selected: selectedFilterData.tier,
      options: getSelectOptions(state.productTiers, "product_tier_id", "title"),
    },
    {
      type: "multiSelect",
      label: t("Warehouse location"),
      selected: selectedFilterData.warehouse_location,
      key: "warehouse_location",
      options: getSelectOptions(
        state.warehouseLocation,
        "warehouse_location_id",
        "title"
      ),
    },
    {
      type: "multiSelect",
      label: t("status"),
      key: "status",
      selected: selectedFilterData.status,
      options: getSelectOptions(state.status, "value", "label"),
    },
    {
      type: "calender",
      label: t("From date"),
      key: "from_date",
      selected: selectedFilterData.from_date,
      max_date: selectedFilterData.to_date,
    },
    {
      type: "calender",
      label: t("To date"),
      key: "to_date",
      selected: selectedFilterData.to_date,
      min_date: selectedFilterData.from_date,
    },
    {
      type: "text",
      label: t("Search by serial number"),
      key: "search",
      selected: selectedFilterData.search,
    },
  ];

  const moveItemBack = async () => {
    await fetchHoldData();
    setState((prev) => ({ ...prev, showPopup: false, serialNumber: null }));
  };

  const handlePopup = (item: any) => {
    if (item.qa_failed) {
      setState((prev) => ({
        ...prev,
        movePopup: true,
        renewalid: item.renewal_id,
        serialNumber: item.serial_number,
        stagename: CommonServices.removeSpaceAndAddUnderscore(item.stage_name),
      }));
    } else {
      setState((prev) => ({
        ...prev,
        showPopup: true,
        serialNumber: item.serial_number,
      }));
    }
  };

  const setPrintPopup = (item: any) => {
    setPrintPopupData((prev: any) => ({
      ...prev,
      showPrintPopup: true,
      item: item,
      skuPrint: !!item?.renewal_merch_rack_info?.merch_rack,
    }));
  };

  const setRepublishPopup = (item: any) => {
    setRepublishData((prev: any) => ({
      ...prev,
      showRepublishPopup: true,
      item: item,
    }));
  };

  const handlePrint = async (data: any, printType: any, item: any) => {
    try {
      let postData;
      if (printType === 1) {
        postData = {
          printerName: data?.inputValue?.trim(),
          labelType: "SerialNumber",
          labelData: {
            serialNumber: item?.serial_number,
          },
        };
      } else if (printType === 2) {
        postData = {
          printerName: data?.inputValue?.trim(),
          labelType: "SKU",
          labelData: {
            style: item?.renewal_product_info?.style,
            skuId: item?.renewal_info?.sku?.toUpperCase(),
            description: item?.renewal_info?.product_description,
            serialNumber: item?.serial_number,
          },
        };
      }

      let error: any = validatepostdata(postData);
      if (!error) {
        let res: any = await CommonServices.printLabelApi(postData);
        res?.message && customAlert(res?.type, t(res?.message), 6000);
        if (res.statusCode === 201) {
          setVarState((prev) => ({ ...prev, viewBarcode: false }));
        }
      } else {
        let errortext = Object.keys(error)
          .filter((key) => error[key] !== "")
          .map((key) => `<div>${t(error[key])}</div>`)
          .join("");
        errortext && customAlert("error", errortext, 6000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRepublish = async (item: any) => {
    try {
      item.bypasspermission = true;
      item.userid = user?.user_id;
      const response = await APICALL.service(
        rePublish,
        "POST",
        item
      );
      if (response.status === 200) {
        let navigateurl = "/" + response?.navigateUrl;
        let bpdata = response?.bpdata?.first[0];
        bpdata.filepath = response?.bpdata?.first[2] !== null ? response?.bpdata?.first[2]?.file_path : '';
        localStorage.setItem("bp_details", JSON.stringify(bpdata));
        localStorage.setItem("bp_id", JSON.stringify(bpdata?.brand_partner_id));
        localStorage.setItem("bp_product_group", JSON.stringify(response?.bpdata?.first[1]));
        localStorage.setItem("bp_permissions_details", JSON.stringify(response?.bpdata?.sixth));
        if (localStorage?.getItem("shipment_details")) {
          localStorage.removeItem("shipment_details");
        }
        localStorage.setItem('singleshipment', 'true');
        localStorage.setItem('shipment_dashboard_url', '/shipment-dashboard');
        localStorage.removeItem("trackTime")
        navigate(navigateurl);
      } else {
        customAlert("error", t(response.message), 6000);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const validatepostdata = (data: any) => {
    let error = {
      printerName: FormValidation.nameValidation(
        data?.printerName,
        255,
        "Printer name"
      ),
      serialNumber: FormValidation.nameValidation(
        data?.labelData?.serialNumber,
        8,
        "Serial number"
      ),
    };
    return Object.values(error)?.some((item) => item !== "") ? error : false;
  };

  const handlePaginationClick = async (newPage: any) => {
    if (newPage === "sort") {
      setSelectedFilterData((prevData) => ({
        ...prevData,
        offset: 0,
        orderby: selectedFilterData.orderby === "ASC" ? "DESC" : "ASC",
      }));
      setState((prev) => ({
        ...prev,
        currentpage: 1,
      }));
    } else {
      // Calculate new offset based on the new page and items per page
      const newOffset = (newPage - 1) * selectedFilterData.limit;
      // Update current page and offset in the state
      setSelectedFilterData((prevData) => ({
        ...prevData,
        offset: newOffset,
      }));
      setState((prev) => ({
        ...prev,
        currentpage: newPage,
      }));
    }
  };

  return (
    <div className="row">
      <div className="col-11 ms-auto px-4">
        <WindowHeightComponent>
          <div className="d-flex flex-column overflow-auto h-100">
            <div className="py-xxl-4 py-xl-3 py-lg-3 page-title">
              {t(HoldItemsConstants.HOLDOVERVIEW)}
            </div>
            <div>
              <CustomFilterHeaderForReports
                filters={filters}
                handleSelectedFilter={handleSelectedFilter}
                // clearFilter={handleClearFilter}
                applyFilter={() => fetchHoldData("apply")}
                handleDownload={() =>
                  fetchHoldData(state.totalRenewalRecordsLength)
                }
              />
            </div>

            <div className="flex-1 overflow-auto">
              {data?.length <= 0 ? (
                <span className="">{t("No result found")}</span>
              ) : (
                <TableStructure
                  // isAction
                  // handleMoveBackItem={handlePopup}
                  data={data}
                  headers={HoldItemsConstants.HOLD_OVERVIEW_TABLE_HEADERS}
                  values={HoldItemsConstants.HOLD_OVERVIEW_TABLE_VALUES}
                  handleSort={handlePaginationClick}
                  sort={selectedFilterData.orderby}
                  actionClass=""
                />
              )}
            </div>
            <div className="position-relative">
              {state.totalRenewalRecordsLength > selectedFilterData.limit &&
                state.totalPages > 1 && (
                  <Pagination
                    currentPage={state.currentpage}
                    totalPages={state.totalPages}
                    handlePaginationClick={handlePaginationClick}
                  />
                )}
              <ActionButtonGroup
                backTitle={t("Back")}
                handleBackClick={() => navigate("/reports")}
              />
            </div>
          </div>
        </WindowHeightComponent>
      </div>
      {state.showPopup && (
        <Popup
          title={t("Action")}
          body={t("Do you want to return this item to the workflow") + "?"}
          cancel={() =>
            setState((prev) => ({
              ...prev,
              showPopup: false,
              serialNumber: null,
            }))
          }
          submit={() => (disable.disableButton ? undefined : moveItemBack())}
          yestext={t(CommonConstants.SAVE)}
          notext={t(CommonConstants.CANCEL)}
        />
      )}
      {state.movePopup && (
        <Popup
          title={t("Are you sure you want to move this item") + "?"}
          body={
            <MoveItemToAnyStage
              renewalid={state.renewalid}
              stagename={state.stagename}
              setShowMoveItemPopup={() =>
                setState((prev) => ({
                  ...prev,
                  movePopup: false,
                  serialNumber: null,
                }))
              }
              fetchHoldData={() => fetchHoldData("moveitemfromhold")}
            />
          }
          modalSize="xl"
          bodyclassName={"moving_item_popup"}
          cancel={() =>
            setState((prev) => ({
              ...prev,
              movePopup: false,
              serialNumber: null,
            }))
          }
        />
      )}
      {printPopupData.showPrintPopup && (
        <Popup
          body={
            <BarcodeBody
              handlePrint={handlePrint}
              holdItemPrint={{
                holdItem: true,
                skuPrint: printPopupData.skuPrint,
              }}
              item={printPopupData.item}
            />
          }
          title={t("Print barcode")}
          cancel={() => {
            setPrintPopupData((prevState) => ({
              ...prevState,
              showPrintPopup: false,
            }));
          }}
        />
      )}
      {republishData.showRepublishPopup && (
        <Popup
          body={t("Are you sure you want to republish?")}
          title={t("Republish item")}
          yestext={t("Yes, edit & republish")}
          notext={t("No, cancel")}
          submit={() => {
            handleRepublish(republishData.item);
          }}
          cancel={() => {
            setRepublishData((prevState) => ({
              ...prevState,
              showRepublishPopup: false,
            }));
          }}
        />
      )}
    </div>
  );
};

export default ManageHoldItemsOrganism;
