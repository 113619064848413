import React from 'react';
import { t } from '../pages/Translations/TranslationUtils';
import { AiFillCloseCircle } from 'react-icons/ai';
import Button from '../components/atoms/Button';
import { CONSTANTS } from '../applicationConstants/AppConstants';

interface Props {
    display: string
}

const TooManyRequestsPopup: React.FC<Props> = (props) => {
    const close = (refresh: boolean) => {
        const toomanyrequestspopup = document.getElementById("429-popup-div-id");
        if (toomanyrequestspopup !== null)
            toomanyrequestspopup.setAttribute("style", "display:none;");
        if (refresh)
        window.location.reload();
    }

    return (
        <div id="429-popup-div-id" style={{ display: props.display }}>
            <div className="disable-screen justify-content-center align-items-center">
                <div className="too-many-requests-popup rounded shadow p-3">
                    <div className="text-end">
                        <Button
                            className='too-many-requests-popup-close'
                            handleClick={() => close(false)}
                            title={<AiFillCloseCircle />}
                        />
                    </div>
                    <div className="mb-3 px-0 text-break text-center modal-body">
                        {t(CONSTANTS.TOO_MANY_REQUEST_POPUP_TEXT)}
                    </div>
                    <div className="text-center">
                        <Button
                            className='submit-btn'
                            title={t(CONSTANTS.RELOAD)}
                            handleClick={() => close(true)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TooManyRequestsPopup
