import React from 'react'

const Managetranslation = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="65" height="70" viewBox="0 0 64.502 62.832">
        <path id="Union_23" data-name="Union 23" d="M38.373,62.832,46.6,53.219l.226-.226.1-.214a3.213,3.213,0,0,0,.326-.654,4.045,4.045,0,0,0,.163-.641l.088-.541-.577-2.488L33.957,10.832H56.964A7.552,7.552,0,0,1,64.5,18.385V55.267a7.563,7.563,0,0,1-7.538,7.565ZM51.908,27.394l-3.851,9.224v.012L46.351,40.7a1.261,1.261,0,0,0,.677,1.646,1.228,1.228,0,0,0,1.63-.665l1.392-3.318h6.034l1.38,3.318a1.252,1.252,0,0,0,1.154.766,1.137,1.137,0,0,0,.49-.1,1.245,1.245,0,0,0,.664-1.646l-1.693-4.071-.012-.012-3.851-9.224a1.232,1.232,0,0,0-1.154-.767A1.26,1.26,0,0,0,51.908,27.394Zm-17.6,35.424a1.232,1.232,0,0,1-.966-.741L28.952,52.012H7.527A7.553,7.553,0,0,1,0,44.447V7.553A7.55,7.55,0,0,1,7.527,0H26.656a1.261,1.261,0,0,1,1.192.841L44.9,50.24a.216.216,0,0,1,.037.126.359.359,0,0,1,.05.189.538.538,0,0,1,.026.2.574.574,0,0,1-.026.214.207.207,0,0,1-.037.113.929.929,0,0,1-.189.426c-.012.026-.037.038-.05.063l-9.27,10.82a1.227,1.227,0,0,1-.954.44A.762.762,0,0,1,34.309,62.818ZM25.151,35.763a2.261,2.261,0,0,0,.275.025,1.26,1.26,0,0,0,.264-2.488,12.7,12.7,0,0,1-6.16-3.568,29.469,29.469,0,0,0,4.152-6.862H27.5a1.257,1.257,0,0,0,0-2.513H19.092V17.48a1.254,1.254,0,1,0-2.509,0v2.877H8.166a1.257,1.257,0,0,0,0,2.513H11.98a30.365,30.365,0,0,0,4.165,6.874A12.816,12.816,0,0,1,9.972,33.3a1.26,1.26,0,0,0,.277,2.488,1.881,1.881,0,0,0,.263-.025,15.262,15.262,0,0,0,7.326-4.172A15.223,15.223,0,0,0,25.151,35.763Zm25.942.088,1.969-4.713,1.969,4.713ZM14.752,22.87h6.171a26.985,26.985,0,0,1-3.085,4.951A27.686,27.686,0,0,1,14.752,22.87Z" fill="#545960" />
      </svg>
    </>
  )
}

export default Managetranslation

