import React, { useEffect, useState } from "react";
import styles from "../../pages/Shipment/shimpment.module.css";
import MinusIcon from "../../static/icons/shipment/Minus";
import { Add } from "../../static/icons/shipment/Add";
import InputTextfield from "../atoms/InputTextField";
import Button from "../atoms/Button";

interface SortCounterProps {
  count: number;
  upperLimit?: number;
  onCountChange: (newCount: number) => void;
  timer: boolean;
  disablePlusMinus?: boolean;
}

const SortCounter: React.FC<SortCounterProps> = ({
  count,
  upperLimit,
  onCountChange,
  timer,
  disablePlusMinus = false
}) => {
  const [localCount, setLocalCount] = useState("");

  useEffect(() => {
    setLocalCount(count.toString())
  }, [count])

  const handleCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (timer) {
      const newValue = event.target?.value;
      const sanitizedValue = newValue?.replace(/\D/g, '').slice(0, 5);
      // If the input is empty or not a number, set the value to 0.
      const newCount = sanitizedValue ? parseInt(sanitizedValue, 10) : 0;
      // setLocalCount(newCount.toString());
      onCountChange(newCount);
    }
  };

  const handleIncrement = () => {
    if (timer) {
      const newCount = Math.min(count + 1, upperLimit ?? 99999);
      onCountChange(newCount);
      // setLocalCount(newCount.toString());
    }
  };

  const handleDecrement = () => {
    if (timer) {
      const newCount = Math.max(0, count - 1);
      onCountChange(newCount);
      // setLocalCount(newCount.toString());
    }
  };

  return (
    <div className={`my-xxl-5 my-xl-3 ${styles.plus_minus}`}>
      { !disablePlusMinus && <div className="my-3">
        <Button handleClick={handleDecrement} className="m-0 p-0 border-0">
          <MinusIcon />
        </Button>
      </div>}
      <div className={`rounded m-auto width_maxcontent ${styles.countnum}`}>
        <InputTextfield
          type="text"
          value={localCount}
          handleChange={handleCountChange}
          className={`${styles.countInput} ${disablePlusMinus ? styles.disableCountInput :""} form-control p-2`}
          readOnly={disablePlusMinus}
        />
      </div>
      { !disablePlusMinus && <div className="mt-3 mb-0">
        <Button handleClick={handleIncrement} className="m-0 p-0 border-0">
          <Add />
        </Button>
      </div>}
    </div>
  );
};

export default SortCounter;
