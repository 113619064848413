import React, { useEffect, useState } from 'react';
import DivButtonAtom from '../atoms/DivButtonAtom';
import styles from '../../pages/Shipment/QualityControl/Qualitycontrol.module.css';
import CommonServices from '../../services/CommonServices';
interface ItemList {
  id: number;
  title: string;
  isSelected: boolean;
  color: string;
}

interface DivButtonMoleculeProps {
  title: string,
  parentHandler: any,
  selectedItem: any,
  list?: any
}

const DivButtonMolecule: React.FC<DivButtonMoleculeProps> = ({ title, parentHandler, selectedItem, list }) => {
  const [items, setItems] = useState<ItemList[]>([]);

  useEffect(() => {
    getIssueTypeList();
  }, []);

  const getIssueTypeList = async () => {
    const mappedArray: ItemList[] = list.map((item: any) => ({
      id: Number(item.issue_type_id),
      title: item.title,
      isSelected: (selectedItem != null && item.issue_type_id == selectedItem),
      color: item.color
    }));

    setItems(mappedArray);
  }

  const handleSelectIssue = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Create a new array with the updated isSelected value for the specific id
    const updatedItems = items.map((item) => {

      if (item.id === Number(event.target.id)) {
        if (parentHandler && !item.isSelected) {
          parentHandler({
            id: item.id,
            text: item.title,
            color: item.color
          });
        } else {
          parentHandler();
        }
        return { ...item, isSelected: !item.isSelected };

      } else {
        return { ...item, isSelected: false };
      }
    });
    setItems(updatedItems);
  };

  return (
    <div>
      <h1>{title}</h1>
      <div className="d-flex flex-wrap mt-4 ">
        {items.map((item) => (
          <DivButtonAtom
            key={item.id}
            id={String(item.id)}
            title={item.title}
            blockStyle={(item.isSelected) ? styles.activeblocks : styles.closeblock}
            handleTriggerEvent={handleSelectIssue}
          />
        ))}
      </div>
    </div>
  );
};

export default DivButtonMolecule;
