import React from 'react'

const Recycle = () => {
  return (
    <>
      <svg id="Group_48464" data-name="Group 48464" xmlns="http://www.w3.org/2000/svg" width="188.3" height="160.655" viewBox="0 0 188.3 160.655">
        <rect id="Rectangle_10700" data-name="Rectangle 10700" width="137.715" height="98.18" transform="translate(24 53.758)" fill="#d3985a" />
        <ellipse id="Ellipse_229" data-name="Ellipse 229" cx="94.15" cy="8.716" rx="94.15" ry="8.716" transform="translate(0 143.223)" fill="rgba(84,89,96,0.06)" />
        <g id="Group_48443" data-name="Group 48443" transform="translate(10.664 0)">
          <rect id="Rectangle_10698" data-name="Rectangle 10698" width="137.715" height="10.631" transform="translate(13.906 53.759)" fill="#fff" opacity="0.09" />
          <rect id="Rectangle_10699" data-name="Rectangle 10699" width="122.852" height="53.759" transform="translate(21.329)" fill="rgba(211,152,90,0.9)" />
          <path id="Path_56292" data-name="Path 56292" d="M286.14,243.529l12.243-18.659,9.086,18.659L293.58,270.416Z" transform="translate(-141.959 -216.658)" fill="rgba(211,152,90,0.9)" />
          <path id="Path_56293" data-name="Path 56293" d="M221.619,243.529,209.376,224.87l-9.086,18.659L214.2,270.416Z" transform="translate(-200.29 -216.658)" fill="rgba(211,152,90,0.9)" />
          <path id="Path_56294" data-name="Path 56294" d="M358.079,231.49H203.57l8.4,34.429H349.682Z" transform="translate(-198.061 -212.16)" fill="#d3985a" />
        </g>
        <path id="Recycle_01" d="M100.319,7.939c.817,0,1.8.164,2.779.164l1.634-4.74,6.7,11.114L99.338,19.053l1.471-4.086c-.164,0-.327-.164-.49-.164a15.4,15.4,0,0,0-12.584,6.537l-2.125-5.72-3.922,2.452A22.2,22.2,0,0,1,100.319,7.939Zm15.69,33.5-4.576-12.095,4.249,1.471v-.49a15.7,15.7,0,0,0-6.374-12.584l5.557-2.125L112.412,11.7a22.2,22.2,0,0,1,10.133,18.631,16.787,16.787,0,0,1-.164,2.779l4.9,1.634-11.277,6.7ZM89.041,46.02,101.3,41.444l-1.471,4.249h.49a15.622,15.622,0,0,0,12.421-6.374l2.124,5.557,4.086-2.452a22.644,22.644,0,0,1-18.631,10.133,14.936,14.936,0,0,1-2.779-.164l-1.8,4.9-6.7-11.277ZM84.465,19.052,89.041,31.31l-4.086-1.471c0,.164-.164.327-.164.49A15.326,15.326,0,0,0,91.329,42.75l-5.72,2.125,2.452,4.086A22.644,22.644,0,0,1,77.927,30.33c0-.981.164-1.961.164-2.779l-4.74-1.8,11.114-6.7Z" transform="translate(-7.352 73.037)" fill="#fff" />
      </svg>
    </>
  )
}

export default Recycle

