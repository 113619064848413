import React from 'react'
type Props = {
    img: string;
    altText?: string;
};

const Cards: React.FC<Props> = ({ img, altText }) => {
    return (
        <div className="scards d-flex justify-content-center align-items-center border rounded shadow">
            <img src={img} alt={altText} className="object-fit-contain img-fluid" title={altText} />
        </div>
    )
}

export default Cards