import { useReducer, useEffect, useState } from "react";
import { UTILITYFN } from "../utility/Utility";
import styles from "../../brandpartner.module.css";
import LabelField from "../../../../components/atoms/LabelField";
import { GenerateRefreshToken } from "../../../../services/RefreshTokenService";
import { useNavigate } from "react-router-dom";
import { APICALL } from "../../../../services/ApiServices";
import { fetchWorkflowStages, getUsernames } from "../../../../routes/ApiEndPoints";
import { t } from "../../../Translations/TranslationUtils";
import ActionButtonGroup from "../../../../components/molecules/ActionButtonGroup";
import { OptionValue } from "../../../../components/atoms/MultiSelectField";
import { Stage } from "../SixthTab";
import CommonServices from "../../../../services/CommonServices";
import customAlert from "../../../../components/atoms/CustomAlert";
import ColorMolecule from "../../../../components/molecules/ColorMolecule";
import IconData from "../../../../static/IconData";
import { selectedIssueInterphase, StageWiseTimeoutInputItemsProps } from "../EighthTab";
import Button from "../../../../components/atoms/Button";

interface Props {
  draftSaved: boolean;
  saveAsDraft: string;
  updateButton: string;
  saveAndNext: string;
  backbutton: string;
  formPageState?: any;
  dispatchFormPageState?: any;
  setPageDirection: (page: number) => void;
}

interface FormState {
  optionalEmail: string;
  userApproval: string;
}

const initialForm: FormState = {
  optionalEmail: "",
  userApproval: "",
};

function NinthTab(props: Props) {
  const {
    draftSaved,
    saveAsDraft,
    saveAndNext,
    updateButton,
    backbutton,
    formPageState,
    dispatchFormPageState,
    setPageDirection
  } = props;
  type MyState = {
    GeneralPropertiesState: {
      title: string;
      warehouse_location: string;
      wms_bp_id: string;
      product_group: object;
      product_type: object;
      issue_type: object;
      work_area: object;
      brand_logo: string;
      isSku: boolean;
      tier3_publishable: boolean;
      product_tier: string;
    };
    colorState: {
      bp_color: object;
    };
    sizeState: {
      bp_size: object;
    };
    dimensionsState: {
      bp_dimension: object;
    };
    permissionState: {
      bpPermission: {
        stage_id: {
          users: OptionValue[];
        };
      },
    },
    resalePriceState: {
      resale_price: object;
      [key: string]: string | object;
    };
    merchRackState: {
      bp_merch_rack: object;
    };
    workflowState: {
      stageWiseTimeout: StageWiseTimeoutInputItemsProps[];
      predefinedIssues: selectedIssueInterphase[];
    };
  };

  const initialState: MyState = {
    GeneralPropertiesState: {
      title: "",
      warehouse_location: "",
      wms_bp_id: "",
      product_group: {},
      product_type: {},
      issue_type: {},
      work_area: {},
      brand_logo: "",
      isSku: false,
      tier3_publishable: false,
      product_tier: ''
    },
    colorState: {
      bp_color: {},
    },
    sizeState: {
      bp_size: {},
    },
    dimensionsState: {
      bp_dimension: {},
    },
    permissionState: {
      bpPermission: {
        stage_id: {
          users: []
        }
      },
    },
    resalePriceState: {
      resale_price: {},
    },
    merchRackState: {
      bp_merch_rack: {}
    },
    workflowState: {
      stageWiseTimeout: [],
      predefinedIssues: []
    },
  }
  const [previewState, setPreviewState] = useState<MyState>(initialState);
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const viewId = Number(searchParams.get("view"));
  const editId = viewId != 0 ? viewId : Number(searchParams.get("edit")); const [stages, setStages] = useState<Stage[]>([]);
  const [userOptions, setUserOptions] = useState<OptionValue[]>([]);
  window.addEventListener("beforeunload", () => {
    sessionStorage.clear();
  });

  useEffect(() => {
    fetchOperators();
    fetchStages();
  }, []);

  const fetchOperators = async () => {
    try {
      const postData = {
        method: 'POST',
        data: {
          permissions: ['workflow']
        }
      };
      const response = await APICALL.service(getUsernames, 'POST', postData, true);
      if (response?.status === 200) {
        const operatorUsers = response?.data?.map((user: any) => ({
          value: user?.user_id,
          label: user?.full_name
        }));
        // Sort the operatorUsers array alphabetically by the label property
        operatorUsers?.sort((a: any, b: any) => a?.label.localeCompare(b?.label));
        setUserOptions(operatorUsers);
      }
    } catch (error) {
      console.error("Error fetching operators:", error);
    }
  };

  const fetchStages = async () => {
    try {
      const response = await APICALL.service(fetchWorkflowStages, 'GET', '');
      if (response?.status === 200) {
        setStages(response.data);
      }
    } catch (error) {
      console.error("Error fetching stages:", error);
    }
  };

  useEffect(() => {
    if (sessionStorage?.getItem("GeneralPropertiesState")) {
      let data = JSON.parse(sessionStorage.getItem("GeneralPropertiesState")!);
      if (Object.keys(data).length > 0) {
        setPreviewState((prevState) => ({
          ...prevState,
          GeneralPropertiesState: {
            ...prevState.GeneralPropertiesState,
            title: data.title,
            warehouse_location: data.warehouse_location?.label,
            wms_bp_id: data.wms_bp_id,
            product_group: data.product_group,
            product_type: data.product_type,
            issue_type: data.issue_type,
            work_area: data.work_area,
            brand_logo: data.brand_logo,
            isSku: data.isSku,
            tier3_publishable: data.tier3_publishable,
            product_tier: data.product_tier?.label
          },
        }));
      }
    }

    if (sessionStorage?.getItem("colorState")) {
      let data = JSON.parse(sessionStorage.getItem("colorState")!);

      setPreviewState((prevState) => ({
        ...prevState,
        colorState: {
          ...prevState.colorState,
          bp_color: data.colors,
        },
      }));
    }

    if (sessionStorage?.getItem("sizeState")) {
      let data = JSON.parse(sessionStorage.getItem("sizeState")!);
      const groupedSizes: { [key: string]: any[] } = {};

      Object.values(data).forEach((item: any) => {
        item.forEach((item1: any) => {
          if (!groupedSizes[item1.category_title]) {
            groupedSizes[item1.category_title] = [];
          }
          groupedSizes[item1.category_title].push(item1);
        });
      });

      setPreviewState((prevState) => ({
        ...prevState,
        sizeState: {
          ...prevState.sizeState,
          bp_size: groupedSizes,
        },
      }));
    }

    if (sessionStorage?.getItem("dimensionsState")) {
      let data = JSON.parse(sessionStorage.getItem("dimensionsState")!);

      setPreviewState((prevState) => ({
        ...prevState,
        dimensionsState: {
          ...prevState.dimensionsState,
          bp_dimension: data.dimensions,
        },
      }));
    }

    if (sessionStorage?.getItem("resalePriceState")) {
      let data = JSON.parse(sessionStorage.getItem("resalePriceState")!);

      setPreviewState((prevState) => ({
        ...prevState,
        resalePriceState: {
          ...prevState.resalePriceState,
          resale_price: data.resale_price,
        },
      }));
    }

    if (sessionStorage?.getItem("bpPermission")) {
      let data = JSON.parse(sessionStorage.getItem("bpPermission")!);

      setPreviewState((prevState) => ({
        ...prevState,
        permissionState: {
          ...prevState.permissionState,
          bpPermission: data.bpPermission,
        },
      }));
    }

    if (sessionStorage?.getItem("merchRackState")) {
      let data = JSON.parse(sessionStorage.getItem("merchRackState")!);

      setPreviewState((prevState) => ({
        ...prevState,
        merchRackState: {
          ...prevState.merchRackState,
          bp_merch_rack: data.merchracks,
        },
      }));
    }

    if (sessionStorage?.getItem("workflowState")) {
      let data = JSON.parse(sessionStorage.getItem("workflowState")!);

      setPreviewState((prevState) => ({
        ...prevState,
        workflowState: {
          ...prevState.workflowState,
          stageWiseTimeout: data.timeoutState,
          predefinedIssues: data.predefinedIssueState
        },
      }));
    }
  }, []);

  const validate = () => {
    const error = { error: false, errormessage: "" };
    const fieldsToValidate = [
      { field: previewState?.colorState?.bp_color, message: t("Select at least one color") },
      { field: previewState?.sizeState?.bp_size, message: t("Select at least one size") },
      { field: previewState?.dimensionsState?.bp_dimension, message: t("Select at least one dimension") },
      { field: previewState?.merchRackState?.bp_merch_rack, message: t("Select at least one merch rack") },
      { field: previewState?.permissionState?.bpPermission, message: t("Assign at least one operator to each stage"), isArray: true },
      {
        field:
          previewState?.GeneralPropertiesState?.warehouse_location && previewState?.GeneralPropertiesState?.product_group
          && previewState?.GeneralPropertiesState?.product_type && previewState?.GeneralPropertiesState?.issue_type
          && previewState?.GeneralPropertiesState?.brand_logo && previewState?.GeneralPropertiesState?.wms_bp_id
          && previewState?.GeneralPropertiesState?.product_tier && previewState?.GeneralPropertiesState?.work_area,
        message: t("Select all mandatory fields in general properties tab")
      },
    ];

    fieldsToValidate.forEach((item) => {
      if (item.isArray) {
        const permissions = item.field;
        if (
          !Array.isArray(permissions) ||
          permissions.length === 0 ||
          permissions.some(
            (stage) =>
              stage.stage_id &&
              stage.users &&
              Array.isArray(stage.users) &&
              (stage.users.length === 0 || stage.users.every((user: any) => user === null))
          )
        ) {
          error.error = true;
          error.errormessage += `<div>${item.message}</div>`;
        }
      } else if (item.field == null || Object.values(item.field).length === 0) {
        error.error = true;
        error.errormessage += `<div>${item.message}</div>`;
      }
    });
    return error;
  };

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    const validationError = validate();

    if (validationError.error) {
      customAlert('error', validationError?.errormessage, 8000);
    } else {
      createBrandPartner();
    }
  };

  async function createBrandPartner() {
    try {
      let token = await GenerateRefreshToken();
      const response = await fetch(
        process.env.REACT_APP_MASTER_DATA_URL +
        "api/master/create-brand-partner",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            issaved: true,
            brand_partner_id: editId != 0 ? editId : Number(sessionStorage.getItem("brand_partner_id")),
          }),
        }
      );
      let message: string = '';
      let status = '';
      if (response?.status !== 200) {
        message = t(`Error while ${editId ? 'updating' : 'creating'} brand partner`);
        status = 'error';
      } else {
        sessionStorage.clear();
        message = t(`Brand partner ${editId ? 'Updated' : 'Created'} successfully`);
        status = 'successAlertMessage'
      }
      localStorage.setItem(status, message);
      if (status !== "error") {
        navigate("/brand-partner/manage");
      }
    } catch (error) {
      console.error(error);
    }
  }

  function formReducer(prevState: FormState, newState: any) {
    return { ...prevState, ...newState };
  }

  const [state, dispatch] = useReducer(formReducer, initialForm);

  useEffect(() => {
    if (sessionStorage.getItem("brandPartnerSummaryState")) {
      dispatch({
        ...JSON.parse(sessionStorage.getItem("brandPartnerSummaryState")!),
      });
    } else {
      sessionStorage.setItem("brandPartnerSummaryState", JSON.stringify(state));
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem("brandPartnerSummaryState", JSON.stringify(state));
  }, [state]);

  return (
    <>
      <div className="flex-1 overflow-auto">
        <div className="border rounded p-4 py-xxl-5 py-4">
          <div className="row">
            <div className="col-6">
              <div className="row">
                <div className="col-6 mb-xxl-5 mb-4">
                  <LabelField title={t("Name") + ':'} className={` ${styles.label}`} />
                </div>
                <div className="col-6 mb-xxl-5 mb-4">
                  <span className="text-break">{previewState.GeneralPropertiesState?.title}</span>
                </div>
                <div className="col-6 mb-xxl-5 mb-4">
                  <LabelField
                    title={t("Warehouse location") + ':'}
                    className={` ${styles.label}`}
                  />
                </div>
                <div className="col-6 mb-xxl-5 mb-4">
                  <span className="text-break">
                    {previewState.GeneralPropertiesState?.warehouse_location}
                  </span>
                </div>
                <div className="col-6 mb-xxl-5 mb-4">
                  <LabelField
                    title={t("WMS Brand partner id") + ':'}
                    className={` ${styles.label}`}
                  />
                </div>
                <div className="col-6 mb-xxl-5 mb-4">
                  <span className="text-break">{previewState.GeneralPropertiesState?.wms_bp_id}</span>
                </div>
                <div className="col-6 mb-xxl-5 mb-4">
                  <LabelField title={t("BP group") + ':'} className={`${styles.label}`} />
                </div>
                <div className="col-6 mb-xxl-5 mb-4 d-flex flex-wrap">
                  {Object.keys(previewState.GeneralPropertiesState?.product_group)
                    .length > 0 &&
                    Object.values(
                      previewState?.GeneralPropertiesState?.product_group
                    ).map((item: any) => {
                      return (
                        <div key={item?.value} className="w-50 p-2 pb-2">
                          <span
                            className={`${styles.sizeblock} h-100 rounded text-break px-4 text center w-100 d-flex align-items-center justify-content-center`}
                          >
                            {item?.label}{" "}
                          </span>
                        </div>
                      );
                    })}
                </div>
                <div className="col-6 mb-xxl-5 mb-4">
                  <LabelField title={t("BP product type") + ':'} className={`${styles.label}`} />
                </div>
                <div className="col-6 mb-xxl-5 mb-4 d-flex flex-wrap">
                  {Object.keys(previewState.GeneralPropertiesState?.product_type)
                    .length > 0 &&
                    Object.values(
                      previewState?.GeneralPropertiesState?.product_type
                    ).map((item: any) => {
                      return (
                        <div key={item?.value} className="w-50 p-2 pb-2">
                          <span
                            className={`${styles.sizeblock} h-100 rounded text-break px-4 text center w-100 d-flex align-items-center justify-content-center`}
                          >
                            {item?.label}{" "}
                          </span>
                        </div>
                      );
                    })}
                </div>
                <div className="col-6 mb-xxl-5 mb-4">
                  <LabelField title={t("BP issue type") + ':'} className={`${styles.label}`} />
                </div>
                <div className="col-6 mb-xxl-5 mb-4 d-flex flex-wrap">
                  {Object.keys(previewState.GeneralPropertiesState?.issue_type)
                    .length > 0 &&
                    Object.values(
                      previewState?.GeneralPropertiesState?.issue_type
                    ).map((item: any) => {
                      return (
                        <div key={item?.value} className="w-50 p-2 pb-2">
                          <span
                            className={`${styles.sizeblock} h-100 rounded text-break px-4 text center w-100 d-flex align-items-center justify-content-center`}
                          >
                            {item?.label}{" "}
                          </span>
                        </div>
                      );
                    })}
                </div>
                <div className="col-6 mb-xxl-5 mb-4">
                  <LabelField title={t("BP work area") + ':'} className={`${styles.label}`} />
                </div>
                <div className="col-6 mb-xxl-5 mb-4 d-flex flex-wrap">
                  {Object.keys(previewState.GeneralPropertiesState?.work_area)
                    .length > 0 &&
                    Object.values(
                      previewState?.GeneralPropertiesState?.work_area
                    ).map((item: any) => {
                      return (
                        <div key={item?.value} className="w-50 p-2 pb-2">
                          <span
                            className={`${styles.sizeblock} h-100 rounded text-break px-4 text center w-100 d-flex align-items-center justify-content-center`}
                          >
                            {item?.label}{" "}
                          </span>
                        </div>
                      );
                    })}
                </div>
                <div className="col-6 mb-xxl-5 mb-4">
                  <LabelField
                    title={t("Is SKU available?")}
                    className={` ${styles.label}`}
                  />
                </div>
                <div className="col-6 mb-xxl-5 mb-4">
                  <span className="text-break">{previewState.GeneralPropertiesState?.isSku ? t('Yes') : t('No')}</span>
                </div>
                <div className="col-6 ">
                  <LabelField
                    title={t("Hold tier")}
                    className={` ${styles.label}`}
                  />
                </div>
                <div className="col-6 ">
                  <span className="text-break">{previewState.GeneralPropertiesState.product_tier}</span>
                </div>
                <div className="col-6 mb-xxl-5 mb-4">
                  <LabelField
                    title={t("Is tier 3 publishable") + "?"}
                    className={` ${styles.label}`}
                  />
                </div>
                <div className="col-6 mb-xxl-5 mb-4">
                  <span className="text-break">{previewState.GeneralPropertiesState?.tier3_publishable ? t('Yes') : t('No')}</span>
                </div>
              </div>
            </div>

            <div className="col-6">
              <div className="row">
                <div className="col-2">
                  <LabelField title={t("Logo") + ':'} className={` ${styles.label}`} />
                </div>
                <div className="col-xxl-8 col-10">
                  <div className="border rounded py-xxl-4 px-xxl-5 py-3 px-3">
                    <img
                      alt={t("LOGO")}
                      className={`w-100 ${styles.logoimg}`}
                      src={previewState.GeneralPropertiesState?.brand_logo}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="border rounded my-3  p-4 pb-2">
          <div className="row">
            <div className="col-3">
              <LabelField title={t("BP Color") + ':'} className={` ${styles.label}`} />
            </div>
            <div className="col-9 ps-0 d-flex flex-wrap">
              {Object.keys(previewState.colorState?.bp_color).length > 0 &&
                Object.values(previewState.colorState?.bp_color).map(
                  (item: any) => {
                    return (
                      <div className={`${styles.previewsizebtn} px-3`}>
                        <ColorMolecule
                          color={item}
                          preview
                          outerStyle={{ height: "120px", width: "100%", maxWidth: "150px", marginBottom: "12px" }}
                        />
                      </div>
                    );
                  }
                )}
            </div>
          </div>
        </div>

        <div className="border rounded my-3  p-4 pb-2">
          <div className="row">
            <div className="col-3">
              <LabelField title={t("Size") + ':'} className={` ${styles.label}`} />
            </div>
            <div className="col-9">
              <div className="d-flex flex-wrap">
                {Object.values(previewState.sizeState?.bp_size)?.flat()?.length >
                  0 &&
                  Object.values(previewState.sizeState?.bp_size)?.flat()?.map(
                    (item: any) => {
                      return (
                        <div className={`${styles.previewsizebtn}`}>
                          <div
                            key={item?.size_id}
                            className="me-4 text-center rounded d-flex flex-column h-100">
                            <p className={`${styles.sizeblock} rounded px-2 text-break`}>
                              {item?.title}
                            </p>
                            <p className="flex-1 border rounded text-gray px-2 text-break">
                              {item?.label}
                            </p>
                          </div>
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
          </div>
        </div>

        <div className="border rounded my-3 p-4 pb-2">
          <div className="row">
            <div className="col-3">
              <LabelField title={t("Dimension") + ':'} className={` ${styles.label}`} />
            </div>
            <div className="col-9">
              <div className="d-flex flex-wrap">
                {Object.keys(previewState.dimensionsState?.bp_dimension).length >
                  0 &&
                  Object.values(previewState.dimensionsState.bp_dimension).map(
                    (item: any) => {
                      return (
                        <div className={`${styles.previewsizebtn}`}>
                          <div
                            key={item?.dimension}
                            className="me-4 text-center rounded d-flex flex-column h-100">
                            <p className={`${styles.sizeblock} rounded px-2 text-break`}>
                              {item.title}
                            </p>
                            <p className="flex-1 border rounded text-gray px-2 text-break">
                              {item.label}
                            </p>
                          </div>
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
          </div>
        </div>

        <div className="border rounded my-3 p-4 pb-2">
          <div className="row">
            <div className="col-3">
              <LabelField title={t("Resale price") + ':'} className={` ${styles.label}`} />
            </div>
            <div className="col-9 d-flex">
              <table
                className={`table table-borderless text-center ${styles.resalepricetabel}`}
              >
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">{t("Discount")}</th>
                    <th scope="col">{t("Currency")}</th>
                  </tr>
                </thead>
                <tbody>
                  {previewState.resalePriceState.resale_price &&
                    Object.keys(previewState.resalePriceState.resale_price)
                      .length > 0 &&
                    Object.keys(previewState.resalePriceState.resale_price).map(
                      (key: string) => {
                        const resalePrice = previewState.resalePriceState
                          .resale_price as { [key: string]: any };
                        return (
                          <tr key={resalePrice[key].tiername}>
                            <th scope="row" className="text-break">{CommonServices.stringFormat(resalePrice[key].tiername)}</th>
                            <td className="text-break">{resalePrice[key].discount}%</td>
                            <td className="text-break">{CommonServices.stringFormat(resalePrice[key].currencyname)}</td>
                          </tr>
                        );
                      }
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="border rounded my-3 p-4 pb-2">
          <div className="row">
            <div className="col-3">
              <LabelField title={t("Permissions") + ':'} className={` ${styles.label}`} />
            </div>
            <div className="col-9 d-flex">
              <table
                className={`table table-borderless ${styles.resalepricetabel}`}
              >
                <thead>
                  <tr>
                    <th scope="col" className="w-50">{t("Stage name")}</th>
                    <th scope="col" className="w-75">{t("Assigned to")}</th>
                  </tr>
                </thead>
                <tbody>
                  {previewState.permissionState.bpPermission &&
                    Object.keys(previewState.permissionState.bpPermission).map((key: string) => {
                      const bpPermission = previewState.permissionState.bpPermission as {
                        [key: string]: any;
                      };
                      const stageId = bpPermission[key]['stage_id'];
                      const users = bpPermission[key]['users']?.map((userId: string) => {
                        const user = userOptions.find((option) => option.value === userId);
                        return user ? user.label : '';
                      });

                      const stage = stages.find((stage) => stage.stage_id === stageId);
                      return (
                        <tr key={stageId}>
                          <td scope="row" className="text-break ps-lg-4">{stage && CommonServices.stringFormat(stage?.stage_name ?? "")}</td>
                          <td>
                            {users?.map((userName: any, index: any) => (
                              <span key={index} >
                                {CommonServices.stringFormat(userName ?? "")}
                                {index !== users.length - 1 && ', '}
                              </span>
                            ))}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="border rounded my-3 p-4 pb-2">
          <div className="row">
            <div className="col-3">
              <LabelField title={t("Merch rack") + ':'} className={` ${styles.label}`} />
            </div>
            <div className="col-9 ps-0">
              <div className="d-flex flex-wrap">
                {Object.keys(previewState.merchRackState?.bp_merch_rack).length >
                  0 &&
                  Object.values(previewState.merchRackState.bp_merch_rack).map(
                    (item: any) => {
                      return (
                        <div className={`${styles.previewsizebtn}`}>
                          <div
                            key={item?.merch_rack_id}
                            className="me-4 text-center rounded d-flex flex-column h-100"
                          >
                            <p
                              className={`${styles.sizeblock} rounded px-2 text-break`}
                            >
                              {CommonServices.stringFormat(item.title ?? "")}
                            </p>
                            <p className="flex-1 border rounded text-gray px-2 text-break">
                              {CommonServices.stringFormat(item.label ?? item.title)}
                            </p>
                          </div>
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="border rounded my-3 p-4 pb-2">
          <div className="row">
            <div className="col-3">
              <LabelField title={t("Workflow") + ':'} className={` ${styles.label}`} />
            </div>
            <div className="col-9">
              <LabelField title={t("Stagewise timeout") + ':'} className={` ${styles.label} mb-2`} />
              {previewState?.workflowState?.stageWiseTimeout?.length > 0 ? (
                <table className="table table-hover">
                  <thead>
                    <tr className="border-0">
                      <th className="border-0" style={{ width: "30%" }}>
                        {t("Stage name")}
                      </th>
                      <th className="border-0" style={{ width: "30%" }}>
                        {t("Show popup after")}
                      </th>
                      <th className="border-0" style={{ width: "40%" }}>
                        {t("Abort activity and go to workflow overview after")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {previewState?.workflowState?.stageWiseTimeout?.map((item, index) => {
                      const stage = stages.find((stage) => stage.stage_id == Number(item.stage_id));
                      return (
                        <>
                          <tr className="border-0" key={index}>
                            <td className="border-0" scope="row">
                              {stage && CommonServices.stringFormat(stage?.stage_name ?? "")}
                            </td>
                            <td className="border-0">
                              <span>{item.value1}</span>
                            </td>
                            <td className="border-0">
                              <span>{item.value2}</span>
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                </table>) : <p>{t("No timeout data found for this brand.")}</p>}
              <LabelField title={t("Pre-defined issues") + ':'} className={` ${styles.label} mb-2`} />
              {previewState?.workflowState?.predefinedIssues?.length > 0 ? (
                <div className="row">
                  {previewState?.workflowState?.predefinedIssues.map((issue, index) => (
                    <div key={index} className="col-4 mb-4">
                      <div style={{ backgroundColor: `${issue?.issue_type?.color}` }} className="p-3 h-100">
                        <div className={`d-flex justify-content-end ${styles.qcedit} ${styles.qceditblock} ${styles.pdqcIssues}`}>
                        </div>
                        <div className="row mb-3">
                          <div className="col-3 text-center">
                            <span className={`${styles.qclabel} mb-1`}> {IconData.Issue}</span>
                          </div>
                          <div className="col-9 align-self-center text-white text-break">
                            {issue?.issue_type?.label}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-3 text-center">
                            <span className={`${styles.qclabel} mb-1`}> {IconData.Zone}</span>
                          </div>
                          <div className="col-9 align-self-center text-white text-break">
                            {issue?.zone?.label}
                          </div>
                        </div>
                        {issue?.environment?.value && <div className="row">
                          <div className="col-3 text-center">
                            <span className={`${styles.qclabel} mb-1`}> {IconData.Issuetype}</span>
                          </div>
                          <div className="col-9 align-self-center text-white text-break">
                            {issue?.environment?.label}
                          </div>
                        </div>}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p>{t("No issues saved.")}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      {viewId === 0 && <ActionButtonGroup
        backTitle={t(backbutton)}
        saveAsDraftTitle={draftSaved ? "" : t(saveAsDraft)}
        saveAndNextTitle={editId ? t(updateButton) : t(saveAndNext)}
        handleBackClick={() => UTILITYFN.setCurrentPageData(props, 8)}
        handleSaveAsDraftClick={() => navigate('/brand-partner/manage')}
        handleSaveAndNextClick={(e) => handleSubmit(e)}
      />}
      {viewId !== 0 && <div className="d-flex justify-content-between mt-2">
        <Button
          title={t("Back")}
          handleClick={() => navigate('/brand-partner/manage')}
          className="back-btn shadow-none text-decoration-none"
        />
      </div>
      }
    </>
  );
}

export default NinthTab;
