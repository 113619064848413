import React, { useEffect, useState } from "react";
import { APICALL } from "../../../services/ApiServices";
import { getShipments } from "../../../routes/ApiEndPoints";
import Layout from "../../../pages/Shipment/Layout";
import { useNavigate } from "react-router-dom";
import { t } from "../../../pages/Translations/TranslationUtils";
import AdminCard from "../../molecules/AdminDashBoardCard";
import Popup from "../../../utils/popup";
import IconData from "../../../static/IconData";
import Button from "../../atoms/Button";
import { CommonConstants } from "../../../pages/Shipment/Constants/ShipmentConstants";

type CountState = {
  stain_treatment: number
  washer: number,
  ozone_cabinet: number,
  dryer: number,
  air_dryer: number,
};

const LauntryInternalOrganism: React.FC = () => {
  const [shipmentData, setShipmentData] = useState({});
  const bpDetails: any = JSON.parse(localStorage?.getItem("bp_details") ?? "{}");
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    fetchShipment();
  }, []);

  async function fetchShipment() {
    try {
      const postData = {
        bpName: bpDetails?.title,
        brand_partner_id: bpDetails?.brand_partner_id
      };
      const response = await APICALL.service(
        getShipments,
        "POST",
        postData
      );

      if (response.status === 200) {
        setShipmentData(response?.data)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handlePopup = (response: boolean) => {
    setPopup(false);
    if (response) {
      navigate("/laundry-internal/stain-treatment");
    } else {
      navigate("/laundry-internal/washing");
    }
  };
  return (
    <Layout
      pagename={("Laundry-Internal")}
      logo={true}
      timer={false}
    >
      {/* title end*/}
      <div className="row">
        <div className="col-md-11 ms-auto px-4 mb-4">
          <div className="p-4 d-flex flex-wrap text-center shadow border rounded font_Brandon_medium ">
            {shipmentData &&
              Object.values(shipmentData).map((item: any) => {
                return (
                  <AdminCard
                    key={item?.title}
                    title={item?.title}
                    handleClick={() => setPopup(true)}
                    icon={IconData.Truck}
                  />
                );
              })}
          </div>
          {/* buttons */}
          <div className="d-flex justify-content-between mt-5 ">
            <Button
              title={t(CommonConstants.BACK)}
              className="back-btn shadow-none "
              handleClick={() => navigate(localStorage?.getItem('shipment_dashboard_url') ?? "/shipment-dashboard")}
            />
          </div>
        </div>
      </div>
      {popup && (
        <Popup
          title={t("Stain treatment")}
          body={t("Stain treatment required ?")}
          yestext={t(CommonConstants.YES)}
          notext={t(CommonConstants.NO)}
          submit={() => handlePopup(true)}
          cancel={() => handlePopup(false)}
          close={() => setPopup(false)}
        />
      )}
    </Layout>
  );
};

export default LauntryInternalOrganism;
