import React from 'react'

const Medialibrary = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="65" height="70" viewBox="0 0 63.996 63.84">
        <path id="Media_library" data-name="Media library" d="M114.12,35h-4.56A4.559,4.559,0,0,0,105,39.56V94.28a4.559,4.559,0,0,0,4.56,4.56h4.56a4.559,4.559,0,0,0,4.56-4.56V39.56A4.559,4.559,0,0,0,114.12,35Zm18.24,0H127.8a4.559,4.559,0,0,0-4.56,4.56V94.28a4.559,4.559,0,0,0,4.56,4.56h4.56a4.559,4.559,0,0,0,4.56-4.56c0-55.929-.48-57.089-1.335-57.944A4.562,4.562,0,0,0,132.36,35Zm36.48,56.567L154.68,38.717a4.56,4.56,0,0,0-5.586-3.214l-4.4,1.186h0a4.56,4.56,0,0,0-3.215,5.586l14.159,52.85h0a4.56,4.56,0,0,0,4.4,3.374,4.345,4.345,0,0,0,1.186-.16l4.4-1.186h0a4.56,4.56,0,0,0,3.215-5.586Z" transform="translate(-105 -35)" fill="#545960" />
      </svg>

    </>
  )
}

export default Medialibrary
