import React from "react";

export const ManageEmailTemplate = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="42.836"
        height="39.694"
        viewBox="0 0 42.836 39.694"
      >
        <g
          id="Manage_email_template_G_"
          data-name="Manage email template (G)"
          transform="translate(-1597.759 -2613.951)"
        >
          <path
            id="Subtraction_798"
            data-name="Subtraction 798"
            d="M5369.349,499.39h-25.543a3.817,3.817,0,0,1-3.806-3.805V475.8c0-2.27,2.167-4,2.189-4.02a.761.761,0,1,1,.95,1.189,6.09,6.09,0,0,0-.851.908l15.978,10.895,16.043-10.938a4.349,4.349,0,0,0-.807-.84.761.761,0,0,1,.882-1.24,5.35,5.35,0,0,1,2.148,4.047v13.87a7.518,7.518,0,0,0-7.184,9.721Zm.333-24.735a1.142,1.142,0,0,1-1.142-1.14v-7.23h-20.548v7.23a1.143,1.143,0,0,1-1.143,1.14,1.139,1.139,0,0,1-1.14-1.14V466a2,2,0,0,1,2-2h21.121a2,2,0,0,1,2,2v7.517A1.142,1.142,0,0,1,5369.682,474.654Z"
            transform="translate(-3742.24 2149.951)"
            fill="#545960"
          />
          <path
            id="Manage_roles"
            data-name="Manage roles"
            d="M48.014,43.865a6.3,6.3,0,1,1,6.3,6.308A6.3,6.3,0,0,1,48.014,43.865Zm4.613,2.7a2.906,2.906,0,0,0,.369.2,3.721,3.721,0,0,0,.394.154v.669a.4.4,0,0,0,.4.4h.839a.393.393,0,0,0,.282-.117.385.385,0,0,0,.119-.286v-.611a3.061,3.061,0,0,0,.407-.125,3.658,3.658,0,0,0,.384-.172l.474.475a.391.391,0,0,0,.565,0l.6-.588a.42.42,0,0,0,0-.572l-.442-.435a2.847,2.847,0,0,0,.2-.373,3.914,3.914,0,0,0,.154-.4h.671a.4.4,0,0,0,.4-.4v-.834a.394.394,0,0,0-.117-.282.388.388,0,0,0-.286-.119h-.612a3.4,3.4,0,0,0-.125-.4,3,3,0,0,0-.173-.38l.472-.482a.375.375,0,0,0,.121-.28.4.4,0,0,0-.121-.286l-.588-.587a.4.4,0,0,0-.572,0l-.435.435a2.9,2.9,0,0,0-.37-.2,3.759,3.759,0,0,0-.4-.154v-.669a.383.383,0,0,0-.117-.286.39.39,0,0,0-.286-.117H54a.4.4,0,0,0-.282.117.382.382,0,0,0-.119.286v.612a3,3,0,0,0-.408.125,3.1,3.1,0,0,0-.375.173l-.482-.476a.375.375,0,0,0-.28-.121.4.4,0,0,0-.286.121l-.587.589a.4.4,0,0,0,0,.572l.435.435a2.916,2.916,0,0,0-.2.369,3.9,3.9,0,0,0-.154.4H50.6a.4.4,0,0,0-.4.4v.839a.4.4,0,0,0,.4.4h.612a3.038,3.038,0,0,0,.125.407,3.832,3.832,0,0,0,.173.384l-.475.474a.366.366,0,0,0-.121.28.393.393,0,0,0,.121.286l.588.6a.407.407,0,0,0,.286.112.4.4,0,0,0,.286-.112l.435-.442h0Zm1.05-1.184a1.626,1.626,0,0,1-.874-.872,1.666,1.666,0,0,1,0-1.282,1.626,1.626,0,0,1,.874-.874,1.673,1.673,0,0,1,1.281,0,1.705,1.705,0,0,1,.525.351,1.658,1.658,0,0,1,.35,1.806,1.62,1.62,0,0,1-.874.874,1.673,1.673,0,0,1-1.281,0Z"
            transform="translate(1579.975 2603.472)"
            fill="#545960"
          />
        </g>
      </svg>
    </>
  );
};
