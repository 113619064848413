import React from 'react'

const Play = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 46.608 46.608">
        <path id="Play_G_" data-name="Play (G)" d="M25.3,2A23.3,23.3,0,1,1,2,25.3,23.3,23.3,0,0,1,25.3,2ZM18.779,33.4a1.592,1.592,0,0,0,2.4,1.368l13.6-8.093a1.592,1.592,0,0,0,0-2.735l-13.6-8.093a1.59,1.59,0,0,0-2.4,1.367Z" transform="translate(-2 -2)" fill="#54596099" />
      </svg>
    </>
  )
}

export default Play

