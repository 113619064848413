import { t } from "../../../Translations/TranslationUtils";

 
export const CommonData = {
    saveAndNext: "Save & next",
    backbutton: "Back",
    saveAsDraft: "Save as draft",
    updateButton: "Update"
};

export const SizeData = {
    saveAndNext: "Save & next",
    backbutton: "/images/turn-left1.png"
};

export const DimensionData = {
    saveAndNext: "Save & next",
    backbutton: "/images/turn-left1.png"
};

export const ResalePriceData = {
    saveAndNext: "Save & next",
    backbutton: "/images/turn-left1.png"
};

export const PreviewData = {
    saveAndNext: "Submit",
    backbutton: "/images/turn-left1.png"
};

export const TabBarsConstData = {
    stepOne: {
        title: t("General properties"),
        stepName: "Step: 1",
        pageNumber: 1,
    },
    stepTwo: {
        title: t("Color"),
        stepName: "Step: 2",
        pageNumber: 2,
    },
    stepThree: {
        title: t("Size"),
        stepName: "Step: 3",
        pageNumber: 3,

    },
    stepFour: {
        title: t("Dimension"),
        stepName: "Step: 4",
        pageNumber: 4,

    },
    stepFive: {
        title: t("Resale price"),
        stepName: "Step: 5",
        pageNumber: 5,

    },
    stepSix: {
      title: t("Permissions"),
      imgSrc: "/images/agreement.png",
      imgBlue: "/images/agreement.png",
      stepName: "Step: 6",
      pageNumber: 6,
    },
    stepSeven: {
        title: t("Merch rack"),
        stepName: "Step: 7",
        pageNumber: 7,
    },
    stepEight: {
        title: t("Workflow"),
        stepName: "Step: 8",
        pageNumber: 8,
    },
    stepNine: {
        title: t("Preview"),
        stepName: "Step: 9",
        pageNumber: 9,
    }
}

const pageDefaultValues = {
  validationStatus: false,
  active: false,
  visited: false,
}

export const formPageInformation = {
    pageOne: pageDefaultValues,
    pageTwo: pageDefaultValues,
    pageThree: pageDefaultValues,
    pageFour: pageDefaultValues,
    pageFive: pageDefaultValues,
    pageSix: pageDefaultValues,
    pageSeven: pageDefaultValues,
    pageEight: pageDefaultValues,
  }


