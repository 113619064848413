import styles from "../../../pages/Shipment/QualityControl/Qualitycontrol.module.css";
import CommonServices from "../../../services/CommonServices";

interface ProductInfoProps {
    dataentry?: any
}

export const ProductInfo: React.FC<ProductInfoProps> = ({ dataentry = {} }) => {
    return (
        <div className="mb-2">
            <div className="text-center text-gray mt-xxl-2">
                <div className={`${styles.product_name} my-xxl-2 text-break`}>{dataentry?.product_type_title}</div>
                <div className="d-flex justify-content-xxl-between align-items-center justify-content-center w-xxl-75 w-100 m-auto flex-column flex-xxl-row">
                    <div className="text-break w-100 w-xxl-25">{dataentry?.color_title || dataentry?.colorlabel}</div>
                    <div className="text-break w-100 w-xxl-50 lh-sm">{dataentry?.size_title || dataentry?.sizelabel}</div>
                    <div className="text-break w-100 w-xxl-25">{dataentry?.product_group_title}</div>
                </div>
            </div>
        </div>
    );
}

