import { createMediaLibraries, deleteFiles, deleteMediaLibraries, fetchMediaLibraries } from "../routes/ApiEndPoints";
import { APICALL } from "./ApiServices";

const MediaLibraryFolderServices = {

  createFolder: async function (parent_id: any = null, brand_partner_id: any = null, type: string = '', doubleClicked: any = null, renameId: any = null, title: any = null, path: any = "") {
    try {
      const postData = {
        method: "POST",
        data: {
          parent_id: parent_id,
          brand_partner_id: brand_partner_id,
          type: type,
          media_library_id: renameId,
          title: title,
          oldPath: path
        },
      };

      const response = await APICALL.service(
        createMediaLibraries,
        "POST",
        postData,
        true
      );

      if (response.status === 200) {
        if (renameId != "") {
          localStorage.setItem(
            "successAlertMessage",
            "Folder renamed successfully"
          );
        }
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  },
  deleteFolder: async function (id: any, name: any) {
    try {

      const postData = {
        method: "POST",
        data: {
          medialibrary_id: id,
        },
      };
      const response = await APICALL.service(
        deleteMediaLibraries,
        "POST",
        postData,
        true
      );

      return response
    } catch (error) {
      console.log(error);
    }
  }
};

export default MediaLibraryFolderServices;


export const getMediaLibraries = async (requestdata: any) => {
  let responsedata = [];  
  try {
    const postData = {
      method: "POST",
      data: {
        ...requestdata,
      },
    };
    const response = await APICALL.service(
      fetchMediaLibraries,
      "POST",
      postData,
      true
    );

    if (response.status === 200) {
      responsedata = response.data
    }
  } catch (error) {
    console.log(error);
  }
  return responsedata;
}