import axios, { AxiosRequestConfig } from 'axios';
import { GenerateRefreshToken } from './RefreshTokenService';

/*
this is for the fetch which will return the sites JSON,which will be executed bofore fetching the ,
since at that time we're using env file to get the backend url,
therefore writing a separate function for that.
*/

    /*
    *Call to the API
    *@param urlendpoint=urlendpoint of the API
    *@param httpmethod=METHOD
    *@param data=data to the API
    *@returns response from the APIsettings
    */
    /**function serviceForSitesJSON(urlendpoint = '', httpmethod = '', data = '') {
        // Default options are marked with *
        return fetch(process.env.REACT_APP_serverURL + urlendpoint, headers(data, httpmethod))
            .then(
                // parses JSON response into native Javascript objects
                result => result.json()
            )
            .then(
                result => { return result }
            );
    }*/


    /*
    *Call to the API
    *@param urlendpoint=urlendpoint of the API
    *@param httpmethod=METHOD
    *@param data=data to the API
    *@returns response from the API
    */
    const service = async( urlendpoint = "", httpmethod = "", data:any = '', file = 0, loading = 1) => {
        const loadingIcon = document.getElementById("loading-div-id");
        if (loadingIcon !== null)
            loadingIcon.setAttribute("style", "display:block;");
        const headersarray:any = headers();

        if (httpmethod === "POST") {
            return await axios
            .post(urlendpoint, data, headersarray)
            .then((result) => {                
                if (loadingIcon !== null)
                    loadingIcon.setAttribute("style", "display:none;");
                return result;
            })
            .catch((error) => {
                console.log("Error service : ", error);
                if (loadingIcon !== null)
                    loadingIcon.setAttribute("style", "display:none;");
                if (error?.response && error?.response?.data) {                    
                    return error?.response?.data;
                } else {
                    return null;
                }
            });
        }
    }
    /*
    *Getting headers for the Ajax
    *@param data =data to the API
    *@param httpmethod=METHOD
    *returns headers for the Ajax
    */
    const headers = async (): Promise<AxiosRequestConfig> => {
        let token = await GenerateRefreshToken();
        const config: AxiosRequestConfig = {
            headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'multipart/form-data',
            "Authorization": token
            },
        };
        return config;
    }


    /**
     * Below is the usage of service function
     * import APICALL from {../give-proper-path-whearver you are importing it}
     * URLENDPOINT is the ENDPOINT of the API CAll
     * httpmethod can be GET, PUT, POST, DELETE etc.
     * data is the json object which you want to send to the backend using post, put, delete and update methods
     * APICALL.service(URLENDPOINT, httpmethod, data)
     *  .then(result => {
     *  use ur result.json()  object here whatever you want to do.
     * })  
     */

export const APICALL = {
    service,
    // serviceForSitesJSON,
    headers
};
