
// SelectTierPopup
import React, { useEffect, useState, useReducer, ChangeEvent } from 'react';
import { Modal, Button } from 'react-bootstrap';

interface SelectTierProps {
    body: React.ReactNode;
    bodyclassName?: string;
    yesicon?: React.ReactNode;
    noicon?: React.ReactNode;
    yestext?: string;
    notext?: string;
    cancel?: () => void;
    submit?: () => void;
  }

const DivWithoutPopup: React.FC<SelectTierProps> = ({ body,  bodyclassName, yesicon, noicon, yestext, notext, cancel, submit }) => {
    return (
        <>
            <Modal.Body className={`text-center mb-4 px-0 ${bodyclassName}`}>
                {body}
            </Modal.Body>
            <Modal.Footer className={`border-0 justify-content-evenly mb-3`}>
                {notext && <Button variant="secondary" className='back-btn shadow-none' onClick={cancel}>{noicon ?? noicon} {notext}</Button>}
                {yestext && <Button variant="primary" className='submit-btn shadow-none' onClick={submit}>{yesicon ?? yesicon}{yestext}</Button>}
            </Modal.Footer>
        </>
    )
}
export default DivWithoutPopup;