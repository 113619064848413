import React, { useEffect, useState } from 'react'
import IconData from '../../../static/IconData'
import Popup from '../../../utils/popup';
import ViewIssueMolecule from '../ViewIssueMolecule';
import { t } from '../../../pages/Translations/TranslationUtils';
import CommonServices from '../../../services/CommonServices';
import { CommonConstants } from '../../../pages/Shipment/Constants/ShipmentConstants';

interface Props {
    configdata: any
    issues: any;
    selectedissue: any;
    title?: string;
    issuecount: string;
    handleSelected: (value: any, count: string) => void;
    handleIssueForward: (value: any, count: string, status?: boolean, forward?: boolean) => void;
    timer: boolean;
    disablebuttons?: boolean;
    selectedTier?: string;
}

const IssueList: React.FC<Props> = ({
    issues,
    selectedissue,
    title,
    issuecount,
    handleSelected,
    configdata,
    handleIssueForward,
    timer,
    disablebuttons,
    selectedTier
}) => {
    const [state, setState] = useState<any>({
        issue: selectedissue,
        popup: false,
        issueresolved: true,
        message: "",
        type: ""
    });
    const multiaction = [
        {
            icon: IconData.IssueIcon,
            name: "repair",
            message: t("Are you sure this issue is not resolved?")
        },
        {
            icon: IconData.forward,
            name: "forward",
            message: `${t("Are you sure this issue has passed inspection") + '?'}`
        }
    ]

    const handleAction = (action: any) => {
        if (timer) {
            const matchingAction: any = multiaction.find(type => type.name === action);

            setState({
                ...state,
                message: matchingAction.message,
                popup: true,
                type: action
            })
        }
    }

    const handleSelect = () => {
        let status = (state.type == 'repair') ? false : true
        setState({ ...state, issueresolved: false, popup: false })
        handleIssueForward(-1, 'next', status, (state.type == 'forward' ? false : true))
    }

    const handleClick = (value: any, key: number) => {
        if (timer) {
            let count = CommonServices.convertNumberIntoTwoDigits(Number(key) + 1);
            setState({
                ...state,
                issue: value,
                number: count
            })
            handleSelected(value, count);
        }
    }

    return (
        <>
            <div className="row mx-0 h-100">
                {((Array.isArray(issues) && issues.length > 0) && issues != null) ? <>
                    <div className="col-5 p-0 h-100">
                        <div className='p-2 border rounded shadow h-100 overflow-auto'>
                            <h4>{title}</h4>
                            <div className='d-flex flex-wrap gap-1'>
                                {issues?.map((value: any, index: number) => (
                                    <React.Fragment key={index}>
                                        <div className='d-flex justify-content-center align-items-center position-relative cursor-pointer'
                                            onClick={() => handleClick(value, index)}
                                        >
                                            <span className={`position-absolute mt-3 ${value.quality_status === null ? "text-red" : `text-white 
                                        ${(selectedissue && value.quality_control_issue_id === selectedissue.quality_control_issue_id) ? '' : 'left_22'}`}
                                        ${(selectedissue && value.quality_control_issue_id === selectedissue.quality_control_issue_id) ? 'c_white' : ''}`}>
                                                {CommonServices.convertNumberIntoTwoDigits(Number(index) + 1)}
                                            </span>
                                            <span className="">
                                                {(() => {
                                                    if (selectedissue && value.quality_control_issue_id === selectedissue.quality_control_issue_id) {
                                                        return IconData.QApolygon;
                                                    } else if (value.quality_status === null) {
                                                        return IconData.QAIssueUpcomingPolygon;
                                                    } else if (value.quality_status === true && value.issue_repaired === true) {
                                                        return IconData.QAIssueSuccessPolygon; // Only returns when both conditions are met
                                                    } else if (value.quality_status === true && value.issue_repaired === false) {
                                                        return IconData.QAIssueForwardPolygon
                                                    } else if (value.quality_status === false) {
                                                        return IconData.QAIssueRejectPolygon;
                                                    }
                                                })()}
                                            </span>
                                        </div>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-7 pe-0 h-100">
                        {selectedissue != null && configdata != null &&
                            <div className='h-100'>
                                <h4 className='position-absolute ps-5 pt-3 text-white'>{title + ' ' + issuecount}</h4>
                                <ViewIssueMolecule
                                    key={selectedissue.quality_assurance_issue_id}
                                    item={selectedissue}
                                    configdata={configdata}
                                    handleAction={handleAction}
                                    multiaction={(selectedissue.quality_status == null && disablebuttons && selectedTier == "") ? multiaction : ''}
                                    idname="quality_assurance_issue_id"
                                />
                            </div>
                        }
                    </div> </> :
                    <div className='p-2 border rounded shadow'>{t("No issues reported")}</div>
                }
            </div>
            {state.popup && <Popup
                body={state.message}
                yestext={t(CommonConstants.YES)}
                notext={t(CommonConstants.NO)}
                cancel={() => setState({ ...state, popup: false })}
                submit={handleSelect}
            />}
        </>
    )
}

export default IssueList