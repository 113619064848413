import React from 'react'

const Pause = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 58 58">
        <path id="Subtraction_745" data-name="Subtraction 745" d="M29,58A29.008,29.008,0,0,1,17.712,2.279,29.007,29.007,0,0,1,40.288,55.721,28.817,28.817,0,0,1,29,58Zm8.068-43.07a4.846,4.846,0,0,0-4.841,4.841V39.135a4.841,4.841,0,0,0,9.682,0V19.771A4.847,4.847,0,0,0,37.069,14.93Zm-16.136,0a4.846,4.846,0,0,0-4.841,4.841V39.135a4.841,4.841,0,1,0,9.681,0V19.771A4.846,4.846,0,0,0,20.932,14.93Z" fill="rgba(84,89,96,0.6)" />
      </svg>
    </>
  )
}

export default Pause

