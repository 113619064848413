import React, { ChangeEvent } from 'react';

const style = {};

interface Props {
  type?: string;
  className?: string;
  value?: string;
  isDisabled?: boolean;
  placeholder?: string;
  customStyle?: React.CSSProperties;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  parentDivClassName?: string;
}

const InputColorfield: React.FC<Props> = ({
  type = 'color',
  className = '',
  value = '',
  isDisabled = false,
  placeholder = '',
  customStyle = {},
  handleChange,
  name = '',
  parentDivClassName = '',
}) => {
  return (
    <div className={parentDivClassName}>
      <input
        style={{ ...style, ...customStyle }}
        type={type}
        className={className}
        disabled={isDisabled}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
}

export default InputColorfield;
