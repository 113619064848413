import React from "react";
import styles from "../../pages/Shipment/shimpment.module.css";
import IconData from "../../static/IconData";
import CommonServices from "../../services/CommonServices";
import { t } from "../../pages/Translations/TranslationUtils";

export interface CardProps {
  title: string;
  text?: any;
  text1?: any;
  icon?: JSX.Element;
  noAccess?: boolean;
  handleOnclick?: (...args: any[]) => void;
  handleCircleClick?: (...args: any[]) => void;
  className?: string;
  style?: React.CSSProperties;
  smallCard?: boolean;
  count?: number | JSX.Element;
  iconclass?: string;
  main_class?: string;
}

const ShipmentDashboardCard: React.FC<CardProps> = ({ title, text, text1, noAccess, icon, count, handleOnclick, handleCircleClick, main_class, className, style, iconclass, smallCard = false }) => {
  return (
    <>
      {(count === 0 || smallCard || (parseInt(text, 10) == 0)) ?
        (
          <div className={`${main_class ? main_class : styles.shipment_card_main_box}`}>
            <div className={`${styles.box} ${className} ${iconclass}`} onClick={handleOnclick} style={style}>
              {noAccess && <span className="position-absolute start-0 top-0 ms-2 mt-2">{IconData.NoAccess}</span>}
              <div className={`text-center ${smallCard ? "mt-2" : styles.box_inner}`}>

                <div>{icon}</div>
                {smallCard ?
                  <p className="m-2 text-truncate text-break">{title}</p>
                  :
                  <div className={`${styles.title} my-3 text-break`}>{title}</div>
                }
              </div>
            </div>
          </div>)
        :
        (
          <div className={`${main_class ? main_class : styles.shipment_card_main_box}`}>
            <div className={`${styles.box} ${className} ${styles.second} shadow-none`} onClick={handleOnclick}>
              {noAccess && <span className="position-absolute start-0 top-0 ms-2 mt-2">{IconData.NoAccess}</span>}
              <span title={typeof count === 'number' ? (count?.toString() ?? "") : t('Transfer')} className={`${styles.count_circel} ${styles.title} `} onClick={handleCircleClick}>
                {(count && Number(count) > 999) ? '999+' : (count ?? 0)}
              </span>
              <div className={`${styles.box_inner} ${iconclass}`}>
                <div>
                  {icon}
                </div>
                <div className={`${styles.title} text-center mt-2 text-break`}>{title}</div>
                {text && <span className={`${styles.title_below} text-break mb-2 border rounded px-2`}>{text}</span>}
                {text1 && <span className={`${styles.title_below} text-break mb-2 border rounded px-2`}>{text1}</span>}
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default ShipmentDashboardCard;
