import React, { useState, useEffect, useRef } from 'react';
import tinycolor from 'tinycolor2';
import InputTextfield from '../../../../components/atoms/InputTextField';
import styles from "./colorpicker.module.css"
import LabelField from '../../../../components/atoms/LabelField';
import Close from '../../../../static/icons/Close';
import Search from '../../../../static/icons/Search';
import { t } from '../../../Translations/TranslationUtils';
import ColorMolecule from '../../../../components/molecules/ColorMolecule';
import customAlert from '../../../../components/atoms/CustomAlert';
import Button from '../../../../components/atoms/Button';
import CheckBoxField from '../../../../components/atoms/CheckBoxField';
import CommonServices from '../../../../services/CommonServices';
import { CONSTANTS } from '../../../../applicationConstants/AppConstants';
interface ColorPickerProps {
	color: any;
	setColor: any;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ color, setColor }) => {
	const colorPickerRef = useRef<HTMLDivElement>(null);
	const [error, setError] = useState("");
	const mainColors = [
		{ name: 'Violet', code: '#EE82EE' },
		{ name: 'Indigo', code: '#4B0082' },
		{ name: 'Blue', code: '#0000FF' },
		{ name: 'Green', code: '#008000' },
		{ name: 'Yellow', code: '#FFFF00' },
		{ name: 'Orange', code: '#FFA500' },
		{ name: 'Red', code: '#FF0000' },
	];

	const handleParentColorChange = (parent: any) => {		
		if (color?.parent_color?.code !== parent) {
			let shades: any = generateColorShades(parent.code, 23);			
			setColor((prev: any) => ({ ...prev, parent_color: parent, colorShades: shades }))
		}
	};

	const handleSelectChange = (e: any, childcolor: any) => {
		e.preventDefault();
		let data = [...color.selected_colors]; // Create a copy of selected_colors array
		if (Object.values(data).find((item: any) => item?.color_code === childcolor?.color_code)) {
		  data = Object.values(data).filter((item: any) => item?.color_code !== childcolor?.color_code);
		} else {
		  data.push({ color_code: childcolor?.color_code, title: childcolor?.color_code }); // Add the new color to the copied array
		}		
		setColor((prev: any) => ({ ...prev, selected_colors: data, searchColor: "", multipleColors: "" }));
	};

	const handleTextChange = (e: any, childcolor: any) => {
		e.preventDefault();
		let data: any = color?.selected_colors?.map((item: any) => {
			if (item?.color_code === childcolor?.color_code) {
				item = {
					...item,
					title: e.target.value
				}
			}
			return item
		})
		setColor((prev: any) => ({ ...prev, selected_colors: data }))		
	};

	//To remove selected colors
	const handleRemoveColor = (deletecolor: any) => {
		setColor((prev: any) => ({ 
			...prev,
			selected_colors: color?.selected_colors?.filter((item: any) =>  item?.color_code !== deletecolor?.color_code)
		 }))		
	};

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();		
		let colorcode: any = event.target.value
		setColor((prev: any) => ({ ...prev,  searchColor: event.target.value}));
		if (colorcode?.length >= 7) {
			if (!isValidHexColor(colorcode)) {
				setError(t('Invalid hexcode'));
				return;
			}
			if (color?.isMulti) {
				handleMultipleColors(event);
			} else {
				const isAlreadySelected = 
					Object.values(color?.selected_colors)?.some((colors: any) => colors?.color_code === colorcode);
				if (isAlreadySelected) {
					setError(t('Color already selected'));
					return
				}
				setColor((prev: any) => ({ ...prev,  searchColor: "", multipleColors: colorcode }));
			}
		}
	}

	const isValidHexColor = (color: string): boolean => {
		const hexColorRegex = /^#[0-9a-fA-F]{6}$/;
		return hexColorRegex.test(color);
	};

	const handleMultipleColors = (e: any, colorselected?: any) => {
		let value = e.target.value ?? colorselected?.color_code;
		let colorCodes = CommonServices?.splitAndJoin(color?.multipleColors, ';');
		if (colorselected?.color_code && colorCodes?.includes(colorselected?.color_code)) {
			colorCodes = colorCodes?.filter((item: any) => item !== colorselected?.color_code)
		} else {
			if ([...colorCodes, value]?.length > CONSTANTS.COLORS) {
				customAlert('error', t(`You cannot select more than ${CONSTANTS.COLORS} colors`), 6000);
			} else if (colorCodes?.includes(value)) {
				customAlert('error', t('Duplicate colors are not allowed'), 6000);
			} else {
				// Push the new color to the array, then convert it to a string and add ';'
				colorCodes = [...colorCodes, value];
			}
		}
		setColor((prev: any) => ({ ...prev, 
			multipleColors: CommonServices?.splitAndJoin(colorCodes, ';', true),
			searchColor: ""
		}));
	}

	const hasColor = (value: string) => {
		let colorCodes = CommonServices?.splitAndJoin(color?.multipleColors, ';');
		return colorCodes?.includes(value);
	}

	const generateColorShades = (baseColor: string, shadeCount: number): string[] => {
		const base = tinycolor(baseColor);
		const hsl = base.toHsl();
		const lightnessStep = (100 - hsl.l) / (shadeCount + 1);
		const saturationStep = hsl.s / (shadeCount + 1);
		const shades: string[] = [];
		// Add parent color as the first shade
		shades.push(base.toHexString());
		for (let i = 1; i <= shadeCount; i++) {
			const modifiedHsl = { ...hsl };
			modifiedHsl.l = hsl.l + lightnessStep * i;
			modifiedHsl.s = hsl.s - saturationStep * i;

			const shade = tinycolor(modifiedHsl).toHexString();
			shades.push(shade);
		}
		return shades;
	};

	return (
		<div ref={colorPickerRef}>
			<div className='d-flex justify-content-end z-index-1 position-sticky top-0 bg-white'>
			{(color?.multipleColors !== "") && (
				<div className="p-2">
					{(() => {
						let colorCode = color.multipleColors;						
						return (
							<ColorMolecule
								color={{ color_code: colorCode, title: colorCode }}
								handleSelectChange={handleSelectChange}
								outerStyle={{height: "120px", width: "50px", maxWidth: "150px",  marginBottom: "12px"}}
							/>
						);
					})()}
				</div>
			)}

				<p className='mb-1'>
					<CheckBoxField
						label={t("isMulti")+ '?'}
						checked={color?.isMulti}
						onCheck={(e: any) => setColor((prev: any) => ({ ...prev, isMulti: e.target.checked, multipleColors: "" }))}
					/>
					<div className={`${styles.colorsearchbox} bg-white border rounded w-100 mb-1 d-flex align-items-center`}>
						<Search />
						<InputTextfield
							className='w-100 border-0'
							type="text"
							value={color?.searchColor}
							handleChange={handleSearchChange}
							handleClick={(e) => (setError(""))}
							placeholder={t("Search by hexcode")}
						/>
					</div>
					<div>
						{error && <div className='text-start' style={{ color: 'red' }}>{error}</div>}
					</div>
				</p>
			</div>
		

			{Object.values(color?.selected_colors).length > 0 && (
				<div className='border rounded p-3 mb-2 mb-xxl-2'>
					<LabelField
						title={t("Selected colors") + ':'}
						mandatory={true}
						className={` ${styles.label} mb-2`}
					/>
					<div className='d-flex flex-wrap gap-4'>
						{Object.values(color?.selected_colors)?.map((item: any) => (							
							<div key={item?.color_code} style={{ maxWidth: '150px', display: 'content' }} className={`${styles.selectedcolorbox}`}>
								<ColorMolecule
									color={item}
									handleRemoveColor={handleRemoveColor}
									handleTextChange={handleTextChange}
									outerStyle={{height: "150px", width: "50px", maxWidth: "150px",  marginBottom: "12px"}}
								/>
							</div>
						))}
					</div>
				</div>
			)}

			<div className="p-3">
				<LabelField
					title={t("Brand partner color family")}
					mandatory={true}
					className={` ${styles.label}`}
				/>
				<div style={{ display: 'flex', alignItems: 'center' }} >
					{mainColors.map((colors) => (
						<div
							className={`text-center d-flex rounded p-2 py-3 mx-xxl-4 mx-xl-2  ${styles.maincolor_box}  `}
							key={colors?.code}
							style={{
								border: color?.parent_color?.code === colors?.code  ? '2px solid red' : 'none',
							}}
							onClick={() => handleParentColorChange(colors)}
						>
						<div
							className='border rounded'
							style={{
								width: '50px',
								height: '70px',
								backgroundColor: colors.code,
								marginBottom: '5px',
								border: color?.parent_color?.code === colors.code ? '2px solid red' : 'none',
							}}

						/>
							<span className={`${styles.color_name} px-2 text-truncate `}>{t(colors.name)}</span>
						</div>
					))}
				</div>
			</div>
			{color?.colorShades?.length > 0 && (
				<div className='p-3 border rounded mt-2 mt-xxl-4'>
					<LabelField
						title={color?.parent_color?.name}
						mandatory={true}
						className={` ${styles.label} mb-2`}
					/>
						<div className='flex-wrap d-flex'>
							{color?.colorShades?.map((item: any) => (
								<ColorMolecule 
									handleSelectChange={color?.isMulti ? handleMultipleColors : handleSelectChange}
									color={{ color_code: item }}
									isBorderRequired={color?.isMulti ? 
										hasColor(item) :
										color?.selected_colors?.some((value: any) => value?.color_code === item
									)}
									outerStyle={{height: "120px", width: "50px", maxWidth: "150px",  marginBottom: "12px"}}
								/>
							))}
						</div>
				</div>
			)}

		</div>
	);
};

export default ColorPicker;
