import React from 'react'

const ShipmentLogo = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="65" height="70" viewBox="0 0 32.859 20.139">
                <g id="Shipment_G_" data-name="Shipment (G)" transform="translate(0 -78.736)">
                    <g id="Group_48740" data-name="Group 48740" transform="translate(0 78.736)">
                        <path id="Path_56705" data-name="Path 56705" d="M94.266,256.572a2.887,2.887,0,1,0,2.887,2.887A2.886,2.886,0,0,0,94.266,256.572Zm0,4.142a1.255,1.255,0,1,1,1.255-1.255A1.255,1.255,0,0,1,94.266,260.714Z" transform="translate(-83.998 -242.207)" fill="#545960" />
                        <path id="Path_56706" data-name="Path 56706" d="M282.735,256.572a2.887,2.887,0,1,0,2.887,2.887A2.886,2.886,0,0,0,282.735,256.572Zm0,4.142a1.255,1.255,0,1,1,1.256-1.255A1.255,1.255,0,0,1,282.735,260.714Z" transform="translate(-257.242 -242.207)" fill="#545960" />
                        <path id="Path_56707" data-name="Path 56707" d="M35.1,133.369a.738.738,0,0,1-.735.735H24.21a.738.738,0,0,1-.735-.735v-.441a.738.738,0,0,1,.735-.735H34.36a.738.738,0,0,1,.735.735Z" transform="translate(-21.579 -127.874)" fill="#545960" />
                        <path id="Path_56708" data-name="Path 56708" d="M73.081,88.537c-1.059-.275-1.664-.448-2.08-1.251l-1.39-2.814a2.975,2.975,0,0,0-2.4-1.46h-1.9a.245.245,0,0,1-.256-.252V80.4a1.525,1.525,0,0,0-1.619-1.664H47.942c-1.577,0-2.326.749-2.326,1.664v1.479a.427.427,0,0,0,.426.44h8.671a1.473,1.473,0,0,1,1.471,1.471v.441A1.473,1.473,0,0,1,54.712,85.7H46.042a.39.39,0,0,0-.426.424v.509c0,.4.554.4.554.4h5.837a1.473,1.473,0,0,1,1.471,1.471v.441a1.473,1.473,0,0,1-1.471,1.471H46.263s-.647-.012-.647.5V94.5a1.669,1.669,0,0,0,1.664,1.664H48.2c.208,0,.239-.116.239-.174a3.762,3.762,0,0,1,7.524,0c0,.059-.017.174.143.174h7.416c.147,0,.141-.124.141-.174a3.762,3.762,0,0,1,7.524,0c0,.059,0,.174.091.174h1.87a1.649,1.649,0,0,0,1.644-1.644V91.4C74.791,88.765,73.96,88.765,73.081,88.537Zm-3.128-.146H65.274a.219.219,0,0,1-.219-.188V84.3s-.011-.15.237-.15h1.335a2.647,2.647,0,0,1,2.135,1.3L70,87.958a1.96,1.96,0,0,0,.167.271C70.211,88.287,70.137,88.391,69.953,88.391Z" transform="translate(-41.931 -78.736)" fill="#545960" />
                        <path id="Path_56709" data-name="Path 56709" d="M10.811,191.8a.738.738,0,0,1-.735.735H.735A.738.738,0,0,1,0,191.8v-.441a.738.738,0,0,1,.735-.735h9.34a.738.738,0,0,1,.735.735Z" transform="translate(0 -181.59)" fill="#545960" />
                    </g>
                </g>
            </svg>

        </>
    )
}

export default ShipmentLogo