import RecordsOverviewOrganism from "../../../components/organisms/RecordOverview/RecordsOverviewOrganism";

const RejectedPublished = () => {
  return (
    <RecordsOverviewOrganism 
      rejected={true}
      // returnurl="/publish/rack"
      />
  );
};

export default RejectedPublished;