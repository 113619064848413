import React from "react";
import styles from "./PageNotFount.module.css"
import { useNavigate } from "react-router-dom";
import { t } from "../Translations/TranslationUtils";

const PageNotFound: React.FC = () => {

  const navigate = useNavigate();
  return (
    <div className="row">
      <div className={`col-6 ${styles.imagage_block}  pe-5 ps-0`}>
        <img src="../images/Curved.png" alt="404 image" />
      </div>
      <div className="col-6 ps-5 m-auto">
        <p className={`${styles.fournotfour} mb-0`}>404</p>
        <p className={`${styles.pagenotfound}`}>{t('Page not found')}</p>
        <p className={`${styles.pagecontent}`}>
          {t("It looks like the page you were searching for is missing or doesn't exist. Let's find a different path to explore together.")}
        </p>
        <a className="submit-btn text-decoration-none py-2" href="/">{t('Go Back to Home')}</a>
      </div>
    </div>
  );
};

export default PageNotFound;
