import React, { useEffect, useState } from "react";
import Usermanagement from "../../static/icons/Admindashboard/Usermanagement";
import Masterdata from "../../static/icons/Admindashboard/Masterdata";
import Workflow from "../../static/icons/Admindashboard/Workflow";
import Medialibrary from "../../static/icons/Admindashboard/Medialibrary";
import { useNavigate } from "react-router-dom";
import { t } from "../Translations/TranslationUtils";
import { ApplicationConfiguration } from "../../static/icons/ApplicationConfiguration";
import { BrandPartnerLogo } from "../../static/icons/BrandPartnerLogo";
import AdminCard from "../../components/molecules/AdminDashBoardCard";
import { useUserContext } from "../../routes/Contextlib";
import LoadingIcon from "../../utils/LoadingIcon";
import AccessControl from "../../services/AccessControl";
import ShipmentLogo from "../../static/icons/ShipmentLogo";
import IconData from "../../static/IconData";

const Admindashboard: React.FC = () => {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const [showPage, setShowPage] = useState('');
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (user != null){
      // console.log(user.roles);
      // console.log(user.permissions);
      // console.log(user);
      
      const lowercaseRoles = user?.roles?.map((role) => role.toLowerCase().trim());
      const access = checkAccess(lowercaseRoles)
      setShowPage(access)
    }
  }, [user != null]);

  const checkAccess =  (lowercaseRoles: any) => {
    
    const supervisor: string = process.env.REACT_APP_SUPERVISOR ?? "";
    const admin: string = process.env.REACT_APP_ADMIN ?? "";

    if (
      lowercaseRoles?.includes(admin) ||
      lowercaseRoles?.includes(supervisor)
    ) {
      return 'admin'
    }
    
    const hasOnlyWorkflowReadPermission = hasWorkflowReadPermission(user?.permissions)
    
    if (hasOnlyWorkflowReadPermission) {
      return 'operator'
    } else {
      return 'admin'
    }
  }

  function hasWorkflowReadPermission(permissions: any): boolean {
    // Find the "workflow" permission in the array
    const workflowPermission = permissions?.find((permission: any) => permission.permission.toLowerCase().trim() === "workflow");

    // Check if the "workflow" permission exists and has "read" set to true
    if (workflowPermission && workflowPermission.read === true) {
        // Check if all other permissions are false
        const otherPermissions = permissions?.filter((permission: any) => permission.permission.toLowerCase().trim() !== "workflow");
        
        const allOtherPermissionsFalse = otherPermissions.every((permission: any) => 
            permission.create === false && permission.read === false && permission.update === false && permission.delete === false
        );
        const workflowActionsFalse = workflowPermission.create === false && workflowPermission.update === false && workflowPermission.delete === false;
        
        return allOtherPermissionsFalse && workflowActionsFalse;
      }

    return false; // If "workflow" permission doesn't exist or "read" is not true
}
  // Constants for admin dashboard
  const Tiles = [
    {
      title: t("User management"),
      icon: <Usermanagement />,
      target: "_self",
      url: "/backend",
      requiredPermissions: [
        { permission: "roles", create: true, read: true, update: true, delete: true },
        { permission: "users", create: true, read: true, update: true, delete: true },
      ],
      strict: false
    },
    {
      title: t("Application configuration"),
      icon: <ApplicationConfiguration />,
      target: "_self",
      url: "/application-configuration",
      requiredPermissions: [
        { permission: "mail template", create: true, read: true, update: true, delete: true },
        { permission: "translations", create: true, read: true, update: true, delete: true },
        { permission: "product catalogue", create: true, read: true, update: true, delete: true },
        { permission: "recaptcha", create: true, read: true, update: true, delete: true },
      ],
      strict: false
    },
    {
      title: t("Master data"),
      icon: <Masterdata />,
      target: "_self",
      url: "/master-data/manage",
      requiredPermissions: [
        {
          permission: "master data",
          create: true, read: true, update: true, delete: true
        },
      ],
      strict: false
    },
    {
      title: t("Workflow"),
      icon: <Workflow />,
      target: "_blank",
      url: `${process.env.REACT_APP_WORKFLOWS_URL}?access_token=${token}`,
      requiredPermissions: [
        {
          permission: "workflow",
          create: true
        },
      ],
      strict: true
    },
    {
      title: t("Reports"),
      icon: IconData.ReportIcon,
      target: "_self",
      url: "/reports",
      requiredPermissions: [
        {permission: "shipment reports",read: true},
        {permission: "published records",read: true},
        {permission: "hold overview",read: true},
        {permission: "time tracker overview",read: true},
      ],
      strict: false
    },
    {
      title: t("Media library"),
      icon: <Medialibrary />,
      target: "_self",
      url: "/media-library",
      requiredPermissions: [
        {
          permission: "media library",
          create: true, read: true, update: true, delete: true
        },
      ],
      strict: false
    },
    {
      title: t("Brand partners"),
      icon: <BrandPartnerLogo />,
      target: "_self",
      url: "/brand-partner",
      requiredPermissions: [
        {
          permission: "brand partner",
          create: true, read: true, update: true, delete: true
        },
      ],
      strict: false
    },
    {
      title: t("Operations"),
      icon: <ShipmentLogo />,
      target: "_self",
      url: "/shipment",
      requiredPermissions: [
        {
          permission: "workflow",
          read: true
        },
      ],
      strict: true
    },
    
  ];
  return (
    <>
      {showPage == '' ? (
        <LoadingIcon display="none" />
      ) : showPage == 'admin' ? (
        <div className="row">
          <div className="col-11 ms-auto px-4">
            <div className="py-xxl-4 py-xl-3 py-lg-3 page-title">
              {t("Admin dashboard")}
            </div>
            <div className="row text-center text-gray">
              {Tiles?.map((item: any) => {
                return (
                  <React.Fragment key={item.title}>
                    <AccessControl requiredPermissions={item.requiredPermissions} strict={item.strict} >
                      <AdminCard
                        title={item.title}
                        icon={item.icon}
                        url={item.url}
                        target={item?.target}
                      />
                    </AccessControl>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      ) : navigate('/shipment')}
    </>
  );
};

export default Admindashboard;
