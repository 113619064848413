import React, { ReactNode, useEffect, useState } from "react";
import { useUserContext } from "../routes/Contextlib";
import AccessDenied from "../pages/AccessDenied";
import { APICALL } from "./ApiServices";
import { fetchWorkflowStages } from "../routes/ApiEndPoints";

type Props = {
  stage_id?: any;
  stage_name?: any;
  children?: ReactNode;
  renderNoAccess?: boolean;
};

const checkPermissions = (
  stage_id: any,
  user_id: any,
  bpPermissionDetails: any
) => {
  return bpPermissionDetails[stage_id]?.some(
    (item: any) => item.user_id === user_id
  );
};

const AccessControlWorkFlow: React.FC<Props> = ({
  stage_id = null,
  stage_name,
  children,
  renderNoAccess = false,
}) => {
  const { user } = useUserContext();
  const [stageId, setStageId] = useState([]);
  const bpPermissiondata = localStorage.getItem("bp_permissions_details");
  const bpPermissionDetails =
    bpPermissiondata != null ? JSON.parse(bpPermissiondata) : "";
  const [permitted, setPermitted] = useState<boolean>(false);
  const [noAccess, setNoAccess] = useState<boolean>(false);

  const fetchStages = async (stage_name: string) => {
    try {
      const response = await APICALL.service(fetchWorkflowStages, "GET", "");
      if (response?.status === 200) {
        return response.data.find((item: any) => item.stage_name === stage_name)
          .stage_id;
      }
    } catch (error) {
      console.error("Error fetching stages:", error);
    }
  };

  const hasAdminOrSupervisorRole = user?.roles?.some(
    (role) =>
      role.toLowerCase().trim() === process.env.REACT_APP_ADMIN || role.toLowerCase().trim() === process.env.REACT_APP_SUPERVISOR
  );

  useEffect(() => {
    if (!hasAdminOrSupervisorRole) {
      fetchWorkflowStage();
    }
  }, [hasAdminOrSupervisorRole]);

  const fetchWorkflowStage = async () => {
    if (stage_id === null) {
      const Id = await fetchStages(stage_name);
      setStageId(Id);
    } else {
      setStageId(stage_id);
    }
  };

  let isPermitted = false;

    if (hasAdminOrSupervisorRole){
      isPermitted = true
    } else {
      isPermitted = checkPermissions(stageId, user?.user_id, bpPermissionDetails)
    }

    useEffect(() => {
      if (isPermitted) {
        setPermitted(true);
        setNoAccess(false);
      } else {
        setPermitted(false);
        setNoAccess(true);
      }
    },[isPermitted])
    

    if (noAccess && renderNoAccess && isPermitted != undefined) {
      return <AccessDenied />;
    }
    if (permitted) {
      return <>{children}</>;
    }
};

export default AccessControlWorkFlow;
