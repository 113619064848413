import React, { useEffect, useState } from "react";
import {
  fetchList,
  getTimeTrackerDetails,
  getUsernames
} from "../../../routes/ApiEndPoints";
import { APICALL } from "../../../services/ApiServices";
import {
  ConfigConstants,
  TimeTrackerOverviewConstants,
} from "../../../pages/Shipment/Constants/ShipmentConstants";
import CommonServices from "../../../services/CommonServices";
import CommonShipmentServices from "../../../services/CommonShipmentServices";
import { t } from "../../../pages/Translations/TranslationUtils";
import CustomFilterHeaderForReports from "../../molecules/CustomFilterHeaderForReports";
import TimeTrackerOverviewCardMolecule from "../../molecules/TimeTracker/TimeTrackerOverviewCardMolecule";
import ActionButtonGroup from "../../molecules/ActionButtonGroup";
import { useNavigate } from "react-router-dom";
import WindowHeightComponent from "../../../commonComponents/WindowHeightComponent";

const TimeTrackerOverviewOrganism = () => {
  const [data, setData] = useState([]);
  const [state, setState] = useState({
    brandPartners: [],
    // shipments: [],
    renewalData: [],
    users: [],
    stages: [],
    workflowstages: [],
  });

  const [selectedFilterData, setSelectedFilterData] = useState({
    brand_partner_id: [],
    // shipment_id: [],
    stage_id: [],
    renewal_id: [],
    user_id: [],
    filter: "all",
    from_date: null,
    to_date: null
  });
  const navigate = useNavigate();
  useEffect(() => {
    if (state.brandPartners?.length) {
      fetchTimeTrackerData();
    } else {
      fetchData();
    }
  }, [state.brandPartners]);

  useEffect(() => {
    if (selectedFilterData.filter !== 'all') {
      // fetchTimeTrackerData("shipments");
      fetchTimeTrackerData("renewal");
    }
  }, [selectedFilterData.brand_partner_id]);

  // useEffect(() => {
  //   if (selectedFilterData.filter !== 'all') {
  //     fetchTimeTrackerData("renewal");
  //   }
  // }, [selectedFilterData.shipment_id]);

  const fetchData = async () => {
    try {
      let postData = {
        method: "POST",
        data: {
          activestatus: [true, false]
        }
      };
      let fetchedUsernames = await APICALL.service(getUsernames, "POST", postData, true);
      let configreqdata = {
        method: "POST",
        data: {
          list: [ConfigConstants.BRANDPARTNER],
        },
      };
      const configres = await APICALL.service(fetchList, "POST", configreqdata, true);
      if (configres.status === 200) {
        setState((prevState) => ({
          ...prevState,
          users: CommonServices.sortAlphabattically(fetchedUsernames?.data ?? [], "full_name"),
          brandPartners: CommonServices.sortAlphabattically(configres?.data?.BrandPartner),
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClearFilter = () => {
    setSelectedFilterData((prevValues) => ({
      ...prevValues,
      brand_partner_id: [],
      // shipment_id: [],
      stage_id: [],
      renewal_id: [],
      user_id: [],
      filter: "",
      from_date: null,
      to_date: null
    }));
  };
  const handleValidation = () => {
    if (selectedFilterData.brand_partner_id.length === 0
      // && selectedFilterData.shipment_id.length === 0 
      && selectedFilterData.renewal_id.length === 0) {
      return t("Please select at least one brand partner or renewal record");
    }
    return false
  }

  const fetchTimeTrackerData = async (filter?: any) => {
    try {
      const validate = handleValidation();

      const postdata: any = { ...selectedFilterData };

      if (filter) {
        postdata.filter = filter;
      }
      if (postdata.filter === "all" || validate) {
        postdata.brand_partner_id = await getSelectOptions(state.brandPartners, "brand_partner_id", "title");
      }
      if (postdata.from_date) {
        postdata.from_date = await CommonServices.getTimezoneOffset(postdata.from_date);
      }
      if (postdata.to_date) {
        postdata.to_date = await CommonServices.getTimezoneOffset(postdata.to_date);
      }
      const response = await APICALL.service(getTimeTrackerDetails, "POST", postdata);
      // let filteredShipments = selectedFilterData.shipment_id;
      let filteredrenewalrecords = selectedFilterData.renewal_id;
      if (response.status === 200) {
        // if (filter === "shipments") {
        //   filteredShipments = response?.shipments?.length ? selectedFilterData.shipment_id.filter((item: any) =>
        //     response.shipments.some((shipment: any) => shipment.shipment_id === item.value)
        //   ) : [];
        //   filteredrenewalrecords = response?.renewaldata?.length ? selectedFilterData.renewal_id.filter((item: any) =>
        //     response.renewaldata.some((record: any) => record.renewal_id === item.value)
        //   ) : [];
        //   setSelectedFilterData((prevData) => ({
        //     ...prevData,
        //     shipment_id: filteredShipments ?? [],
        //     renewal_id: filteredrenewalrecords ?? []
        //   }));
        //   setState((prevState) => ({
        //     ...prevState,
        //     shipments: CommonServices.sortAlphabattically(response?.shipments ?? [], "title"),
        //     renewalData: response?.renewaldata ?? [],
        //   }));
        // } else 
        if (filter === "renewal") {
          filteredrenewalrecords = response?.renewaldata?.length ? selectedFilterData.renewal_id.filter((item: any) =>
            response.renewaldata.some((record: any) => record.renewal_id === item.value)
          ) : [];
          setSelectedFilterData((prevData) => ({
            ...prevData,
            renewal_id: filteredrenewalrecords ?? []
          }));
          setState((prevState) => ({ ...prevState, renewalData: response?.renewaldata ?? [] }));
        } else {
          if (selectedFilterData.filter === "all") {
            setState((prevState) => ({
              ...prevState,
              shipments: CommonServices.sortAlphabattically(response?.shipments ?? [], "title"),
              stages: CommonServices.sortAlphabattically(
                response?.stages
                  ?.map((item: any) => ({
                    ...item,
                    stage_name: CommonServices.capitalizeLabel(item?.stage_name ?? ""),
                  })),
                'stage_name'
              ),
              renewalData: response?.renewaldata,
              workflowstages: response?.workfowstages
            }));
          }
          // Filter items where "tier" is "active" and update stage_name
          const filteredData = response?.data.map((item: any) => {
            // Modify the stage_name property
            item.stage_name = CommonServices?.capitalizeLabel(item?.stages?.stage_name ?? "");
            const brand_partner = item.brand_partner_id ? CommonShipmentServices.getFilteredTitle(state.brandPartners, "brand_partner_id", item.brand_partner_id) : "-";
            const shipment_title = item.shipments?.title || "-";
            return { ...item, brand_partner: brand_partner[0], shipment_title };
          });
          // Set the filtered data as the state]
          setData(filteredData);
        }
        if (selectedFilterData.filter) {
          setSelectedFilterData((prevData) => ({
            ...prevData,
            filter: "",
          }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSelectOptions = (data: any, id: any, label: any) => {
    const convertedData: any = Object.values(data).map((item: any) => ({
      value: item[id],
      label: item[label],
    }));
    return convertedData;
  };

  const handleSelectedFilter = (value: any, key: string) => {
    setSelectedFilterData((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleSelectedData = (selected: any) => {
    if (handleValidation()) {
      // Check if an update is necessary
      if (
        selectedFilterData.stage_id.length !== 0 ||
        selectedFilterData.renewal_id.length !== 0 ||
        selectedFilterData.user_id.length !== 0
      ) {
        setSelectedFilterData((prevValues) => ({
          ...prevValues,
          stage_id: [],
          renewal_id: [],
          user_id: [],
          // shipment_id: [],
        }));
      }
      return [];
    } else {
      return selected;
    }
  }

  const handleSelectedTime = (selected: any) => {
    if (handleValidation()) {
      // Check if an update is necessary
      if (
        selectedFilterData.from_date !== null ||
        selectedFilterData.to_date !== null
      ) {
        setSelectedFilterData((prevValues) => ({
          ...prevValues,
          from_date: null,
          to_date: null
        }));
      }
      return null;
    } else {
      return selected;
    }
  }

  // Define an array of filter objects with properties for rendering filters
  const filters: any = [
    { type: "multiSelect", label: t("Brand partners"), key: "brand_partner_id", selected: handleSelectedData(selectedFilterData.brand_partner_id), options: getSelectOptions(state.brandPartners, "brand_partner_id", "title"), },
    // { type: "multiSelect", label: t("Shipments"), key: "shipment_id", selected: handleSelectedData(selectedFilterData.shipment_id), options: getSelectOptions(state.shipments, "shipment_id", "title"), },
    { type: "multiSelect", label: t("Renewal records"), key: "renewal_id", selected: handleSelectedData(selectedFilterData.renewal_id), options: getSelectOptions(state.renewalData, "renewal_id", "serial_number"), },
    { type: "multiSelect", label: t("Stages"), key: "stage_id", selected: handleSelectedData(selectedFilterData.stage_id), options: getSelectOptions(state.stages, "stage_id", "stage_name"), disable: handleValidation() },
    { type: "multiSelect", label: t("Users"), key: "user_id", selected: handleSelectedData(selectedFilterData.user_id), options: getSelectOptions(state.users, "user_id", "full_name"), disable: handleValidation() },
    { type: "calender", label: t("From date"), key: "from_date", selected: handleSelectedTime(selectedFilterData.from_date), "max_date": selectedFilterData.to_date, disable: handleValidation() },
    { type: "calender", label: t("To date"), key: "to_date", selected: handleSelectedTime(selectedFilterData.to_date), "min_date": selectedFilterData.from_date, disable: handleValidation() },
  ];

  return (
    <div className="row">
      <div className="col-11 ms-auto px-4">
        <WindowHeightComponent>
          <div className="d-flex flex-column overflow-auto h-100">
            <div className="py-xxl-4 py-xl-3 py-lg-3 page-title">
              {t(TimeTrackerOverviewConstants.TIMETRACKEROVERVIEW)}
            </div>
            <CustomFilterHeaderForReports
              filters={filters}
              handleSelectedFilter={handleSelectedFilter}
              applyFilter={() => fetchTimeTrackerData()}
            // clearFilter={handleClearFilter}
            />
            <div className="flex-1 overflow-auto">
              <TimeTrackerOverviewCardMolecule
                data={data}
                workflowStages={state.workflowstages}
                t={t}
                TimeTrackerOverviewConstants={TimeTrackerOverviewConstants}
                CommonServices={CommonServices}
              />
            </div>
            <ActionButtonGroup
              backTitle={t("Back")}
              handleBackClick={() => navigate('/reports')}
            />
          </div>
        </WindowHeightComponent>
      </div>
    </div>
  );
};

export default TimeTrackerOverviewOrganism;