import React from 'react'

const Reneval_lite = () => {
  return (
    <>
      <svg id="Group_49955" data-name="Group 49955" xmlns="http://www.w3.org/2000/svg" width="188.3" height="160.655" viewBox="0 0 188.3 160.655">
        <ellipse id="Ellipse_229" data-name="Ellipse 229" cx="94.15" cy="8.716" rx="94.15" ry="8.716" transform="translate(0 143.223)" fill="rgba(84,89,96,0.06)" />
        <g id="Group_48443" data-name="Group 48443" transform="translate(10.664)">
          <rect id="Rectangle_10697" data-name="Rectangle 10697" width="137.715" height="98.18" transform="translate(13.906 53.759)" fill="#d3985a" />
          <rect id="Rectangle_10698" data-name="Rectangle 10698" width="137.715" height="10.631" transform="translate(13.906 53.759)" fill="#fff" opacity="0.09" />
          <rect id="Rectangle_10699" data-name="Rectangle 10699" width="122.852" height="53.759" transform="translate(21.329)" fill="rgba(211,152,90,0.9)" />
          <path id="Path_56292" data-name="Path 56292" d="M286.14,243.529l12.243-18.659,9.086,18.659L293.58,270.416Z" transform="translate(-141.959 -216.658)" fill="rgba(211,152,90,0.9)" />
          <path id="Path_56293" data-name="Path 56293" d="M221.619,243.529,209.376,224.87l-9.086,18.659L214.2,270.416Z" transform="translate(-200.29 -216.658)" fill="rgba(211,152,90,0.9)" />
          <path id="Path_56294" data-name="Path 56294" d="M358.079,231.49H203.57l8.4,34.429H349.682Z" transform="translate(-198.061 -212.16)" fill="#d3985a" />
          <path id="Lite_weight_renewal" data-name="Lite weight renewal" d="M28.514,43.954A12.436,12.436,0,1,1,37.308,47.6a12.436,12.436,0,0,1-8.794-3.642Zm6.479-2.384a6.806,6.806,0,0,0,7.551-2.055v1.927H43.59V37.255H39.4V38.3h2.733A5.759,5.759,0,0,1,31.548,35.16H30.5a6.826,6.826,0,0,0,4.492,6.409Zm3.955-11.93a5.757,5.757,0,0,1,4.121,5.522H44.12a6.806,6.806,0,0,0-12.043-4.356V28.873H31.025v4.189h4.189V32.015H32.481a5.752,5.752,0,0,1,6.467-2.379ZM5.7,33.229A19.465,19.465,0,1,1,38.93,19.465a19.751,19.751,0,0,1-.15,2.428A12.445,12.445,0,0,0,24.52,38.271a19.6,19.6,0,0,1-5.055.659A19.333,19.333,0,0,1,5.7,33.229Zm9.881-17.556C12,19.438,10.077,23.35,9.856,27.3L7.1,28.865A.287.287,0,0,0,7,29.3a.5.5,0,0,0,.254.124h.069a.478.478,0,0,0,.224-.05l2.812-1.594c.266.005.541.009.809.009,7.946,0,13.577-2.584,16.739-7.683a.224.224,0,0,0,.019-.2.249.249,0,0,0-.117-.172l-1.719-1.172H29.12a.446.446,0,0,0,.176-.031.541.541,0,0,0,.156-.108,10.825,10.825,0,0,0,2.4-8.645.292.292,0,0,0-.137-.2.487.487,0,0,0-.274-.078C24.577,9.757,19.238,11.835,15.582,15.672ZM10.7,27.143l.019-.235c3.813-2.221,6.3-4.029,7.405-5.377a22.8,22.8,0,0,1,2.169-2.47c-.042.079-.108.184-.2.314-.264.39-.744,1.047-1.426,1.953a25.437,25.437,0,0,1-7.245,5.8l-.722.015Z" transform="translate(57.893 79.052)" fill="#fff" />
        </g>
      </svg>
    </>
  )
}

export default Reneval_lite

