import React from 'react'

const CreateShipmenticon = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="42.922" height="50" viewBox="0 0 42.922 42.922">
        <path id="Add_widget_v02" data-name="Add widget_v02" d="M-185.071,15238.922h-34.936a4,4,0,0,1-3.993-3.992v-34.936a3.994,3.994,0,0,1,3.993-3.994h34.936a3.994,3.994,0,0,1,3.993,3.994v34.936A4,4,0,0,1-185.071,15238.922Zm-29.865-23.012a1.552,1.552,0,0,0-1.55,1.549,1.551,1.551,0,0,0,1.55,1.551h10.849v10.85a1.551,1.551,0,0,0,1.55,1.551,1.551,1.551,0,0,0,1.55-1.551v-10.85h10.845a1.55,1.55,0,0,0,1.55-1.549,1.552,1.552,0,0,0-1.55-1.551h-10.845v-10.848a1.551,1.551,0,0,0-1.55-1.549,1.551,1.551,0,0,0-1.55,1.549v10.848Z" transform="translate(224 -15196)" fill="#545960" />
      </svg>
    </>
  )
}

export default CreateShipmenticon