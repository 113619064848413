import React, { RefObject, useState } from 'react';
import Button from '../atoms/Button';
import InputTextfield from '../atoms/InputTextField'; // Correct the import path
import { t } from '../../pages/Translations/TranslationUtils';
import ErrorMessage from '../atoms/ErrorMessage';

interface InputWithButtonProps {
  handleClick: (param: any) => void;
  textFieldClass?: string;
  buttonClass?: string;
  buttonTitle?: string;
  error?: string;
  noerror?: boolean;
  placeholder?: string;
  inputRef?: RefObject<HTMLInputElement> | null;
}

const InputWithButton: React.FC<InputWithButtonProps> = ({
  handleClick,
  textFieldClass = "",
  buttonClass = "",
  buttonTitle = "Submit",
  error = "",
  noerror = false,
  placeholder = t("Search"),
  inputRef = null
}) => {
  const [state, setState] = useState({
    inputValue: '',
    error: false
  });

  const handleSubmit = () => {
    if (state.inputValue || noerror) {
      handleClick(state.inputValue.trim());
    } else {
      setState((prevState) => ({
        ...prevState,
        error: true,
      }));
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      inputValue: event.target.value,
    }));
  };

  return (
    <>
      <div className='d-flex'>
        <InputTextfield
          type="text"
          placeholder={placeholder}
          value={state.inputValue}
          inputRef={inputRef}
          handleChange={handleInputChange}
          handleClick={() => setState((prevState) => ({ ...prevState, error: false }))}
          className={textFieldClass + ` me-3 ${state.error ? "border-danger" : ""} rounded`}
        />
        <Button
          title={buttonTitle}
          handleClick={handleSubmit}
          className={buttonClass + ' submit-btn'}
        />
      </div>
      {state.error && <ErrorMessage
        errorMessage={t(error)}
        className='fs-6 text-start'
      />}
    </>
  );
};

export default InputWithButton;
