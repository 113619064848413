// SelectTierPopup
import React, { useEffect, useState } from 'react'
import CommonServices from '../../../services/CommonServices';
import IconData from '../../../static/IconData';
import ImageField from '../../atoms/ImageField';
import styles from "../molecules.module.css";

interface SelectTierProps {
    handleClick: (id: any, tierTitle: string) => void;
    tierlist?: any;
}

const SelectTierPopup: React.FC<SelectTierProps> = ({ handleClick, tierlist }) => {
    const [list, setTierList] = useState<any>()
    useEffect(() => {
        const fetchConfigData = async () => {
            if (tierlist != null || tierlist != undefined) {
                setTierList(tierlist)
            } else {
                const data = await CommonServices.fetchMasterData("model", "ProductTier", true)                
                setTierList(data)
            }
        }
        fetchConfigData();
    }, [])
    const [selected, setSelected] = useState("")
    const handleSelect = (id: any, tierTitle: string) => {
        setSelected(id)
        handleClick(id, tierTitle)
    }

    return (
        <div className={`d-flex flex-wrap pt-5 ${styles.select_tier_popup} `} >
            {list?.map((item: any) => (
                <div key={item.product_tier_id} id={item.product_tier_id} onClick={() => handleSelect(item.product_tier_id, item.title)}
                    className={`max-content-height p-4 pb-0 cursor-pointer d-flex flex-column ${styles.product_tier_image} ${(selected == item.product_tier_id) ? "shadow" : ''}`}>
                    {(item.icon != null && item.icon != '') ?
                        <div className='flex-1'> <ImageField className={`object-fit-content w-100 ${styles.tierimg} `} value={item.icon} /></div> :
                        IconData.Tier
                    }
                    <div className={`flex-1 fs-4 my-2 text-break`}>{CommonServices.stringFormat(item.title)}</div>
                </div>
            ))}
        </div>
    )
}

export default SelectTierPopup;
