import React, { useEffect, useState } from "react";
import InputRangefield from "../components/atoms/InputRangeField";
import LabelField from "../components/atoms/LabelField";
import { APICALL } from "../services/ApiServices";
import { updateCaptchaScore, ManageCaptchaScore } from "../routes/ApiEndPoints";
import Button from "../components/atoms/Button";
import FormValidation from "../services/FormValidation";
import AccessControl from "../services/AccessControl";
import { t } from "./Translations/TranslationUtils";
import { useNavigate } from "react-router-dom";
import { CommonConstants } from "./Shipment/Constants/ShipmentConstants";
import customAlert from "../components/atoms/CustomAlert";
import CommonServices from "../services/CommonServices";
import { useUserContext } from "../routes/Contextlib";
import WindowHeightComponent from "../commonComponents/WindowHeightComponent";

const ManageRecaptcha = () => {
  const { user } = useUserContext();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [requiredPermissions, setRequiredPermissions] = useState<any>([]);
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInputValue(value);
  };

  const handleUpdate = () => {
    let error = "";
    if (inputValue === "") {
      error = "This field is required";
    }
    if (error == "") {
      error = FormValidation.isDecimalValidation(inputValue, 0.1, 0.8);
    }
    setError(error);
    if (error == "") {
      updateScore();
    }
  };

  useEffect(() => {
    manageScore();
  }, []);

  useEffect(() => {
    const storageMessage = localStorage.getItem("successAlertMessage");
    localStorage.removeItem("successAlertMessage");
    if (storageMessage) {
      localStorage.removeItem("successAlertMessage");
      customAlert("success", t(storageMessage), 8000);
    }
  }, [refresh]);

  async function updateScore() {
    try {
      const postData = {
        // Encrypting the request data
        method: "POST",
        data: { title: "recaptcha", score: inputValue },
      };
      const response = await APICALL.service(
        updateCaptchaScore,
        "POST",
        postData,
        true
      );

      if (response.status === 200) {
        localStorage.setItem(
          "successAlertMessage",
          "Score updated successfully"
        );
        setRefresh(!refresh);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function manageScore() {
    try {
      const postData = {
        // Encrypting the request data
        method: "POST",
        data: {},
      };
      const response = await APICALL.service(
        ManageCaptchaScore,
        "POST",
        postData,
        true
      );

      if (response?.status === 200) {
        setInputValue(response.score[0]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // useEffect(() => {
  //   const checkStatus = async () => {
  //     if (user != null){
  //       const status = await CommonServices.checkPermissions(user.permissions, [
  //         {
  //           permission: "recaptcha",
  //           update: true,
  //         },
  //       ], true, false)
  //       setIsDisabled(!status)
  //     }
  //   }
  //   checkStatus()
  // },[user])

  useEffect(() => {
    const userPerm: any = user?.permissions;
    const truePerm: any = userPerm?.find(
      (item: any) => item.permission.toLowerCase().trim() === "recaptcha"
    );
    let permissions = [
      {
        permission: "recaptcha",
        read: true,
      },
    ];
    if (truePerm?.update === true) {
      permissions[0].read = false;
      setIsDisabled(false);
    }
    setRequiredPermissions(permissions);
  }, [user != null]);

  return (
    <AccessControl
      requiredPermissions={requiredPermissions}
      renderNoAccess={true}
    >
      <>
        {" "}
        <div className="row">
          <div className="col-md-11 ms-auto px-4">

            <WindowHeightComponent>
              <div className="d-flex flex-column overflow-auto h-100">
                <div className="py-xxl-4 py-xl-3 py-lg-3 page-title top-0">
                  {t("Manage reCAPTCHA")}
                </div>

                <div className="flex-1">
                  <LabelField title={t("Set minimum score")} />
                  <div className="row m-0">
                    <div className="col-md-6 mb-4 mt-3 position-relative">
                      <InputRangefield
                        className="border rounded text-gray ps-2 min-height-50"
                        name={"dimension"}
                        handleChange={handleInputChange}
                        handleClick={(event) => event.stopPropagation()}
                        min={"0.1"}
                        max={"0.8"}
                        value={String(inputValue)}
                        step={"0.1"}
                        placeholder="0.0"
                        error={error}
                        isDisabled={isDisabled}
                      />
                    </div>
                    <div className="col-md-6 pe-5 mb-4 mt-3">
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "recaptcha",
                            update: true,
                          },
                        ]}
                      >
                        <Button
                          className="submit-btn"
                          title={t("update")}
                          handleClick={handleUpdate}
                        />
                      </AccessControl>
                    </div>
                  </div>


                </div>

                <div className="row m-0">
                  <div className="col-md-6 float-start p-0">
                    <button
                      className="back-btn shadow-none float-start text-decoration-none"
                      onClick={() => navigate("/application-configuration")}
                    >
                      {t(CommonConstants.BACK)}
                    </button>
                  </div>
                </div>
              </div>
            </WindowHeightComponent>
          </div>
        </div>
      </>
    </AccessControl>
  );
};

export default ManageRecaptcha;
