import React, { ChangeEvent, MouseEvent, MouseEventHandler } from "react";

interface CheckBoxFieldProps {
  name?: string;
  label?: string;
  customStyle?: React.CSSProperties;
  className?: string;
  id?: any;
  checked?: boolean;
  onCheck?: (event: ChangeEvent<HTMLInputElement>) => void;
  clicked?: MouseEventHandler<HTMLInputElement>;
  disable?: boolean;
}

let styles_id: React.CSSProperties = {};

const CheckBoxField: React.FC<CheckBoxFieldProps> = ({
  name,
  // value =null,
  label,
  customStyle = {},
  className,
  id = "",
  checked,
  onCheck,
  clicked,
  disable = false,
}) => {
  const handleClick = (event: MouseEvent<HTMLLabelElement>) => {
    // Only trigger the checkbox when clicking on the input element
    if ((event.target as HTMLInputElement).tagName !== "INPUT") {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <>
      <label
        style={{ ...styles_id, ...customStyle }}
        className={className}
        onClick={handleClick}
      >
        <input
          name={name}
          type="checkbox"
          checked={checked || false}
          id={id}
          disabled={disable}
          onChange={onCheck}
          onClick={clicked}
          className="form-check-input shadow-none rounded-0 me-2"
          style={{ border: "1px solid #4759694D" }}
        />
        <span> {label} </span>
      </label>
    </>
  );
};

export default CheckBoxField;
