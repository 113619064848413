import React, { useState, ChangeEvent, FocusEvent, MouseEvent } from "react";
import LabelField from "./LabelField";

const style = {};

interface Props {
  svgData?: React.ReactNode;
  iconLabel?: String;
  handleClick?: (value: any) => void;
  classname1?: string;
  classname2?: string;
  hoverText?: string;
}

const IconWithLabel: React.FC<Props> = ({ svgData, iconLabel, handleClick, classname1, classname2, hoverText = '' }) => {

  return (
    <>
      <div className={`${classname1}`} title={hoverText}
        onClick={handleClick}>
        <span className="cursor-pointer">{svgData}</span>
        {iconLabel && <LabelField title={iconLabel} className={classname2} />}
      </div>
    </>
  );
};

export default IconWithLabel;
