import React from 'react'

const minus = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="44.777" height="44.777" viewBox="0 0 44.777 44.777">
        <path id="Add_widget_v02" data-name="Add widget_v02" d="M-183.388,15240.777h-36.446a4.172,4.172,0,0,1-4.165-4.164v-36.447a4.166,4.166,0,0,1,4.165-4.166h36.446a4.166,4.166,0,0,1,4.165,4.166v36.447A4.172,4.172,0,0,1-183.388,15240.777Zm-31.155-24.008a1.621,1.621,0,0,0-1.617,1.617,1.617,1.617,0,0,0,1.617,1.617c11.83-.127,14.587.2,25.866,0a1.617,1.617,0,0,0,1.617-1.615,1.618,1.618,0,0,0-1.617-1.617C-189.741,15217.121-208.551,15216.8-214.544,15216.77Z" transform="translate(224 -15196)" fill="rgba(84,89,96,0.6)" />
      </svg>

    </>
  )
}

export default minus
