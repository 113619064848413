import { useInactivityTimeoutContext } from "./useInactivityTimeoutContext";

const useUpdateInactivityTimeoutService = () => {
  const { inactivityTimeoutState, updateInactivityTimeout } = useInactivityTimeoutContext();

  const updateTimeout = (stage_name: string) => {

    if (stage_name === "reset_default") {
      updateInactivityTimeout({
        ...inactivityTimeoutState,
        timeoutTime: null,
        redirectTime: null,
        showPopup: false,
        redirectUrl: "/logout"
      });
    } else {
      // console.log(stage_name);

      const workflowStages = localStorage.getItem('workflowstages') ? JSON.parse(localStorage.getItem('workflowstages') ?? '') : null;
      const workflowTimeoutDetails = localStorage.getItem('workflowTimeoutDetails') ? JSON.parse(localStorage.getItem('workflowTimeoutDetails') ?? '') : null;
      const stage_id = workflowStages?.find((item: any) => item.stage_name === stage_name)?.stage_id

      // console.log(stage_id);

      updateInactivityTimeout({
        ...inactivityTimeoutState,
        timeoutTime: workflowTimeoutDetails?.find((item: any) => item.stage_id == stage_id)?.value1,
        redirectTime: workflowTimeoutDetails?.find((item: any) => item.stage_id == stage_id)?.value2,
        redirectUrl: "/shipment-dashboard"
      });
    }

  };

  return { updateTimeout };
};

export default useUpdateInactivityTimeoutService;
