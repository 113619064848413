import React, { useEffect, useState } from 'react'
import Layout from '../../../pages/Shipment/Layout'
import { t } from '../../../pages/Translations/TranslationUtils'
import TableStructure from '../../atoms/TableStructure'
import { APICALL } from '../../../services/ApiServices'
import { managePhotoStudioUrl } from '../../../routes/ApiEndPoints'
import { DataEntryConstants } from '../../../pages/Shipment/Constants/ShipmentConstants'
import CommonShipmentServices from '../../../services/CommonShipmentServices'
import { useNavigate } from 'react-router-dom';
import Popup from '../../../utils/popup'
import { ScannerMolecule } from '../../molecules/ScannerMolecule'

const ManagePhotoStudioOrganism = () => {
    const localdata = CommonShipmentServices.getPostData();
    const [photoStudioInfo, setPhotoStudioInfo] = useState({})
    const navigate = useNavigate();
    const [popup, setPopup] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        fetchPhotoStudioInfo();
    }, [])

    const fetchPhotoStudioInfo = async () => {
        const response = await APICALL.service(managePhotoStudioUrl, 'POST', localdata);

        if (response.status == 200) {
            setPhotoStudioInfo(response?.data);
            if (response.data.length > 0) {
                setPopup(true)
            }
        }
    }

    const handleScan = async (value: any) => {
        const scan_value: any = Object.values(photoStudioInfo).find((item: any) => item.serial_number == value.trim())
        if (scan_value) {
            navigate(`/photo-studio/view?id=${scan_value.serial_number}`)
        } else {
            setError(true);
        }
    }

    return (
        <Layout pagename={t('Photo studio')} logo={true} timer={false}>
            <div className="flex-1 overflow-auto">
                <TableStructure
                    isAction
                    data={photoStudioInfo}
                    headers={DataEntryConstants.RENEWAL_TABLE_HEADERS}
                    values={DataEntryConstants.RENEWAL_TABLE_VALUES}
                    handleEdit={(value: any) => navigate(`/photo-studio/view?id=${value.serial_number}`)}
                />
            </div>
            {popup &&
                <Popup
                    cancel={() => setPopup(false)}
                    title={t("Scan renewal record")}
                    modalSize='xl'
                    body={
                        <ScannerMolecule
                            handleClick={handleScan}
                            error={error}
                        />
                    }
                />}
        </Layout>
    )
}

export default ManagePhotoStudioOrganism