import React from "react";
import ManagePhotoEditingOrganism from "../../../components/organisms/PhotoEditing/ManagePhotoEditingOrganism";
import AccessControlWorkFlow from "../../../services/AccessControlWorkFlow";
import AccessControl from "../../../services/AccessControl";

const ManagePhotoEditing = () => {
  return (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={[
        {
          permission: "workflow",
          read: true,
        },
      ]}
    >
      <AccessControlWorkFlow stage_name="photo_editing" renderNoAccess={true}>
        <ManagePhotoEditingOrganism />
      </AccessControlWorkFlow>
    </AccessControl>
  );
};

export default ManagePhotoEditing;
