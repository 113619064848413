import React, { useEffect, useState } from 'react'
import { getrowvalues } from "./State";
import { renderInput } from '../../../services/InputUtils';
import { RenewalServices } from '../../../services/RenewalServices';

interface RenewalRecordRightSideOrganismProps {
  className?: string;
  state: any;
  configdata?: any;
  determineHandler?: any;
  removeError?: any;
  formerrors?: any;
  view?: boolean;
  dataentry?: boolean;
  issku: boolean;
}
const RenewalRecordRightSideOrganism: React.FC<RenewalRecordRightSideOrganismProps> = ({
  className, state, configdata, determineHandler, removeError, formerrors, view, dataentry, issku
}) => {
  const [rows, setRows] = useState({})

  useEffect(() => {
    let values = getrowvalues(configdata, dataentry)
    setRows(values)
  }, []);

  return (
    <div className={`${className ?? 'col-7'} h-100 overflow-auto pe-0`}>
      <div className="py-4 pb-2 px-xxl-5 px-3 border rounded shadow h-100 position-relative overflow-auto">
        {/* <div className="fs-4 fw-bold text-break  mb-4">
          {RenewalServices.generateSkuNumber(state, issku)}
        </div> */}
        {rows && rows != null && state.serial_number != '' &&
          Object.entries(rows).map(([key, values]) => (
            <>
              {values &&
                <div className="row">
                  {Object.entries(values).map(([key1, value1]) => (
                    <div className="col-12 mb-xxl-5 mb-2 col-xxl-6">
                      {
                        renderInput(
                          removeError,
                          value1,
                          determineHandler(value1.type),
                          state,
                          formerrors,
                          view ? true : (value1.disable ? value1.disable : false),
                          true
                        )}
                    </div>
                  ))}
                </div>
              }
            </>
          ))
        }
      </div>
    </div>
  )
}
export default RenewalRecordRightSideOrganism;
