import React, { useEffect, useState } from 'react';
import DivButtonAtom from '../atoms/DivButtonAtom';
import styles from '../../pages/Shipment/QualityControl/Qualitycontrol.module.css';
import LabelField from '../atoms/LabelField';
import ErrorMessage from '../atoms/ErrorMessage';
import { t } from '../../pages/Translations/TranslationUtils';
import CommonServices from '../../services/CommonServices';

interface DisplayBlockMoleculeProps {
  handleTriggerEvent?: any
  state: any
  all?: boolean
  list?: any
  errors?: any
  idname: string
  label: string
  mandatory?: boolean
}

const DisplayBlockMolecule: React.FC<DisplayBlockMoleculeProps> = ({ handleTriggerEvent, state, list, errors, idname, all, label, mandatory = false }) => {

  return (
    <div className='mt-4'>
      <div>
        <LabelField
          title={label}
          mandatory={mandatory}
        />
      </div>
      <div className="d-flex flex-wrap">
        {all &&
          <DivButtonAtom
            key="all"
            id="0"
            title={t("All")}
            blockStyle={(state[idname] == '0') ? styles.activeblocks : ''}
            handleTriggerEvent={handleTriggerEvent}
          />
        }
        {Object.values(list).map((item: any) => (
          <DivButtonAtom
            key={item[idname]}
            id={item[idname]}
            title={item.title ?? ""}
            blockStyle={(state[idname] == item[idname]) ? styles.activeblocks : ''}
            handleTriggerEvent={handleTriggerEvent}
          />
        ))}
      </div>
      <ErrorMessage
        errorMessage={t(errors[idname])}
      />
    </div>
  );
};

export default DisplayBlockMolecule;
