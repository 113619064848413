import React, { useEffect, useState, useRef } from "react";
import CustomAlert from "../../components/atoms/CustomAlert";
import { t } from "../Translations/TranslationUtils";
import Edit from "../../static/icons/Edit";
import Pagination from "../../services/Paginations";
import { APICALL } from "../../services/ApiServices";
import {
  fetchLanguageStringsUrl,
  importTranslations,
} from "../../routes/ApiEndPoints";
import { useNavigate } from "react-router-dom";
import AccessControl from "../../services/AccessControl";
import { CommonConstants } from "../Shipment/Constants/ShipmentConstants";
import { useUserContext } from "../../routes/Contextlib";
import Button from "../../components/atoms/Button";
import DownloadXlsx from "../../services/DownloadXlsx";
import styles from "./../ProductCatalogue/MailTemplate.module.css";
import { Upload } from "../../static/icons/Upload";
import * as XLSX from "xlsx";
import customAlert from "../../components/atoms/CustomAlert";
import InputWithButton from "../../components/molecules/InputWithButton";
import { CONSTANTS } from "../../applicationConstants/AppConstants";
import WindowHeightComponent from "../../commonComponents/WindowHeightComponent";
import { set } from "date-fns";

const ManageTranslations = () => {
  const { user } = useUserContext();
  const [requiredPermissions, setRequiredPermissions] = useState<any>([]);
  const [languages, setLanguages] = useState([]);
  const [translations, setTranslations] = useState<{ [key: string]: any }>({});
  const [state, setState] = useState({
    offset: 0,
    limit: CONSTANTS.PAGINATION_LIMIT,
    searchText: "",
    totalPages: 1,
    totalLength: 1,
    currentPage: 1,
  })
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    // Fetch the language strings from your backend API
    fetchLanguageStrings();
  }, [state.offset, state.searchText]);

  const fetchLanguageStrings = async (filter?: any) => {
    try {
      let data: any = {
        manage_translations: true,
        search: state.searchText
      }
      if (filter !== 'export') {
        data.offset = state.offset;
        data.limit = state.limit;
      } else {
        data.export = true
      }
      const postData = {
        method: "POST",
        data: data
      };
      const response = await APICALL.service(
        fetchLanguageStringsUrl,
        "POST",
        postData,
        true
      );
      if (response?.status == 200) {
        if (filter === 'export') {
          return response?.data;
        } else {
          response?.languageOptions && setLanguages(response?.languageOptions);
          setTranslations(response?.data);
          const totalPages = Math.ceil(
            response?.totalLength / state.limit
          );
          setState((prev) => ({ ...prev, totalLength: response?.totalLength, totalPages: totalPages }))
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (e: string) => {
    const url = `/translations/update/?edit=${e}`;
    window.location.href = url;
  };

  window.addEventListener("beforeunload", () => {
    const urlExtension = "/translations/manage";
    localStorage.setItem("backbuttonURL", urlExtension);
  });

  const handlePaginationClick = (pageNumber: number) => {
    const newOffset = (pageNumber - 1) * state.limit;
    setState((prevData) => ({
      ...prevData,
      currentPage: pageNumber,
      offset: newOffset,
    }))
  };

  const handleImport = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const requiredColumns: any = ["String", "En", "Es", "Nl", "Po"];
    const file = event.target.files && event.target.files[0];
    if (file) {
      const fileExtension = file.name.split(".").pop();
      if (fileExtension == "xlsx") {
        const workbook = XLSX.read(await file.arrayBuffer(), { type: "array" });
        // Assuming you want to read data from the first sheet
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        // Parse the data
        const importedData: any = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
        });

        const arrayComp = compareArrays(requiredColumns, importedData[0]);

        if (arrayComp.isEqual) {
          const oldData: any = translations;
          const updatedData: any = checkForExistingAndNewData(
            oldData,
            importedData
          );
          if (updatedData == null) {
            customAlert('error', t("One or more cell has more than 255 characters"), 6000);
          } else if (Object.keys(updatedData)?.length > 0) {
            await updateTranslations(updatedData);
          } else {
            customAlert('error', t("No new data found"), 6000);
          }
        } else {
          customAlert('error',
            (`${t('Missing columns')} ${arrayComp.missingItems.map((item) => item).join(", ")}`),
            6000);
        }
      } else {
        customAlert('error', t("Please upload a valid file"), 6000);
      }
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const checkForExistingAndNewData = (oldData: any, importedData: any) => {
    for (let index = 1; index < importedData.length; index++) {
      const element = importedData[index];

      if (element.length == 0 || element[0] == undefined || element[0] == null || element[0] == '') {
        importedData.splice(index, 1);
        index--;
      }
    }

    const updatedData: any = {};

    for (let i = 1; i < importedData?.length; i++) {
      const [string, en, es, nl, po] = importedData[i];
      const handleString = string?.trim().toLowerCase().replace(/ /g, "_");
      if (
        string?.length > 255 ||
        en?.length > 255 ||
        es?.length > 255 ||
        nl?.length > 255 ||
        po?.length > 255
      ) {
        return null;
      }

      if (string !== "" || string !== null) {
        if (!oldData[handleString]) {
          // If the handleString is not present in oldData, add a new entry
          updatedData[handleString] = [
            {
              development_string: string,
              handle_string: handleString,
              en: en,
              es: es,
              nl: nl,
              po: po,
            },
          ];
        } else {
          // If the handleString is present, check for differences
          const oldEntry = oldData[handleString][0];
          if (oldEntry.en !== en || oldEntry.fr !== es || oldEntry.nl !== nl || oldEntry.po !== po) {
            updatedData[handleString] = [
              {
                development_string: string,
                handle_string: handleString,
                en: en,
                es: es,
                nl: nl,
                po: po,
              },
            ];
          }
        }
      }
    }
    return updatedData;
  };

  function compareArrays(
    array1: string[],
    array2: string[]
  ): { isEqual: boolean; missingItems: string[] } {
    const isEqual =
      array1?.length === array2?.length &&
      array1.every((item, index) => item === array2[index]);
    const missingItems = array1.filter((item) => !array2.includes(item));

    return {
      isEqual,
      missingItems,
    };
  }

  const updateTranslations = async (updatedData: any) => {
    try {
      const postData = {
        method: "POST",
        data: { data: updatedData },
      };
      const response = await APICALL.service(
        importTranslations,
        "POST",
        postData,
        true
      );
      if (response?.status == 200) {
        setState((prevData) => ({ ...prevData, currentPage: 1, offset: 0, searchText: "" }));
        if (state.offset === 0 && state.searchText === "") {
          fetchLanguageStrings();
        }
        customAlert('success', t(response.message), 6000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleExport = async () => {
    const requiredColumns: any = ["development_string", "en", "es", "nl", "po"];
    let data: any = await fetchLanguageStrings('export');

    const trans = Object.values(data).map((item: any) => {
      return item[0];
    });
    const selectedFields = trans.map((obj: any) =>
      requiredColumns.reduce((acc: any, column: string) => {
        acc[column] = obj[column];
        return acc;
      }, {})
    );
    const modifiedFields = selectedFields.map((obj: any) => {
      const newObj: any = { string: obj["development_string"], ...obj };
      delete newObj["development_string"];
      return newObj;
    });
    DownloadXlsx(modifiedFields, "Translations");
  };

  useEffect(() => {
    const storageMessage = localStorage.getItem("successAlertMessage");
    if (storageMessage) {
      localStorage.removeItem("successAlertMessage");
      CustomAlert("success", t(storageMessage), 6000);
    }
  });

  useEffect(() => {
    const userPerm: any = user?.permissions;
    const truePerm: any = userPerm?.find(
      (item: any) => item.permission.toLowerCase().trim() === "translations"
    );
    let permissions = [
      {
        permission: "translations",
        read: true,
      },
    ];
    if (truePerm?.update === true) {
      permissions[0].read = false;
    }
    setRequiredPermissions(permissions);
  }, [user != null]);

  return (
    <AccessControl
      requiredPermissions={requiredPermissions}
      renderNoAccess={true}
    >
      <div className="row">
        <div className="col-md-11 px-4 ms-auto">
          <WindowHeightComponent>

            <div className="d-flex flex-column overflow-auto h-100">
              <div className="py-xxl-4 py-xl-3 py-lg-3 page-title top-0">
                {t("Manage translations")}
              </div>

              <div className="flex-1 d-flex flex-column overflow-auto h-100">
                <div className="d-flex mb-3">
                  <div className="user-table-actions w-100">
                    <InputWithButton
                      handleClick={(searchText) => { setState((prev) => ({ ...prev, searchText: searchText, offset: 0, currentPage: 1 })) }}
                      textFieldClass="form-control shadow-none"
                      buttonClass="submit-btn shadow-none"
                      noerror={true}
                      buttonTitle={t('Search')}
                    />
                  </div>
                  <AccessControl
                    strict={false}
                    actions={true}
                    requiredPermissions={[
                      {
                        permission: "translations",
                        update: true,
                        create: true,
                      },
                    ]}
                  >
                    <div className="">
                      <div className="ms-3">
                        <div className={`d-flex justify-content-center`}>
                          <label
                            className={`${styles.file_input} d-flex align-items-center  justify-content-center back-btn   cursor-pointer mb-1`}
                            htmlFor="customFile"
                          >
                            <Upload />
                            <span className="ps-3">{t("Import")}</span>
                            <input
                              className={`visually-hidden`}
                              type="file"
                              onClick={(e: any) => e.target.value = null}
                              accept="xls/*"
                              id="customFile"
                              onChange={handleImport}
                              ref={fileInputRef}
                            />
                          </label>
                        </div>
                        <p
                          className={`${styles.import_error_text} text-gray text-break text-truncate text-left`}
                          title={t("Please select .xlsx file")}
                        >
                          {t("Please select .xlsx file")}
                        </p>
                      </div>
                    </div>
                  </AccessControl>
                  <div className="">
                    <Button
                      handleClick={() => handleExport()}
                      className={`rounded d-flex align-items-center submit-btn ms-3`}
                    >
                      {" "}
                      {t("Export")}{" "}
                    </Button>
                  </div>
                </div>


                <div className="manage-translation-table mb-4 flex-1 overflow-auto">
                  <table className="table">
                    <thead className="position-sticky top-0">
                      <tr>
                        <th>{t("String")}</th>
                        {
                          languages?.map((lang: any) => {
                            return <th>{ lang?.language_name }</th>
                          })
                        }
                        <AccessControl
                          requiredPermissions={[
                            {
                              permission: "translations",
                              update: true,
                            },
                          ]}
                        >
                          <th className="text-center">{t("Actions")}</th>
                        </AccessControl>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.values(translations).length > 0 ? (
                        Object.values(translations)?.map((type: any) => (
                          <tr key={type}>
                            <td>{type[0]?.development_string}</td>
                            {
                              languages.map((lang: any) => {                                
                                return <td>{type[0]?.[lang?.language_code]}</td>
                              })
                            }
                            <AccessControl
                              requiredPermissions={[
                                {
                                  permission: "translations",
                                  update: true,
                                },
                              ]}
                            >
                              <td className="text-break manage-users-action text-center">
                                <button
                                  onClick={() =>
                                    handleEdit(
                                      type[0]?.translation_id
                                    )
                                  }
                                  className="me-2 border-0 bg-transparent shadow-none"
                                  data-toggle="tooltip"
                                  title={t("Edit")}
                                >
                                  <Edit />
                                </button>
                              </td>
                            </AccessControl>
                          </tr>
                        ))
                      ) : (
                        <td colSpan={5} className="text-center text-danger">
                          {t(CommonConstants.NO_RESULTS_FOUND)}
                        </td>
                      )}
                    </tbody>
                  </table>
                </div>


                <div className="row m-0 position-relative mt-2">
                {state?.totalLength > state.limit && state.totalPages > 1 && (
                    <Pagination
                      currentPage={state.currentPage}
                      totalPages={state.totalPages}
                      handlePaginationClick={handlePaginationClick}
                    />
                  )}
                  <div className="col-md-6 float-start p-0">
                    <button
                      className="back-btn shadow-none float-start text-decoration-none"
                      onClick={() => navigate("/application-configuration")}
                    >
                      {t(CommonConstants.BACK)}
                    </button>
                  </div>
                 
                </div>
              </div>
            </div>
          </WindowHeightComponent>
        </div>
      </div>
    </AccessControl >
  );
};

export default ManageTranslations;
