import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { APICALL } from './ApiServices';
import { fetchTimeout } from '../routes/ApiEndPoints';

interface TimeoutState {
  page: string;
  defaultTime: number;
  timeoutTime: number | null;
  redirectTime?: number | null;
  redirectUrl: string;
  showPopup: boolean;
  userResponse: string;
  timeoutRedirection: boolean;
}

interface InactivityTimeoutContextData {
  inactivityTimeoutState: TimeoutState;
  updateInactivityTimeout: (newState: TimeoutState) => void;
}

const timeoutTimeInitialState: TimeoutState = {
  page: "all",
  defaultTime: 15,
  timeoutTime: null,
  redirectTime: null,
  redirectUrl: "/logout",
  showPopup: false,
  userResponse: "",
  timeoutRedirection: false,
};

const InactivityTimeoutContext = createContext<InactivityTimeoutContextData>({
  inactivityTimeoutState: timeoutTimeInitialState,
  updateInactivityTimeout: () => { },
});

export const useInactivityTimeoutContext = () => useContext(InactivityTimeoutContext);

interface ProviderProps {
  children: ReactNode;
}

export const InactivityTimeoutProvider: React.FC<ProviderProps> = ({ children }) => {
  const [inactiveTime, setInactiveTime] = useState<number>(0); // Tracks main inactivity time in seconds
  const [redirectInactiveTime, setRedirectInactiveTime] = useState<number>(0); // Tracks redirect timer in seconds
  const [inactivityTimeoutState, setInactivityTimeoutState] = useState<TimeoutState>(timeoutTimeInitialState);

  // Reset the inactivity timer
  const resetTimer = () => {
    setInactiveTime(0);
    setRedirectInactiveTime(0);
    setInactivityTimeoutState((prev) => ({ ...prev, showPopup: false, userResponse: "", timeoutRedirection: false }));
  };

  // List of events to track user activity
  const activityEvents = ["mousemove", "keydown", "mousedown", "touchstart"];

  useEffect(() => {
    const fetchTimeoutTime = async () => {
      try {
        const postData = {
          method: "GET",
          data: {},
        };
        const response = await APICALL.service(fetchTimeout, "POST", postData, true);
        if (response.status === 200) {
          setInactivityTimeoutState((prev) => ({
            ...prev,
            defaultTime: response.data.timeoutTime ?? 15
          }));
        }
      } catch (error) {
        console.error("Error fetching timeout:", error);
      }
    };
    fetchTimeoutTime();
  }, []);

  useEffect(() => {
    // console.log(inactivityTimeoutState);
    // console.log(redirectInactiveTime);


    if (!inactivityTimeoutState.showPopup) {
      // Handle user activity to reset the timer
      const handleActivity = () => resetTimer();
      activityEvents.forEach((event) => window.addEventListener(event, handleActivity));

      const checkInterval = 1; // Check inactivity every second

      // Main inactivity timer
      const mainTimer = setInterval(() => {
        setInactiveTime((prev) => prev + checkInterval);

        // console.log(`Timer 1: ${inactiveTime} seconds`);

        const timeOutTime = inactivityTimeoutState.timeoutTime ?? inactivityTimeoutState.defaultTime;
        // console.log(timeOutTime, "timeOutTime");

        // Trigger popup if the user is inactive for the specified timeout
        if (inactiveTime >= timeOutTime * 60) {
          clearInterval(mainTimer); // Stop the main timer
          setInactiveTime(0); // Reset the inactive time
          if (inactivityTimeoutState.redirectTime != null) {
            setInactivityTimeoutState((prev) => ({ ...prev, showPopup: true }));
          } else {
            resetTimer()
            window.location.href = inactivityTimeoutState.redirectUrl;
          }
        }
      }, checkInterval * 1000);

      return () => {
        clearInterval(mainTimer); // Cleanup the interval
        activityEvents.forEach((event) => window.removeEventListener(event, handleActivity));
      };
    }
  }, [inactivityTimeoutState.showPopup, inactiveTime]);


  useEffect(() => {
    if (inactivityTimeoutState.showPopup) {
      const handleActivity = () => resetTimer();
      // activityEvents.forEach((event) => window.addEventListener(event, handleActivity));
      const redirectCheckInterval = 1; // Check every second

      // Redirect timer logic
      const redirectTimer = setInterval(() => {
        setRedirectInactiveTime((prev) => prev + redirectCheckInterval);

        // console.log(`Timer 2: ${redirectInactiveTime} seconds`);

        // Log out when redirect time is reached
        const redirectTime = inactivityTimeoutState.redirectTime ?? 0;
        const timeoutTime = inactivityTimeoutState.timeoutTime ?? 0;

        if (
          redirectInactiveTime >= (redirectTime - timeoutTime) * 60 ||
          inactivityTimeoutState.userResponse === "no"
        ) {
          // console.log("cleared");
          clearInterval(redirectTimer); // Cleanup the interval
          setRedirectInactiveTime(0);
          // console.log("redirect 2");
          setInactivityTimeoutState((prev) => ({ ...prev, timeoutRedirection: true }));
          // resetTimer()
          // window.location.href = inactivityTimeoutState.redirectUrl;
        }

        if (inactivityTimeoutState.userResponse === "yes") {
          clearInterval(redirectTimer); // Cleanup the interval
          setRedirectInactiveTime(0);
          resetTimer()
        }
      }, redirectCheckInterval * 1000);

      return () => {
        clearInterval(redirectTimer); // Cleanup the interval
        activityEvents.forEach((event) => window.removeEventListener(event, handleActivity));
      };
    };
  }, [inactivityTimeoutState.showPopup, redirectInactiveTime, inactivityTimeoutState.userResponse]);

  const updateInactivityTimeout = (newState: TimeoutState) => {
    setInactivityTimeoutState(newState);
  };

  return (
    <InactivityTimeoutContext.Provider value={{ inactivityTimeoutState, updateInactivityTimeout }}>
      {children}
    </InactivityTimeoutContext.Provider>
  );
};
