import React, { useEffect, useState } from 'react'
import Layout from '../../pages/Shipment/Layout'
import { Add } from '../../static/icons/shipment/Add'
import styles from "../../pages/Shipment/DataEntry/dataentry.module.css"
import { APICALL } from '../../services/ApiServices'
import { getFinishing } from '../../routes/ApiEndPoints'
import TableStructure from '../atoms/TableStructure'
import { useNavigate } from 'react-router-dom'
import { t } from '../../pages/Translations/TranslationUtils'
import CommonShipmentServices from '../../services/CommonShipmentServices'
import Popup from '../../utils/popup'
import { ScannerMolecule } from '../molecules/ScannerMolecule'

interface VarState {
    popup: any,
    barcode: boolean,
    barcodetitle: string,
    barcodeid: string,
    barcodeurl: string
}

const ManageFinishingOrganism = () => {
    const navigate = useNavigate();
    const [finishingData, setFinishingData] = useState([]);
    const shipmentDetails = JSON.parse(localStorage.getItem('shipment_details') ?? '{}');
    const bpDetails = JSON.parse(localStorage.getItem('bp_details') ?? '{}');
    const user_id = Number(localStorage.getItem("user_id"));
    const [popup, setPopup] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const postData = {
                shipment_id: shipmentDetails?.shipment_id,
                brand_partner_id: bpDetails?.brand_partner_id
            };
            const response = await APICALL.service(getFinishing, 'POST', postData);
            if (response?.status === 200) {
                if (response?.data?.length > 0) {
                    setPopup(true)
                }
                setFinishingData(response?.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleEdit = async (value: any) => {
        navigate(`/finishing/create?edit=${value?.renewal_id}`)
    }

    const handleScan = async (value: any) => {
        const scan_value: any = Object.values(finishingData).find((item: any) => item.serial_number == value.trim())
        if (scan_value) {
            navigate(`/finishing/create?edit=${scan_value?.renewal_id}`);
        } else {
            setError(true);
        }
    }

    const filteredData = finishingData?.filter((value: any) => value?.status == "active");

    const TABLE_HEADERS = [
        { name: t('Serial number'), width: 50 },
    ]
    const TABLE_VALUES = [
        'serial_number',
    ]
    return (
        <Layout pagename={t('Finishing')} logo={true} timer={false}>

            {/* create start */}
            {/* <div className="d-flex justify-content-between align-items-center mt-3">
                        <div className={`${styles.manage_title} text-break`}>
                            {shipmentDetails?.title}
                        </div>
                        <div className='d-flex align-items-center'>

                        </div>
                    </div> */}
            {/* create ends */}
            <div className="flex-1 overflow-auto">
                <TableStructure
                    isAction
                    data={filteredData}
                    headers={TABLE_HEADERS}
                    values={TABLE_VALUES}
                    handleEdit={handleEdit}
                />
            </div>

            {popup && <Popup title={t("Scan renewal record")} modalSize="xl" cancel={() => setPopup(false)} body={<ScannerMolecule handleClick={handleScan} error={error} />} />}
        </Layout >
    )
}

export default ManageFinishingOrganism;
