import React, { ReactNode, CSSProperties } from "react";
import MultiSelect, {
  SelectValue,
  Props as MultiSelectProps,
} from "../atoms/MultiSelectField"; // Update the import path
import LabelField, { Props as LabelFieldProps } from "../atoms/LabelField"; // Update the import path
import ErrorMessage, { Props as ErrorMessageProps } from "../atoms/ErrorMessage";
import { t } from "../../pages/Translations/TranslationUtils";

interface LabeledMultiSelectProps
  extends MultiSelectProps<SelectValue>,
    LabelFieldProps, ErrorMessageProps {
    }

const MultiSelectWithLabel: React.FC<LabeledMultiSelectProps> = ({
  title,
  mandatory = false,
  className: labelClassName = "",
  errorMessage,
  ...multiSelectProps
}) => {
  return (
    <div className="row mb-4">
      <div className="col-xxl-6 col-5 pe-0">
        <LabelField
          title={title}
          mandatory={mandatory}
          className={labelClassName}
        />
      </div>
      <div className="col-xxl-6 col-7 ">
        <MultiSelect {...multiSelectProps} />
        {errorMessage &&
          <ErrorMessage
            errorMessage={t(errorMessage)}
        />}
      </div>
    </div>
  );
};

export default MultiSelectWithLabel;
