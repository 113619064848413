import React from 'react'

const DataEntry = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="96.93" height="70" viewBox="0 0 96.93 65.351">
        <g id="Group_49956" data-name="Group 49956" transform="translate(-10.979 3.528)">
          <g id="Group_28150" data-name="Group 28150" transform="translate(10.979 1.133)">
            <path id="Path_55633" data-name="Path 55633" d="M686.092,12.391c.007.094.016.185.023.279.625,7.047,4.749,12.493,9.742,12.493,5.059,0,9.225-5.588,9.764-12.768a10.97,10.97,0,0,0,.114-1.582,11.159,11.159,0,0,0-.114-1.582,9.814,9.814,0,0,0-19.527.005,11.091,11.091,0,0,0-.112,1.579c0,.216.007.432.019.647.019.314.048.622.093.928Zm3.673-5.606s6.826,3.321,11.417,0c0,0,2.724,4.311,1.958,6.006l1.387-1.593a9.991,9.991,0,0,1-.1,1.246c-.473,6.3-4.13,11.21-8.572,11.21-4.37,0-7.98-4.748-8.547-10.9a4.2,4.2,0,0,1,1.37.641S687.705,7.6,689.765,6.785Z" transform="translate(-670.471 -0.415)" fill="rgba(84,89,96,0.6)" />
            <path id="Path_55634" data-name="Path 55634" d="M700.227,17.622c-.024,1.182-2.091,4.324-4.142,7.16h14.538A12.911,12.911,0,0,0,700.227,17.622Z" transform="translate(-667.007 5.486)" fill="rgba(84,89,96,0.6)" />
            <path id="Path_55635" data-name="Path 55635" d="M694.972,22.145a2.912,2.912,0,0,0,1.609-2.435,6.538,6.538,0,0,1-5.45,0,2.912,2.912,0,0,0,1.612,2.435l-.134,1.921h2.5Z" transform="translate(-668.707 6.204)" fill="rgba(84,89,96,0.6)" />
            <path id="Path_55636" data-name="Path 55636" d="M690.155,17.622a12.906,12.906,0,0,0-10.4,7.16h14.177c-1.87-2.835-3.755-5.978-3.781-7.16" transform="translate(-672.607 5.486)" fill="rgba(84,89,96,0.6)" />
            <path id="Path_55637" data-name="Path 55637" d="M694.917,33.764a1.292,1.292,0,1,1-1.292-1.292,1.293,1.293,0,0,1,1.292,1.292" transform="translate(-668.294 10.581)" fill="rgba(84,89,96,0.6)" />
            <path id="Path_55638" data-name="Path 55638" d="M719.172,44.055h-40.8a1.04,1.04,0,1,0,0,2.08h40.8a1.04,1.04,0,0,0,0-2.08" transform="translate(-673.44 14.555)" fill="rgba(84,89,96,0.6)" />
            <path id="Path_55639" data-name="Path 55639" d="M696.484,23.345c.1-.13.189-.262.286-.392H694.09l.028.392Z" transform="translate(-667.692 7.316)" fill="rgba(84,89,96,0.6)" />
            <path id="Path_55640" data-name="Path 55640" d="M692.86,23.345l.027-.392h-2.571c.086.132.175.262.262.392Z" transform="translate(-668.986 7.316)" fill="rgba(84,89,96,0.6)" />
            <path id="Path_55641" data-name="Path 55641" d="M681.724,50.68h36.084a2.427,2.427,0,0,0,2.427-2.43l4.86-22.867a2.43,2.43,0,0,0-2.431-2.43h-4.613c.063.129.126.259.187.392h4.209a2.394,2.394,0,0,1,2.408,2.378l-4.86,22.182a2.393,2.393,0,0,1-2.405,2.378H681.943a2.393,2.393,0,0,1-2.407-2.378l-4.862-22.182a2.392,2.392,0,0,1,2.407-2.378H681.4c.059-.133.125-.263.188-.392h-4.721a2.428,2.428,0,0,0-2.43,2.43l4.859,22.867A2.43,2.43,0,0,0,681.724,50.68Z" transform="translate(-674.434 7.316)" fill="rgba(84,89,96,0.6)" />
            <path id="Path_55642" data-name="Path 55642" d="M694.247,23.345c-.086-.13-.176-.261-.262-.392H679.808c-.065.129-.129.259-.189.392Z" transform="translate(-672.655 7.316)" fill="rgba(84,89,96,0.6)" />
            <path id="Path_55643" data-name="Path 55643" d="M694.763,23.345l-.028-.392h-2.5l-.027.392Z" transform="translate(-668.336 7.316)" fill="rgba(84,89,96,0.6)" />
            <path id="Path_55644" data-name="Path 55644" d="M710.884,23.345c-.059-.133-.124-.263-.188-.392H696.159c-.1.13-.191.263-.286.392Z" transform="translate(-667.079 7.316)" fill="rgba(84,89,96,0.6)" />
          </g>
          <path id="Path_56411" data-name="Path 56411" d="M537.662.61v8.2h1.822V2.433h6.376V.611h-8.2Zm34.614,0V2.432h6.376V8.808h1.821V.61h-8.2Zm-30.97,26.416h2.733V4.254h-2.733Zm3.644,0h.911V4.254h-.911Zm2.277,0h4.554V4.254h-4.554Zm6.376,0h.911V4.254H553.6Zm1.822,0h.911V4.254h-.911Zm1.822,0h.911V4.254h-.911Zm1.821,0h3.644V4.254h-3.644Zm4.554,0h2.733V4.254h-2.733Zm4.554,0h.911V4.254h-.911Zm1.822,0h.911V4.254H570Zm2.278,0H574.1V4.254h-1.822Zm2.732,0h1.822V4.254h-1.822Zm-37.347-4.554v8.2h8.2V28.848h-6.376V22.472h-1.822Zm40.991,0v6.376h-6.376v1.821h8.2v-8.2Z" transform="translate(-472.565 -4.138)" fill="#989b9f" />
        </g>
      </svg>
    </>
  )
}

export default DataEntry

