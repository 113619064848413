export const initialstate = {
    brand_partner_id: null,
    brand_partner: '',
    shipment: '',
    gender: null,
    color: null,
    colorlabel: "",
    sizelabel: "",
    dimension: null,
    product_type: null,
    style_number: '',
    warehouse_location: null,
    size: null,
    clean_process: null,
    weight: '',
    product_group: null,
    fibre_content: null,
    product_description: '',
    sku_number: '',
    shipment_id: null,
    image_id: [],
    image_url: []
};

export const errorstate = {
    color: '',
    size: '',
    style_number: '',
    product_type: '',
    product_group: '',
}

export const initialvarstate = {
    popup: false,
    barcode: false,
    barcodeurl: '',
    barcode_id: "",
    totaltime: 0,
    timerrunning: true,
    nextflowcount: true,
    viewBarcode: false,
    configdata: null,
    issku: false
};
