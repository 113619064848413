import ManageMerchandisingOrganism from "../../../../components/organisms/ManageMerchandisingOrganism";
import AccessControl from "../../../../services/AccessControl";
import AccessControlWorkFlow from "../../../../services/AccessControlWorkFlow";

const ManageMerchandising = () => {
  return (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={[
        {
          permission: "workflow",
          read: true,
        },
      ]}
    >
      <AccessControlWorkFlow stage_name="merchandising" renderNoAccess={true}>
        <ManageMerchandisingOrganism />
      </AccessControlWorkFlow>
    </AccessControl>
  );
};

export default ManageMerchandising;
