import React, { useEffect, useState } from 'react'
import IconData from '../../../static/IconData'
import styles from '../molecules.module.css';
import Popup from '../../../utils/popup';
import SelectVendor from './SelectVendor';
import { useNavigate } from 'react-router-dom';
import { LaundryStatuses } from '../../../utils/LaundryExternal/LaundryStatuses';
import { APICALL } from '../../../services/ApiServices';
import { createBatch } from '../../../routes/ApiEndPoints';
import CommonShipmentServices from '../../../services/CommonShipmentServices';
import CommonServices from '../../../services/CommonServices';
import { t } from '../../../pages/Translations/TranslationUtils';
import { CommonConstants } from '../../../pages/Shipment/Constants/ShipmentConstants';
import { useUserContext } from '../../../routes/Contextlib';

interface Props {
    item: any;
    fetch: () => any;
    externalvendor: any;
}

const BatchInfo: React.FC<Props> = ({ item, fetch, externalvendor }) => {
    const navigate = useNavigate();
    const productslength = item.external_laundry_batch_items?.length;
    const productschecked = !item.external_laundry_batch_items?.some(
        (item: any) => item.laundry_status === true
    );
    const { user } = useUserContext();
    const localdata = CommonShipmentServices.getPostData();
    const [issuedetails, setIssueDetails] = useState<any>({ // state object
        isdetails: true,
        popup: false,
        external_vendor: item.external_vendor,
        arrivalpopup: false
    });

    useEffect(() => {
        let preselected = { // setting the selected values for the select vendor popup
            inputdata: item,
            selected: CommonShipmentServices.getFilteredObject(externalvendor, 'external_vendor_id', item.external_vendor)[0]
        }
        setIssueDetails({ ...issuedetails, selected: preselected });
    }, []);

    const getSelected = async (selected: any) => { // handling save of the select vendor popup
        try {
            if (selected?.selected) {
                let batchstatus;
                if (selected?.inputdata.delivered_date) { // making the status as arrived when delivered date is present
                    batchstatus = 'ARD';
                } else if (selected?.inputdata?.pickup_date && selected?.inputdata?.expected_delivery_date) { // making the status as dispatched when pickup and expected delivery date is present
                    batchstatus = 'DPD';
                } else { // making the default status as ready to dispatch
                    batchstatus = 'RTD';
                }

                let postdata = {
                    ...localdata,
                    external_laundry_batch_id: item.external_laundry_batch_id,
                    title: item.title,
                    external_vendor: batchstatus !== 'RTD' ? selected.selected.external_vendor_id : null,
                    pickup_date: selected.inputdata.pickup_date,
                    expected_delivery_date: selected.inputdata.expected_delivery_date,
                    delivered_date: selected.inputdata.delivered_date,
                    batch_status: batchstatus
                };

                // updating the batch on submit of the select vendor popup
                const response = await APICALL.service(createBatch, 'POST', postdata);

                if (response?.status === 200) {
                    fetch();
                    let preselected = {
                        inputdata: response?.data,
                        selected: response?.data.external_vendor ? CommonShipmentServices.getFilteredObject(externalvendor, 'external_vendor_id', response?.data.external_vendor)[0] : null
                    }
                    setIssueDetails({
                        ...issuedetails,
                        popup: false,
                        selected: preselected,
                        isdetails: true,
                        external_vendor: response?.data?.external_vendor
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleBatchClick = () => { // conditionally navigating on click of the batch icon
        if (item.batch_status === 'DPD') { // showing the arrival confirmation popup when the status is dispatched
            setIssueDetails({ ...issuedetails, arrivalpopup: true });
        } else if (item.batch_status !== 'ARD') { // navigating when the status is not arrived
            navigate(`/laundry-external/create-batch?id=${item.external_laundry_batch_id}`);
        }
    }

    const handleStatusChange = async () => { // handling the arrival popup submit and changing the status from dispatched to in arrival
        try {
            let postdata = {
                ...localdata,
                external_laundry_batch_id: item.external_laundry_batch_id,
                batch_status: 'IAL',
                external_vendor: issuedetails?.external_vendor,
                expected_delivery_date: issuedetails?.selected.inputdata.expected_delivery_date,
                pickup_date: issuedetails?.selected.inputdata.pickup_date
            };

            // API call to update the batch
            const response = await APICALL.service(createBatch, 'POST', postdata);

            if (response?.status === 200) {
                fetch();
                let preselected = {
                    inputdata: response?.data,
                    selected: CommonShipmentServices.getFilteredObject(externalvendor, 'external_vendor_id', response?.data?.external_vendor)[0]
                }
                setIssueDetails({
                    ...issuedetails,
                    selected: preselected,
                    arrivalpopup: false
                });
                localStorage.setItem(
                    "successAlertMessage",
                    t("This batch has been received")
                );
                navigate(`/laundry-external/create-batch?id=${item.external_laundry_batch_id}`);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            {issuedetails.popup &&
                <Popup // select vendor popup
                    title={t('Select vendors')}
                    body={
                        <SelectVendor
                            getSelected={getSelected}
                            externalvendor={externalvendor}
                            selecteddata={issuedetails.selected}
                            closePopup={() => setIssueDetails({ ...issuedetails, popup: false })}
                        />
                    }
                    modalSize='xl'
                    cancel={() => setIssueDetails({ ...issuedetails, popup: false })}
                />
            }
            {issuedetails.arrivalpopup &&
                <Popup // arrival confirmation popup
                    body={t('Confirm batch arrival and begin checking in goods')}
                    yestext={t(CommonConstants.YES)}
                    notext={t(CommonConstants.NO)}
                    submit={handleStatusChange}
                    cancel={() => setIssueDetails({ ...issuedetails, arrivalpopup: false })}
                />
            }
            <div className={`text-center p-4`}>
                <div className={`${styles.laundry_batch_icon} cursor-pointer d-flex justify-content-center align-items-center`}
                    onClick={handleBatchClick}
                >
                    {IconData.LaundryExternalBatchIcon}
                    <span className={`position-absolute text-white`}
                    >
                        {CommonServices.convertNumberIntoTwoDigits(productslength)}
                    </span>
                </div>
                <div className={`mt-3 text-truncate px-2 ${styles.laundry_batch_title}`}>{item.title}</div>
                {item.batch_status === 'DPD' &&
                    <div className='mt-3 cursor-pointer' onClick={() => setIssueDetails({ ...issuedetails, popup: productslength !== 0 })}>
                        <div className='mt-3'>
                            <span className='me-3'>{IconData.ExternalVendorIcon}</span>
                            {issuedetails.selected?.selected?.title ??
                                CommonShipmentServices.getFilteredTitle(externalvendor, 'external_vendor_id', item.external_vendor)}
                        </div>
                        <div className='mt-3'>
                            <span className='me-3'>{IconData.ExpectedPickupDateIcon}</span>
                            {CommonServices.showDateBasedOnFormat(issuedetails.selected?.inputdata?.pickup_date ?? item.pickup_date, user?.date_format ?? 'dd-MM-yyyy')}
                        </div>
                        <div className='mt-3'>
                            <span className='me-3'>{IconData.ExpectedDeliveryDateIcon}</span>
                            {CommonServices.showDateBasedOnFormat(issuedetails.selected?.inputdata?.expected_delivery_date ?? item.expected_delivery_date, user?.date_format ?? 'dd-MM-yyyy')}
                        </div>
                    </div>
                }
                {item.batch_status === 'RTD' &&
                    <div className={`mt-3 cursor-pointer ${styles.laundry_status}`}
                        onClick={() => setIssueDetails({ ...issuedetails, popup: productslength !== 0 })}
                    >
                        {IconData.ReadyToDispatchIcon}<span className='ms-3'>{LaundryStatuses[item.batch_status]}</span>
                    </div>
                }
                {item.batch_status === 'IAL' &&
                    <div className={`mt-3 cursor-pointer ${styles.laundry_status}`}
                        onClick={() => setIssueDetails({ ...issuedetails, popup: productschecked })}
                    >
                        {IconData.LaundryArrivalIcon}<span className='ms-3'>{LaundryStatuses[item.batch_status]}</span>
                    </div>
                }
                {item.batch_status === 'ARD' &&
                    <div className='mt-3'>
                        <span className='me-3'>{IconData.LaundryDeliveredSuccessfulIcon}</span>
                        {CommonServices.showDateBasedOnFormat(issuedetails.selected?.inputdata?.delivered_date ?? item.delivered_date, user?.date_format ?? 'dd-MM-yyyy')}
                    </div>
                }
            </div>
        </>
    )
}

export default BatchInfo