import React from "react";
import styles from "../../pages/Shipment/shimpment.module.css";

interface Dimension {
  title: string;
  dimension_id: any;
  status: boolean;
  type: string;
}
interface DimensionPopupBodyProps {
  fetcheddimension?: Dimension;
  setState: any;
  state: any;
  setPopup: any;
  handleClick?: any;
}

const DimensionPopupBody: React.FC<DimensionPopupBodyProps> = ({
  fetcheddimension,
  setState,
  state,
  setPopup,
  handleClick
}) => {

  const handleDimensionClick = (dimension: any) => {
    setState((prevFormData: any) => ({
      ...prevFormData,
      dimension: dimension.value,
    }));
    setPopup((prev: any)=> ({ ...prev, showDimensionPopup: false }))
    if(handleClick) {
      handleClick('dimension')
    }
  };

  return (
    <div className="d-flex flex-wrap">
      {fetcheddimension &&
        Object.entries(fetcheddimension).map(([key, value]) => (
          <div key={key} className={`${styles.merch_dimension_blocks} p-2`}>
            <div
              title={value?.label}
              className={`fs-5 rounded border text-truncate h-100 px-1 p-2 ${value.value == state.dimension ? 'bg_color_raven_red text-white' : ''}`}
              onClick={() => handleDimensionClick(value)}
            >
              {value.label}
            </div>
          </div>
        ))}
    </div>
  );
};

export default DimensionPopupBody;
