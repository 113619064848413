import React, { useState, useEffect, useRef } from "react";
import { t } from "../../pages/Translations/TranslationUtils";
import InputTextfield from "../atoms/InputTextField";
import Button from "../atoms/Button";
import ErrorMessage from "../atoms/ErrorMessage";

interface Props {
  title?: string;
  barcodeUrl?: string;
  barcodeId?: string;
  handlePrint: (param: any, printType? : any, item? : any) => void;
  holdItemPrint?: any;
  item?: any;
}

const BarcodeBody: React.FC<Props> = ({ handlePrint, holdItemPrint = {}, item = {} }) => {
  const [state, setState] = useState({
    inputValue: "",
    error: false,
    printCount: 1,
    totalPrintCount: 1,
    
  });

  const handleSubmit = () => {
    if (state.inputValue) {
      handlePrint(state);
    } else {
      setState((prevState) => ({
        ...prevState,
        error: true,
      }));
    }
  };
  
  const handlePrintButtonClick = (printType: any) => {
    if (state.inputValue) {
      handlePrint(state, printType, item);
    } else {
      setState((prevState) => ({
        ...prevState,
        error: true,
      }));
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      inputValue: event.target.value,
    }));
  };
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    // To handle page redirection to a stage, where the item belongs to.
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" && inputRef.current) {
        const scannedData = inputRef.current.value;
        if (scannedData && handlePrint) {
          // setState((prevState) => ({
          //   ...prevState,
          //   inputValue: scannedData,
          // }));
          let data = { ...state, inputValue: scannedData };
          handlePrint(data);
          inputRef.current.value = "";
        }
      }
    };
    if (inputRef.current) {
      inputRef.current.focus();
    }
    const handleMouseMove = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="row px-5">
      {/* <img src={barcodeUrl} alt="barcode" /> */}
      <div className="">
        <div className="text-end">
          <InputTextfield
            type="text"
            placeholder={t("Search or scan printer")}
            value={state.inputValue}
            handleChange={handleInputChange}
            handleClick={() =>
              setState((prevState) => ({ ...prevState, error: false }))
            }
            className={`form-control shadow-none me-3 ${
              state.error ? "border-danger" : ""
            } rounded`}
          />
          {state.error && (
            <ErrorMessage
              errorMessage={t("Please add serial number")}
              className="fs-6 text-start"
            />
          )}
          <input
            type="text"
            ref={inputRef}
            style={{
              opacity: "0",
            }}
          />
          {holdItemPrint?.holdItem ? (
            <div className="d-flex align-items-center justify-content-end">
              <Button
                title={t("Print serial number")}
                handleClick={() => handlePrintButtonClick(1)}
                className={"submit-btn shadow-none mt-3"}
              />
              {holdItemPrint.skuPrint && (
                <Button
                  title={t("Print SKU")}
                  handleClick={() => handlePrintButtonClick(2)}
                  className={"submit-btn shadow-none mt-3 ms-3"}
                />
              )}
            </div>
          ) : (
            <Button
              title={t("Submit")}
              handleClick={handleSubmit}
              className={"submit-btn shadow-none mt-3"}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default BarcodeBody;
