import React, { useState } from 'react'
import LabelField from '../../atoms/LabelField'
import DatePicker from 'react-datepicker'
import { t } from '../../../pages/Translations/TranslationUtils';
import { CommonConstants } from '../../../pages/Shipment/Constants/ShipmentConstants';
import Button from '../../atoms/Button';
import FormValidation from '../../../services/FormValidation';
import ErrorMessage from '../../atoms/ErrorMessage';
import CommonShipmentServices from '../../../services/CommonShipmentServices';
import { APICALL } from '../../../services/ApiServices';
import { createBatch } from '../../../routes/ApiEndPoints';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../../routes/Contextlib';

interface Input {
    value: string | null;
    error: string
}

interface Props {
    id: number;
    pickup_date: any;
    cancel: () => void;
}

const DeliveredPopupContent: React.FC<Props> = ({ cancel, id, pickup_date }) => {
    const [input, setInput] = useState<Input>({
        value: null,
        error: ''
    });
    const localdata = CommonShipmentServices.getPostData();
    const { user } = useUserContext();
    const navigate = useNavigate();

    const formatDate = (dateValue: any) => { // onchange of the date
        if (dateValue !== null) { // formatting and setting the state with the formatted date
            const date = new Date(dateValue);
            const timezoneOffset = date.getTimezoneOffset() * 60 * 1000; // To set time zone offset
            const formattedDate = new Date(date.getTime() - timezoneOffset)
                .toISOString()
                .slice(0, 10);
            setInput({ ...input, value: formattedDate });
        } else {
            setInput({ ...input, value: null });
        }
    };

    const isValidated = (value: string | null) => {
        let error = FormValidation.selectValidation(value);
        setInput({ ...input, error: error });

        return error == '';
    }

    const handleSubmit = async () => {
        let validated = isValidated(input.value);

        if (validated) {
            let postdata = {
                ...localdata,
                external_laundry_batch_id: id,
                delivered_date: input.value,
                batch_status: 'ARD'
            };

            const response = await APICALL.service(createBatch, 'POST', postdata);

            if (response.status === 200) {
                navigate(`/laundry-external/batch-overview`);
            }
        }
    }

    return (
        <>
            <div className='flex-1'>
                <div className="">
                    <LabelField
                        title={t('Confirm batch deliver date')}
                    />
                </div>
                <div className="d-flex justify-content-center">
                    <div>
                        <LabelField
                            title={t('Delivery date') + ':'}
                        />{' '}
                    </div>
                    <div className='ms-2'>
                        <DatePicker
                            autoComplete="off"
                            name="delivered_date"
                            dateFormat={user?.date_format}
                            placeholderText={user?.date_format}
                            selected={input.value ? new Date(input.value) : null}
                            onChange={(dateValue) => formatDate(dateValue)}
                            showMonthDropdown
                            showYearDropdown
                            calendarStartDay={1}
                            dropdownMode="select"
                            className="ps-2 w-100 form-control"
                            onKeyDown={(e: any) => {
                                if (e.target.value.length >= 10 && e.key !== 'Backspace' && e.key !== 'Delete') {
                                    e.preventDefault();
                                }
                            }}
                            minDate={ pickup_date? new Date(pickup_date) : null}
                        />
                        <ErrorMessage className="text-start" errorMessage={t(input.error)} />
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-between mt-5'>
                <Button
                    title={t(CommonConstants.CANCEL)}
                    className='back-btn'
                    handleClick={cancel}
                />
                <Button
                    title={t(CommonConstants.SAVE)}
                    className='submit-btn'
                    handleClick={handleSubmit}
                />
            </div>
        </>
    )
}

export default DeliveredPopupContent