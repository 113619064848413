import React from "react";
import CreateFinishingOrganism from "../../../../components/organisms/CreateFinishingOrganism";
import AccessControlWorkFlow from "../../../../services/AccessControlWorkFlow";
import AccessControl from "../../../../services/AccessControl";

const CreateFinishing = () => {
  return (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={[
        {
          permission: "workflow",
          read: true,
        },
      ]}
    >
      <AccessControlWorkFlow stage_name="finishing" renderNoAccess={true}>
        <CreateFinishingOrganism />
      </AccessControlWorkFlow>
    </AccessControl>
  );
};

export default CreateFinishing;
