import React from 'react'
import { t } from '../../pages/Translations/TranslationUtils';
import Button from '../atoms/Button';

interface Props {
    list: any,
    tabSwitch: any,
    activetab?: string,
    classname?: string
}

const TabsView: React.FC<Props> = (props) => {
    return (
        <>{Object.values(props.list).map((value: any) => (
            <Button
                key={value.title}
                className={`tabsview border-0 bg-transparent tab-btn me-4  ${value.key == props.activetab ? 'active' : ''}`}
                handleClick={() => props.tabSwitch(value.key)}>
                {t(value.title)}
            </Button>
        ))
        }</>
    )
}

export default TabsView;
// btn {
//     background-color: var(--raven-red);
//     border: 1px solid var(--raven-red);
//     border-radius: 4px;
//     color: var(--color-white);
//     font-size: var(--font-size-22);
//     height: 50px;
//     min-width: -webkit-max-content;
//     min-width: max-content;
//     padding: 0 2rem;
// }