import React from 'react'

const Calendar_fill = () => {
  return (
    <>
      <svg id="calendar-fill" xmlns="http://www.w3.org/2000/svg" width="24.5" height="24.5" viewBox="0 0 24.5 24.5">
        <path id="Path_4302" data-name="Path 4302" d="M7.516,0a.766.766,0,0,1,.766.766v.766a.766.766,0,0,1-1.531,0V.766A.766.766,0,0,1,7.516,0ZM21.3,0a.766.766,0,0,1,.766.766v.766a.766.766,0,0,1-1.531,0V.766A.766.766,0,0,1,21.3,0Z" transform="translate(-2.156)" fill="rgba(84,89,96,0.5)" fillRule="evenodd" />
        <path id="Subtraction_581" data-name="Subtraction 581" d="M21.438,22.969H3.063A3.066,3.066,0,0,1,0,19.906V6.125H24.5V19.906A3.066,3.066,0,0,1,21.438,22.969Zm-.976-5.445a.5.5,0,0,0-.5.5v1.722a.5.5,0,0,0,.5.5h1.723a.5.5,0,0,0,.5-.5V18.024a.5.5,0,0,0-.5-.5Zm-4.537,0a.5.5,0,0,0-.5.5v1.722a.5.5,0,0,0,.5.5h1.723a.5.5,0,0,0,.5-.5V18.024a.5.5,0,0,0-.5-.5Zm-4.537,0a.5.5,0,0,0-.5.5v1.722a.5.5,0,0,0,.5.5H13.11a.5.5,0,0,0,.5-.5V18.024a.5.5,0,0,0-.5-.5Zm-4.537,0a.5.5,0,0,0-.5.5v1.722a.5.5,0,0,0,.5.5H8.573a.5.5,0,0,0,.5-.5V18.024a.5.5,0,0,0-.5-.5Zm-4.537,0a.5.5,0,0,0-.5.5v1.722a.5.5,0,0,0,.5.5H4.036a.5.5,0,0,0,.5-.5V18.024a.5.5,0,0,0-.5-.5Zm18.148-4.537a.5.5,0,0,0-.5.5v1.722a.5.5,0,0,0,.5.5h1.723a.5.5,0,0,0,.5-.5V13.487a.5.5,0,0,0-.5-.5Zm-4.537,0a.5.5,0,0,0-.5.5v1.722a.5.5,0,0,0,.5.5h1.723a.5.5,0,0,0,.5-.5V13.487a.5.5,0,0,0-.5-.5Zm-4.537,0a.5.5,0,0,0-.5.5v1.722a.5.5,0,0,0,.5.5H13.11a.5.5,0,0,0,.5-.5V13.487a.5.5,0,0,0-.5-.5Zm-4.537,0a.5.5,0,0,0-.5.5v1.722a.5.5,0,0,0,.5.5H8.573a.5.5,0,0,0,.5-.5V13.487a.5.5,0,0,0-.5-.5Zm-4.537,0a.5.5,0,0,0-.5.5v1.722a.5.5,0,0,0,.5.5H4.036a.5.5,0,0,0,.5-.5V13.487a.5.5,0,0,0-.5-.5ZM20.462,8.45a.5.5,0,0,0-.5.5v1.722a.5.5,0,0,0,.5.5h1.723a.5.5,0,0,0,.5-.5V8.951a.5.5,0,0,0-.5-.5Zm-4.537,0a.5.5,0,0,0-.5.5v1.722a.5.5,0,0,0,.5.5h1.723a.5.5,0,0,0,.5-.5V8.951a.5.5,0,0,0-.5-.5Zm-4.537,0a.5.5,0,0,0-.5.5v1.722a.5.5,0,0,0,.5.5H13.11a.5.5,0,0,0,.5-.5V8.951a.5.5,0,0,0-.5-.5Zm-4.537,0a.5.5,0,0,0-.5.5v1.722a.5.5,0,0,0,.5.5H8.573a.5.5,0,0,0,.5-.5V8.951a.5.5,0,0,0-.5-.5Zm-4.537,0a.5.5,0,0,0-.5.5v1.722a.5.5,0,0,0,.5.5H4.036a.5.5,0,0,0,.5-.5V8.951a.5.5,0,0,0-.5-.5ZM24.5,4.594H0V3.062A3.066,3.066,0,0,1,3.063,0H21.438A3.065,3.065,0,0,1,24.5,3.062V4.593Z" transform="translate(0 1.532)" fill="rgba(84,89,96,0.5)" />
      </svg>
    </>
  )
}

export default Calendar_fill

