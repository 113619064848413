import React, { useState, useEffect } from "react";
import { t } from "../Translations/TranslationUtils";
import { useNavigate } from "react-router-dom";
import CustomAlert from "../../components/atoms/CustomAlert";
import styles from "./MailTemplate.module.css";
import Pagination from "../../services/Paginations";
import { FaSort } from "react-icons/fa";
import { APICALL } from "../../services/ApiServices";
import { manageMailTemplate } from "../../routes/ApiEndPoints";
import AccessControl from "../../services/AccessControl";
import { CommonConstants } from "../Shipment/Constants/ShipmentConstants";
import { useUserContext } from "../../routes/Contextlib";
import WindowHeightComponent from "../../commonComponents/WindowHeightComponent";

const ManageMailTemplate: React.FC = () => {
  const { user, setUser } = useUserContext();
  const navigate = useNavigate();
  const [templateDetails, setTemplateDetails] = useState({});
  const [activeTab, setActiveTab] = useState<"active" | "inactive">("active");
  const [searchText, setSearchText] = useState("");
  const [languages, setLanguages] = useState([]);
  const itemsPerPage = 10;
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [currentPage, setCurrentPage] = useState(1);
  const [isSorted, setIsSorted] = useState(false); // New state variable to track sorting
  const [requiredPermissions, setRequiredPermissions] = useState<any>([])

  const filteredTemplateArray = Object.values(templateDetails).filter(
    (item: any) => {
      const title = item.template_name ? item.template_name.toLowerCase() : "";
      const searchTextLower = searchText.toLowerCase();

      return (
        title.includes(searchTextLower)
      );
    }
  );

  const sortedTemplates = isSorted
    ? filteredTemplateArray.slice().sort((a: any, b: any) => {
      const usernameA = a.template_name.toLowerCase();
      const usernameB = b.template_name.toLowerCase();

      if (sortOrder === "asc") {
        if (usernameA < usernameB) return -1;
        if (usernameA > usernameB) return 1;
        return 0;
      } else {
        if (usernameA > usernameB) return -1;
        if (usernameA < usernameB) return 1;
        return 0;
      }
    })
    : filteredTemplateArray;

  // Calculate the indexes of the users to be displayed on the current page
  const totalTemplates = sortedTemplates.length;
  const totalPages = Math.ceil(totalTemplates / itemsPerPage);
  const validPageNumber = currentPage > totalPages ? totalPages : currentPage;
  const indexOfLastTemplate = validPageNumber * itemsPerPage;
  const indexOfFirstTemplate = indexOfLastTemplate - itemsPerPage;
  const currentTemplates = sortedTemplates.slice(
    indexOfFirstTemplate,
    indexOfLastTemplate
  );

  const handleSortClick = () => {
    !isSorted && setIsSorted(true);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handlePaginationClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const storageMessage = localStorage.getItem("successAlertMessage");

    if (storageMessage) {
      localStorage.removeItem("successAlertMessage");
      CustomAlert("success", t(storageMessage), 6000);
    }
  }, []);

  async function fetchTemplateData() {
    try {
      const postData = {
        // Encrypting the request data
        method: "POST",
        data: {},
      };

      const response = await APICALL.service(
        manageMailTemplate,
        "POST",
        postData,
        true
      );

      if (response?.status !== 200) {
        throw new Error("Request failed");
      }
      languages.length === 0 && response?.languageOptions && setLanguages(response?.languageOptions)
      setTemplateDetails(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const urlExtension = `/mail-template/manage/`;
    localStorage.setItem("backbuttonURL", urlExtension);
    const { id, name } = event.currentTarget;
    const url = `/mail-template/create/?language=${name}&id=${id}`;
    navigate(url);
  };

  useEffect(() => {
    fetchTemplateData();
  }, []);

  useEffect(() => {
    const userPerm: any = user?.permissions;
    const truePerm: any = userPerm?.find((item: any) => item.permission.toLowerCase().trim() === "mail template")
    let permissions = [
      {
        permission: "mail template",
        read: true,
      }
    ]
    if (truePerm?.update === true) {
      permissions[0].read = false;
    }
    setRequiredPermissions(permissions)
  }, [user != null])

  return (
    <AccessControl
      requiredPermissions={requiredPermissions}
      renderNoAccess={true}
    >
      <>
        <div className="row">
          <div className="col-md-11 ms-auto px-4">

            <WindowHeightComponent>
              <div className="d-flex flex-column overflow-auto h-100">
                <div className="py-xxl-4 py-xl-3 py-lg-3 page-title top-0">
                  {t("Manage mail template")}
                </div>
                <div className="flex-1 h-100 overflow-auto">
                  <div className="d-flex flex-column h-100 overflow-auto">
                    <div className="mb-3">
                      <input
                        type="text"
                        placeholder={t("Search by name")}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        className="form-control shadow-none"
                      />
                    </div>
                    <div className="form-group flex-1 overflow-auto">
                      <div className={`${styles.manage_mailtemp_table}`}>
                        <table className="table">
                          <thead className="position-sticky top-0">
                            <tr>
                              <th scope="col" className="border-bottom-0">
                                {t("Name")}
                                <button
                                  onClick={handleSortClick}
                                  className="sort-btn border-0 bg-transparent text-gray"
                                >
                                  {sortOrder === "asc" ? <FaSort /> : <FaSort />}
                                </button>
                              </th>
                              <th scope="col" className="border-bottom-0">
                                {t("Description")}
                              </th>
                              <AccessControl
                                requiredPermissions={[
                                  {
                                    permission: "mail template",
                                    update: true,
                                  },
                                ]}
                              >
                                <th scope="col" className="border-bottom-0 text-center">
                                  {t("Language")}
                                </th>
                              </AccessControl>
                            </tr>
                          </thead>
                          <tbody>
                            {currentTemplates.length ? Object.values(currentTemplates).map((item: any) => (
                              <tr key={item.role_id}>
                                <td className="text-break">{item.template_name}</td>
                                <td className="text-break">
                                  {item.template_description}
                                </td>
                                <AccessControl
                                  requiredPermissions={[
                                    {
                                      permission: "mail template",
                                      update: true,
                                    },
                                  ]}
                                >
                                  <td className="text-break manage-users-action text-center table_actions mail-temp-langauges">
                                    {
                                      languages.map((lang: any) => {
                                        return (
                                          <button
                                            className="me-2 border-0 bg-transparent"
                                            name={lang.language_code}
                                            id={item.mail_template_id}
                                            onClick={handleButtonClick}
                                          >
                                            { lang?.language_name }
                                          </button>
                                        )
                                      })
                                    }
                                  </td>
                                </AccessControl>
                              </tr>
                            )) :
                              <td colSpan={3} className="text-center text-danger">
                                {t(CommonConstants.NO_RESULTS_FOUND)}
                              </td>
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="row m-0 position-relative mt-2">
                    {sortedTemplates.length > itemsPerPage && totalPages > 1 && (
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          handlePaginationClick={handlePaginationClick}
                        />
                      )}
                      <div className="col-md-6 float-start p-0">
                        <button
                          className="back-btn shadow-none float-start text-decoration-none"
                          onClick={() => navigate("/application-configuration")}
                        >
                          {t(CommonConstants.BACK)}
                        </button>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </WindowHeightComponent>
          </div>
        </div>
      </>
    </AccessControl>
  );
};

export default ManageMailTemplate;
