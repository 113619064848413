import React from 'react';
import LabelField from '../atoms/LabelField';
import InputTextfield from '../atoms/InputTextField';
import Close from '../../static/icons/Close';
import styles from "../../pages/BrandPartner/brandpartner.module.css"
import CommonServices from '../../services/CommonServices';

interface SelectedSizesAndDimensionsProps {
  title: string;
  selectedItems: any[]; // Array of selected sizes and dimensions
  onRemoveItem: (id: number) => void; // Function to remove an item
  onLabelChange: (e: React.ChangeEvent<HTMLInputElement>, id: number) => void; // Function to handle label change
}

const SelectedSizesAndDimensions: React.FC<SelectedSizesAndDimensionsProps> = ({
  title,
  selectedItems,
  onRemoveItem,
  onLabelChange,
}) => {
  return (
    <div>
      {selectedItems?.length > 0 &&
        <div className="border rounded p-4 mb-3 mb-xxl-4">
          <LabelField
            title={title + ':'}
            className={` ${styles.label} mb-3`}
          />
          <div className="d-flex flex-wrap">
            {selectedItems.map((item: any) => (
              <div className={` ${styles.bpsize_btn} p-1 mb-3`}>
              <div
                key={item.id}
                className={`${styles.activebrandpartnerselectBorder} position-relative p-2 py-3 rounded h-100`}
              >
                <div className="position-relative d-flex flex-column h-100">
                  <div className={`${styles.activebrandpartnerselect} ${styles.color_btn} rounded text-break`}>
                    {item.title ?? ""}
                  </div>
                  <div
                    className={`${styles.selected_close_btn} position-absolute cursor-pointer top-0`}
                    onClick={() => onRemoveItem(item?.dimension_id ?? item?.size_id ?? item?.merch_rack_id)}
                  >
                    <Close />
                  </div>
                  <InputTextfield
                    className="border rounded mt-3 text-gray text-truncate"
                    // className={`border rounded mt-3 text-gray text-truncate ${(item.label ?? item.title === '') || (item.label ?? item.title).length > 10 ? 'border-danger' : "" }`}
                    value={item.label ?? item.title ?? "" ?? ''}
                    handleChange={(e) => onLabelChange(e, item?.dimension_id ?? item?.size_id ?? item?.merch_rack_id)}
                    handleClick={(event) => event.stopPropagation()}
                    customStyle={{ width: '100%', textAlign: 'center', minHeight: 'unset' }}
                  />
                </div>
              </div>
              </div>
            ))}
          </div>
        </div>}
    </div>
  );
};

export default SelectedSizesAndDimensions;
