import React, { useEffect } from "react";
import { useUserContext } from "../routes/Contextlib";
import { useLoggedInContext } from "../Context/LoginContext";
import { useNavigate } from "react-router-dom";

const Logout: React.FC = () => {
    const { user, setUser } = useUserContext();
    const { updateLoggedIn } = useLoggedInContext();
    const navigate = useNavigate();
    const handleLogout = async () => {  
        try {
            const response = await fetch((process.env.REACT_APP_B_IDENTITY_MANAGER_URL + "api/logout"), {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${user?.token}`, // Include the token in the request headers
                },
            });
            if (response.status === 200) {
                setUser(null);
                updateLoggedIn(false);
                localStorage.clear()
                window.location.href = process.env.REACT_APP_B_IDENTITY_MANAGER_URL ?? '';
            } else {
                navigate('/');
            }
        } catch (error) {
            console.log(error);
        }      
    }
    useEffect(() => {
        if (user != null) {
            handleLogout();
        }
    },[user]);
    return null;
}

export default Logout;
