import React from "react";
import ExternalCreateBatchOrganism from "../../../components/organisms/LaundryExternal/ExternalCreateBatchOrganism";
import AccessControlWorkFlow from "../../../services/AccessControlWorkFlow";
import AccessControl from "../../../services/AccessControl";

const ExternalCreateBatch = () => {
  return (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={[
        {
          permission: "workflow",
          read: true,
        },
      ]}
    >
      <AccessControlWorkFlow
        stage_name="external_laundry"
        renderNoAccess={true}
      >
        <ExternalCreateBatchOrganism />
      </AccessControlWorkFlow>
    </AccessControl>
  );
};

export default ExternalCreateBatch;
