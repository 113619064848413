import React from "react";
import styles from "../../pages/Admin/admin.module.css";
import { t } from "../../pages/Translations/TranslationUtils";
import { Link } from 'react-router-dom';

interface AdminCardProps {
  title?: string;
  icon?: JSX.Element;
  className?: string;
  style?: React.CSSProperties;
  url?: string;
  target?: string;
  text1?: string;
  handleClick?: () => void;
  selected?: string;
  mainclassName?: string;
  mainDivStyle?: any;
  innerDivStyle?: any;
  titleStyle?: any;
}

const AdminCard: React.FC<AdminCardProps> = ({ title, icon, url = '', className, mainclassName, style, target = '', handleClick, text1, selected, mainDivStyle, innerDivStyle, titleStyle }) => {
  return (
    <div className={`${styles.admincard} ${mainclassName}`} onClick={handleClick} style={mainDivStyle}>
      <Link to={url} target={target} className={`${styles.cards} ${className} ${selected ?? selected} text-decoration-none text-gray`} style={innerDivStyle}>
        <div className='mb-3'>
          {icon}
        </div>
        <p className={`text-break`} style={titleStyle}>{title && title}</p>
        <p className="text-break">{text1 && text1}</p>
      </Link>
    </div>
  );
};

export default AdminCard;
