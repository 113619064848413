import FormValidation from "../FormValidation";

export const inputValidation = (value: any, type: any, length = 255) => {    
    switch (type) {
        case 'required_text':
            return FormValidation.nameValidation(value, length);
        
        case 'not_required_text':
            return FormValidation.textValidation(value, length);

        case 'range':
        case 'number':
            return FormValidation.numberValidation(value);

        case 'textarea':
            return FormValidation.textareaValidation(value);

        case 'color':
            return FormValidation.emptyValidation(value);

        case 'select':
            return FormValidation.selectValidation(value);

        case 'multiselect':
            return FormValidation.multiselectValidation(value);
        
        case 'zipcode':
            return FormValidation.zipcodeValidation(value);
        
        case 'file':
            return FormValidation.fileValidation(value);
        
        default:
            return null;
    }
};

const DEFAULT_VALIDATION_LENGTH: [string, number] = ['title', 255];

export const getValidationLength = (name: string) => {
    const validationLengths: {[key: string]: [string, number]} = {
        'size': ['title', 10],
        'product_group': ['title', 10],
        'warehouse_location': ['wms_id', 10],
        'product_tier': ['wms_id', 10],
        'gender': ['wms_id', 1]
    };
    return validationLengths[name] || DEFAULT_VALIDATION_LENGTH;
};