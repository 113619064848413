import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import EncryptDecryptService from '../../services/EncryptDecryptService';
import { useUserContext } from '../../routes/Contextlib';
import CustomAlert from '../../components/atoms/CustomAlert'
import { t } from '../Translations/TranslationUtils';
import ProfilePictureUpload from '../../ProfileImage/ProfilePicture';
import { APICALL } from '../../services/ApiServices';
import { createUserUrl, fetchLanguagesUrl, getusers } from '../../routes/ApiEndPoints';
import MultiSelect, { OptionValue } from '../../components/atoms/MultiSelectField';
import FormValidation from '../../services/FormValidation';
import { CommonConstants } from '../Shipment/Constants/ShipmentConstants';
import LabelField from '../../components/atoms/LabelField';
import WindowHeightComponent from '../../commonComponents/WindowHeightComponent';
interface FormValues {
    first_name: string;
    last_name: string;
    username: string;
    email: string;
    user_id: number;
    user_language: OptionValue[];
    profileimageurl: string;
}

const Myprofile: React.FC = () => {
    const { user } = useUserContext();
    const user_id = Number(localStorage.getItem("user_id")) ?? 1;
    const [formValues, setFormValues] = useState<FormValues>({
        first_name: user ? user.firstname : '',
        last_name: user ? user.lastname : '',
        username: user ? user.username : '',
        email: user ? user.email : '',
        user_id: user_id,
        user_language: [],
        profileimageurl: "",
    });
    const [roles, setRoles] = useState([]);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        user_language: ''
    });
    const [languageOptions, setLanguageOptions] = useState<OptionValue[]>([]);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (languageOptions?.length !== 0) {
            fetchUserDetails();
        }
    }, [languageOptions])
    //If user_id id present in the url
    useEffect(() => {
        const previousURL = document.referrer;
        const url = new URL(previousURL ? previousURL : `${process.env.REACT_APP_URL}`);
        localStorage.setItem('backbuttonURL', url.pathname);
        const storedOptions = localStorage?.getItem('languageOptions');
        if (storedOptions) {
            try {
                const options = JSON.parse(storedOptions);
                setLanguageOptions(options);
            } catch (error) {
                fetchLanguages();
            }
        } else {
            fetchLanguages();
        }
    }, []);

    // Fetch user details from the backend using the userId
    const fetchUserDetails = async () => {
        try {
            const postData = { // Encrypting the request data
                'method': 'POST',
                'data': { userid: user_id }
            };
            const response = await APICALL.service(getusers, 'POST', postData, true)

            if (response.status == 200) {
                const userdata = response.data[0];

                setRoles(userdata?.roles?.filter((role: any) => role?.status == true)
                    .map((role: any) => {
                        return role.title
                    }
                    ))
                let lang: any = languageOptions?.find(c => c.value === userdata?.user_language);
                setFormValues({
                    ...formValues,
                    user_id: user_id,
                    first_name: userdata.first_name ? userdata.first_name : '',
                    last_name: userdata.last_name ? userdata.last_name : '',
                    username: userdata.username ? userdata.username : '',
                    email: userdata.email ? userdata.email : null,
                    user_language: lang ? [lang] : [],
                    profileimageurl: userdata.profile_picture ? userdata.profile_picture : "",
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    //To fetch language options
    const fetchLanguages = async () => {
        try {
            const postData = {
                // Encrypting the request data
                method: "GET",
                data: {},
            };
            const response = await APICALL.service(fetchLanguagesUrl, 'POST', postData, true)
            if (response?.status !== 200) {
                throw new Error("Request failed");
            }

            // Decrypting the response
            const mappedLanguages = response.data
                .map((item: any) => ({
                    value: item.language_code,
                    label: item.language_name,
                }));
            // Sort roles alphabetically by label
            mappedLanguages.sort((a: any, b: any) => a.label.localeCompare(b.label));
            setLanguageOptions(mappedLanguages);
        } catch (error) {
            console.error(error);
        }
    }

    //To handle change for every field
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormValues((prevValues: typeof formValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    //To remove error
    const removeError = (fieldName: string) => {
        // Handle the click event here    
        setErrors(prevState => ({
            ...prevState,
            [fieldName]: '' // Set the value of the field dynamically
        }));
    };

    const validate = () => {
        let error = {
            first_name: '',
            last_name: '',
            username: '',
            email: '',
            user_language: ''
        }
        error.first_name = FormValidation.nameValidation(formValues.first_name);
        error.last_name = FormValidation.nameValidation(formValues.last_name);
        error.username = FormValidation.nameValidation(formValues.username);
        error.user_language = FormValidation.selectValidation(formValues.user_language);
        setErrors(error);
        if (
            error.first_name === '' &&
            error.last_name === '' &&
            error.username === '' &&
            error.user_language === ''
        ) {
            return true;
        }
        return false;
    }

    //To handle the submit
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const isValidated = validate();
        if (isValidated) {
            try {
                setIsSaving(true);
                let language: any = formValues?.user_language

                const convertedFormValues: any = {
                    ...formValues,
                    user_language: formValues?.user_language[0]?.value ?? language?.value
                };
                const postData = { // Encrypting the request data
                    "method": "POST",
                    "data": EncryptDecryptService.encryptData(JSON.stringify(convertedFormValues))
                };
                const response = await APICALL.service(createUserUrl, 'POST', postData)
                let decrypteddata = JSON.parse(EncryptDecryptService.decryptData(response));

                if (decrypteddata?.status === 409) {
                    const validationErrors: any = {
                        username: response?.filed === 'username' ? response?.message : "",
                        email: response?.filed === 'email' ? response?.message : "",
                    };
                    setErrors(validationErrors);
                    setIsSaving(false);
                } else if (decrypteddata?.status !== 200) {
                    throw new Error("Request failed");
                } else {
                    let message: string = `Profile updated successfully`;
                    localStorage.setItem("profileSuccessAlertMessage", message);
                    localStorage.setItem('activeLanguage', (language[0]?.value ?? language?.value));
                    let backButtonURL: any = localStorage.getItem('backbuttonURL');
                    localStorage.removeItem('backbuttonURL');
                    window.location.href = backButtonURL ?? '/';
                }
            } catch (error) {
                CustomAlert('error', t("Error while updating profile"), 6000)
                console.error(error);
            }
            setIsSaving(false);

        }
    };

    const goToPreviousPage = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (event.detail === 1) {
            let backButtonURL: any = localStorage.getItem('backbuttonURL');
            localStorage.removeItem('backbuttonURL');
            navigate(backButtonURL ?? '/');
        }
    };

    window.addEventListener('beforeunload', () => {
        const urlExtension = '/users/myprofile';
        localStorage.setItem('backbuttonURL', urlExtension);
    });

    return (
        <>
            <div className='row'>
                <div className='col-md-11 ms-auto px-4'>
                    {/* Page title */}

                    <WindowHeightComponent>

                        <div className="d-flex flex-column h-100 overflow-auto">
                            <div className='py-xxl-4 py-xl-3 py-lg-3 page-title'>
                                {t("My profile")}
                            </div>
                            {/* form */}
                            <form encType="multipart/form-data" className='flex-1 d-flex flex-column h-100 overflow-auto'>

                                <div className='flex-1 overflow-auto'>
                                    <div className='create-form p-4 h-100 overflow-auto'>
                                        <div className='row m-0'>
                                            <div className="col-10 m-auto text-center mt-3 mb-2 ">
                                                <div className="text-end ">
                                                    <Link to={`/password/manage/?edit=${user_id}`} className='py-2 text-decoration-none submit-btn'>
                                                        {t("Change password")}
                                                    </Link>
                                                </div>
                                                <span className='position-relative d-flex justify-content-center mb-3'>
                                                    <ProfilePictureUpload url={formValues.profileimageurl} />
                                                </span>
                                                <span>
                                                    {t("Upload profile picture of 512 x 512 upto 2mb")}
                                                </span>
                                            </div>
                                            <div className="col-10 m-auto mt-3 mb-5 ">
                                                <div className="d-flex flex-wrap justify-content-center">
                                                    <LabelField
                                                        title="Roles:"
                                                        mandatory={false}
                                                    />
                                                    {roles &&
                                                        roles?.map((item: any) => {
                                                            return (
                                                                <span className="fw-bold text-gray py-1 px-2 rounded text-break">
                                                                    {item}
                                                                </span>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-md-10 m-auto'>
                                                <div className='row'>
                                                    {/* First name */}
                                                    <div className='col-md-6 mb-3'>
                                                        <div>
                                                            <label htmlFor="first_name">{t("First name")}</label>
                                                            <input
                                                                type="text"
                                                                id="first_name"
                                                                name="first_name"
                                                                className='form-control shadow-none'
                                                                value={formValues.first_name}
                                                                onChange={handleChange}
                                                                style={errors.first_name ? { borderColor: 'red' } : {}}
                                                                onClick={() => removeError('first_name')}
                                                                disabled
                                                            />
                                                            {errors.first_name && <div className="error" style={{ color: 'red' }}>
                                                                {errors.first_name}
                                                            </div>}
                                                        </div>
                                                    </div>
                                                    {/* Last name */}
                                                    <div className='col-md-6 mb-3'>
                                                        <div>
                                                            <label htmlFor="last_name">{t("Last name")}</label>
                                                            <input
                                                                type="text"
                                                                id="last_name"
                                                                name="last_name"
                                                                style={errors.last_name ? { borderColor: 'red' } : {}}
                                                                value={formValues.last_name}
                                                                onChange={handleChange}
                                                                onClick={() => removeError('last_name')}
                                                                className='form-control shadow-none'
                                                                disabled
                                                            />
                                                            {errors.last_name && <div className="error" style={{ color: 'red' }}>
                                                                {t(errors.last_name)}
                                                            </div>}
                                                        </div>
                                                    </div>

                                                    {/* Username */}
                                                    <div className='col-md-6 mb-3'>
                                                        <div>
                                                            <label htmlFor="username">{t("Username")}</label>
                                                            <input
                                                                type="text"
                                                                id="username"
                                                                name="username"
                                                                value={formValues.username}
                                                                style={errors.username ? { borderColor: 'red' } : {}}
                                                                onClick={() => removeError('username')}
                                                                disabled
                                                                className='form-control shadow-none'
                                                            />
                                                            {errors.username && <div className="error" style={{ color: 'red' }}>
                                                                {t(errors.username)}
                                                            </div>}
                                                        </div>
                                                    </div>
                                                    {/* Email */}
                                                    <div className='col-md-6 mb-3'>
                                                        <div>
                                                            <label htmlFor="email">{t("Email")}</label>
                                                            <input
                                                                type="email"
                                                                id="email"
                                                                name="email"
                                                                value={formValues.email}
                                                                disabled
                                                                className='form-control shadow-none'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 multi-border-none'>
                                                        <div onClick={() => removeError('user_language')}>
                                                            <label htmlFor="user_language">{t("Languages")}<span className='asterick'> *</span></label>
                                                            <MultiSelect<OptionValue[]>
                                                                options={languageOptions} // Provide your array of country options
                                                                standards={formValues.user_language}
                                                                handleChange={(newValue, actionMeta) => {
                                                                    setFormValues((prevValues) => ({
                                                                        ...prevValues,
                                                                        user_language: newValue as OptionValue[],
                                                                    }));
                                                                }}
                                                                customStyle={
                                                                    errors.user_language
                                                                        ? { border: "1px solid red", borderRadius: "5px" }
                                                                        : undefined
                                                                }
                                                                placeholder={t("Select")}
                                                                menuPlacement="auto"
                                                                isSearchable={true}
                                                                isMulti={false}
                                                                name="user_language"
                                                                classNamePrefix="react-select-prefix"
                                                                isClearable={true}
                                                            />
                                                            {errors.user_language && (
                                                                <div className="error" style={{ color: "red" }}>
                                                                    {t(errors.user_language)}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* end of row */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* save button */}
                                <div className='row m-0 mt-2'>
                                    <div className='col-md-6 float-start'>
                                        <button onClick={goToPreviousPage} className='back-btn shadow-none float-start text-decoration-none'>{t(CommonConstants.BACK)}</button>
                                    </div>
                                    <div className='col-6 float-end'>
                                        <button
                                            type="submit"
                                            className='submit-btn shadow-none float-end'
                                            disabled={isSaving}
                                            onClick={handleSubmit}
                                        >
                                            {isSaving ? t('Saving...') : t('Save')}
                                        </button>
                                    </div>
                                </div>
                                {/* end of row */}
                            </form>
                        </div>
                    </WindowHeightComponent>
                </div>
                {/* end of col-md-11 */}
            </div>
        </>
    );
};

export default Myprofile;
