import AccessControl from "../../../../services/AccessControl";
import AccessControlWorkFlow from "../../../../services/AccessControlWorkFlow";
import RecordsOverviewOrganism from "../../../../components/organisms/RecordOverview/RecordsOverviewOrganism";

const CreateMerchandising = () => {
  return (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={[
        {
          permission: "workflow",
          read: true,
        },
      ]}
    >
      <AccessControlWorkFlow stage_name="merchandising" renderNoAccess={true}>
        <RecordsOverviewOrganism 
          returnurl="/merchandising/manage"/>
      </AccessControlWorkFlow>
    </AccessControl>
  );
};

export default CreateMerchandising;
