import React from 'react';
import { t } from '../pages/Translations/TranslationUtils';
import Button from '../components/atoms/Button';
interface PaginationProps {
  currentPage: number;
  totalPages: number;
  handlePaginationClick: (pageNumber: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  handlePaginationClick
}) => {
  const generatePaginationNumbers = (): JSX.Element[] => {
    const paginationNumbers: JSX.Element[] = [];

    // First page
    paginationNumbers.push(
      <Button
        key={"First"}
        title={t("First")}
        handleClick={() => handlePaginationClick(1)}
        className="bg-transparent border-0 px-2 py-2 shadow-none"
        disabled={currentPage <= 1}
      />
    );

    // Previous page
    paginationNumbers.push(
      <Button
        key={"Previous"}
        title={t("Previous")}
        handleClick={() => handlePaginationClick(currentPage - 1)}
        className="bg-transparent border-0 px-2 py-2 shadow-none"
        disabled={currentPage <= 1}
      />
    );

    // Left side page
    if (currentPage > 1) {
      paginationNumbers.push(
        <Button
          key={"Current" + (currentPage - 1)}
          title={(currentPage - 1).toString()}
          handleClick={() => handlePaginationClick(currentPage - 1)}
          className="bg-transparent border-0 px-2 py-2 shadow-none "
        />
      );
    }

    // Current page
    paginationNumbers.push(
      <Button
        key={"Current" + currentPage}
        title={(currentPage).toString()}
        handleClick={() => handlePaginationClick(currentPage)}
        className="active border-0 px-2 py-2 shadow-none "
      />
    );

    // Right side page
    if (currentPage < totalPages) {
      paginationNumbers.push(
        <Button
          key={"Current" + (currentPage + 1)}
          title={(currentPage + 1).toString()}
          handleClick={() => handlePaginationClick(currentPage + 1)}
          className="bg-transparent border-0 px-2 py-2 shadow-none "
        />
      );
    }

    // Next page
    paginationNumbers.push(
      <Button
        key={"Next"}
        title={t("Next")}
        handleClick={() => handlePaginationClick(currentPage + 1)}
        className="bg-transparent border-0 px-2 py-2 shadow-none"
        disabled={currentPage >= totalPages}
      />
    );

    // Last page
    paginationNumbers.push(
      <Button
        key={"Last"}
        title={t("Last")}
        handleClick={() => handlePaginationClick(totalPages)}
        className="bg-transparent border-0 px-2 py-2 shadow-none"
        disabled={currentPage >= totalPages}
      />
    );

    return paginationNumbers;
  };

  return (
    <div className="pagination justify-content-center">
      <div>
        {generatePaginationNumbers()}
      </div>
    </div>
  );
};

export default Pagination;
