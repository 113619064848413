// ProfilePictureContext.tsx
import React, { createContext, useState, useContext } from 'react';
import Profile from "./profile.png"
interface ProfilePictureContextData {
  profilePictureUrl: string;
  updateProfilePicture: (newUrl: string) => void;
}

const ProfilePictureContext = createContext<ProfilePictureContextData>({
  profilePictureUrl: "",
  updateProfilePicture: () => {},
});

export const useProfilePicture = () => useContext(ProfilePictureContext);

interface providerProps  {
    children:any
}
export const ProfilePictureProvider: React.FC<providerProps> = ({ children }) => {
  const [profilePictureUrl, setProfilePictureUrl] = useState('');

  const updateProfilePicture = (newUrl: string) => {
    setProfilePictureUrl(newUrl);
  };

  return (
    <ProfilePictureContext.Provider value={{ profilePictureUrl, updateProfilePicture }}>
      {children}
    </ProfilePictureContext.Provider>
  );
};
