import React from 'react'

const Repair = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="84" height="70" viewBox="0 0 51.48 43.29">
        <g id="Repair_assessment" data-name="Repair assessment" transform="translate(-2 -5)">
          <circle id="Ellipse_218" data-name="Ellipse 218" cx="1.755" cy="1.755" r="1.755" transform="translate(38.855 17.87)" fill="rgba(84,89,96,0.6)" />
          <path id="Path_56126" data-name="Path 56126" d="M51.14,27.23a2.34,2.34,0,0,0,2.34-2.34V20.21a2.34,2.34,0,0,0-2.34-2.34H48.707a8.19,8.19,0,0,0-8.1-7.02h-.042V7.34a1.17,1.17,0,1,0-2.34,0v3.51H15.84a1.17,1.17,0,0,1-.828-.343l-.969-.969A3.484,3.484,0,0,0,11.56,8.51H10.19V6.17a1.17,1.17,0,0,0-2.34,0V8.51a3.51,3.51,0,0,0-3.51,3.51V29.57a3.51,3.51,0,0,0,3.51,3.51v4.68a1.17,1.17,0,0,0,2.34,0V33.08h.574a3.526,3.526,0,0,0,3.375-2.546l1.37-4.8a1.17,1.17,0,0,1,1.126-.847H31.25a3.51,3.51,0,0,1,3.51,3.51v2.34a1.17,1.17,0,0,1-.468.936l-1.872,1.4a5.879,5.879,0,0,0-2.34,4.68v3.51H5.51a3.51,3.51,0,1,0,0,7.02H49.97a3.51,3.51,0,0,0,0-7.02H48.8V27.23ZM41.78,37.76a1.17,1.17,0,0,1-2.34,0V36.59a1.17,1.17,0,1,1,2.34,0Zm0-5.85a1.17,1.17,0,1,1-2.34,0V28.4a1.17,1.17,0,0,1,2.34,0Zm-1.17-8.19a4.1,4.1,0,1,1,4.1-4.1A4.1,4.1,0,0,1,40.61,23.72Z" fill="rgba(84,89,96,0.6)" />
        </g>
      </svg>
    </>
  )
}

export default Repair

