import React from 'react'
import styles from '../../organisms/organisms.module.css';
import { t } from '../../../pages/Translations/TranslationUtils';

interface Props {
    title?: string;
    issue_size: string;
    // issue_location: string; please do not remove this code
    repair_assesment: string;
    work_area: string;
}
const IssueDetails: React.FC<Props> = ({ title, issue_size, repair_assesment, work_area }) => {
    return (

        <div className="h-100 d-flex">
            <div className='px-4 my-auto w-100 py-2'>
                <h4><b>{title}</b></h4>
                <div className="row text-start">
                    <div className={`col-4 ${styles.qclabel}`}>
                        {t('Issue size')}:
                    </div>
                    <div className={`col-6 ${styles.qcvalue} text-gray text-break`}>
                        {issue_size}
                    </div>
                    {/* Please do not remove this block code, it may be required in future */}
                    {/* <div className={`col-6 ${styles.qclabel}`}>
                            {t('Issue location')}:
                        </div>
                        <div className={`col-6 ${styles.qcvalue} text-gray text-truncate`}>
                            {issue_location}
                        </div> */}
                    <div className={`col-4 ${styles.qclabel}`}>
                        {t('Repair assessment')}:
                    </div>
                    <div className={`col-6 ${styles.qcvalue} text-gray text-break`}>
                        {repair_assesment}
                    </div>
                    <div className={`col-4 ${styles.qclabel}`}>
                        {t('Work area')}:
                    </div>
                    <div className={`col-6 ${styles.qcvalue} text-gray text-break`}>
                        {work_area}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IssueDetails