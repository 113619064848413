import React from 'react'

const Edit = () => {
  return (
    <>
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 27.924 27.896">
          <g id="edit" transform="translate(-0.02)">
            <path id="Path_56349" data-name="Path 56349" d="M24.453,27.9H3.511a3.363,3.363,0,0,1-2.468-1.023A3.363,3.363,0,0,1,.02,24.406V3.463A3.318,3.318,0,0,1,1.043,1.009,3.386,3.386,0,0,1,3.511,0H19.218l-3.49,3.463H5.256a1.664,1.664,0,0,0-1.241.518,1.7,1.7,0,0,0-.5,1.227V22.661A1.664,1.664,0,0,0,4.029,23.9a1.7,1.7,0,0,0,1.227.5H22.708a1.723,1.723,0,0,0,1.745-1.745V12.189l3.49-3.49V24.406a3.491,3.491,0,0,1-3.49,3.49ZM8.746,15.244l3.927,3.927L7,20.916Zm5.672,2.618-4.363-4.336L23.117.436a1.616,1.616,0,0,1,2.209,0L27.48,2.618a1.512,1.512,0,0,1,.45,1.077,1.461,1.461,0,0,1-.45,1.1Z" transform="translate(0 0)" fill="rgba(84,89,96,0.35)" />
          </g>
        </svg>
      </span>
    </>
  )
}

export default Edit

