import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const app = (
  // <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  // </Provider>
)
const container = document.getElementById('root')!;
const root = ReactDOM.createRoot(container); 
root.render(app);
