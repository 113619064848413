import ManageQualityControlOrganism from "../../../components/organisms/ManageQualityControlOrganism";
import AccessControl from "../../../services/AccessControl";
import AccessControlWorkFlow from "../../../services/AccessControlWorkFlow";

const ManageQualityControl = () => {
  //add your hooks here
  return (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={[
        {
          permission: "workflow",
          read: true,
        },
      ]}
    >
      <AccessControlWorkFlow stage_name="quality_control" renderNoAccess={true}>
        <ManageQualityControlOrganism />
      </AccessControlWorkFlow>
    </AccessControl>
  );
};

export default ManageQualityControl;
