
import React, { createContext, useState, useContext } from 'react';

interface LoginContextData {
  isloggedin: boolean;
  updateLoggedIn: (response: boolean) => void;
}

const LoginContext = createContext<LoginContextData>({
  isloggedin: false,
  updateLoggedIn: () => {},
});

export const useLoggedInContext = () => useContext(LoginContext);


interface providerProps  {
  children:any
}
export const LoginProvider: React.FC<providerProps> = ({ children }) => {
const [isloggedin, setIsLoggedIn] = useState(false);

const updateLoggedIn = (status: boolean) => {
  setIsLoggedIn(status);
};

return (
  <LoginContext.Provider value={{isloggedin, updateLoggedIn }}>
    {children}
  </LoginContext.Provider>
);
};