import React from 'react'

const QRscansidebar: React.FC = () => {
  return (
    <>
    <svg id="QR_scan_G_" data-name="QR scan (G)" xmlns="http://www.w3.org/2000/svg"  width="28" height="28" viewBox="0 0 39.912 36.698">
  <defs>
    <clipPath id="clipPath">
      <rect id="Rectangle_444" data-name="Rectangle 444" width="39.912" height="36.698" fill="#545960"/>
    </clipPath>
  </defs>
  <g id="Group_27761" data-name="Group 27761" clipPath="url(#clipPath)">
    <path id="Path_55740" data-name="Path 55740" d="M34.533,2.295h7.844v7.813a1.148,1.148,0,0,0,1.147,1.249,1.148,1.148,0,0,0,1.147-1.249V1.143A1.15,1.15,0,0,0,43.524,0H34.533a.927.927,0,0,0-.117,0,1.148,1.148,0,0,0,.117,2.293" transform="translate(-6.153 0)" fill="#545960" fillRule="evenodd"/>
    <path id="Path_55741" data-name="Path 55741" d="M12.373,40.157H4.528V32.343a1.149,1.149,0,0,0-1.162-1.158,1.155,1.155,0,0,0-.8.347,1.142,1.142,0,0,0-.326.811V41.3A1.147,1.147,0,0,0,3.382,42.45h8.992a1.147,1.147,0,1,0,0-2.293" transform="translate(-0.412 -5.753)" fill="#545960" fillRule="evenodd"/>
    <path id="Path_55742" data-name="Path 55742" d="M3.381,11.359a1.148,1.148,0,0,0,1.147-1.249V2.3h7.844a1.152,1.152,0,0,0,.975-.581A1.148,1.148,0,0,0,12.372,0H3.381a1.159,1.159,0,0,0-.81.336,1.144,1.144,0,0,0-.337.807v8.966a1.148,1.148,0,0,0,1.147,1.249" transform="translate(-0.411 0)" fill="#545960" fillRule="evenodd"/>
    <path id="Path_55743" data-name="Path 55743" d="M43.522,31.185h0a1.149,1.149,0,0,0-1.131,1.158v7.814H34.545a1.153,1.153,0,0,0-.975.581,1.146,1.146,0,0,0,.975,1.713h8.992A1.149,1.149,0,0,0,44.685,41.3V32.343a1.15,1.15,0,0,0-1.163-1.158" transform="translate(-6.166 -5.753)" fill="#545960" fillRule="evenodd"/>
    <path id="Path_55744" data-name="Path 55744" d="M37.916,21.013H2c-1.1,0-2,.589-2,1.315s.892,1.316,1.995,1.316H37.916c1.1,0,2-.589,2-1.315s-.894-1.316-2-1.316" transform="translate(0 -3.877)" fill="#545960" fillRule="evenodd"/>
  </g>
</svg>

    </>
  )
}

export default QRscansidebar