import React, { useState, useEffect, ReactNode } from 'react';

interface WindowHeightComponentProps {
    children: ReactNode; // This type is appropriate for any valid React child
    className?: string;
}

function WindowHeightComponent({ children, className }: WindowHeightComponentProps) {
    const [remainingHeight, setRemainingHeight] = useState(window.innerHeight - 85); // Assuming 100px is taken by other content

    useEffect(() => {
        const handleResize = () => {
            setRemainingHeight(window.innerHeight - 85); // Adjust 100 to whatever the total height of other elements is
        };

        window.addEventListener('resize', handleResize);
        // Cleanup on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        //  <div style={{ height: `${remainingHeight}px`, border: '1px solid black', overflow: 'auto' }} className={className}>
        <div style={{ height: `${remainingHeight}px`, overflow: 'auto' }} className={className}>
            {children}
        </div>
    );
}

export default WindowHeightComponent;
