import React from "react";

export const ApplicationConfiguration = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="70"
        height="65"
        viewBox="0 0 41.078 37.352"
      >
        <g
          id="application_configuration_G_"
          data-name="application configuration (G)"
          transform="translate(-1310.24 -1417.282)"
        >
          <g
            id="_6223940941594721594"
            data-name="6223940941594721594"
            transform="translate(1307.85 1412.503)"
          >
            <path
              id="Path_56642"
              data-name="Path 56642"
              d="M36.464,4.78H6.479A4.016,4.016,0,0,0,2.39,8.869V33.4a4.016,4.016,0,0,0,4.089,4.089H20.654a13.168,13.168,0,0,1-1.908-6.815,13.67,13.67,0,0,1,13.63-13.63,14.016,14.016,0,0,1,8.178,2.726V8.869A4.016,4.016,0,0,0,36.464,4.78Zm-26.033,6c0,.136-.136.273-.273.409a1.318,1.318,0,0,1-1.908,0,1.318,1.318,0,0,1,0-1.908l.136-.136c.136,0,.136-.136.273-.136.136-.136.136-.136.273-.136h.545c.136,0,.136,0,.273.136.136,0,.136.136.273.136l.136.136c.136.136.273.273.273.409a.819.819,0,0,1,.136.545A.819.819,0,0,1,10.431,10.777Zm3.816.409a1.318,1.318,0,0,1-1.908,0,1.237,1.237,0,0,1-.409-.954.819.819,0,0,1,.136-.545c.136-.136.136-.273.273-.409S12.612,9,12.748,9a1.244,1.244,0,0,1,1.5.273c.136.136.273.273.273.409a.819.819,0,0,1,.136.545A1.237,1.237,0,0,1,14.248,11.186Zm4.362-.409c-.136.136-.136.273-.273.409a1.237,1.237,0,0,1-.954.409.819.819,0,0,1-.545-.136c-.136-.136-.273-.136-.409-.273s-.273-.273-.273-.409a.819.819,0,0,1-.136-.545.819.819,0,0,1,.136-.545c.136-.136.136-.273.273-.409a1.318,1.318,0,0,1,1.908,0c.136.136.273.273.273.409a.819.819,0,0,1,.136.545A.819.819,0,0,1,18.609,10.777Z"
              fill="#545960"
            />
          </g>
          <path
            id="Manage_roles"
            data-name="Manage roles"
            d="M48.014,48.557A11,11,0,1,1,59.007,59.563,11,11,0,0,1,48.014,48.557Zm8.049,4.716a5.071,5.071,0,0,0,.643.349,6.49,6.49,0,0,0,.688.268v1.167a.7.7,0,0,0,.7.7h1.465a.685.685,0,0,0,.492-.2.672.672,0,0,0,.208-.5V53.989a5.339,5.339,0,0,0,.71-.218,6.381,6.381,0,0,0,.67-.3l.828.829a.683.683,0,0,0,.986,0l1.038-1.027a.732.732,0,0,0,0-1l-.772-.76a4.968,4.968,0,0,0,.352-.651,6.829,6.829,0,0,0,.268-.689h1.17a.7.7,0,0,0,.7-.7V48.015a.688.688,0,0,0-.2-.492.677.677,0,0,0-.5-.208H64.445a5.94,5.94,0,0,0-.218-.7,5.242,5.242,0,0,0-.3-.663l.824-.841a.654.654,0,0,0,.212-.488.689.689,0,0,0-.212-.5L63.721,43.1a.7.7,0,0,0-1,0l-.76.759a5.05,5.05,0,0,0-.645-.35,6.561,6.561,0,0,0-.689-.268V42.072a.668.668,0,0,0-.2-.5.68.68,0,0,0-.5-.2H58.464a.689.689,0,0,0-.492.2.666.666,0,0,0-.208.5v1.067a5.238,5.238,0,0,0-.711.218,5.412,5.412,0,0,0-.654.3l-.841-.83a.654.654,0,0,0-.488-.211.693.693,0,0,0-.5.211l-1.024,1.028a.7.7,0,0,0,0,1l.759.76a5.087,5.087,0,0,0-.349.643,6.8,6.8,0,0,0-.269.689H52.522a.7.7,0,0,0-.7.7v1.463a.7.7,0,0,0,.7.7h1.067a5.3,5.3,0,0,0,.218.71,6.684,6.684,0,0,0,.3.67l-.829.828a.638.638,0,0,0-.212.488.685.685,0,0,0,.212.5l1.027,1.038a.71.71,0,0,0,.5.2.7.7,0,0,0,.5-.2l.76-.772h0ZM57.9,51.208a2.837,2.837,0,0,1-1.525-1.521,2.907,2.907,0,0,1,0-2.236A2.837,2.837,0,0,1,57.9,45.926a2.919,2.919,0,0,1,2.235,0,2.975,2.975,0,0,1,.915.612,2.892,2.892,0,0,1,.611,3.152,2.826,2.826,0,0,1-1.525,1.525,2.919,2.919,0,0,1-2.235,0Z"
            transform="translate(1281.308 1395.072)"
            fill="#545960"
          />
        </g>
      </svg>
    </>
  );
};
