import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { Add } from "../../../static/icons/shipment/Add";
import styles from "./dataentry.module.css";
import { APICALL } from "../../../services/ApiServices";
import { getDataEntry } from "../../../routes/ApiEndPoints";
import TableStructure from "../../../components/atoms/TableStructure";
import {
  CommonConstants,
  DataEntryConstants,
} from "../Constants/ShipmentConstants";
import { useNavigate } from "react-router-dom";
import { t } from "../../Translations/TranslationUtils";
import Button from "../../../components/atoms/Button";
import Popup from "../../../utils/popup";
import BarcodeBody from "../../../components/organisms/BarcodeBody";
import AccessControlWorkFlow from "../../../services/AccessControlWorkFlow";
import AccessControl from "../../../services/AccessControl";
import CommonServices from "../../../services/CommonServices";
import FormValidation from "../../../services/FormValidation";
import customAlert from "../../../components/atoms/CustomAlert";

interface VarState {
  barcode: boolean;
  barcodetitle: string;
  barcodeid: string;
  barcodeurl: string;
  stagecount: number;
}

const ManageDataEntryOverview = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [varState, setVarState] = useState<VarState>({
    barcode: false,
    barcodetitle: "",
    barcodeid: "",
    barcodeurl: "",
    stagecount: 0
  });
  const shipmentDetails = JSON.parse(
    localStorage.getItem("shipment_details") ?? "{}"
  );
  const bpdetails = JSON.parse(localStorage.getItem("bp_details") ?? "{}");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const postData: any = {
        brand_partner_id: bpdetails.brand_partner_id,
      };
      if (shipmentDetails?.shipment_id) {
        postData.shipment_id = shipmentDetails.shipment_id;
      }
      const response = await APICALL.service(getDataEntry, "POST", postData);
      if (response.status === 200) {
        setVarState((prev)=> ({ ...prev, stagecount:  response.stagecount ? Number(response.stagecount) : 0 }))
        let formatted: any = [];
        response.data[0].forEach((item: any) => {
          formatted.push({
            ...item,
            stage_name: CommonServices.capitalizeLabel(item.stage_name ?? '')
          })
        });

        setData(formatted);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (value: any) => {
    navigate(`/data-entry/overview?edit=${value.renewal_id}`);
  };

  const handlePrint = async (data: any) => {
    try {      
      const postdata = {
        "printerName": data?.inputValue.trim(),
        "labelType": "SerialNumber",
        "labelData": {
            "serialNumber": varState?.barcodeid
        }
      }
      let error: any = validatepostdata(postdata);
      if(!error) {        
        let res: any = await CommonServices.printLabelApi(postdata);
        res?.message && customAlert(res?.type, t(res?.message), 6000);
        if (res.statusCode === 201) {
          setVarState({ ...varState, barcode: false, barcodeid: "", barcodeurl: "" });
        }
      } else {
        let errortext = Object.keys(error).filter(key => error[key] !== "").map(key => `<div>${t(error[key])}</div>`).join("");
        errortext && customAlert('error', errortext, 6000);
      }
      // setVarState({ ...varState, barcode: false, barcodeid: "", barcodeurl: "" });
    } catch (error) {
      console.log(error);
    }
  }
  const validatepostdata = (data: any) => {    
    let error = {
      "printerName": FormValidation.nameValidation(data?.printerName, 255 ,'Printer name'),
      "serialNumber": FormValidation.nameValidation(data?.labelData?.serialNumber, 8 ,'Serial number'),
    }
    return Object.values(error)?.some(item => item !== "") ? error : false;
  }
  return (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={[
        {
          permission: "workflow",
          read: true,
        },
      ]}
    >
      <AccessControlWorkFlow stage_name="data_entry" renderNoAccess={true}>
        <Layout pagename={t("Data entry")} logo={true} timer={false}>
          {varState.barcode && (
            <Popup
              body={
                <BarcodeBody
                  handlePrint={handlePrint}
                />
              }
              title={t("Print barcode")}
              cancel={() => setVarState({ ...varState, barcode: false, barcodeid: "", barcodeurl: "" })}              
            />
          )}
          <div className="row">
            <div className="col-11 ms-auto px-4 mb-4">
              {/* create start */}
              { varState.stagecount > 0 &&
                <div className="d-flex justify-content-end mb-2">
                  <div className="d-flex align-items-center">
                    <button
                      onClick={() => navigate("/data-entry/create")}
                      className={`submit-btn shadow-none ${styles.create_btn}`}
                    >
                      <span className="mb-3 me-2">
                        <Add />
                      </span>{" "}
                      {t('Create new entry')}
                    </button>
                  </div>
                </div>
              }
              {/* create ends */}
              <div className="">
                <TableStructure
                  isAction
                  data={data}
                  headers={DataEntryConstants.DATA_ENTRY_TABLE_HEADERS}
                  values={DataEntryConstants.DATA_ENTRY_TABLE_VALUES}
                  handleEdit={handleEdit}
                  handleBarcode={(value: any) =>
                    setVarState({
                      ...varState,
                      barcode: true,
                      barcodetitle: value.serial_number,
                      barcodeid: value.barcode_id,
                      barcodeurl: value.barcode_url,
                    })
                  }
                />
              </div>
              <div className="d-flex justify-content-between mt-5 mb-3">
                <Button
                  title={t(CommonConstants.BACK)}
                  className={"back-btn shadow-none text-decoration-none"}
                  handleClick={() => navigate(localStorage?.getItem('shipment_dashboard_url') ?? "/shipment-dashboard")}
                />
              </div>
            </div>
          </div>
        </Layout>
      </AccessControlWorkFlow>
    </AccessControl>
  );
};

export default ManageDataEntryOverview;
