import React, { useEffect, useState } from 'react';
import styles from '../organisms/organisms.module.css';
import CommonShipmentServices from '../../services/CommonShipmentServices';
import IconData from '../../static/IconData';
import { t } from '../../pages/Translations/TranslationUtils';

interface ViewIssueMoleculeProps {
  item: any,
  configdata: any,
  handleAction?: any
  handleEditAction?: any;
  action?: any
  setState?: any
  actiondelete?: any
  idname: string
  object?: boolean
  multiaction?: any
  actionedit?: any;
}

const ViewIssueMolecule: React.FC<ViewIssueMoleculeProps> = ({ item, configdata, handleAction, handleEditAction, action, actiondelete, setState, idname, object = false, multiaction, actionedit }) => {

  return (
    <div className="col-12 h-100 " key={item[idname]}>
      <div className='p-2 h-100' style={{ backgroundColor: (CommonShipmentServices.getFilteredTitle(configdata['IssueType'] ?? [], 'issue_type_id', item?.issue_id, 'color')) }}>

        <div className={`d-flex justify-content-end ${styles.qcedit} ${styles.qceditblock}`}>
          {actionedit &&
            <div
              title={t('Edit')}
              onClick={() => handleEditAction(object == true ? item : item[idname], true)}
              className={`me-2 mt-2 hoversvg ${styles.qcedit}`}>
              {actionedit}
            </div>
          }
          {action &&
            <div
              onClick={() => handleAction(object == true ? item : item[idname])}
              className={`me-2 mt-2 hoversvg ${styles.qcedit}`}>
              {action}
            </div>
          }
          {actiondelete &&
            <div
              // onClick={() => setState((prev: any)=> ({...prev, deletePopup: true, deleteissue: item}))}
              onClick={() => setState(item)}
              className={`me-2 mt-2 hoversvg ${styles.qcedit}`}>
              {actiondelete}
            </div>
          }
          {multiaction &&
            Object.values(multiaction).map((item: any) => (
              <div
                onClick={() => handleAction(item.name)}
                className={`me-2 mt-2 ${styles.qcedit}`}>
                {item.icon}
              </div>
            ))
          }
        </div>
        <div className="row align-items-center px-3 ">
          <div className="col-3 text-center">
            <div className={`${styles.qclabel} mb-1`}> {IconData.Issue}</div>
          </div>
          <div className="col-9">
            <div className={`${styles.qcvalue} mb-1 text-break `}>{CommonShipmentServices.getFilteredTitle(configdata['IssueType'] ?? [], 'issue_type_id', item?.issue_id)}</div>
          </div>
          <div className="col-3 text-center">
            <div className={`${styles.qclabel} mb-1`}>{IconData.Zone}</div>
          </div>
          <div className="col-9">
            <div className={`${styles.qcvalue} mb-3 text-break`}>{item.zone_id == 0 ? 'All' : CommonShipmentServices.getFilteredTitle(configdata['Zone'] ?? [], 'zone_id', Number(item?.zone_id))}</div>
          </div>
          {item.environment_id &&
            <>
              <div className="col-3 text-center">
                <div className={`${styles.qclabel} `}>{IconData.Issuetype}</div>
              </div>
              <div className="col-9">
                <div className={`${styles.qcvalue} text-break`}>{CommonShipmentServices.getFilteredTitle(configdata['Environment'] ?? [], 'environment_id', item?.environment_id)}</div>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default ViewIssueMolecule;
