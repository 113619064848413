import * as XLSX from "xlsx";
import { t } from "../pages/Translations/TranslationUtils";

const DownloadXlsx = (data: any, title: string) => {
  
  data = transformData(data);
  const jsonData = data;
  const worksheet = XLSX.utils.json_to_sheet(jsonData);

  const columnWidths = columnWidth(data);
  worksheet["!cols"] = columnWidths;

  // Loop through all cells and set alignment
  for (const key in worksheet) {
    if (worksheet.hasOwnProperty(key) && key !== '!ref' && key !== '!margins') {
      worksheet[key].s = { alignment: { horizontal: 'left', vertical: 'center' } };
    }
  }

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `${t(title)}.xlsx`);
};

function transformData(data: any) {
  return data.map((item: any) => {
    const transformedItem: Record<string, any> = {};
    for (const key in item) {
      if (item.hasOwnProperty(key)) {
        const camelCasedKey = key
          .replace(/_/g, " ")
          .replace(/\b\w/g, (match) => match.toUpperCase());
        transformedItem[camelCasedKey] = item[key];
      }
    }
    return transformedItem;
  });
}

function columnWidth(data: any) {
  const keyLengthArray = [];

  for (const key in data[0]) {
    if (data[0].hasOwnProperty(key)) {
      const maxColumnWidth = Math.max(
        key.length,
        ...data.map((item: any) => `${item[key]}`.length)
      );

      keyLengthArray.push({ wch: maxColumnWidth });
    }
  }

  return keyLengthArray;
}

export default DownloadXlsx;
