import React from "react";
import ManageQualityAssuranceOrganism from "../../../components/organisms/QualityAssurance/ManageQualityAssuranceOrganism";
import AccessControlWorkFlow from "../../../services/AccessControlWorkFlow";
import AccessControl from "../../../services/AccessControl";

const ManageQualityAssurance = () => {
  return (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={[
        {
          permission: "workflow",
          read: true,
        },
      ]}
    >
      <AccessControlWorkFlow
        stage_name="quality_assurance"
        renderNoAccess={true}
      >
        <ManageQualityAssuranceOrganism />
      </AccessControlWorkFlow>
    </AccessControl>
  );
};

export default ManageQualityAssurance;
