import React from 'react'

const Usermanagement = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="65" height="70" viewBox="0 0 69.553 69.556">
        <defs>
          <clipPath id="clipPath">
            <rect id="Rectangle_437" data-name="Rectangle 437" width="69.553" height="69.556" fill="#545960" />
          </clipPath>
        </defs>
        <g id="User_management_" data-name="User management " transform="translate(0 0)">
          <g id="Group_27753" data-name="Group 27753" transform="translate(0 0)">
            <path id="Path_55714" data-name="Path 55714" d="M69.538,55.165l.014-7.054s-4.654-.923-4.733-1.234c-.084-.349-.177-.689-.283-1.015a20.758,20.758,0,0,1,3.172-3.236l-5.251-6.99a49.993,49.993,0,0,1-4.251,3.208c-.4-.155-.809-.291-1.242-.419-.46-.113-1.723-4.6-1.723-4.6l-7.075-.056s-.759,4.341-1.909,4.64a8.6,8.6,0,0,0-1.149.433,49.093,49.093,0,0,1-4.25-3.256L35.606,42.7s2.632,2.653,2.81,3.54c-.114.277-.213.561-.32.852-1.128.39-4.343,1.029-4.343,1.029l.015,7.054s3.739.782,4.448,1.4c.149.405.32.8.5,1.193a26.061,26.061,0,0,1-3.165,2.874L40.97,67.7a49.563,49.563,0,0,1,4.954-2.909c.347.149.7.291,1.064.419.638.227,1.093,4.335,1.093,4.335l7.061.014s.241-4.03.759-4.193a14.881,14.881,0,0,0,1.5-.561,30.59,30.59,0,0,1,3.386,3.017l6.982-7.11a20.968,20.968,0,0,1-3.307-2.753q.288-.6.532-1.236c.235-.66,4.548-1.553,4.548-1.553m-17.95,5.376a8.91,8.91,0,1,1,8.911-8.91,8.91,8.91,0,0,1-8.911,8.91m-22.677-27.1c8.1,0,16.358-7.974,16.358-16.416S37.416,0,29.308,0,13.141,8.8,13.141,17.231s7.662,16.21,15.77,16.21m3.564,3.511H16.265c-4.053,0-10.138,2.109-12.161,8.434S2.08,51.712.049,58.045c-1.015,3.182,13.837,4.8,28.6,4.751,4.9-.015,1.562-4.041,1.562-9.146,0-11.643,9.932-16.7,2.264-16.7" transform="translate(0 0)" fill="#545960" />
          </g>
        </g>
      </svg>
    </>
  )
}

export default Usermanagement

