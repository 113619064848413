import React from "react";
import { t } from "../../pages/Translations/TranslationUtils";
import styles from "../../pages/Shipment/shimpment.module.css";
import SortCounter from "./SortCounterMolecule";

interface SortCategoriesMoleculeProps {
  sortCategories: any[];
  apiData: any[];
  handleCountChange?: (categoryId: any, newCount: number) => void;
  timer: boolean;
  disablePlusMinus?: boolean;
}

const SortCategoriesMolecule: React.FC<SortCategoriesMoleculeProps> = ({
  sortCategories,
  apiData,
  handleCountChange,
  timer,
  disablePlusMinus = false
}) => {   
  return (
    <div className="shadow rounded h-100 overflow-auto  py-4 px-3">
      <div className="py-xxl-2 py-0 pt-xxl-4 pt-0">
        <div className="row">
          {sortCategories?.map((category) => {
            const categoryData = apiData?.find((data) => data.name === category.name);            
            if (!categoryData) return null;
            return (
              <div key={category.name} className={`col d-flex flex-column text-center mb-2 ${styles.sortcounter}`}>
                <div className={`${styles.category_icon}`}>
                  <category.icon />
                </div>
                <div className={`${styles.sortingheading} my-2 flex-1`}>{t(categoryData?.title)}</div>
                <div className={`mt-2 mb-0 ${styles.plus_minus}`}>
                  <SortCounter
                    count={categoryData?.counts}
                    onCountChange={(newCount) => handleCountChange ? handleCountChange(categoryData?.sort_category_id, newCount) : ""}
                    timer={timer}
                    disablePlusMinus={disablePlusMinus}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SortCategoriesMolecule;
