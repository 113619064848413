import { UTILITYFN } from '../utility/Utility';
import { t } from '../../../Translations/TranslationUtils';
interface Props {
  setPageDirection: (page: number) => void;
  pageNumber: number;
  currentPage: number;
  imgSrc?: string;
  imgBlue?: string;
  title?: string;
  stepName?: string;
  showErrorMessage?: (() => void) | undefined;
}
function getPageValidationStatus(props: any) {
  switch (props.currentPage) {
    case 1:
      return props.pageOne.validationStatus;
    case 2:
      return props.pageTwo.validationStatus;
    case 3:
      return props.pageThree.validationStatus;
    case 4:
      return props.pageFour.validationStatus;
    case 5:
      return props.pageFive.validationStatus;
    case 6:
      return props.pageFive.validationStatus;
    default:
      return false;
  }
}

function TabBars(props: Props) {
  function handleUserClickOnTab(event: React.MouseEvent<HTMLDivElement>) {
    event.preventDefault();
    if (sessionStorage?.getItem("brand_partner_id")) {
      props.setPageDirection(props.pageNumber);
    } else if (props.pageNumber > 1 && props.showErrorMessage) {
      localStorage.setItem("errorAlertMessage", t(`Please fill all the required fields`));
      props.showErrorMessage();
    }
  }

  return (
    <div
      onClick={handleUserClickOnTab}
      className={` move_nav_menu col px-1 ${UTILITYFN.getPageTickStatus(props) ? "custom-cursor" : ""
        } `}
    >
      <ul className="nav nav-pills nav-fill col-md-12">
        <li className={`nav-item  flex ${props.currentPage === props.pageNumber ? "active_tabbtn" : "tabbtn"
          }`}>
          <div>
            <div>
              <span className='text-truncate px-1'>{props.title}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default TabBars;
