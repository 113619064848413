import React from "react";
import QualityAssuranceOrganism from "../../../components/organisms/QualityAssurance/QualityAssuranceOrganism";
import AccessControlWorkFlow from "../../../services/AccessControlWorkFlow";
import AccessControl from "../../../services/AccessControl";

const QualityAssurance = () => {
  return (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={[
        {
          permission: "workflow",
          read: true,
        },
      ]}
    >
      <AccessControlWorkFlow
        stage_name="quality_assurance"
        renderNoAccess={true}
      >
        <QualityAssuranceOrganism />
      </AccessControlWorkFlow>
    </AccessControl>
  );
};

export default QualityAssurance;
