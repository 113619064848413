import React from 'react'

const Sort = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="147.589" height="70" viewBox="0 0 147.589 83.01">
        <path id="Union_14" data-name="Union 14" d="M110.273,83.01V64.976h17.664l4.441-5.568v23.6Zm2.907-7.418h8.146V72.128h-8.146Zm-55.5,7.418V64.976H75.343l4.441-5.568v23.6Zm2.907-7.418h8.146V72.128H60.586ZM5.085,83.01V64.976H22.749l4.441-5.568v23.6Zm2.907-7.418h8.146V72.128H7.992Zm125.725,7.418v-23.6l4.448,5.567h4.34V83.009Zm-52.594,0v-23.6l4.448,5.567h4.34V83.009Zm-52.594,0v-23.6l4.448,5.567h4.34V83.009Zm110.279-19.37-5.091-6.37h8.789l5.084,6.37Zm-33.62,0,5.085-6.37h22.105l-5.085,6.37Zm-18.974,0-5.091-6.37h8.789L95,63.639Zm-33.62,0,5.085-6.37H79.784L74.7,63.639Zm-18.974,0-5.091-6.37h8.789l5.084,6.37ZM0,63.639l5.085-6.37H27.19l-5.085,6.37ZM72.815,46.16a.751.751,0,0,1-.274-.273L66.7,35.825a.753.753,0,0,1,.653-1.131h3.975V.753A.754.754,0,0,1,72.077,0H74.31a.754.754,0,0,1,.754.753V34.694H79.04a.753.753,0,0,1,.653,1.131L73.846,45.886a.755.755,0,0,1-1.031.273Zm52.405-.34h0l-5.733-9.9a.8.8,0,0,1,.691-1.193h3.613A16.424,16.424,0,0,0,107.5,20.349h-7.054A20.405,20.405,0,0,1,80.063.919.873.873,0,0,1,80.932,0h2.253a.86.86,0,0,1,.865.807,16.419,16.419,0,0,0,16.4,15.567H107.5a20.409,20.409,0,0,1,20.3,18.35h3.838a.8.8,0,0,1,.691,1.193l-5.733,9.9a.8.8,0,0,1-1.381,0Zm-104.232,0-5.733-9.9a.8.8,0,0,1,.691-1.193h3.838a20.409,20.409,0,0,1,20.3-18.35h7.054A16.419,16.419,0,0,0,63.54.808.86.86,0,0,1,64.4,0h2.253a.873.873,0,0,1,.868.919,20.405,20.405,0,0,1-20.384,19.43H40.089A16.424,16.424,0,0,0,23.8,34.724H27.41a.8.8,0,0,1,.691,1.193l-5.733,9.9h0a.8.8,0,0,1-1.38,0Z" fill="rgba(84,89,96,0.6)" />
      </svg>
    </>
  )
}

export default Sort

