import React, { useState } from 'react'
import styles from '../molecules.module.css';
import DatePicker from 'react-datepicker';
import Button from '../../atoms/Button';
import IconData from '../../../static/IconData';
import { t } from '../../../pages/Translations/TranslationUtils';
import { CommonConstants } from '../../../pages/Shipment/Constants/ShipmentConstants';
import { useUserContext } from '../../../routes/Contextlib';
import CommonServices from '../../../services/CommonServices';

interface Props {
  getSelected: (selected: any) => void;
  externalvendor: any;
  selecteddata: any;
  closePopup: () => void;
}

const SelectVendor: React.FC<Props> = ({ getSelected, externalvendor, selecteddata, closePopup }) => {
  const [inputdata, setInputData] = useState<any>({ // state object
    pickup_date: selecteddata?.inputdata.pickup_date,
    expected_delivery_date: selecteddata?.inputdata.expected_delivery_date,
    delivered_date: selecteddata?.inputdata.delivered_date,
    selected: selecteddata?.selected
  });
  const { user } = useUserContext();

  const formatDate = (dateValue: any, variable: string) => { // onchange of the date
    if (dateValue !== null) { // formatting and setting the state with the formatted date
      const date = new Date(dateValue);
      const timezoneOffset = date.getTimezoneOffset() * 60 * 1000; // To set time zone offset
      const formattedDate = new Date(date.getTime() - timezoneOffset)
        .toISOString()
        .slice(0, 10);
      setInputData({ ...inputdata, [variable]: formattedDate });
    } else { // setting the state with the null when it is cleared
      setInputData({ ...inputdata, [variable]: null });
    }
  };

  const handleClick = (item: any) => { // handling on click of external vendor
    if (selecteddata?.inputdata.batch_status !== 'IAL') {
      setInputData({ ...inputdata, selected: item });
    }
  }

  const handleSave = (selected: any, inputdata: any) => { // handling the submit of the popup
    const data = { selected, inputdata };
    getSelected(data); // sending data to batch info
  }

  return (
    <div className=''>
      <div className={`${styles.select_vendor_pop_body}`}>
        <div className={`d-flex flex-wrap px-5`}>
          {externalvendor?.map((item: any) => (
            <div key={item.external_vendor_id} className={`${styles.vendors} position-relative d-flex flex-column `}>
              <div className={`${styles.vendors_btn} rounded text-break flex-1 d-flex align-items-center justify-content-center
                        ${inputdata.selected && inputdata.selected.external_vendor_id === item.external_vendor_id ? styles.activeItem : ''} 
                    `}
                onClick={() => handleClick(item)}
              >
                {item.title}
              </div>
              {(inputdata.selected?.external_vendor_id === item.external_vendor_id) &&
                (selecteddata?.inputdata.batch_status !== 'IAL' ?
                  <>
                    <div className='mt-2 d-flex'>
                      <div className={`${styles.select_vendor_icon}`}>{IconData.ExpectedPickupDateIcon}</div>
                      <DatePicker
                        autoComplete="off"
                        isClearable
                        name="pickup_date"
                        dateFormat={user?.date_format}
                        placeholderText={user?.date_format}
                        selected={inputdata.pickup_date ? new Date(inputdata.pickup_date) : null}
                        onChange={(dateValue) => formatDate(dateValue, 'pickup_date')}
                        showMonthDropdown
                        showYearDropdown
                        calendarStartDay={1}
                        dropdownMode="select"
                        className="ps-2 w-100 form-control"
                        onKeyDown={(e: any) => {
                          if (e.target.value.length >= 10 && e.key !== 'Backspace' && e.key !== 'Delete') {
                            e.preventDefault();
                          }
                        }}
                        maxDate={inputdata.expected_delivery_date ? new Date(inputdata.expected_delivery_date) : null}
                      />
                    </div>
                    <div className='mt-2 d-flex'>
                      <div className={`${styles.select_vendor_icon}`}>{IconData.ExpectedDeliveryDateIcon}</div>
                      <DatePicker
                        autoComplete="off"
                        isClearable
                        name="expected_delivery_date"
                        dateFormat={user?.date_format}
                        placeholderText={user?.date_format}
                        selected={inputdata.expected_delivery_date ? new Date(inputdata.expected_delivery_date) : null}
                        onChange={(dateValue) => formatDate(dateValue, 'expected_delivery_date')}
                        showMonthDropdown
                        showYearDropdown
                        calendarStartDay={1}
                        dropdownMode="select"
                        className="ps-2 w-100 form-control"
                        onKeyDown={(e: any) => {
                          if (e.target.value.length >= 10 && e.key !== 'Backspace' && e.key !== 'Delete') {
                            e.preventDefault();
                          }
                        }}
                        minDate={inputdata.pickup_date ? new Date(inputdata.pickup_date) : null}
                      />
                    </div>
                  </> :
                  <div className='mt-3 d-flex'>
                    <div className={`${styles.select_vendor_icon}`}>{IconData.LaundryDeliveredSuccessfulIcon}</div>
                    <DatePicker
                      autoComplete="off"
                      name="delivered_date"
                      dateFormat={user?.date_format}
                      placeholderText={user?.date_format}
                      selected={inputdata.delivered_date ? new Date(inputdata.delivered_date) : null}
                      onChange={(dateValue) => formatDate(dateValue, 'delivered_date')}
                      showMonthDropdown
                      showYearDropdown
                      calendarStartDay={1}
                      dropdownMode="select"
                      className="ps-2 w-100 form-control"
                      onKeyDown={(e: any) => {
                        if (e.target.value.length >= 10 && e.key !== 'Backspace' && e.key !== 'Delete') {
                          e.preventDefault();
                        }
                      }}
                      minDate={inputdata.pickup_date ? new Date(inputdata.pickup_date) : null}
                    />
                  </div>
                )
              }
            </div>
          ))}
        </div>
      </div>
      <div className='d-flex flex-wrap justify-content-between mt-xxl-5 mt-4' >
        <Button
          title={t(CommonConstants.CANCEL)}
          className='back-btn'
          handleClick={closePopup}
        />
        <Button
          title={t(CommonConstants.SAVE)}
          className='submit-btn'
          handleClick={() => handleSave(inputdata.selected, inputdata)}
        />
      </div>
    </div>
  )
}

export default SelectVendor;
