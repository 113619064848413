import React, { useEffect, useState } from "react";
import UserTable from "./UserTable";
import EncryptDecryptService from "../../services/EncryptDecryptService";
import { useUserContext } from "../../routes/Contextlib";
import CustomAlert from "../../components/atoms/CustomAlert";
import { t } from "../Translations/TranslationUtils";
import { getusers, createUserUrl } from "../../routes/ApiEndPoints";
import { APICALL } from "../../services/ApiServices";
import AccessControl from "../../services/AccessControl";
import { CONSTANTS } from "../../applicationConstants/AppConstants";
import WindowHeightComponent from "../../commonComponents/WindowHeightComponent";

const ManageUser: React.FC = () => {
  const [requiredPermissions, setRequiredPermissions] = useState<any>([]);
  interface User {
    user_id: number;
    username: string;
    email: string;
    roles: [];
    status: boolean;
  }
  const { user, setUser } = useUserContext();
  const [users, setUsers] = useState<User[]>([]);
  const [state, setState] = useState({
    limit: CONSTANTS.PAGINATION_LIMIT,
    offset: 0,
    totalLength: 1,
    totalPages: 1,
    activeTab: "active",
    searchText: "",
    issorted: false,
    filter: 'desc'
  });
  useEffect(() => {
    const storageMessage = localStorage.getItem("successAlertMessage");
    if (storageMessage) {
      localStorage.removeItem("successAlertMessage");
      CustomAlert("success", t(storageMessage), 6000);
    }
  });

  useEffect(() => {
    if (user != null) {
      fetchUsers();
    }
  }, [user != null, state.offset, state.activeTab, state.searchText]);

  // Fetch user details here
  const fetchUsers = async (filter?: any) => {
    try {
      let data: any = {
        manage: true,
        offset: (filter ? 0 : state.offset),
        limit: state.limit,
        status: state.activeTab,
        search: state.searchText,
      };
      if (filter || state.issorted) {
        data.order = ["profiles.first_name", "profiles.last_name", filter ?? state.filter];
      }
      let postData = {
        method: "POST",
        data: EncryptDecryptService.encryptData(JSON.stringify(data)),
      };
      let res = await APICALL.service(getusers, "POST", postData);
      let decrypteddata = JSON.parse(EncryptDecryptService.decryptData(res));
      if (decrypteddata?.status !== 200) {
        throw new Error("Request failed");
      }
      const filteredUsers = decrypteddata.data?.data?.filter(
        (item: User) => item.user_id != user?.user_id
      );
      const modifiedUsers = filteredUsers.map((item: any) => {
        const firstName =
          item.first_name?.charAt(0).toUpperCase() + item.first_name?.slice(1);
        const lastName =
          item.last_name?.charAt(0).toUpperCase() + item.last_name?.slice(1);
        const username = `${firstName}${lastName ? " " + lastName : ""}`;
        return {
          ...item,
          username: username,
        };
      });

      setUsers(modifiedUsers);
      const totalPages = Math.ceil(
        decrypteddata?.data?.totalLength / state.limit
      );
      setState((prev) => ({
        ...prev,
        totalLength: decrypteddata?.data?.totalLength,
        totalPages: totalPages,
        offset: filter ? 0 : state.offset,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const deleteUser = async (userId: number, status: boolean) => {
    try {
      let postData = {
        // Encrypting the request data
        method: "POST",
        data: EncryptDecryptService.encryptData(
          JSON.stringify({
            user_id: userId,
            user_status: !status,
            profile_status: !status,
          })
        ),
      };
      let response = await APICALL.service(createUserUrl, "POST", postData);
      let decrypteddata = JSON.parse(
        EncryptDecryptService.decryptData(response)
      );
      if (decrypteddata?.status === 200) {
        // Update users state after successful deletion
        CustomAlert("success", t("User updated successfully"), 6000);
      }
    } catch (error) {
      CustomAlert("error", t("Error while updating user"), 6000);
      console.error(error);
    }
  };

  useEffect(() => {
    const userPerm: any = user?.permissions;
    const truePerm: any = userPerm?.find(
      (item: any) => item.permission.toLowerCase().trim() === "users"
    );

    let permissions = [
      {
        permission: "users",
        read: true,
      },
    ];
    if (truePerm?.update === true || truePerm?.delete === true) {
      permissions[0].read = false;
    }
    setRequiredPermissions(permissions);
  }, [user != null]);

  return (
    <AccessControl
      requiredPermissions={requiredPermissions}
      renderNoAccess={true}
    >
      <div className="row">
        <div className="col-md-11 ms-auto px-4">
          <WindowHeightComponent>
            <div className="d-flex flex-column h-100">
              <div className="py-xxl-4 py-xl-3 py-lg-3 page-title top-0">
                {t("Manage users")}
              </div>
              <div className="flex-1 overflow-auto">
                <UserTable
                  fetchUsers={fetchUsers}
                  state={state}
                  setState={setState}
                  users={users}
                  onDeleteUser={deleteUser}
                />
              </div>
            </div>
          </WindowHeightComponent>
        </div>
      </div>
    </AccessControl>
  );
};

export default ManageUser;
