import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Mousewheel, Pagination, Keyboard, Navigation } from "swiper/modules";
import ViewFixedIssueMolecule from "../../molecules/ViewFixedIssueMolecule";
import { t } from "../../../pages/Translations/TranslationUtils";
import CommonServices from "../../../services/CommonServices";

type RecordsIssueList = {
  selectedIssueList: any;
  configdata: any;
  rejected: boolean;
  rejectionReason?: any;
};

const RecordsIssueList: React.FC<RecordsIssueList> = ({
  selectedIssueList,
  configdata,
  rejected,
  rejectionReason = ""
}) => {

  const [numColumns, setNumColumns] = useState(3);
  useEffect(() => {
    function updateNumColumns() {
      if (window.innerWidth >= 1400) {
        setNumColumns(3);
      } else {
        setNumColumns(2);
      }
    }
    window.addEventListener("resize", updateNumColumns);
    updateNumColumns();
    return () => {
      window.removeEventListener("resize", updateNumColumns);
    };
  }, []);

  return (
    <div className="">
      {rejected == true ?
        <>
          <div className={`row mt-3 ms-1 m-0 rounded shadow px-2 border merchandingissues`}>
            <h3 className="pt-2 m-0">{t('Reason for rejected items')}</h3>
            <div className="py-2 text-break">
              {CommonServices.stringFormat(rejectionReason)}
            </div>
          </div>
        </>
        :
        (<>
          {selectedIssueList && Object.keys(configdata).length > 0 &&
            Object.keys(selectedIssueList).length > 0 ?
            (<div className={`row m-0 mt-3 rounded shadow px-2 border merchandingissues`}>
              <Swiper
                direction={"vertical"}
                slidesPerView={1}
                spaceBetween={30}
                mousewheel={true}
                keyboard={{
                  enabled: true,
                }}
                navigation={true}
                modules={[Mousewheel, Pagination, Keyboard, Navigation]}
                className="mySwiper"
              >
                {Object.values(selectedIssueList).map(
                  (item: any, index: number) =>
                    // Wrap every 'numColumns' items in a new row
                    index % numColumns === 0 && (
                      <SwiperSlide key={item?.quality_control_issue_id}>
                        <div className={`row w-100 me-4`}>
                          {Array.from({ length: numColumns }).map((_, colIndex) => {
                            const dataIndex = index + colIndex;
                            if (dataIndex < selectedIssueList?.length) {
                              const colWidth = `col-${12 / numColumns
                                }`;
                              return (
                                <div
                                  key={selectedIssueList[dataIndex]?.quality_control_issue_id}
                                  className={colWidth}
                                >
                                  <ViewFixedIssueMolecule
                                    item={selectedIssueList[dataIndex]}
                                    configdata={configdata}
                                    idname="quality_control_issue_id"
                                  />
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                      </SwiperSlide>
                    )
                )
                }
              </Swiper>
            </div>)
            : (
              <div className="p-5 text-center">
                {t("No issues selected")}
              </div>
            )
          }
        </>)
      }
    </div>
  );
};
export default RecordsIssueList;