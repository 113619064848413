import React from "react";
import { t } from "../../pages/Translations/TranslationUtils"; 
import styles from "../../pages/Shipment/shimpment.module.css";
import Success from "../../static/icons/shipment/Success";

interface SuccessBoxMoleculeProps {
  apiData: any[];
  SortingConstants: any;
}

const SuccessBoxMolecule: React.FC<SuccessBoxMoleculeProps> = ({
  apiData,
  SortingConstants,
}) => {  
  return (
    <div className="shadow rounded overflow-auto py-4 px-3">
      <div className="text-center pb-4">
        <Success />
        <h2 className='my-xxl-5 my-3 text-gray'>{`${t(SortingConstants?.SUCCESS_MESSAGE)}`}</h2>
        <div className='row'>
          <div className={`col-xxl-7 col-xl-8 col-lg-10 m-auto p-5 px-md-3 px-xl-3 px-xxl-5 ${styles.success_box}`}>
            <div className="row p-2 px-5">
              {apiData?.map((categoryData) => (
                <div className={`col-6 ${styles.success_box_content} mb-3`} key={categoryData?.sort_category_id}>
                  <div className="d-flex justify-content-between">
                    <div>
                      {t(categoryData?.title)}
                    </div>
                    <div>{categoryData?.counts}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessBoxMolecule;
