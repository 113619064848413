import React from 'react';
import { t } from '../../pages/Translations/TranslationUtils';

interface FileReaderProps {
  fileurl: any;
}

const FileReader: React.FC<FileReaderProps> = ({ fileurl }) => {

  return (
    <div>
      <a href={fileurl} target="_blank" rel="noopener noreferrer" className='btn btn-danger'>{t('Click to view file')}</a>
    </div>
  );
};

export default FileReader;
