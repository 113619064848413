import React from "react";
import Button from "../atoms/Button";

interface ActionButtonGroupProps {
  backTitle?: string;
  saveAsDraftTitle?: string;
  saveAndNextTitle?: string;
  handleBackClick?: () => void;
  handleSaveAsDraftClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleSaveAndNextClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  saveDisabled?: boolean;
  backDisabled?: boolean;
  draftDisabled?: boolean;
  backbuttoncss?: string;
  draftbuttoncss?: string;
  savebuttoncss?: string;
  maindivcss?: string;
}

const ActionButtonGroup: React.FC<ActionButtonGroupProps> = ({
  backTitle = "",
  saveAsDraftTitle = "",
  saveAndNextTitle,
  handleBackClick,
  handleSaveAsDraftClick,
  handleSaveAndNextClick,
  saveDisabled = false,
  draftDisabled = false,
  backDisabled = false,
  backbuttoncss = "back-btn shadow-none text-decoration-none",
  draftbuttoncss= "submit-btn shadow-none me-3",
  savebuttoncss= "submit-btn shadow-none",
  maindivcss = "d-flex justify-content-between mt-2" 
}) => {  
  return (
    <div className={maindivcss}>
      {handleBackClick && backTitle !== "" && (
        <Button
          title={backTitle}
          handleClick={handleBackClick}
          className={backbuttoncss}
          disabled={backDisabled}
        />
      )}
      <div>
        {handleSaveAsDraftClick && saveAsDraftTitle !== "" && (
          <Button
            title={saveAsDraftTitle}
            handleClick={handleSaveAsDraftClick}
            className={draftbuttoncss}
            disabled={draftDisabled}
          />
        )}
        {handleSaveAndNextClick && saveAndNextTitle !== "" && (
        <Button
          title={saveAndNextTitle}
          handleClick={handleSaveAndNextClick}
          className= {savebuttoncss}
          disabled={saveDisabled}
        />
        )}
      </div>
    </div>
  );
};

export default ActionButtonGroup;
