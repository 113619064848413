import React from 'react'

const Accessories = () => {
  return (
    <>
      <svg id="Group_48461" data-name="Group 48461" xmlns="http://www.w3.org/2000/svg" width="188.3" height="160.655" viewBox="0 0 188.3 160.655">
        <ellipse id="Ellipse_229" data-name="Ellipse 229" cx="94.15" cy="8.716" rx="94.15" ry="8.716" transform="translate(0 143.223)" fill="rgba(84,89,96,0.06)" />
        <g id="Group_48443" data-name="Group 48443" transform="translate(10.664 0)">
          <rect id="Rectangle_10697" data-name="Rectangle 10697" width="137.715" height="98.18" transform="translate(13.906 53.759)" fill="#d3985a" />
          <rect id="Rectangle_10698" data-name="Rectangle 10698" width="137.715" height="10.631" transform="translate(13.906 53.759)" fill="#fff" opacity="0.09" />
          <rect id="Rectangle_10699" data-name="Rectangle 10699" width="122.852" height="53.759" transform="translate(21.329)" fill="rgba(211,152,90,0.9)" />
          <path id="Path_56292" data-name="Path 56292" d="M286.14,243.529l12.243-18.659,9.086,18.659L293.58,270.416Z" transform="translate(-141.959 -216.658)" fill="rgba(211,152,90,0.9)" />
          <path id="Path_56293" data-name="Path 56293" d="M221.619,243.529,209.376,224.87l-9.086,18.659L214.2,270.416Z" transform="translate(-200.29 -216.658)" fill="rgba(211,152,90,0.9)" />
          <path id="Path_56294" data-name="Path 56294" d="M358.079,231.49H203.57l8.4,34.429H349.682Z" transform="translate(-198.061 -212.16)" fill="#d3985a" />
          <path id="Accessories" d="M30.655,50.1c-.789-.788-1.5-13.414-1.735-16.258-.158-1.341-1.106-8.367.553-13.181a16.534,16.534,0,0,0,6.866-1.5h0a23.958,23.958,0,0,0,5.683-3.867c1.262-1.341,20.52,19.415,20.441,21.31,0,0-9.551,7.812-9.707,7.892-.479.158-2.671-1.635-4.753-3.43a48.381,48.381,0,0,0-4.095-3.437c-.552.474.316,9.157-.079,10.182a80.818,80.818,0,0,1-12.62,2.385.971.971,0,0,1-.555-.1Zm.633-1.579a38.265,38.265,0,0,1,5.209-.789c.158,0,.394-.079.631-.079h.158c.315-.079.706-.315,1.025-.394a12.553,12.553,0,0,1,3.315-.631,5.416,5.416,0,0,1,.826-.016c.309.013.515.025.515-.064a8.272,8.272,0,0,0-1.737-.079,17.694,17.694,0,0,0-3.157.552,3.107,3.107,0,0,1-.706.315c-1.814.4-4.812.474-6.312,1.183ZM61.12,35.656ZM17.474,39.6a25.556,25.556,0,0,1-.946-3.709c-.4-1.735-5.6-15.312-6.708-15.549-.947-.16-1.895,1.183-2.447,1.814C6.347,23.58-.521,16.238.032,15.687,4.058,11.82,8,7.559,11.951,3.849c.079-.158.158-.4.315-.316A6.038,6.038,0,0,0,20,.613c.394-.789,16.971-.789,18.47-.158.789.315.394,9.234-.706,9.319-.789,0-3.553-.315-3.473.631.075,1.494,2.844,7.965,2.844,7.965A13.993,13.993,0,0,1,29.4,20.347,20.944,20.944,0,0,0,28.136,28.4c.236,3.709.315,4.972.631,7.419.073.22-9.477,3.853-11.07,3.853C17.572,39.667,17.5,39.646,17.474,39.6Zm4.736-3.314c-1.42.474-3.389,1.185-4.815,1.579l-.158.158a42.586,42.586,0,0,0,5.13-1.5A37.356,37.356,0,0,0,28.287,34H28.05a51.752,51.752,0,0,1-5.84,2.289ZM1.609,14.819c1.106,1.106,2.29,2.21,3.473,3.315a29.461,29.461,0,0,0,2.841,2.683l.158.079a38.089,38.089,0,0,0-2.841-2.919,36.92,36.92,0,0,0-3.709-3.236Z" transform="translate(51.53 77.748)" fill="#fff" />
        </g>
      </svg>
    </>
  )
}

export default Accessories

