import React, { useEffect, useState } from 'react'
import Layout from '../../../pages/Shipment/Layout'
import { t } from '../../../pages/Translations/TranslationUtils'
import TableStructure from '../../atoms/TableStructure'
import { APICALL } from '../../../services/ApiServices'
import { fetchList, getQA } from '../../../routes/ApiEndPoints'
import { ConfigConstants, QaConstants } from '../../../pages/Shipment/Constants/ShipmentConstants'
import CommonShipmentServices from '../../../services/CommonShipmentServices'
import { useNavigate } from 'react-router-dom';
import customAlert from '../../atoms/CustomAlert';
import Popup from '../../../utils/popup'
import { ScannerMolecule } from '../../molecules/ScannerMolecule'

const ManageQualityAssuranceOrganism = () => {
    const localdata = CommonShipmentServices.getPostData();
    const [qaData, setQaData] = useState([]);
    const navigate = useNavigate();
    const user_id = Number(localStorage.getItem("user_id"));
    const [popup, setPopup] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const storageMessage = localStorage.getItem("qaalert");
        if (storageMessage) {
            localStorage.removeItem("qaalert");
            customAlert("success", t(storageMessage), 6000);
        }
        fetchData();
    }, [])

    const fetchData = async () => {
        try {
            let configreqdata = {
                "method": "POST",
                "data": {
                    "list": [
                        ConfigConstants.PRODUCTTYPE,
                        ConfigConstants.ACTIVE_WAREHOUSELOCATION
                    ]
                }
            }
            const configres = await APICALL.service(fetchList, 'POST', configreqdata, true);

            let configdata: any = [];
            if (configres.status === 200) {
                configdata = configres.data
                const response = await APICALL.service(getQA, 'POST', localdata);

                if (response.status === 200) {
                    // Filter items with "status" equal to "active"
                    const filteredData = response.data?.filter((item: any) => {
                        return item?.renewal_record?.status === "active";
                    });
                    const mappeddata = filteredData?.map((item: any) => ({
                        ...item,
                        product_type: CommonShipmentServices.getFilteredTitle(
                            configdata['ProductType'],
                            'product_type_id',
                            item?.renewal_record?.renewal_product_info?.product_type
                        ),
                        warehouse_location: CommonShipmentServices.getFilteredTitle(
                            configdata['WarehouseLocation'],
                            'warehouse_location_id',
                            item?.renewal_record?.renewal_product_info?.warehouse_location
                        )
                    }));
                    if (mappeddata.length > 0) {
                        setPopup(true)
                    }
                    setQaData(mappeddata);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleEdit = async (value: any) => {
        navigate(`/quality-assurance/create?id=${value.quality_assurance_id}`)
    }

    const handleScan = async (value: any) => {
        const scan_value: any = Object.values(qaData).find((item: any) => item.serial_number == value.trim())
        if (scan_value) {
            navigate(`/quality-assurance/create?id=${scan_value.quality_assurance_id}`)
        } else {
            setError(true);
        }
    }

    return (
        <Layout pagename={t('Quality assurance')} logo={true} timer={false}>
            <div className="flex-1 overflow-auto">
                <TableStructure
                    isAction
                    data={qaData}
                    headers={QaConstants.TABLE_HEADERS}
                    values={QaConstants.TABLE_VALUES}
                    handleEdit={handleEdit}
                />
            </div>
            {popup &&
                <Popup
                    modalSize="xl"
                    title={t("Scan renewal record")}
                    cancel={() => setPopup(false)}
                    body={
                        <ScannerMolecule
                            handleClick={handleScan}
                            error={error}
                        />
                    }
                />}
        </Layout>
    )
}

export default ManageQualityAssuranceOrganism