import { useState, useEffect, useRef } from "react";
import Logo from "../static/icons/Raven-logo.svg";
import "./header.css";
import Humburgersidebar from "../static/icons/Humburgersidebar";
import BPsidebar from "../static/icons/BPsidebar";
import QRscansidebar from "../static/icons/QRscansidebar";
import Reportsidebar from "../static/icons/Reportsidebar";
import SettingsSidebar from "../static/icons/SettingsSidebar";
import { t } from "../pages/Translations/TranslationUtils";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import ScanningOrganism from "../components/organisms/ScanningOrganism";
import Popup from "../utils/popup";
import AccessControl from "../services/AccessControl";
import customAlert from "../components/atoms/CustomAlert";
import Close from "../static/icons/Close";

const Navigation: React.FC = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    showScanPopup: false
  });

  useEffect(() => {
    const storageMessage = localStorage.getItem("profileSuccessAlertMessage");
    if (storageMessage) {
      localStorage.removeItem("profileSuccessAlertMessage");
      customAlert(
        "success",
        t(storageMessage),
        6000
      )
    }
  }, []);

  const [isSidebarExpanded, setIsSidebarExpanded] = useState<boolean>(false);
  const toggleSidebar = () => {
    setIsSidebarExpanded(prev => !prev);
  };


  const sidebarRef = useRef<HTMLDivElement | null>(null);

  const closeSidebar = () => {
    setIsSidebarExpanded(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
        closeSidebar();
      }
    };

    if (isSidebarExpanded) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSidebarExpanded]);

  return (
    <div className="container-fluid px-0 sticky-top">
      <div className="row">
        <div ref={sidebarRef} className={`col-md-1 px-0 sidenav ${isSidebarExpanded ? 'expanded' : ''}`}>
          <div className="p-xxl-3 p-xl-2">
            <div className="px-1 text-center">
              <div onClick={() => navigate("/")} className="cursor-pointer">
                <img
                  src={Logo}
                  alt={t("Raven logo")}
                  className="img-fluid py-3 mt-xxl-2 mt-xl-2 mt-lg-2 raven-logo"
                ></img>
              </div>
            </div>

            <div className="d-flex flex-column align-items-center mt-3">
              <div className="m-auto">

                <span title={t("Dashboard")} className="mt-4 pt-3 cursor-pointer sidebar_item d-flex width_maxcontent" onClick={toggleSidebar}>
                  {isSidebarExpanded ? <Close /> : <Humburgersidebar />}
                  {isSidebarExpanded && <span className="sidebar-text">{t("Close")}</span>}
                </span>

                <AccessControl
                  renderNoAccess={false}
                  requiredPermissions={[
                    {
                      permission: "brand partner",
                      create: true,
                    },
                  ]}
                >
                  <div onClick={() => { navigate("/brand-partner/create"); closeSidebar(); }} className={`mt-4 pt-3 cursor-pointer sidebar_item d-flex width_maxcontent ${window.location.pathname === "/brand-partner/create"
                    ? "sidetabActive text-red"
                    : "sidetab"
                    }`}
                    title={t("Create brand partner")}>
                    <BPsidebar />
                    {isSidebarExpanded && <span className="sidebar-text">{t("Brand partner")}</span>}
                  </div>

                </AccessControl>
                <div className="mt-4 pt-3 cursor-pointer d-flex sidebar_item width_maxcontent" title={t("QR scan")} onClick={() => setState((prev) => ({ ...prev, showScanPopup: true }))}>
                  <QRscansidebar />
                  {isSidebarExpanded && <span className="sidebar-text">{t("QR scan")}</span>}
                </div>
                <div onClick={() => { navigate('/reports'); closeSidebar(); }} className="mt-4 pt-3 cursor-pointer d-flex sidebar_item width_maxcontent" title={t("Reports")}>
                  <Reportsidebar />
                  {isSidebarExpanded && <span className="sidebar-text">{t("Reports")}</span>}
                </div>
                <div
                  onClick={() => { navigate('/master-data/manage'); closeSidebar(); }}
                  className={'mt-4 pt-3 cursor-pointer d-flex sidebar_item width_maxcontent'}
                  title={t("Master data")}
                >
                  <SettingsSidebar />
                  {isSidebarExpanded && <span className="sidebar-text">{t("Dashboard")}</span>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Header />
        {state.showScanPopup && (
          <div className="quickaccess">
            <Popup
              title={t("Scan renewal record")}
              body={
                <ScanningOrganism
                  setShowScanPopup={() => setState((prev) => ({ ...prev, showScanPopup: false }))}
                />
              }
              modalSize="xl"
              cancel={() => setState((prev) => ({ ...prev, showScanPopup: false }))}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Navigation;