import React from "react";
import Close from "../../static/icons/Close";
import styles from "../../pages/MediaLibrary/MediaLibrary.module.css";
import ImageField from "../atoms/ImageField";
import AccessControl from "../../services/AccessControl";
import { t } from "../../pages/Translations/TranslationUtils";

interface Props {
  Key?: any;
  medialibrary_id?: any;
  url?: any;
  handleImagePopup?: (
    url: any,
    title: any,
    medialibrary_id: any
  ) => void;
  title?: any;
  setShowDeleteFolderPopup?: (
    id: any,
    name?: any,
    state?: any,
    key?: any
  ) => void;
  modalimages?: any;
}

const MediaLibraryFileMolecule: React.FC<Props> = ({
  Key,
  medialibrary_id,
  url,
  handleImagePopup,
  title,
  setShowDeleteFolderPopup,
  modalimages,
}) => {
  return (
    <div className={`pb-5 px-0 ${styles.folder_img}`}>
      <div
        className={`shadow position-relative rounded w-100 h-100 d-flex align-items-center py-3 px-1  ${styles.folder_img_inner
          } 
         ${modalimages?.find(
            (image: any) => image.media_id == medialibrary_id
          )
            ? "border border-danger border-2"
            : ""
          }`}
      >
        <div className="w-100 h-100 text-center text-truncate px-1">
          <AccessControl
            requiredPermissions={[
              {
                permission: "media library",
                delete: true,
              },
            ]}
            renderNoAccess={false}
          >
            <span
              onClick={() =>
                setShowDeleteFolderPopup &&
                setShowDeleteFolderPopup({
                  id: medialibrary_id,
                  name: title,
                  state: true,
                  key: Key,
                })
              }
              className={`${styles.img_close} position-absolute`}
            >
              <Close />
            </span>
          </AccessControl>
          <ImageField
            value={url}
            altText={t("image")}
            id={Key}
            className={`w-100 cursor-pointer`}
            handleClick={() =>
              handleImagePopup &&
              handleImagePopup(url, title, medialibrary_id)
            }
          />
          <div
            className={`text-truncate px-1 ${styles.medialibraryfoldername}`}
          >
            {title}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaLibraryFileMolecule;
