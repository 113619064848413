import { UTILITYFN } from "../utility/Utility";
import { useEffect, useReducer, useRef, useState } from "react";
import styles from "../../brandpartner.module.css";
import LabelField from "../../../../components/atoms/LabelField";
import { GenerateRefreshToken } from "../../../../services/RefreshTokenService";
import InputTextfield from "../../../../components/atoms/InputTextField";
import { AddWidget } from "../../../../static/icons/AddWidget";
import Close from "../../../../static/icons/Close";
import EncryptDecryptService from "../../../../services/EncryptDecryptService";
import { APICALL } from "../../../../services/ApiServices";
import {
  fetchConfigData,
} from "../../../../routes/ApiEndPoints";
import ActionButtonGroup from "../../../../components/molecules/ActionButtonGroup";
import { t } from "../../../Translations/TranslationUtils";
import { useNavigate } from "react-router-dom";
import SelectedSizesAndDimensions from "../../../../components/molecules/SelectedSizesAndDimensions";
import customAlert from "../../../../components/atoms/CustomAlert";
import FormValidation from "../../../../services/FormValidation";
import AddConfigToBrandPartner from "../../../../components/organisms/AddConfigToBrandPartner";
import CommonServices from "../../../../services/CommonServices";

interface Props {
  draftSaved: boolean;
  saveAsDraft: string;
  saveAndNext: string;
  backbutton: string;
  toggleAlertMessage: () => void;
  setAlertMessage: (message: string) => void;
  formPageState: any;
  dispatchFormPageState: any;
  setPageDirection: (page: number) => void;
}

interface FetchedMerchRack {
  [key: number]: any;
}

const merchRackState = {
  merchracks: [],
};

function SeventhTab(props: Props) {
  const {
    draftSaved,
    saveAndNext,
    saveAsDraft,
    backbutton,
    formPageState,
    dispatchFormPageState,
  } = props;
  const [state, dispatch] = useReducer(formReducer, merchRackState);
  const [fetchedMerchRack, setFetchedMerchRack] = useState<FetchedMerchRack>(
    []
  );
  const [selectedMerchRack, setSelectedMerchRack] = useState(null);
  const [addnew, setAddNew] = useState(false);
  const addMerchRack = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  async function validateFormData(
    event: React.FormEvent<HTMLFormElement>,
    draft?: boolean
  ) {
    event.preventDefault();
    let updatedFormPageState = { ...formPageState };

    const { requiredStatus, requiredMessage } = validateRequiredField();

    if (!draft && requiredStatus) {
      customAlert("error", t(requiredMessage), 6000);
      updatedFormPageState.pageSeven.validationStatus = false;
      dispatchFormPageState({ ...formPageState, ...updatedFormPageState });
    } else {
      let token = await GenerateRefreshToken();
      const bp_id = sessionStorage.getItem("brand_partner_id");
      const response = await fetch(
        process.env.REACT_APP_MASTER_DATA_URL +
        "api/master/create-brand-partner",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            bp_merch_rack: state.merchracks,
            brand_partner_id: bp_id,
          }),
        }
      );
      if (response?.status === 200) {
        if (draft) {
          navigate("/brand-partner/manage");
        }
        updatedFormPageState.pageSeven.validationStatus = true;
        dispatchFormPageState({ ...formPageState, ...updatedFormPageState });

        sessionStorage.setItem("merchRackState", JSON.stringify(state));
        UTILITYFN.setCurrentPageData(props, 8);
      }
    }
  }

  function validateRequiredField() {
    let emptyResponse;
    if (state.merchracks.length === 0) {
      emptyResponse = {
        requiredStatus: true,
        requiredMessage: t("No merch rack selected"),
      };
    } else {
      emptyResponse = state.merchracks.some((merchrack: any) => {
        return FormValidation.nameValidation(merchrack?.label);
      });
      emptyResponse = emptyResponse
        ? {
          requiredStatus: true,
          requiredMessage:
            t("Some of the selected merch rack label is empty or more than 255 characters"),
        }
        : { requiredStatus: false, requiredMessage: "" };
    }

    return emptyResponse;
  }

  function formReducer(prevState: any, newsState: any) {
    return { ...prevState, ...newsState };
  }

  window.addEventListener("beforeunload", () => {
    sessionStorage.clear();
  });

  useEffect(() => {
    if (sessionStorage.getItem("merchRackState")) {
      dispatch({
        ...JSON.parse(sessionStorage.getItem("merchRackState") || ""),
      });
    } else {
      sessionStorage.setItem("merchRackState", JSON.stringify(state));
    }

    fetchMerchRack();
  }, []);

  useEffect(() => {
    if (addnew) {
      window.scrollTo({
        top: addMerchRack.current?.offsetTop,
        behavior: "smooth",
      });
    }
  }, [addnew]);

  const fetchMerchRack = async () => {
    const postData = {
      // Encrypting the request data
      method: "POST",
      data: EncryptDecryptService.encryptData(
        JSON.stringify({
          type: "model",
          name: "MerchRack",
          status: true,
        })
      ),
    };
    const response = await APICALL.service(fetchConfigData, "POST", postData);
    let decrypteddata = JSON.parse(EncryptDecryptService.decryptData(response));
    if (decrypteddata?.status !== 200) {
      throw new Error("Request failed");
    }
    let racks = JSON.parse(sessionStorage.getItem("merchRackState") || "");
    let data = decrypteddata?.data.map((item: any) => {
      let label = racks?.merchracks?.filter((rack: any) => item.merch_rack_id == rack.merch_rack_id)?.[0];      
      return {
        ...item,
        label: label?.label ?? item?.title
      }
    });
    setFetchedMerchRack(data);
  };

  const handleLabelChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    merch_rack_id: number
  ) => {
    const updatedFetchedMerchRacks = Object.values(fetchedMerchRack).map(
      (merchrack: any) => {
        if (merchrack.merch_rack_id === merch_rack_id) {
          return { ...merchrack, label: e.target.value };
        }
        return merchrack;
      }
    );

    const updatedStateMerchRacks = state.merchracks.map((merchrack: any) => {
      if (merchrack.merch_rack_id === merch_rack_id) {
        return { ...merchrack, label: e.target.value };
      }
      return merchrack;
    });

    setFetchedMerchRack(updatedFetchedMerchRacks);
    dispatch({ ...state, merchracks: updatedStateMerchRacks });
  };

  const handleClick = (merchrack: any) => {
    const isMerchRackAlreadyPresent = state.merchracks?.some(
      (s: any) => s.merch_rack_id === merchrack.merch_rack_id
    );
    setSelectedMerchRack(merchrack.merch_rack_id);
    if (!isMerchRackAlreadyPresent) {
      dispatch({ ...state, merchracks: [...state.merchracks, { ...merchrack, label: merchrack.title }] });
    }
  };

  const addNewMerchRack = (merchrack: any) => {    
    setAddNew(false);
    merchrack = { ...merchrack, label: merchrack?.title };
    fetchMerchRack();
    dispatch({ ...state, merchracks: [...state.merchracks, merchrack ] });
  };

  const removeDimension = (merch_rack_id: number) => {
    const updatedMerchRacks = state.merchracks?.filter(
      (merch_rack: any) => merch_rack.merch_rack_id !== merch_rack_id
    );
    dispatch({ ...state, merchracks: updatedMerchRacks });
  };

  return (
    <>
      <form
        className="contract-meter-form flex-1 d-flex flex-column overflow-auto"
        onSubmit={(e) => validateFormData(e)}
      >
        <div className="flex-1 overflow-auto">
          <SelectedSizesAndDimensions
            title={t("Selected merch racks")}
            selectedItems={state?.merchracks}
            onRemoveItem={removeDimension}
            onLabelChange={handleLabelChange}
          />

          <div className="border rounded p-4 mb-xxl-4 mb-3">
            <LabelField
              title={t("Select merch rack")}
              mandatory={true}
              className={` ${styles.label} mb-3`}
            />
            <div className="d-flex flex-wrap">
              {Object.entries(fetchedMerchRack).map(([key, value]) => (
                <div
                  key={key}
                  className={`mb-3 position-relative p-4 py-3 rounded ${selectedMerchRack === value.merch_rack_id
                    ? styles.activebrandpartnerselectBorder
                    : ""
                    } ${styles.bpsize_btn}`}
                >
                  <div
                    className="position-relative h-100 d-flex flex-column ">
                    <div
                      title={value.title}
                      className={`${styles.color_btn} d-flex align-items-center justify-content-center rounded text-break h-100 ${state.merchracks?.some(
                        (s: any) => s.merch_rack_id === value.merch_rack_id
                      )
                        ? styles.activebrandpartnerselect
                        : ""
                        }`}
                      onClick={() => handleClick(value)}
                    >
                      {value.title ?? ""}
                    </div>
                    {state.merchracks?.some(
                      (d: any) => d.merch_rack_id === value.merch_rack_id
                    ) && (
                        <div
                          className={`${styles.selected_close_btn} position-absolute cursor-pointer top-0`}
                          onClick={() => removeDimension(value.merch_rack_id)}
                        >
                          <Close />
                        </div>
                      )}
                    {selectedMerchRack === value.merch_rack_id && (
                      <div className="position-relative">
                        <InputTextfield
                          className="border rounded mt-3 text-gray text-truncate"
                          value={value.label ?? value.title ?? ""}
                          handleChange={(e) =>
                            handleLabelChange(e, value.merch_rack_id)
                          }
                          handleClick={(event) => event.stopPropagation()}
                          customStyle={{ width: '100%', textAlign: 'center' }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
              <div
                className={`rounded me-5 mb-3 p-4 py-3`}
                onClick={() => setAddNew(true)}
              >
                <div className={`${styles.add_btn} cursor-pointer`}
                  onClick={() => setAddNew(true)}>
                  <AddWidget />
                </div>
              </div>
            </div>
          </div>
          {addnew && (
            <div className="border rounded p-4 mb-2" ref={addMerchRack}>
              <span
                className="float-end cursor-pointer"
                onClick={() => setAddNew(false)}
              >
                <Close />
              </span>
              <LabelField
                title={t("Add merch rack")}
                mandatory={false}
                className={`${styles.label}`}
              />
              <AddConfigToBrandPartner
                model={'MerchRack'}
                addNew={addNewMerchRack}
              />
            </div>
          )}
        </div>
        <ActionButtonGroup
          backTitle={t(backbutton)}
          saveAsDraftTitle={draftSaved ? "" : t(saveAsDraft)}
          saveAndNextTitle={t(saveAndNext)}
          handleBackClick={() => UTILITYFN.setCurrentPageData(props, 6)}
          handleSaveAsDraftClick={(e: any) => validateFormData(e, true)}
          handleSaveAndNextClick={(e: any) => validateFormData(e)}
        />
      </form>
    </>
  );
}

export default SeventhTab;
