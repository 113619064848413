import React from "react";
import styles from "../../../pages/Shipment/shimpment.module.css"
import IconData from "../../../static/IconData";
import ProductImageCard from "../../atoms/ProductImageCard";
import CommonShipmentServices from "../../../services/CommonShipmentServices";
import { APICALL } from "../../../services/ApiServices";
import { fetchConfigData } from "../../../routes/ApiEndPoints";
import CommonServices from "../../../services/CommonServices";

type MerchRackItemProps = {
  item:any;
  state: any;
  showPopup: (serialnumber?: any) => void;
  handleChange: (serialnumber: any) => void;
  index: number;
  configdata: any;
};

const MerchRackItem: React.FC<MerchRackItemProps> = ({
  item,
  state,
  handleChange,
  showPopup,
  index,
  configdata
}) => {
  let imageurl = '';
  if (item.image_url != '' && item.image_url != null) {
    imageurl = item.image_url
  } else {
    imageurl = item?.renewal_product_info?.product_type ? CommonShipmentServices.getFilteredTitle(configdata.productType, 'product_type_id', item?.renewal_product_info?.product_type, 'front_image') : '';
  }

  const isSelected = state.selectedDataEntryIds.includes(item?.serial_number);
  imageurl = item?.renewal_image ? CommonShipmentServices.getFilteredTitle(configdata?.imageslist, 'medialibrary_id', item?.renewal_image[0]?.image_id, 'url') : '';  
  const handleClick = () => {
    if (!item?.is_published) {
      handleChange(item?.serial_number);
    }
  };
  let tierlabel = CommonShipmentServices.getFilteredTitle(configdata?.tiers, 'product_tier_id', item?.tier)
  const handleShowPopupClick = (e: React.MouseEvent) => {
    // Prevent the click event from propagating to the parent handleClick function
    e.stopPropagation();
    showPopup(item?.serial_number);
  };

  return (
    <div
      key={index}
      className={`p-3 text-center border text-gray rounded shadow me-3 h-100 cursor-pointer position-relative ${!item.is_published ? styles.prepublish_card : styles.prepublish_card_success} ${isSelected ? styles.prepublish_card_selected : ""
        } ${item.is_published ? "disabled" : ""}`}
      onClick={!item.is_published ? handleClick : undefined}
    >
      {item.is_published ? (
        <span className={`position-absolute top-0 end-0 p-3 ${styles.succesicon}`}>{IconData.success}</span>
      ) : (
        <span className={`position-absolute top-0 end-0 m-3 ${styles.forwardicon}`} onClick={handleShowPopupClick} >{IconData.forward}</span>
      )}
      <div className="text-truncate pe-4"> <strong title={item.serial_number}> {item.serial_number} </strong></div>
      <ProductImageCard
        imgurl={imageurl}
        imgClass={styles.product_image}
        title={''}
        produnctimgmainclass=" h-0"
      />
      <div className="d-flex justify-content-between gap-2 flex-xxl-row flex-column">
        <div className="text-break col" title={tierlabel} > {tierlabel?.[0]} </div>
        {/* <div className="text-break col" title={item.product_type_label} > {item.product_type_label} </div>
        <div className="text-break col" title={item.genderlabel} > {item.genderlabel} </div> */}
      </div>

    </div>
  );
};

export default MerchRackItem;
