import { t } from "../Translations/TranslationUtils"

const Changepassword = () => {
    return (
        <>
            <div className='row'>
                <div className='col-md-11 ms-auto px-4 mb-4'>
                    {/* Page title */}
                    <div className='py-xxl-4 py-xl-3 py-lg-3 page-title'>
                        {t('Change password')}
                    </div>
                    {/* Page title */}
                    {/* form starts*/}
                    <form>
                        <div className='row'>
                            <div className='col-7 mx-auto'>
                                <div className='create-form p-5 mb-4'>
                                    <div className='row'>
                                        <div className='col-md-12 m-auto'>
                                            <div className='row'>
                                                {/* current password start*/}
                                                <div className='col-12 mb-3'>
                                                    <div>
                                                        <label htmlFor="first_name">{t('current password')} <span className='asterick'>*</span></label>
                                                        <input
                                                            type="text"
                                                            className='form-control shadow-none'
                                                        />
                                                    </div>
                                                </div>
                                                {/*current password end */}

                                                {/* new password starts */}
                                                <div className='col-12 mb-3'>
                                                    <div>
                                                        <label htmlFor="last_name">{t('new password')} <span className='asterick'>*</span></label>
                                                        <input
                                                            type="text"
                                                            className='form-control shadow-none'
                                                        />
                                                    </div>
                                                </div>
                                                {/* new password ends */}

                                                {/* confirm password starts*/}
                                                <div className='col-12 mb-3'>
                                                    <div>
                                                        <label htmlFor="username">{t('confirm password')} <span className='asterick'> *</span></label>
                                                        <input
                                                            type="text"
                                                            className='form-control shadow-none'
                                                        />
                                                    </div>
                                                </div>
                                                {/* confirm password ends*/}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* save button starts*/}
                                <div className='row'>
                                    <div className='col-12 float-end'>
                                        <button type="submit" className='submit-btn shadow-none float-end'>
                                            {t('Save')}
                                        </button>
                                    </div>
                                </div>
                                {/* save button ends*/}
                            </div>
                        </div>
                    </form>
                    {/* form ends*/}
                </div>
            </div>
        </>
    )
}

export default Changepassword
