import React, { useState, useEffect, useRef } from 'react';

interface Props {
    running: boolean | undefined;
    className: string;
    time: number;
}
const Timer: React.FC<Props> = ({ running, className, time }) => {
    const timerRef = useRef<number | undefined>(undefined);
    const [timeElapsed, setTimeElapsed] = useState(0);

    useEffect(() => {
        setTimeElapsed(time);
    }, [time]);

    useEffect(() => {
        if (running) {
            const startTime = Date.now() - timeElapsed;
            timerRef.current = window.setInterval(() => {
                const currentTime = Date.now();
                setTimeElapsed(currentTime - startTime);
            }, 10);
        }

        return () => {
            if (timerRef.current !== undefined) {
                clearInterval(timerRef.current);
                timerRef.current = undefined;
            }
        };
    }, [running, timeElapsed]);

    const formatTime = (time: number) => {
        const hours = Math.floor(time / 3600000);
        const minutes = Math.floor((time % 3600000) / 60000);
        const seconds = Math.floor((time % 60000) / 1000);
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <>
            <span className={className}>{formatTime(timeElapsed)}</span>
        </>
    );
}

export default Timer;