import { createContext, useContext } from 'react';
import { UserContextType } from '../@types/user';
// import { RefreshTokenContextType } from '../@types/refresh_token';

export const UserContext = createContext<UserContextType>({
  user: null,
  setUser: () => {}
});

export const useUserContext = () => useContext(UserContext);

// export const RefreshTokenContext = createContext<RefreshTokenContextType>({
//   token: null,
//   setRefreshToken: () => {}
// });

// export const useRefreshTokenContext = () => useContext(RefreshTokenContext);