import React, { useState, ChangeEvent, FocusEvent, MouseEvent } from "react";
import Close from "../../static/icons/Close";
import styles from "../../pages/MediaLibrary/MediaLibrary.module.css";
import { FolderIcon } from "../../static/icons/FolderIcon";
import InputTextfield from "../atoms/InputTextField";
import AccessControl from "../../services/AccessControl";

interface Props {
  parent_id?: any;
  media_library_id?: any;
  title?: any;
  setShowDeleteFolderPopup?: (id: any, name?: any, state?: any) => void;
  brand_partner_id?: any;
  handleFolderClick?: (media_library_id: any, title?: any, item?: any) => void;
  folderId?: any;
  doubleClicked?: any;
  renameId?: any;
  inputValue?: any;
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: () => void;
  handleDoubleClick?: (media_library_id: any, title?: any) => void;
  modal?: boolean;
  folderRenameError?: string;
  autoFocus?: boolean;
  homePage?: boolean;
  index?: any;
  item?: any;
}

const MediaLibraryFolderMolecule: React.FC<Props> = ({
  parent_id,
  media_library_id,
  title,
  setShowDeleteFolderPopup,
  brand_partner_id,
  handleFolderClick,
  folderId,
  doubleClicked,
  renameId,
  inputValue,
  handleInputChange,
  handleBlur,
  handleDoubleClick,
  modal = false,
  folderRenameError,
  autoFocus,
  homePage,
  index,
  item,
}) => {
  return (
    <div
      key={`folder-${index}`}
      className={`text-center ${
        modal ? styles?.media_folder_box_modal : styles?.media_folder_box
      }`}
    >
      <div
        key={`inner-${index}`}
        className={`${styles.media_folder_box_modal_inner} py-2 rounded d-flex justify-content-center align-items-center flex-column position-relative shadow w-100 h-100`}
      >
        <div
          key={`innerr-${index}`}
          className={`position-absolute ${styles?.media_folder}`}
        >
          {parent_id != 0 && (
            <AccessControl
              requiredPermissions={[
                {
                  permission: "media library",
                  delete: true,
                },
              ]}
              renderNoAccess={false}
            >
              <div
                onClick={() =>
                  setShowDeleteFolderPopup &&
                  setShowDeleteFolderPopup({
                    id: media_library_id,
                    name: title,
                    state: true,
                  })
                }
                className=""
              >
                <Close />
              </div>
            </AccessControl>
          )}
        </div>
        <div
          key={`click-${index}`}
          id={brand_partner_id ?? media_library_id}
          onClick={() => handleFolderClick && handleFolderClick(item)}
          className={`${styles.mediafoldericon} cursor-pointer`}
        >
          <FolderIcon />
        </div>

        <div
          key={`input-${index}`}
          className={`text-break mt-2 w-100 ${styles.edit_folder} d-flex justify-content-center flex-column px-1`}
        >
          {folderId != 0 && doubleClicked && renameId === media_library_id ? (
            <InputTextfield
              type="text"
              className="w-100"
              value={inputValue}
              id={media_library_id}
              handleChange={handleInputChange}
              handleBlur={() => handleBlur && handleBlur()}
              error={folderRenameError}
              autoFocus={autoFocus}
            />
          ) : (
            <div
              key={`text-${index}`}
              className={`text-break px-1 ${styles.medialibraryfoldername}`}
              onDoubleClick={() =>
                handleDoubleClick && handleDoubleClick(media_library_id, title)
              }
              id={media_library_id}
              title={title}
            >
              {title}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MediaLibraryFolderMolecule;
