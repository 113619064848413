import React, { ReactNode, useEffect, useState } from "react";
import { useUserContext } from "../routes/Contextlib";
import { useNavigate } from "react-router-dom";
import AccessDenied from "../pages/AccessDenied";
import CommonServices from "./CommonServices";

type Props = {
  requiredPermissions?: any;
  children?: ReactNode;
  renderNoAccess?: boolean;
  strict?: boolean;
  actions?: boolean;
  override?: boolean;
};

const AccessControl: React.FC<Props> = ({
  requiredPermissions = [],
  children,
  renderNoAccess = false,
  strict = true,
  actions,
  override = false,
}) => {
  const { user, setUser } = useUserContext();
  const userPermissions = user?.permissions;
  const [permitted, setPermitted] = useState<boolean>(false);
  const [noAccess, setNoAccess] = useState<boolean>(false);

  useEffect(() => {
    const checkUserPermissions = async () => {
      const isPermitted = await CommonServices.checkPermissions(
        userPermissions,
        requiredPermissions,
        strict,
        actions,
        override
      );
      if (isPermitted) {
        setPermitted(true);
        setNoAccess(false);
      } else {
        setPermitted(false);
        setNoAccess(true);
      }
    };

    userPermissions != undefined && checkUserPermissions();
  }, [userPermissions, requiredPermissions]);

  if (noAccess && renderNoAccess) {
    return <AccessDenied />;
  }
  if (permitted) {
    return <>{children}</>;
  }
};

export default AccessControl;
