import React from 'react'

const Createuser: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="41.754" height="41.284" viewBox="0 0 41.754 41.284">
  <g id="Create_user_G_" data-name="Create user (G)" transform="translate(-12587.318 -8356.016)">
    <path id="Subtraction_741" data-name="Subtraction 741" d="M19.031,38.074A19.017,19.017,0,0,1,0,19.034a19.086,19.086,0,0,1,2.1-8.7A19.062,19.062,0,0,1,26.443,1.5a18.836,18.836,0,0,1,6.051,4.082,19.049,19.049,0,0,1,4.082,6.052,18.8,18.8,0,0,1,1.5,7.406,19.054,19.054,0,0,1-.887,5.768A9.34,9.34,0,0,0,25.439,36.969,19,19,0,0,1,19.031,38.074ZM18.9,20.093a11.066,11.066,0,0,0-6.219,1.874,2,2,0,0,0-.873,1.669v3.51H25.994v-3.51a1.993,1.993,0,0,0-.873-1.669A11.064,11.064,0,0,0,18.9,20.093Zm0-9.151a4.054,4.054,0,1,0,2.861,1.187A4,4,0,0,0,18.9,10.942Z" transform="translate(12587.32 8356.016)" fill="#545960"/>
    <g id="Group_48682" data-name="Group 48682" transform="translate(12613.901 8382.129)">
      <path id="Create_user" data-name="Create user" d="M35.184,38.729a7.585,7.585,0,1,1,7.586,7.586,7.585,7.585,0,0,1-7.586-7.586Zm3.789.76h3.034v3.034h1.517V39.488h3.041V37.971H43.528V34.936H42.011V37.97H38.977Z" transform="translate(-35.184 -31.144)" fill="#545960"/>
    </g>
  </g>
</svg>

  )
}

export default Createuser