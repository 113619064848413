import React, { useEffect } from "react";
import styles from "../../pages/MediaLibrary/MediaLibrary.module.css";
import InputTextfield from "../atoms/InputTextField";
import Button from "../atoms/Button";
import { t } from "../../pages/Translations/TranslationUtils";
import { Add } from "../../static/icons/shipment/Add";
import { Upload } from "../../static/icons/Upload";
import { FaSort } from "react-icons/fa";
import AccessControl from "../../services/AccessControl";
import LabelField from "../atoms/LabelField";

interface Props {
  searchText?: any;
  setSearchText?: any;
  handleSortClick: () => void;
  handleSearch: () => void;
  sortOrder: any;
  folderId: any;
  setCreateFolderPopup: (status: boolean) => void;
  handleFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  imageInputRef: any;
  setSearchError: any;
  searchError: any;
  disable: boolean;
}

const MediaLibraryActionsMolecule: React.FC<Props> = ({
  searchText,
  setSearchText,
  handleSortClick,
  sortOrder,
  folderId,
  handleFileUpload,
  setCreateFolderPopup,
  imageInputRef,
  disable,
  setSearchError,
  searchError,
  handleSearch,
}) => {
  return (
    <div className="d-flex flex-wrap gap-1 align-items-center justify-content-end">
      <InputTextfield
        type="text"
        placeholder={t('Search by tag or title')}
        className={`rounded d-flex align-items-center ms-3 w-100 px-2 ${styles.mediasearch}`}
        value={searchText}
        handleChange={(e) => {setSearchText(e.target.value), setSearchError('')}}
        // handleClick={setSearchError("")}
        error={searchError}
      />
      <Button
        handleClick={handleSearch}
        className={`rounded d-flex align-items-center submit-btn ms-3 ${styles.mediacreatbtn}`}
      >
        {t("Search")}
      </Button>
      <Button
        handleClick={handleSortClick}
        className={`rounded d-flex align-items-center submit-btn ms-3 ${styles.mediacreatbtn}`}
      >
        {t("Sort")}
        {sortOrder === "asc" ? <FaSort /> : <FaSort />}
      </Button>

      {folderId != 0 && (
        <AccessControl
          requiredPermissions={[
            {
              permission: "media library",
              create: true,
            },
          ]}
          renderNoAccess={false}
        >
          <Button
            handleClick={() => setCreateFolderPopup(true)}
            className={`rounded d-flex align-items-center submit-btn ms-3 ${styles.mediacreatbtn}`}
          >
            {" "}
            <Add />
            {t("Create")}{" "}
          </Button>
        </AccessControl>
      )}
      {folderId != 0 && (
        <AccessControl
          requiredPermissions={[
            {
              permission: "media library",
              create: true,
            },
          ]}
          renderNoAccess={false}
        >
          <label
            className={`d-flex align-items-center justify-content-center back-btn  cursor-pointer ms-3 ${styles.media_upload}`}
            htmlFor="customFile"
          >
            <Upload />
            <span className="ps-3">{t("Import")}</span>
            <input
              disabled={disable}
              type="file"
              className="visually-hidden "
              accept=".png, .jpg, .jpeg"
              multiple
              onClick={(e: any) => e.target.value = null}
              onChange={handleFileUpload}
              ref={imageInputRef}
              id="customFile"
            />
          </label>
        </AccessControl>
      )}
    </div>
  );
};

export default MediaLibraryActionsMolecule;
