import React, { useState, useEffect } from "react";
import { FaSort } from "react-icons/fa";
import customAlret from "../../components/atoms/CustomAlert";
import Popup from "../../utils/popup";
import { t } from "../Translations/TranslationUtils";
import Archive from "../../static/icons/Archive";
import { useNavigate } from "react-router-dom";
import Unarchive from "../../static/icons/Unarchive";
import Edit from "../../static/icons/Edit";
import Pagination from "../../services/Paginations";
import AccessControl from "../../services/AccessControl";
import { CommonConstants } from "../Shipment/Constants/ShipmentConstants";
import Button from "../../components/atoms/Button";
import { APICALL } from "../../services/ApiServices";
import { createRole, fetchRolesUrl } from "../../routes/ApiEndPoints";
import { useUserContext } from "../../routes/Contextlib";
import WindowHeightComponent from "../../commonComponents/WindowHeightComponent";

const ManageRoles: React.FC = () => {
  type StatusObj = {
    edit: number;
    status: boolean;
  };
  const initialStatus: StatusObj = {
    edit: 0,
    status: false,
  };

  const [roleArray, setRoleArray] = useState<any[]>([]);
  const { user, setUser } = useUserContext();
  const [requiredPermissions, setRequiredPermissions] = useState<any>([]);
  const [searchText, setSearchText] = useState("");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState<"active" | "inactive">("active");
  const itemsPerPage = 10;
  const [refreshRoles, setRefreshRoles] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [status, setStatus] = useState<StatusObj>(initialStatus);
  const navigate = useNavigate();
  const [isSorted, setIsSorted] = useState(false);
  // Filter the users based on search criteria and status
  const filteredRoleArray = roleArray.filter((roles: any) => {
    const title = roles.title ? roles.title.toLowerCase().trim() : "";
    const searchTextLower = searchText.toLowerCase().trim();

    return (
      ((activeTab === "active" && roles.status === true) ||
        (activeTab === "inactive" && roles.status === false)) &&
      title.includes(searchTextLower)
    );
  });

  const sortedRoles = isSorted
    ? filteredRoleArray.slice().sort((a, b) => {
      const usernameA = a.title.toLowerCase().trim();
      const usernameB = b.title.toLowerCase().trim();
      if (sortOrder === "asc") {
        if (usernameA < usernameB) return -1;
        if (usernameA > usernameB) return 1;
        return 0;
      } else {
        if (usernameA > usernameB) return -1;
        if (usernameA < usernameB) return 1;
        return 0;
      }
    })
    : filteredRoleArray;

  // Calculate the indexes of the users to be displayed on the current page
  const totalRoles = sortedRoles.length;
  const totalPages = Math.ceil(totalRoles / itemsPerPage);
  const validPageNumber = currentPage > totalPages ? totalPages : currentPage;
  const indexOfLastRole = validPageNumber * itemsPerPage;
  const indexOfFirstRole = indexOfLastRole - itemsPerPage;
  const currentUsers = sortedRoles.slice(indexOfFirstRole, indexOfLastRole);

  const handleSortClick = () => {
    !isSorted && setIsSorted(true);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handlePaginationClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchData();
  }, [refreshRoles]);

  //To display alert message
  useEffect(() => {
    const storageMessage = localStorage.getItem("successAlertMessage");
    if (storageMessage) {
      localStorage.removeItem("successAlertMessage");
      customAlret("success", storageMessage, 6000);
    }
  });

  async function fetchData() {
    try {
      let apiData: object = {
        id: null,
      };
      const postData = {
        method: "POST",
        data: apiData,
      };
      const response = await APICALL.service(
        fetchRolesUrl,
        "POST",
        postData,
        true
      );
      setRoleArray(response?.data);
    } catch (error) {
      console.error(error);
    }
  }

  const handleEdit = (e: number) => {
    const url = `/roles/create/?edit=${e}`;
    navigate(url);
  };

  const handleStatus = (role_id: number, status: boolean) => {
    setStatus((prevState) => ({ ...prevState, edit: role_id, status: status }));
    setShowPopup(true);
  };

  async function handleChangeStatus() {
    try {
      let requestdata = JSON.parse(JSON.stringify(status));
      requestdata["status"] = !requestdata["status"] ? 0 : true;

      const postData = {
        method: "POST",
        data: requestdata,
      };
      const response = await APICALL.service(
        createRole,
        "POST",
        postData,
        true
      );
      setShowPopup(!showPopup);
      setRefreshRoles(!refreshRoles);
      let message: string = t(`Role updated successfully`);
      localStorage.setItem("successAlertMessage", message);
      status?.status ? setActiveTab("inactive") : setActiveTab("active");
    } catch (error) {
      localStorage.setItem("errorAlertMessage", t(`Error while updating role`));
      console.error(error);
    }
  }

  const handleTabClick = (tab: "active" | "inactive") => {
    setActiveTab(tab);
    setCurrentPage(1); // Reset the current page when switching tabs
  };

  window.addEventListener("beforeunload", () => {
    const currentURL = new URL(window.location.href);
    // const urlExtension = currentURL.pathname;
    const urlExtension = "/roles/manage";
    localStorage.setItem("backbuttonURL", urlExtension);
  });

  useEffect(() => {
    const userPerm: any = user?.permissions;
    const truePerm: any = userPerm?.find(
      (item: any) => item.permission.toLowerCase().trim() === "roles"
    );
    let permissions = [
      {
        permission: "roles",
        read: true,
      },
    ];
    if (truePerm?.update === true || truePerm?.delete === true) {
      permissions[0].read = false;
    }
    setRequiredPermissions(permissions);
  }, [user != null]);

  return (
    <AccessControl
      requiredPermissions={requiredPermissions}
      renderNoAccess={true}
    >
      <>
        <div className="row">
          <div className="col-md-11 ms-auto px-4">
            {/* Page title */}
            <WindowHeightComponent>
              <div className="d-flex flex-column h-100">
                <div className="py-xxl-4 py-xl-3 py-lg-3 page-title top-0">
                  {t("Manage roles")}
                </div>

                <div className="form-group flex-1 overflow-auto">
                  <div className="d-flex flex-column h-100 manage-roles-table">
                    <div className="position-sticky top-0 bg-white">
                      <input
                        type="text"
                        placeholder={t("Search")}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        className="form-control shadow-none"
                      />
                      <div className="user-tabs my-3">
                        <button
                          className={`${activeTab === "active" ? "active" : ""
                            } border-0 bg-transparent tab-btn me-4`}
                          onClick={() => handleTabClick("active")}
                        >
                          {t("Active")}
                        </button>
                        <button
                          className={`${activeTab === "inactive" ? "active" : ""
                            } border-0 bg-transparent tab-btn`}
                          onClick={() => handleTabClick("inactive")}
                        >
                          {t("Inactive")}
                        </button>
                      </div>
                    </div>
                    <div className="flex-1 overflow-auto">
                      <table className="table">
                        <thead className="position-sticky ">
                          <tr>
                            <th scope="col" className="border-bottom-0">
                              {t("Name")}
                              <button
                                onClick={handleSortClick}
                                className="sort-btn border-0 bg-transparent text-gray"
                              >
                                {sortOrder === "asc" ? <FaSort /> : <FaSort />}
                              </button>
                            </th>
                            <AccessControl
                              requiredPermissions={[
                                {
                                  permission: "roles",
                                  update: true,
                                  delete: true,
                                },
                              ]}
                              actions={true}
                              strict={false}
                            >
                              <th
                                scope="col"
                                className="border-bottom-0 text-center"
                              >
                                {t("Actions")}
                              </th>
                            </AccessControl>
                          </tr>
                        </thead>
                        <tbody>
                          {currentUsers.length ? (
                            currentUsers.map((item: any) => (
                              <tr key={item.role_id}>
                                {/* <td className="text-break">{item.role_id}</td> */}
                                <td className="text-break">{item.title}</td>
                                <AccessControl
                                  requiredPermissions={[
                                    {
                                      permission: "roles",
                                      update: true,
                                      delete: true,
                                    },
                                  ]}
                                  actions={true}
                                  strict={false}
                                >
                                  <td className="text-break manage-users-action text-center">
                                    <AccessControl
                                      requiredPermissions={[
                                        {
                                          permission: "roles",
                                          update: true,
                                        },
                                      ]}
                                    >
                                      {item.role_id !==
                                        Number(
                                          process.env.REACT_APP_ADMIN_ROLE_ID
                                        ) && (
                                          <button
                                            onClick={() =>
                                              handleEdit(item.role_id)
                                            }
                                            key={item.permission_id}
                                            id={item.permission_id}
                                            className="shadow-none border-0 bg-transparent manage-users-action me-4 px-0"
                                            data-toggle="tooltip"
                                            title={t("Edit")}
                                          >
                                            <Edit />
                                          </button>
                                        )}
                                    </AccessControl>
                                    <AccessControl
                                      requiredPermissions={[
                                        {
                                          permission: "roles",
                                          delete: true,
                                        },
                                      ]}
                                    >
                                      {item.role_id !==
                                        Number(
                                          process.env.REACT_APP_ADMIN_ROLE_ID
                                        ) &&
                                        item.role_id !==
                                        Number(
                                          process.env.REACT_APP_SUPERVISOR_ID
                                        ) && (
                                          <button
                                            onClick={() =>
                                              handleStatus(
                                                item.role_id,
                                                item.status
                                              )
                                            }
                                            className="border-0 bg-transparent shadow-none  manage-users-action me-4 px-0"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                            title={
                                              item.status
                                                ? t("Deactivate")
                                                : t("Activate")
                                            }
                                          >
                                            {item.status ? (
                                              <Archive />
                                            ) : (
                                              <Unarchive />
                                            )}
                                          </button>
                                        )}
                                    </AccessControl>
                                  </td>
                                </AccessControl>
                              </tr>
                            ))
                          ) : (
                            <td colSpan={3} className="text-center text-danger">
                              {t(CommonConstants.NO_RESULTS_FOUND)}
                            </td>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="position-relative w-100 row mt-2 m-0">
                  {sortedRoles.length > itemsPerPage && totalPages > 1 && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      handlePaginationClick={handlePaginationClick}
                    />
                  )}
                  <div className="col-6 p-0">
                    <Button
                      className="back-btn shadow-none float-start text-decoration-none"
                      handleClick={() => navigate("/backend")}
                    >
                      {t(CommonConstants.BACK)}
                    </Button>
                  </div>

                </div>
              </div>
            </WindowHeightComponent>
          </div>

          {showPopup && (
            <Popup
              title={t("Confirmation")}
              body={t(
                `Are you sure you want to ${activeTab === "active" ? "deactivate" : "activate"
                } this role?`
              )}
              cancel={() => setShowPopup(!showPopup)}
              submit={handleChangeStatus}
              yestext={t(CommonConstants.YES)}
              notext={t(CommonConstants.NO)}
            />
          )}
        </div>
      </>
    </AccessControl>
  );
};

export default ManageRoles;
