import React from 'react'

const Manageroles: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="39.596" height="37.336" viewBox="0 0 39.596 37.336">
  <g id="Manage_user" data-name="Manage user" transform="translate(-12680.58 -8354.012)">
    <g id="Group_48559" data-name="Group 48559" transform="translate(12680.58 8354.012)">
      <path id="Subtraction_742" data-name="Subtraction 742" d="M9.857,17.871a8.236,8.236,0,0,1-2.066-.294,9,9,0,0,1-3.042-1.488A9.13,9.13,0,0,1,2.208,13.1a8.216,8.216,0,0,1-.989-3.206A8.917,8.917,0,0,1,2.227,4.712c.143-.267.146-.272-.075-.475C1.6,3.727,1.045,3.219.508,2.728L.466,2.69a1.27,1.27,0,0,1-.428-.623A.957.957,0,0,1,.506.933,1.02,1.02,0,0,1,1.022.8a1.154,1.154,0,0,1,.774.3c.544.5,1.1,1.014,1.64,1.507l.043.039.014.013c.072.065.108.1.142.1s.067-.033.131-.1l.007-.007A8.368,8.368,0,0,1,7.122.522,9.714,9.714,0,0,1,9.339.037C9.6.012,9.872,0,10.14,0a9,9,0,0,1,3.652.777,8.94,8.94,0,0,1,4.826,5.407,8.212,8.212,0,0,1,.28,1.148,7.026,7.026,0,0,0-3.065,8.477,8.957,8.957,0,0,1-3.065,1.664,8.247,8.247,0,0,1-2.567.391h-.083C10.04,17.869,9.955,17.871,9.857,17.871ZM8.066,7.089A.155.155,0,0,0,8,7.111c-.063.035-.129.068-.193.1l-.013.007-.129.065A2.7,2.7,0,0,0,6.115,9.69c0,.041,0,.082,0,.123v.01c0,.1,0,.205-.013.308l-.024.336-.007.094c-.046.647-.092,1.314-.149,1.968a1.2,1.2,0,0,0,.356.964,12.243,12.243,0,0,0,3.642.305c1.8,0,3.832-.107,4.039-.341a1.124,1.124,0,0,0,.334-.794c-.008-.218-.026-.437-.044-.649V12c-.01-.124-.021-.253-.029-.378-.024-.359-.046-.716-.07-1.112q-.023-.371-.046-.741a3.056,3.056,0,0,0-.26-1.171,3.121,3.121,0,0,0-1.728-1.541h0l-.017.032a.241.241,0,0,0-.025.052,1.773,1.773,0,0,1-.63.9,2.1,2.1,0,0,1-1.306.485,2.248,2.248,0,0,1-.889-.2,1.943,1.943,0,0,1-1.092-1.16C8.13,7.115,8.1,7.089,8.066,7.089ZM10.077,1.92A2.549,2.549,0,0,0,7.566,4.481,2.559,2.559,0,0,0,10.107,6.99h0a2.536,2.536,0,0,0,.073-5.07h-.106Z" transform="translate(11.472 18.194)" fill="#545960"/>
      <path id="Path_56422" data-name="Path 56422" d="M42.953,115.57a1.714,1.714,0,0,0-.848-.518,1.641,1.641,0,0,0-2.049,1.7,1.549,1.549,0,0,0,.505,1.027c.037.036.075.072.111.109.121.122.122.123-.024.225a8.076,8.076,0,0,1-1.7.844,6.363,6.363,0,0,1-1.871.411,7.763,7.763,0,0,1-1.659-.069A7.312,7.312,0,1,1,41.6,106.826c.213.2.4.428.6.648.056.062.1.035.145,0,.393-.278.788-.553,1.178-.835.527-.381,1.05-.768,1.575-1.152a.742.742,0,0,1,.774-.095.694.694,0,0,1,.443.662.771.771,0,0,1-.33.66c-.342.26-.688.513-1.034.767q-.772.564-1.547,1.125c-.08.058-.166.111-.25.165-.059.037-.082.072-.047.147a7.383,7.383,0,0,1,.7,3.715,7.247,7.247,0,0,1-.533,2.215c-.061.153-.124.305-.19.456-.035.081-.077.16-.127.264Zm-9.844-.985.02,0a1.624,1.624,0,0,1,0,.247.884.884,0,0,0,.729.909c.552.105,5.068.215,5.468-.093a1.115,1.115,0,0,0,.464-1.087c-.064-.546-.076-1.1-.127-1.643a5.231,5.231,0,0,0-.15-1.024,2.32,2.32,0,0,0-1.362-1.433c-.084-.039-.121-.021-.153.065a1.594,1.594,0,0,1-.454.64,1.651,1.651,0,0,1-2.6-.534c-.1-.195-.1-.2-.3-.1a2.2,2.2,0,0,0-1.387,1.932c-.016.269-.018.537-.036.8-.032.44-.073.88-.109,1.32Zm3.332-8.243a2.016,2.016,0,1,0,.026,4.031,2.015,2.015,0,1,0-.026-4.03Z" transform="translate(-29.193 -96.606)" fill="#545960"/>
      <path id="Path_56423" data-name="Path 56423" d="M171.383,37.461c.239-.19.473-.368.7-.555a1.163,1.163,0,0,0,.448-.967,1.131,1.131,0,0,0-.663-1.039,1.1,1.1,0,0,0-1.171.075c-.152.1-.3.213-.444.319-.052.037-.108.069-.184.118a6.208,6.208,0,0,1-.458-2.689,6.364,6.364,0,0,1,2.407-4.749,6.523,6.523,0,1,1-.633,9.488Zm7.533-1.942c-.007-.108-.014-.217-.021-.324-.03-.449-.05-.9-.095-1.347a2.9,2.9,0,0,0-.146-.721,2.061,2.061,0,0,0-1.068-1.1c-.105-.054-.153-.05-.2.069a1.313,1.313,0,0,1-.7.731,1.4,1.4,0,0,1-1.846-.729c-.041-.093-.076-.122-.171-.072a2.732,2.732,0,0,0-.847.637,1.256,1.256,0,0,0-.328.7c-.069.72-.113,1.441-.184,2.161a.8.8,0,0,0,.333.743c.074.059.153.115.232.167.655.432,4.119.263,4.62-.09a.8.8,0,0,0,.423-.817Zm-2.805-6.977a1.711,1.711,0,0,0-1.684,1.789,1.749,1.749,0,0,0,1.676,1.617,1.7,1.7,0,1,0,.007-3.406Z" transform="translate(-154.98 -26.499)" fill="#545960"/>
    </g>
    <path id="Manage_roles" data-name="Manage roles" d="M48.014,43.262a5.7,5.7,0,1,1,5.7,5.7A5.7,5.7,0,0,1,48.014,43.262Zm4.172,2.444a2.629,2.629,0,0,0,.333.181,3.362,3.362,0,0,0,.356.139v.6a.361.361,0,0,0,.364.364H54a.355.355,0,0,0,.255-.106.348.348,0,0,0,.108-.259v-.552a2.767,2.767,0,0,0,.368-.113,3.308,3.308,0,0,0,.347-.156l.429.43a.354.354,0,0,0,.511,0l.538-.532a.38.38,0,0,0,0-.517l-.4-.394a2.575,2.575,0,0,0,.182-.338,3.538,3.538,0,0,0,.139-.357h.607a.361.361,0,0,0,.365-.365v-.754a.357.357,0,0,0-.106-.255.351.351,0,0,0-.259-.108h-.553a3.08,3.08,0,0,0-.113-.364,2.717,2.717,0,0,0-.157-.344l.427-.436a.339.339,0,0,0,.11-.253.357.357,0,0,0-.11-.259l-.532-.531a.361.361,0,0,0-.517,0l-.394.393a2.618,2.618,0,0,0-.334-.182,3.4,3.4,0,0,0-.357-.139v-.6a.346.346,0,0,0-.106-.258.352.352,0,0,0-.259-.106H53.43a.357.357,0,0,0-.255.106.345.345,0,0,0-.108.258v.553a2.715,2.715,0,0,0-.369.113,2.8,2.8,0,0,0-.339.157l-.436-.43a.339.339,0,0,0-.253-.109.359.359,0,0,0-.259.109l-.531.533a.361.361,0,0,0,0,.517l.393.394a2.639,2.639,0,0,0-.181.333,3.523,3.523,0,0,0-.14.357h-.6a.361.361,0,0,0-.364.364v.758a.365.365,0,0,0,.364.363H50.9a2.748,2.748,0,0,0,.113.368,3.466,3.466,0,0,0,.157.347l-.43.429a.331.331,0,0,0-.11.253.355.355,0,0,0,.11.259l.532.538a.368.368,0,0,0,.259.1.364.364,0,0,0,.259-.1l.394-.4h0Zm.95-1.07a1.47,1.47,0,0,1-.79-.788,1.507,1.507,0,0,1,0-1.159,1.47,1.47,0,0,1,.79-.79,1.513,1.513,0,0,1,1.158,0,1.542,1.542,0,0,1,.474.317,1.5,1.5,0,0,1,.317,1.633,1.465,1.465,0,0,1-.79.79,1.513,1.513,0,0,1-1.158,0Z" transform="translate(12660.763 8342.382)" fill="#545960"/>
  </g>
</svg>

  )
}

export default Manageroles