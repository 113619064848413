import React, { useState, ChangeEvent, FocusEvent } from 'react';

const style = {};

interface Props {
  id?: string;
  type?: string;
  className?: string;
  value?: string;
  isDisabled?: boolean | any;
  placeholder?: string;
  customStyle?: React.CSSProperties;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleClick?: (event: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  min?: string;
  max?: string;
  step?: string;
  error?: string;
}

const InputRangefield: React.FC<Props> = ({
  id = '',
  type = "range",
  className = '',
  value = '',
  isDisabled = false,
  customStyle = {},
  handleChange,
  handleClick,
  name = '',
  min = '0',
  max = '',
  step = '',
  placeholder,
  error
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className='d-flex align-items-center flex-1'>
      <input
        style={{ ...style, ...customStyle }}
        id={id}
        type={type}
        className={`sliderRed me-4 w-100`}
        disabled={isDisabled}
        name={name}
        value={value}
        onChange={handleChange}
        onFocus={handleClick}
        onBlur={handleBlur}
        min={min}
        max={max}
        step={step}
      />
      <input
        style={{ ...style, ...customStyle }}
        type={'number'}
        className={className + ' ps-2'}
        disabled={isDisabled}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={handleChange}
        onFocus={handleClick}
        onBlur={handleBlur}
        min={min}
        max={max}
        step={step}
      />
      {error && <span className="text-danger position-absolute top-100">{error}</span>}
    </div>
  );
};

export default InputRangefield;
