import React from 'react';

interface TimeTrackerOverviewCardMoleculeProps {
  data: any[];
  workflowStages: string[];
  t: (key: string) => string;
  TimeTrackerOverviewConstants: any;
  CommonServices: any;
}

const TimeTrackerOverviewCardMolecule: React.FC<TimeTrackerOverviewCardMoleculeProps> = ({ data, workflowStages, t, TimeTrackerOverviewConstants, CommonServices }) => {
  const includestages: string[] = ['sort', 'category_sort', 'internal_laundry', 'data_entry', 'external_laundry', 'publishing'];
  return (
    data?.length <= 0 ? (
      <div>{t("No result found")}</div>
    ) : (
      <div className="row m-0">
        {workflowStages.map((stageName, index) => {
          const value = data.find(item => item?.stages?.stage_name === stageName);
          if (!value) {
            return null;
          }
          return (
            <div className="col-4 p-2" key={index}>
              <div className="rounded shadow p-3 h-100">
                <div className="text-center fw-bold text-gray pb-2">
                  {t(CommonServices.capitalizeLabel(value?.stages?.stage_name ?? ''))}
                </div>
                <div className="row">
                  <div className="col-4 fw-bold text-gray">
                    {t(TimeTrackerOverviewConstants?.TOTALTIME) + ': '}
                  </div>
                  <div className="col-8">
                    {CommonServices.formatTimeToDays(value?.total_time ?? '')}
                  </div>
                  <div className="col-4 fw-bold text-gray">
                    {t(TimeTrackerOverviewConstants?.COUNT) + ': '}
                  </div>
                  <div className="col-8 d-flex align-items-center">
                    {value?.distinct_count ?? 0}
                  </div>
                  <div className="col-4 fw-bold text-gray">
                    {t(TimeTrackerOverviewConstants?.AVGTIME) + ': '}
                  </div>
                  <div className="col-8">
                  {
                    includestages.includes(value?.stages?.stage_name)
                      ? CommonServices.formatTimeToDays(
                            value?.total_time
                              ? CommonServices.convertTime(
                                  CommonServices.convertTime(
                                    value?.total_time) / Number((!value?.distinct_count || value?.distinct_count === "0") ? 1 : value?.distinct_count)
                                , true)
                              : 0
                        )
                      : CommonServices.formatTimeToDays(value?.avg_time ?? '')
                  }
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    )
  );
};

export default TimeTrackerOverviewCardMolecule;
