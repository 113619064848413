import React from "react";
import CreateDataEntryWithSku from "./CreateDataEntryWithSku";
import AccessControlWorkFlow from "../../../services/AccessControlWorkFlow";
import AccessControl from "../../../services/AccessControl";
import CreateDataEntry from "./CreateDataEntry";

export const CreateDataEntryPage = () => {
  const bpdetails = JSON.parse(localStorage.getItem("bp_details") ?? "");
  return bpdetails.isSku ? (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={[
        {
          permission: "workflow",
          read: true,
        },
      ]}
    >
      <AccessControlWorkFlow stage_name="data_entry" renderNoAccess={true}>
        <CreateDataEntryWithSku />{" "}
      </AccessControlWorkFlow>
    </AccessControl>
  ) : (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={[
        {
          permission: "workflow",
          read: true,
        },
      ]}
    >
      <AccessControlWorkFlow stage_name="data_entry" renderNoAccess={true}>
        <CreateDataEntry />
      </AccessControlWorkFlow>
    </AccessControl>
  );
};
