import React, { CSSProperties } from 'react';

const style = {};

export interface Props {
  customStyle?: CSSProperties;
  errorMessage?: string;
  className?: string;
}

const ErrorMessage: React.FC<Props> = ({
  customStyle = {},
  errorMessage = '',
  className = '',
}) => {
  return (
    <>
      {errorMessage && <div className={`${className} text-danger fs-6`} style={customStyle}>{errorMessage}</div>}
    </>
  );
};

export default ErrorMessage;
