import React, { useState, useEffect } from "react";
import { t } from "../Translations/TranslationUtils";
import { useNavigate } from "react-router-dom";
import CustomAlert from "../../components/atoms/CustomAlert";
import Editor from "../../components/atoms/Editor";
import { InputWithLabel } from "../../components/molecules/InputWithLabel";
import { APICALL } from "../../services/ApiServices";
import {
  createMailTemplate,
  getMailTokens,
  manageMailTemplate,
} from "../../routes/ApiEndPoints";
import AccessControl from "../../services/AccessControl";
import { CommonConstants } from "../Shipment/Constants/ShipmentConstants";
import PageNotFound from "../PageNotFound";
import LabelField from "../../components/atoms/LabelField";
import FormValidation from "../../services/FormValidation";
import WindowHeightComponent from "../../commonComponents/WindowHeightComponent";

type MailState = {
  template_name: string;
  template_description: string;
  subject: string;
  title: string;
  body: string;
  language: string;
  template_id: any;
};

const initialMailState: MailState = {
  template_name: "",
  template_description: "",
  title: "",
  subject: "",
  body: "",
  language: "",
  template_id: null,
};

interface FormErrors {
  template_name: string;
  template_description: string;
  title: string;
  subject: string;
  body: string;
}

// const CreateMailTemplate: React.FC = () => {
const CreateMailTemplate: React.FC = () => {
  const navigate = useNavigate();
  const [mailState, setMailState] = useState<MailState>(initialMailState);
  const searchParams = new URLSearchParams(location.search);
  const editId = searchParams.get("id");
  const language = searchParams.get("language") ?? "";
  const [isSaving, setIsSaving] = useState(false);
  const [mailTokens, setMailTokens] = useState<{ [key: string]: string }>({});
  const [errors, setErrors] = useState<FormErrors>({
    template_name: "",
    template_description: "",
    title: "",
    subject: "",
    body: "",
  });
  const [existingTemplates, setExistingTemplates] = useState<string[]>([]);

  useEffect(() => {
    let apiData;
    if (editId) {
      setMailState((prevValues) => ({
        ...prevValues,
        template_id: editId,
      }));
      apiData = {
        mail_template_id: Number(editId),
        language: language,
      };
    } else {
      apiData = {};
    }
    fetchTemplateData(apiData);
  }, []);

  useEffect(() => {
    const storageMessage = localStorage.getItem("errorAlertMessage");
    if (storageMessage) {
      localStorage.removeItem("errorAlertMessage");
      CustomAlert("error", t(storageMessage), 6000);
    }
    fetchMailTokens();
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setMailState((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleEditorChange = (data: string) => {
    setMailState((prevValue) => ({
      ...prevValue,
      body: data,
    }));
  };

  async function fetchMailTokens() {
    try {
      const postData = {
        method: "POST",
        data: {},
      };

      const response = await APICALL.service(
        getMailTokens,
        "POST",
        postData,
        true
      );

      if (response?.status !== 200) {
        throw new Error("Request failed");
      }
      const responseData = response.data;
      let tokens: { [key: string]: string } = {};
      if (response.status === 200) {
        Object.values(responseData).map((item: any) => {
          tokens[item.mail_token_name] = item.mail_token_description;
          return item;
        });
      }
      setMailTokens(tokens);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchTemplateData(apiData: any) {
    try {
      const postData = {
        method: "POST",
        data: apiData,
      };
      const response = await APICALL.service(
        manageMailTemplate,
        "POST",
        postData,
        true
      );

      if (response?.status === 200) {
        const responseData = response.data;
        editId && responseData.length > 0 &&
          Object.entries(responseData[0]).forEach(([key, value]) => {
            setMailState((prevValues) => ({
              ...prevValues,
              [key]: value,
            }));
          });

        let templates: string[];

        if (editId) {
          templates = Object.values(responseData)
            .filter((item: any) => Number(editId) !== item.mail_template_id)
            .map((item1: any) => {
              return item1.template_name.toLowerCase();
            });
        } else {
          templates = Object.values(responseData).map((item: any) => {
            return item.template_name.toLowerCase();
          });
        }

        setExistingTemplates(templates);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function saveTemplate() {
    setIsSaving(true);
    try {
      const postData = {
        // Encrypting the request data
        method: "POST",
        data: mailState,
      };
      const response = await APICALL.service(
        createMailTemplate,
        "POST",
        postData,
        true
      );

      if (response?.status !== 200) {
        throw new Error("Request failed");
      }
      if (response.status === 200) {
        setIsSaving(false);
        let message: string = `Template ${editId ? "updated" : "created"
          } successfully`;
        localStorage.setItem("successAlertMessage", message);
        navigate("/mail-template/manage");
      }
    } catch (error) {
      localStorage.setItem(
        "errorAlertMessage",
        `Error while ${editId ? "updating" : "creating"}
          template
        )}`
      );
      console.log(error);
    }
  }

  //Validation rules for each field
  const validateForm = (): FormErrors | undefined => {
    const validationErrors: FormErrors = {
      template_name: "",
      template_description: "",
      title: "",
      subject: "",
      body: "",
    };

    // Add validation rules for each field
    validationErrors.template_name = FormValidation.nameValidation(mailState.template_name ?? "");
    validationErrors.template_description = FormValidation.nameValidation(mailState.template_description ?? "");
    validationErrors.title = FormValidation.nameValidation(mailState.title ?? "");
    validationErrors.subject = FormValidation.nameValidation(mailState.subject ?? "");
    validationErrors.body = FormValidation.nameValidation(mailState.body ?? "", 5000);

    if (existingTemplates.includes(mailState.template_name)) {
      validationErrors.template_name = t("Template name already exists");
    }

    setErrors(validationErrors);
    if (
      validationErrors.template_name === "" &&
      validationErrors.template_description === "" &&
      validationErrors.title === "" &&
      validationErrors.subject === "" &&
      validationErrors.body === ""
    ) {
      return undefined;
    } else {
      return validationErrors;
    }
  };

  const removeError = (event: React.FocusEvent<HTMLInputElement>) => {
    // Handle the click event here
    let fieldName = (event.target as HTMLInputElement).name;

    setErrors((prevState) => ({
      ...prevState,
      [fieldName]: "", // Set the value of the field dynamically
    }));
  };

  const removeErrorCkEditor = (fieldName: any) => {
    // Handle the click event here

    setErrors((prevState) => ({
      ...prevState,
      [fieldName]: "", // Set the value of the field dynamically
    }));
  };

  const hasError = (field: keyof FormErrors): boolean => {
    return Array.isArray(errors[field])
      ? errors[field].length > 0
      : !!errors[field];
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const urlExtension = `/mail-template/create`;
    localStorage.setItem("backbuttonURL", urlExtension);
    const validationErrors = validateForm();
    if (validationErrors === undefined) {
      saveTemplate();
    }
  };

  const goToPreviousPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    let backButtonURL: any = localStorage.getItem("backbuttonURL");
    localStorage.removeItem("backbuttonURL");
    navigate(backButtonURL ?? "/");
  };

  return editId ? (
    <AccessControl
      requiredPermissions={[
        {
          permission: "mail template",
          update: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div className="row">
        <div className="col-md-11 ms-auto px-4">

          <WindowHeightComponent>
            <div className="d-flex flex-column overflow-auto h-100">
              <div className="py-xxl-4 py-xl-3 py-lg-3 page-title top-0">
                {t("Mail template")}
              </div>
              <div className="flex-1 overflow-auto">
                <form onSubmit={handleSubmit} className="d-flex flex-column h-100 overflow-auto">
                  <div className="flex-1 overflow-auto">
                    <div className="row m-0">
                      <div className="col-6">
                        <InputWithLabel
                          label={t("Template name") + ':'}
                          type="text"
                          name="template_name"
                          value={mailState.template_name}
                          className={`shadow-none form-control ${errors.template_name ? 'error_border_color' : ''}`}
                          handleChange={handleInputChange}
                          isDisabled={editId ? true : false}
                          handleClick={removeError}
                          error={hasError("template_name") ? t(errors.template_name) : ""}
                        />
                      </div>
                      <div className="col-6">
                        <InputWithLabel
                          label={t("Template description") + ':'}
                          type="text"
                          name="template_description"
                          value={mailState.template_description}
                          className={`shadow-none form-control  ${errors.template_description ? 'error_border_color' : ''}`}
                          handleChange={handleInputChange}
                          handleClick={removeError}
                          mandatory
                          error={
                            hasError("template_description")
                              ? t(errors.template_description)
                              : ""
                          }
                        />
                      </div>
                      <div className="col-6">
                        <InputWithLabel
                          label={t("Title") + ':'}
                          type="text"
                          name="title"
                          value={mailState.title}
                          className={`shadow-none form-control ${errors.title ? 'error_border_color' : ''}`}
                          handleChange={handleInputChange}
                          handleClick={removeError}
                          mandatory
                          error={hasError("title") ? t(errors.title) : ""}
                        />
                      </div>
                      <div className="col-6">
                        <InputWithLabel
                          label={t("Subject") + ':'}
                          type="text"
                          name="subject"
                          value={mailState.subject}
                          className={`shadow-none form-control ${errors.subject ? 'error_border_color' : ''}`}
                          handleChange={handleInputChange}
                          handleClick={removeError}
                          mandatory
                          error={hasError("subject") ? t(errors.subject) : ""}
                        />
                      </div>
                    </div>
                    <div className="row m-0">
                      <div className="col-12">
                        <LabelField
                          title={t("Body") + ':'}
                          mandatory
                        />
                      </div>
                      <div
                        className="form-group col-md-12 mb-4"
                        onClick={() => removeErrorCkEditor("body")}
                      >
                        <Editor
                          type="text"
                          parentclass={errors.body ? 'error_border_color' : ""}
                          onChange={handleEditorChange}
                          editorLoadedprops={"body"}
                          name="body"
                          defaultHeight="200px"
                          value={mailState.body}
                        />
                        {hasError("body") && (
                          <div className="error" style={{ color: "red" }}>
                            {t(errors.body)}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row m-0">
                      <div className="col-12">
                        <label>{t("Tokens")}:</label>
                      </div>
                      <div className="row">
                        {mailTokens &&
                          Object.keys(mailTokens).map((item: any) => (
                            <div className="row">
                              <div className="col-2">
                                <span className="mb-1">{item}</span>
                              </div>
                              :
                              <div className="col">
                                <span>{t(mailTokens[item])}</span>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2 m-0">
                    <div className="col-md-6 float-start">
                      <button
                        className="back-btn shadow-none float-start text-decoration-none"
                        onClick={() => navigate(`${editId ? '/mail-template/manage' : '/application-configuration'}`)}
                      >
                        {t(CommonConstants.BACK)}
                      </button>
                    </div>
                    <div className="col-md-6 float-end">
                      <button
                        type="submit"
                        className="submit-btn shadow-none float-end"
                        disabled={isSaving}
                      >
                        {editId
                          ? isSaving
                            ? t("Saving...")
                            : t("Update")
                          : isSaving
                            ? t("Saving...")
                            : t("Create")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </WindowHeightComponent>
        </div>
      </div>
    </AccessControl>
  ) : (
    <PageNotFound />
  );
};

export default CreateMailTemplate;
