import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navigation from '../commonComponents/Navigation';
import Backend from '../pages/Backend';
import CreateRole from '../pages/Roles/CreateRole';
import ManageRoles from '../pages/Roles/ManageRoles';
import CreateUser from '../pages/Users/CreateUser';
import ManageUser from '../pages/Users/ManageUser';
import Logout from '../pages/Logout';
import Myprofile from '../pages/Users/Myprofile';
import Changepassword from '../pages/Users/Changepassword';
import EditPassword from '../pages/EditPassword';
import ManageMasterData from '../pages/MasterData/ManageMasterData';
import CreateMasterData from '../pages/MasterData/CreateMasterData';
import UpdateTranslations from '../pages/Translations/UpdateTranslations';
import ManageTranslations from '../pages/Translations/ManageTranslations';
import Admindashboard from '../pages/Admin/Admindashboard'
import BrandPartner from '../pages/BrandPartner'
import CreateMailTemplate from '../pages/MailTemplates/CreateMailTemplate';
import ManageMailTemplate from '../pages/MailTemplates/ManageMailTemplate';
import ApplicationConfiguration from '../pages/ApplicationConfiguration';
import ManageBrandPartner from '../pages/BrandPartner/ManageBrandPartner';
import BrandPartnerDashboard from '../pages/BrandPartnerDashboard';
import ShipmentBrandPartnerDashboard from '../pages/ShipmentBrandPartnerDashboard';
import { ShipmentDashboard } from '../pages/Shipment/ShipmentDashboard/ShipmentDashboard';
import { CreateShipment } from '../pages/Shipment/Shipment/CreateShipment';
import { ManageShipment } from '../pages/Shipment/Shipment/ManageShipment';
import CreateSort from '../pages/Shipment/Sort/CreateSort';
import ManageDataEntryOverview from '../pages/Shipment/DataEntry/ManageDataEntryOverview';
import ProductCatalogue from '../pages/ProductCatalogue/ProductCatalogue';
import EditProductCatalogue from '../pages/ProductCatalogue/EditProductCatalogue';
import MediaLibrary from '../pages/MediaLibrary/MediaLibrary';
import QualityControl from '../pages/Shipment/QualityControl/QualityControl';
import ManageRecaptcha from '../pages/ManageRecaptcha';
import { CreateDataEntryPage } from '../pages/Shipment/DataEntry/CreateDataEntryPage';
import ManageQualityControl from '../pages/Shipment/QualityControl/ManageQualityControl';
import Repair from '../pages/Shipment/Repair/Repair';
import ManageRepair from '../pages/Shipment/Repair/ManageRepair';
import PageNotFound from '../pages/PageNotFound';
import CreateMerchandising from '../pages/Shipment/Merchandising/CreateMerchandising';
import ManageMerchandising from '../pages/Shipment/Merchandising/ManageMerchandising';
import QualityAssurance from '../pages/Shipment/QualityAssurance/QualityAssurance';
import ManageQualityAssurance from '../pages/Shipment/QualityAssurance/ManageQualityAssurance';
import PrePublish from '../pages/Shipment/PrePublish/PrePublish';
import CreateFinishing from '../pages/Shipment/Finishing/CreateFinishing';
import ManageFinishing from '../pages/Shipment/Finishing/ManageFinishing';
import ExternalBatchOverview from '../pages/Shipment/LaundryExternal/ExternalBatchOverview';
import ExternalCreateBatch from '../pages/Shipment/LaundryExternal/ExternalCreateBatch';
import ManagePhotoStudio from '../pages/Shipment/PhotoStudio/ManagePhotoStudio';
import PhotoStudio from '../pages/Shipment/PhotoStudio/PhotoStudio';
import ManagePhotoEditing from '../pages/Shipment/PhotoEditing/ManagePhotoEditing';
import PhotoEditing from '../pages/Shipment/PhotoEditing/PhotoEditing';
import ManageHoldItems from '../pages/Reports/HoldItem/ManageHoldItems';
import ShipmentReports from '../pages/Reports/ShipmentReports';
import Reports from '../pages/Reports/ReportsDashboard';
import LauntryInternal from '../pages/Shipment/LauntryInternal/LauntryInternal';
import StainTreatmentOrganism from '../components/organisms/LaundryInternal/StainTreatmentOrganism';
import WashingOrganism from '../components/organisms/LaundryInternal/WashingOrganism';
import TimeTrackerOverview from '../pages/Reports/TimeTrackerOverview';
import ViewRenewalRecord from '../pages/Reports/ViewRenewalRecord';
import CategorySort from '../pages/Shipment/CategorySort/CategorySort';
import ViewSortReports from '../pages/Reports/ViewSortReports';
import PublishingOverviewOrganism from '../components/organisms/PrePublish/PublishingOverviewOrganism';
import RejectedPublished from '../pages/Shipment/PrePublish/RejectedPublished';
import ManageInactivityTimeout from '../pages/ManageInactivityTimeout';
import CreateDataEntry from '../pages/Shipment/DataEntry/CreateDataEntry';


const AppRoutes: React.FC = () => {
  return (
    <>
      <Router>
      <Navigation />
        <Routes>
          <Route path='/' element={<Admindashboard />} />
          <Route path="/backend" element={<Backend />} />
          <Route path="/application-configuration" element={<ApplicationConfiguration />} />
          <Route path="/users/create" element={<CreateUser />} />
          <Route path="/users/manage" element={<ManageUser />} />
          <Route path="/roles/create" element={<CreateRole />} />
          <Route path="/roles/manage" element={<ManageRoles />} />
          <Route path="/password/manage" element={<EditPassword />} />
          <Route path="/logout" element={<Logout />} />
          <Route path='/users/myprofile' element={<Myprofile />} />
          <Route path='/users/changepassword' element={<Changepassword />} />
          <Route path='/master-data/manage' element={<ManageMasterData />} />
          <Route path='/master-data/create' element={<CreateMasterData />} />
          <Route path='/translations/update' element={<UpdateTranslations />} />
          <Route path='/translations/manage' element={<ManageTranslations />} />
          <Route path='/mail-template/create' element={<CreateMailTemplate />} />
          <Route path='/mail-template/manage' element={<ManageMailTemplate />} />
          <Route path='/product-catalogue/manage' element={<ProductCatalogue/>} />
          <Route path="/product-catalogue" element={<EditProductCatalogue/>}/>
          <Route path="/brand-partner/create" element={<BrandPartner />} />
          <Route path="/brand-partner/manage" element={<ManageBrandPartner />} />
          <Route path="/brand-partner" element={<BrandPartnerDashboard />} />
          <Route path="/shipment" element={<ShipmentBrandPartnerDashboard />} />
          <Route path="/shipment-dashboard" element={<ShipmentDashboard />} />
          <Route path="/shipment/create" element={<CreateShipment />} />
          <Route path="/shipment/manage" element={<ManageShipment />} />
          <Route path="/shipment/sort/create" element={<CreateSort />} />
          <Route path="/data-entry/create" element={<CreateDataEntryPage />} />
          <Route path="/data-entry/overview" element={<CreateDataEntry />} />
          <Route path="/data-entry/manage-overview" element={<ManageDataEntryOverview />} />
          <Route path="/media-library" element={<MediaLibrary />} />
          <Route path="/shipment/quality-control" element={<QualityControl/>} />
          <Route path="/shipment/quality-control/manage" element={<ManageQualityControl/>} />
          <Route path="/shipment/repair" element={<Repair/>} />
          <Route path="/shipment/repair/manage" element={<ManageRepair/>} />
          <Route path="/captcha/manage" element={<ManageRecaptcha/>} />
          <Route path="/laundry-internal" element={<LauntryInternal />}/>
          <Route path="/laundry-internal/stain-treatment" element={<StainTreatmentOrganism />}/>
          <Route path="/laundry-internal/washing" element={<WashingOrganism />}/>
          <Route path="/merchandising/manage" element={<ManageMerchandising />}/>
          <Route path="/merchandising/create" element={<CreateMerchandising />}/>
          <Route path="/quality-assurance/create" element={<QualityAssurance />}/>
          <Route path="/quality-assurance/manage" element={<ManageQualityAssurance />}/>
          <Route path="/quality-assurance/manage" element={<QualityAssurance />}/>
          <Route path="/publish/manage" element={<PrePublish />}/>
          <Route path="/publishing" element={<PrePublish />}/>
          <Route path="/publish/rack" element={<PublishingOverviewOrganism />}/>
          <Route path="/publish/rejected" element={<RejectedPublished />}/>
          <Route path="/finishing/create" element={<CreateFinishing />}/>
          <Route path="/finishing/manage" element={<ManageFinishing />}/>
          <Route path="/laundry-external/batch-overview" element={<ExternalBatchOverview />}/>
          <Route path="/laundry-external/create-batch" element={<ExternalCreateBatch />}/>
          <Route path="/photo-studio/manage" element={<ManagePhotoStudio />}/>
          <Route path="/photo-studio/view" element={<PhotoStudio />}/>
          <Route path="/photo-editing/manage" element={<ManagePhotoEditing />}/>
          <Route path="/photo-editing/view" element={<PhotoEditing />}/>
          <Route path="/reports/operations-overview" element={<ManageHoldItems />}/>
          <Route path="/reports/shipment" element={<ShipmentReports />}/>
          <Route path="/reports" element={<Reports />}/>
          <Route path="/reports/time-tracker-overview" element={<TimeTrackerOverview/>}/>
          <Route path="/reports/view-renewal-record" element={<ViewRenewalRecord />}/>
          <Route path="/shipment/category-sort/create" element={<CategorySort />}/>
          <Route path="/reports/sort-overview" element={<ViewSortReports />}/>
          <Route path="/incactivity-timeout/manage" element={<ManageInactivityTimeout />}/>
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </>
  );
};

export default AppRoutes;
