import React from 'react'

const Reportsidebar: React.FC = () => {
  return (
    <>
    <svg id="Report_an_issue_G_" data-name="Report an issue (G)" xmlns="http://www.w3.org/2000/svg"  width="28" height="28" viewBox="0 0 41.642 37">
  <defs>
    <clipPath id="clipPath">
      <rect id="Rectangle_447" data-name="Rectangle 447" width="41.642" height="37" fill="#545960"/>
    </clipPath>
  </defs>
  <g id="Group_27767" data-name="Group 27767" clipPath="url(#clipPath)">
    <path id="Path_55747" data-name="Path 55747" d="M5.611,37H36.033A5.536,5.536,0,0,0,40.9,34.182h0a5.558,5.558,0,0,0-.044-5.622L25.644,2.752a5.6,5.6,0,0,0-9.643,0L.789,28.56A5.591,5.591,0,0,0,5.611,37M19.2,14.1a1.484,1.484,0,0,1,.423-1.062,1.514,1.514,0,0,1,2.124.012,1.435,1.435,0,0,1,.423,1.051v2.34l-.434,7.791a1,1,0,0,1-.3.682,1.038,1.038,0,0,1-.737.26,1.088,1.088,0,0,1-.748-.26.972.972,0,0,1-.325-.672L19.2,16.446Zm.326,12.86a1.605,1.605,0,0,1,1.17-.5,1.635,1.635,0,0,1,1.191.5,1.6,1.6,0,0,1,.488,1.17,1.664,1.664,0,0,1-.488,1.191,1.623,1.623,0,0,1-1.191.488,1.6,1.6,0,0,1-1.17-.488,1.633,1.633,0,0,1-.5-1.191,1.608,1.608,0,0,1,.5-1.17" transform="translate(0)" fill="#545960"/>
  </g>
</svg>

    </>
  )
}

export default Reportsidebar