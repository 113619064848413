import React, { useState, useRef, useEffect } from "react";
import { APICALL } from '../services/AxiosServices';
import { uploadProfilePicture } from '../routes/ApiEndPoints';
import ProfileImage from "./ProfileImage";
import customAlert from "../components/atoms/CustomAlert";
import Popup from "../utils/popup";
import { t } from "../pages/Translations/TranslationUtils";
interface ProfilePictureUploadProps {
  url: string;
}

const ProfilePictureUpload: React.FC<ProfilePictureUploadProps> = ({ url }) => {
  const imageInputRef = useRef<HTMLInputElement | null>(null);
  const [profilepictureurl, updateProfilePicture] = useState(url);
  const [alert, setAlert] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false)

  const handleEditProfile = () => {
    if (imageInputRef.current) {
      imageInputRef.current.value = '';
      (imageInputRef.current as HTMLInputElement).click();
    }
  };

  //To display alert message
  useEffect(() => {
    const storageMessage = localStorage.getItem("errorAlertMessage");
    if (storageMessage) {
      localStorage.removeItem("errorAlertMessage");
      customAlert("error", t(storageMessage), 8000);
    }
  }, [alert]);

  //To display alert message
  useEffect(() => {
    const storageMessage = localStorage.getItem("profileSuccessAlertMessage");
    if (storageMessage) {
      localStorage.removeItem("profileSuccessAlertMessage");
      customAlert(
        "success",
        t(storageMessage),
        6000
      )
    }
  }, [alert]);

  const handlePhotoChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (file == undefined) return;
    if (file && file.size < 2 * 1024 * 1024) {
      const formdata = new FormData();
      const userid = Number(localStorage.getItem('user_id'));
      formdata.append("profilepicture", file);
      formdata.append("userid", userid.toString());
      let response: any = await APICALL.service(uploadProfilePicture, 'POST', formdata, 1)

      if (response.data.status === 200) {
        localStorage.setItem("successAlertMessage", "Profile picture uploaded successfully");
        setAlert(!alert);
      } else {
        localStorage.setItem("errorAlertMessage", "Error while updating Profile picture");
        setAlert(!alert);
      }
      if (response['data']['path']) {
        localStorage.setItem("profilepicture", response['data']['path']);
        updateProfilePicture(response['data']['path'])
      }
    } else {
      setShowPopup(true)
    }
  };
  return (
    <div className='me-4 position-relative width_maxcontent'>
      <ProfileImage profilepictureurl={profilepictureurl} />
      <input
        type="file"
        accept="image/*"
        className='invisible edit_profile_btn position-absolute'
        onChange={handlePhotoChange}
        ref={imageInputRef}
      />
      <img src='../images/Camera.svg' alt='camera' onClick={handleEditProfile} className='edit_profile_img1 position-absolute' />
      {showPopup &&
        (
          <Popup
            title={t('Upload profile picture')}
            body={t(`Upload a file less than 2 mb`)}
            cancel={() => setShowPopup(!showPopup)}
            submit={() => setShowPopup(!showPopup)}
            yestext={t('Okay')}
          />
        )
      }
    </div>
  );
};

export default ProfilePictureUpload;
