import React from 'react'
import { t } from '../../pages/Translations/TranslationUtils'
import FileReader from '../atoms/FileReader';
interface Props {
    image?: any;
    file?: any;
    message?: string;
    accept?: string;
    classname?: any;
}

const ViewPreviewFile: React.FC<Props> = (props) => {
    return (
        (props.file && props.file != '') ? (
            <div>
                {props.accept && props.accept == ".png, .jpg, .jpeg" ?
                    <div>
                        <div className="mt-2">{t("Preview image")}:</div>
                        <div className="border rounded text-center">
                            <img
                                src={props.file}
                                alt={t("Preview")}
                                className={`${props.classname}`}
                            />
                        </div>
                    </div>
                    :
                    <FileReader
                        fileurl={props.file}
                    />
                }
            </div>
        ) : (
            <p> {props.message}</p>
        )
    )
}

export default ViewPreviewFile;
