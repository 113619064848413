import React, {
  useState,
  ChangeEvent,
  FocusEvent,
  MouseEvent,
  useEffect,
  useRef,
} from "react";
import InputWithButton from "./InputWithButton";
import IconData from "../../static/IconData";
import { t } from "../../pages/Translations/TranslationUtils";
import MultiSelect from "../atoms/MultiSelectField";

interface LabelWithInputFieldProps {
  className?: string;
  handleClick: (param: any) => void;
  error?: boolean;
  search?: boolean;
  select?: any;
}

export const ScannerMolecule: React.FC<LabelWithInputFieldProps> = ({
  className = "form-control",
  handleClick,
  error,
  search = true,
  select = false,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    // To handle page redirection to a stage, where the item belongs to.
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" && inputRef.current) {
        const scannedData = inputRef.current.value;
        if (scannedData && handleClick) {
          handleClick(scannedData);
          inputRef.current.value = "";
        }
      }
    };
    if (inputRef.current) {
      inputRef.current.focus();
    }
    const handleMouseMove = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const modifySelect = (data: any) => {
    let newSelect;
      newSelect = data.map((item: any) => {
        return {
          ...item,
          label: item.title + ": " + item.label,
        };
      });
    return newSelect;
  };

  return (
    <>
      <div className="float-end">
        {search && (
          <>
            <InputWithButton
              handleClick={handleClick}
              inputRef={inputRef}
              textFieldClass="form-control shadow-none"
              buttonClass="submit-btn shadow-none"
              error="Please enter serial number"
            />
            {/* <input
              type="text"
              ref={inputRef}
              style={{
                opacity: "0",
              }}
            /> */}
          </>
        )}
        {select && (
          <>
            <MultiSelect
              placeholder={t("Select shipment")}
              isClearable
              isSearchable
              options={modifySelect(select)}
              className="fs-6 w-200px"
              handleChange={handleClick}
              inputRef={inputRef}
            />
            {/* <input
              type="text"
              ref={inputRef}
              style={{
                opacity: "0",
              }}
            /> */}
          </>
        )}
      </div>
      <div>
        <div className="row w-100 p-5 my-3">
          {IconData.BarCodeScanIcon}
          <span className="text-centre mt-3">{t("Scan now")}</span>
          {error && (
            <span className="text-centre mt-3 text-danger">
              {t("Please scan a valid barcode")}
            </span>
          )}
        </div>
      </div>
    </>
  );
};
