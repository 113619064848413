import {
  createMediaLibraries,
  createMediaTags,
  deleteFiles,
  deleteMediaLibraries,
  deleteMediaTags,
  getMediaTags,
  manageMediaTags,
} from "../routes/ApiEndPoints";
import { APICALL as AXIOSCALL } from "./AxiosServices";
import { APICALL } from "./ApiServices";

const MediaLibraryTagsServices = {
  savetags: async function (
    tags: string[],
    imagePopupId: any,
    setExistingtags?: (tags: string[]) => void,
    setTagInput?: (tag: string) => void,
    setMediaTags?: (tags: string[]) => void
  ) {
    try {
      const postData = {
        method: "POST",
        data: {
          tags: tags,
          medialibrary_id: imagePopupId,
        },
      };

      const response = await APICALL.service(
        createMediaTags,
        "POST",
        postData,
        true
      );

      if (response.status === 200) {
        this.fetchTags(response.file_id, setExistingtags, setMediaTags);
        // this.getAllTags(setMediaTags);
        setTagInput && setTagInput("");
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  },

  fetchTags: async function (
    id: any,
    setExistingtags?: (tags: string[]) => void,
    setMediaTags?: (tags: string[]) => void
  ) {
    try {
      const postData = {
        method: "POST",
        data: {
          medialibrary_id: id,
        },
      };
      const response = await APICALL.service(
        manageMediaTags,
        "POST",
        postData,
        true
      );
      if (response.status == 200) {
        setExistingtags && setExistingtags(response.mediaTags);
        setMediaTags && setMediaTags(response.allTags);
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  },

  deleteTags: async function (
    id: any,
    tagId: any,
    setExistingtags?: (tags: string[]) => void,
    setMediaTags?: (tags: string[]) => void
  ) {
    try {
      const postData = {
        method: "POST",
        data: {
          file_id: id,
          tag_id: tagId,
        },
      };
      const response = await APICALL.service(
        deleteMediaTags,
        "POST",
        postData,
        true
      );
      if (response.status === 200) {
        this.fetchTags(id, setExistingtags, setMediaTags);
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  },

};

export default MediaLibraryTagsServices;
