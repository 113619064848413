import React from 'react'

const Success = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="95" height="95" viewBox="0 0 95 95">
        <g id="success-standard-solid" transform="translate(-3.556 -3.556)">
          <g id="Group_48484" data-name="Group 48484" transform="translate(3.556 3.556)">
            <path id="Path_56332" data-name="Path 56332" d="M51.056,3.556a47.5,47.5,0,1,0,47.5,47.5A47.5,47.5,0,0,0,51.056,3.556ZM82.079,35.113,43.07,74.093,20.032,51.056a4.2,4.2,0,0,1,5.938-5.938L43.129,62.278,76.2,29.235a4.2,4.2,0,0,1,5.938,5.938Z" transform="translate(-3.556 -3.556)" fill="#789070" />
          </g>
        </g>
      </svg>
    </>
  )
}

export default Success

