import React, { useEffect, useState } from "react";
import styles from "../../pages/Shipment/shimpment.module.css";
import InputTextAreaField from "../atoms/InputTextAreaField";
import Button from "../atoms/Button";
import { t } from "../../pages/Translations/TranslationUtils";
import CommonServices from "../../services/CommonServices";
import ErrorMessage from "../atoms/ErrorMessage";
import { useUserContext } from "../../routes/Contextlib";

interface Props {
  commentText: string;
  setCommentText: (text: string) => void;
  commentList: any[];
  setCommentList: (list: any[]) => void;
  handleAddComment: () => void;
  showError: boolean;
  setShowError: (error: boolean) => void;
}

const CommentsBody: React.FC<Props> = ({
  commentText,
  setCommentText,
  commentList,
  setCommentList,
  handleAddComment,
  showError,
  setShowError,
}) => {
  const [usernames, setUsernames] = useState<{ [key: number]: string }>({});
  const { user } = useUserContext();

  // Function to fetch usernames based on IDs
  const fetchUsernames = async (userIds: number[]) => {
    const fetchedUsernames = await CommonServices.fetchUsernamesByIds(userIds);
    setUsernames(fetchedUsernames);
  };

  useEffect(() => {
    if (commentList != undefined) {
      // Extract the 'updated_by' IDs from commentList and fetch usernames
      const createdByIds = commentList?.map((comment) => comment.updated_by);
      fetchUsernames(createdByIds);
    }
  }, [commentList != undefined]);

  return (
    <div className="text-start">
      <div className="border-bottom">
        <div className="">
          <div className={``}>
            <InputTextAreaField
              value={commentText}
              handleChange={(e) => setCommentText(e.target.value)}
              handleClick={() => setShowError(false)}
              customStyle={showError ? { borderColor: "red" } : {}}
            />
            <div className="row">
              <div className="col-6">
                {showError && (
                  <ErrorMessage
                    className="fs-6"
                    errorMessage={t("Field is empty")}
                  />
                )}
              </div>
              <div className="text-end col-6">
                <Button
                  title={t("Comment")}
                  handleClick={handleAddComment}
                  className={`${styles.comment_btn} my-2`}
                />
              </div>
            </div>
          </div>
          <div className={`${styles.commentbox} px-1`}>
            {commentList?.map((comment: any) => (
              <div
                key={comment?.stagewise_comment_id}
                className="border-top mb-2 shadow rounded p-2 px-3"
              >
                <div className={`${styles.commentstagename} row`}>
                  <div className="col-2 mb-2 ">{t("Stage")}:</div>
                  <div
                    className={`col-10 ps-4 text-truncate mb-2 text-gray`}
                    title={CommonServices.capitalizeLabel(
                      comment?.stages?.stage_name
                    )}
                  >
                    {CommonServices.capitalizeLabel(
                      comment?.stages?.stage_name
                    )}
                  </div>

                  <div className="col-2">{t("Comment")}:</div>
                  <div className="col-10 ps-4">
                    <div
                      className={`${styles.commenttext} border rounded text-break p-2`}
                    >
                      {comment?.comments}
                    </div>
                  </div>
                  <div className="col-10 ps-4 ms-auto">
                    <div className="d-flex align-items-center text-gray">
                      <span
                        className={`me-2 text-truncate ${styles.commentedbyname}`}
                        title={usernames[comment?.updated_by]}
                      >
                        {usernames[comment?.updated_by]}
                      </span>
                      <span>{t("on")}</span>
                      <span className={`${styles.commentedbydate} text-nowrap ms-2 `}>
                        {CommonServices.showDateBasedOnFormat(comment.updatedAt, user?.date_format ?? 'dd-MM-yyyy', true)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentsBody;
