import LabelField from "../../../components/atoms/LabelField";
import InputTextAreaField from "../../../components/atoms/InputTextAreaField";
import { InputWithLabel } from "../../../components/molecules/InputWithLabel";
import Calendar_fill from "../../../static/icons/Calendar_fill";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import FormValidation from "../../../services/FormValidation";
import Layout from "../Layout";
import { APICALL } from "../../../services/ApiServices";
import {
  getShipments,
  createShipments,
  fetchConfigData,
} from "../../../routes/ApiEndPoints";
import customAlert from "../../../components/atoms/CustomAlert";
import { t } from "../../Translations/TranslationUtils";
import GenerateBarcodeMolecule from "../../../components/molecules/GenerateBarcodeMolecule";
import Button from "../../../components/atoms/Button";
import {
  CommonConstants,
  ShipmentConstants,
  ShipmentWeightTypes,
} from "../Constants/ShipmentConstants";
import MultiSelect from "../../../components/atoms/MultiSelectField";
import AccessControl from "../../../services/AccessControl";
import { useUserContext } from "../../../routes/Contextlib";
import CommonServices from "../../../services/CommonServices";

export const CreateShipment = () => {
  type ShipmentState = {
    title: string;
    brand_partner_id: number;
    bpName: string;
    date_of_delivery: any;
    pallets: Number;
    cartons: Number;
    items: Number;
    weight: Number;
    weight_type: Number;
    comments: string;
    shipment_id: string;
    created_by: string;
    updated_by: string;
    publish_status: string;
  };

  const setDefaultDate = () => {
    const date = new Date();
    const timezoneOffset = date.getTimezoneOffset() * 60 * 1000; //To set time zone offset
    const formattedDate = new Date(date.getTime() - timezoneOffset)
      .toISOString()
      .slice(0, 10);
    return formattedDate;
  };
  const initialShipmentState: ShipmentState = {
    title: "",
    brand_partner_id: 0,
    bpName: "",
    date_of_delivery: setDefaultDate(),
    pallets: 0,
    cartons: 0,
    items: 0,
    weight: 0.0,
    weight_type: 0,
    comments: "",
    shipment_id: "",
    created_by: String(localStorage.getItem("user_id")) ?? "",
    updated_by: String(localStorage.getItem("user_id")) ?? "",
    publish_status: "",
  };

  interface FormErrors {
    title: string;
    date_of_delivery: string;
    pallets: string;
    cartons: string;
    items: string;
    weight: string;
  }

  const { user, setUser } = useUserContext();
  const navigate = useNavigate();
  const [shipmentState, setShipmentState] =
    useState<ShipmentState>(initialShipmentState);
  const [shipmentDetails, setShipmentDetails] = useState();
  const [errors, setErrors] = useState<FormErrors>({
    title: "",
    date_of_delivery: "",
    pallets: "",
    cartons: "",
    items: "",
    weight: "",
  });
  const [popup, setPopup] = useState({
    viewBarcode: false,
  });
  const [refresh, setRefresh] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const [existingShipments, setExistingShipments] = useState<String[]>([]);
  const editId = searchParams.get("edit");
  const bpDetails: any = JSON.parse(localStorage?.getItem("bp_details") ?? "");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [productGroup, setProductGroup] = useState<string[]>([]);
  const [submitState, setSubmitState] = useState(true);
  const [state, setState] = useState({
    barcodeUrl: "",
    barcode_id: "",
    nextFlowCount: 0,
    initialItems: 0,
    updateitems: 0,
  });
  const [requiredPermissions, setRequiredPermissions] = useState<any>([]);

  useEffect(() => {
    const storageMessage = localStorage.getItem("errorAlertMessage");

    if (storageMessage) {
      localStorage.removeItem("errorAlertMessage");
      customAlert("error", t(storageMessage), 6000);
    }
  }, [refresh]);

  useEffect(() => {
    fetchShipment();
    fetchProductGroup();
    setShipmentState((prevValues) => ({
      ...prevValues,
      shipment_id: editId ?? "",
      brand_partner_id: bpDetails.brand_partner_id,
    }));
  }, []);

  async function fetchShipment() {
    try {
      const postData: any = {
        bpName: bpDetails?.title,
        brand_partner_id: bpDetails?.brand_partner_id,
      };
      if (editId) {
        postData.shipment_id = editId;
      }
      const response = await APICALL.service(getShipments, "POST", postData);
      if (response.status === 200) {
        if (editId) {
          Object.values(response.data)
            .filter((item: any) => item.shipment_id == editId)
            .map((item1: any) => {
              formatDate(item1.dateof_delivery);
              setState((prev) => ({
                ...prev,
                nextFlowCount: response?.nextFlowCount,
                initialItems: item1.items,
              }));
              setShipmentState((prevValues) => ({
                ...prevValues,
                title: item1.title,
                pallets: item1.pallets,
                cartons: item1.cartons,
                items: item1.items,
                weight: item1.weight,
                weight_type: item1.weight_type,
                comments: item1.comment,
                publish_status: item1.publish_status,
              }));
            });
        }
        let shipmentArray;

        if (editId) {
          shipmentArray = Object.values(response.data)
            .filter((item: any) => item.shipment_id != editId)
            .map((item1: any) => {
              return item1.title;
            });
        } else {
          shipmentArray = Object.values(response.data).map((item: any) => {
            return item.title;
          });
        }
        setExistingShipments(shipmentArray);
        setShipmentDetails(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const fetchProductGroup = async () => {
    try {
      const postData = {
        method: "POST",
        data: { type: "model", name: "ProductGroup" },
      };
      const response = await APICALL.service(
        fetchConfigData,
        "POST",
        postData,
        true
      );

      if (response.status == "200") {
        let responseData = response.data;

        let bp_product_group = JSON.parse(
          localStorage.getItem("bp_product_group") ?? ""
        );

        const products: string[] = responseData
          .filter((item: any) =>
            bp_product_group?.includes(item.product_group_id)
          )
          .map((item: any) => item.title);
        setProductGroup(products);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const prepareShipment = () => {
    let data = {
      title: shipmentState.title,
      brand_partner_id: Number(shipmentState.brand_partner_id),
      bpName: bpDetails?.title,
      date_of_delivery: shipmentState.date_of_delivery,
      pallets: Number(shipmentState.pallets),
      cartons: Number(shipmentState.cartons),
      items: Number(shipmentState.items),
      weight: Number(shipmentState.weight),
      weight_type: Number(shipmentState.weight_type),
      comments: shipmentState.comments,
      shipment_id: Number(shipmentState.shipment_id),
      created_by: Number(shipmentState.created_by),
      updated_by: Number(shipmentState.updated_by),
    };
    return data;
  };

  async function createShipment(changePage: boolean) {
    try {
      const prepared: any = prepareShipment();
      let validateitems: any = 0;
      if (editId) {
        validateitems = await validateItemCount();
        prepared.updateitems = validateitems;
      }

      if (validateitems >= 0) {
        const response = await APICALL.service(
          `${createShipments}`,
          "POST",
          prepared
        );

        if (response.status === 200) {
          setState((prev) => ({
            ...prev,
            barcodeUrl: response.data[0],
            barcode_id: response?.data[1],
          }));
          const userPerm: any = user?.permissions;
          const truePerm: any = userPerm?.find(
            (item: any) => item.permission.toLowerCase().trim() === "shipment"
          );
          let message = `Shipment ${
            editId ? "updated" : "created"
          } successfully`;
          changePage
            ? customAlert("success", t(message), 6000)
            : localStorage.setItem("successAlertMessage", message);
          changePage && setPopup((item) => ({ ...item, viewBarcode: true }));
          if (truePerm?.read === true) {
            changePage ? setSubmitState(false) : navigate("/shipment/manage");
          } else {
            changePage
              ? setSubmitState(false)
              : navigate(
                  localStorage?.getItem("shipment_dashboard_url") ??
                    "/shipment-dashboard"
                );
          }
        } else {
          customAlert("error", t("Error while creating shipment"), 6000);
        }
        setRefresh(!refresh);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    let { name, value } = e.target;
    let val: any;

    if (name === ("title" || "date_of_delivery" || "comments")) {
      val = value;
    } else {
      val = Number(value);
    }

    setShipmentState((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleInputChangeWithLabel = (e: any) => {
    if (e !== null) {
      const { name, value } = e;
      setShipmentState((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const formatDate = (dateValue: any) => {
    removeError("date_of_delivery");
    if (dateValue !== null) {
      setSelectedDate(new Date(dateValue));
      const date = new Date(dateValue);
      const timezoneOffset = date.getTimezoneOffset() * 60 * 1000; //To set time zone offset
      const formattedDate = new Date(date.getTime() - timezoneOffset)
        .toISOString()
        .slice(0, 10);

      setShipmentState((prevValues) => ({
        ...prevValues,
        date_of_delivery: formattedDate,
      }));
    }
  };

  const validateItemCount = async () => {
    let updatecounts: any;
    if (Number(shipmentState.items) >= state.initialItems) {
      updatecounts =
        state.nextFlowCount +
        (Number(shipmentState.items) - state.initialItems);
    } else {
      if (
        state.nextFlowCount <
        state.initialItems - Number(shipmentState.items)
      ) {
        const min = state.initialItems - state.nextFlowCount;
        let error = FormValidation.isNumberValidation(
          shipmentState.items,
          min,
          10000
        );
        setErrors((prev) => ({ ...prev, items: error }));
        return -1;
      } else {
        updatecounts =
          state.nextFlowCount -
          (state.initialItems - Number(shipmentState.items));
      }
    }
    return updatecounts;
  };

  const isValidated = async () => {
    let valStatus = false;

    let errorObj: FormErrors = {
      title: FormValidation.nameValidation(shipmentState.title),
      date_of_delivery: FormValidation.emptyValidation(
        shipmentState.date_of_delivery
      ),
      pallets:
        shipmentState.pallets != null
          ? FormValidation.isNumberValidation(shipmentState.pallets, 0, 10000)
          : "",
      cartons:
        shipmentState.cartons != null
          ? FormValidation.isNumberValidation(shipmentState.cartons, 0, 10000)
          : "",
      items:
        shipmentState.items != null
          ? FormValidation.isNumberValidation(shipmentState.items, 0, 10000)
          : "",
      weight:
        shipmentState.weight != null
          ? FormValidation.isDecimalValidation(shipmentState.weight, 0, 10000)
          : "",
    };
    if (existingShipments.includes(shipmentState.title)) {
      errorObj.title = t("Title already exists");
    }
    setErrors(errorObj);

    if (
      errorObj.title == "" &&
      errorObj.pallets == "" &&
      errorObj.cartons == "" &&
      errorObj.items == "" &&
      errorObj.weight == ""
    ) {
      valStatus = true;
    } else {
      valStatus = false;
    }
    return valStatus;
  };

  const handleSubmit = async (event: React.FormEvent, changePage: boolean) => {
    event.preventDefault();
    const valid = await isValidated();
    if (valid) {
      createShipment(changePage);
    }
  };

  const removeError = (fieldName: string) => {
    setErrors((prevValues) => ({
      ...prevValues,
      [fieldName]: "",
    }));
  };

  const changeEmptyToZero = (value: any, name: any) => {
    console.log();

    if (value === "") {
      value = 0;
    }
    setShipmentState((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  useEffect(() => {
    let permissions = [
      {
        permission: "shipment",
        create: true,
        update: true,
      },
    ];
    if (editId) {
      permissions[0].create = false;
    } else {
      permissions[0].update = false;
    }
    setRequiredPermissions(permissions);
  }, [editId]);

  const handlePrint = async (data: any) => {
    try {
      const postdata = {
        printerName: data?.inputValue.trim(),
        labelType: "LPN",
        labelData: {
          licensePlateNumber: state.barcode_id,
          clientId: bpDetails?.wms_bp_id,
          shipmentDate: CommonServices.showDateBasedOnFormat(
            shipmentState?.date_of_delivery,
            "dd/MM/yyyy"
          ),
          palletCount: Number(shipmentState?.pallets),
          cartonCount: Number(shipmentState?.cartons),
          printCount: data.printCount,
          totalPrintCount: data.totalPrintCount,
        },
      };
      let error: any = validatepostdata(postdata);
      if (!error) {
        let res: any = await CommonServices.printLabelApi(postdata);
        res?.message && customAlert(res?.type, t(res?.message), 6000);
        if (res.statusCode === 201) {
          setPopup((prev) => ({ ...prev, viewBarcode: false }));
        }
      } else {
        let errortext = Object.keys(error)
          .filter((key) => error[key] !== "")
          .map((key) => `<div>${t(error[key])}</div>`)
          .join("");
        customAlert("error", errortext, 6000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validatepostdata = (data: any) => {
    let error = {
      printerName: FormValidation.nameValidation(
        data?.printerName,
        255,
        "Printer name"
      ),
      licensePlateNumber: FormValidation.nameValidation(
        data?.labelData?.licensePlateNumber,
        8,
        "License plate number"
      ),
      clientId: FormValidation.nameValidation(
        data?.labelData?.clientId,
        255,
        "ClientId"
      ),
      shipmentDate: FormValidation.dateValidation(
        data?.labelData?.shipmentDate,
        /^\d{2}\/\d{2}\/\d{4}$/,
        "Shipment date"
      ),
    };
    return Object.values(error)?.some((item) => item !== "") ? error : false;
  };
  return (
    <AccessControl
      requiredPermissions={requiredPermissions}
      renderNoAccess={true}
    >
      <Layout pagename={t("Create shipment")} logo={true} timer={false}>
        <form className="d-flex flex-column h-100 overflow-auto">
          <div className="create-form p-4 flex-1 overflow-auto">
            <div className="row m-0">
              <div className="col-md-10 m-auto">
                <div className="row">
                  {/* Title */}
                  {submitState ? (
                    <>
                      <div className="col-12 mb-2 mb-xxl-3">
                        <div>
                          <InputWithLabel
                            label={t("Title")}
                            name="title"
                            value={shipmentState.title}
                            handleChange={handleInputChange}
                            error={errors.title}
                            handleClick={() => removeError("title")}
                            mandatory={true}
                          />
                        </div>
                      </div>
                      {/* Product group */}
                      <div className="col-8 mb-2 mb-xxl-3">
                        <div className="formselect">
                          <LabelField
                            title={t("Product group")}
                            mandatory={false}
                          />
                          <div className="d-flex flex-wrap bg-white p-1 rounded px-2 gap-2 mb-3 min-h45">
                            {productGroup &&
                              productGroup.map((item: any) => {
                                return (
                                  <span className="text-gray text-break py-1 px-3 rounded  bg_color_raven_gray">
                                    {item}
                                  </span>
                                );
                              })}
                          </div>
                        </div>
                      </div>

                      {/* Date of delivery */}
                      <div className="col-4 mb-2 mb-xxl-3">
                        <LabelField
                          title={t("Date of delivery")}
                          mandatory={true}
                        />
                        <div className="date_of_delivery date-container">
                          <DatePicker
                            autoComplete="off"
                            name="date_of_delivery"
                            dateFormat={user?.date_format}
                            placeholderText={user?.date_format}
                            selected={selectedDate}
                            onChange={(dateValue) => formatDate(dateValue)}
                            // minDate={new Date()}
                            yearDropdownItemNumber={4}
                            showMonthDropdown
                            showYearDropdown
                            calendarStartDay={1}
                            dropdownMode="select"
                            className="ps-2 w-100"
                            onKeyDown={(e: any) => {
                              if (
                                e.target.value.length >= 10 &&
                                e.key !== "Backspace" &&
                                e.key !== "Delete"
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                          <span className="cal-open-button">
                            <Calendar_fill />
                          </span>
                          {errors.date_of_delivery && (
                            <span className="text-danger">
                              {errors.date_of_delivery}
                            </span>
                          )}
                        </div>
                      </div>

                      {/* Pallets */}
                      <div className="col-3 mb-2 mb-xxl-3">
                        <div>
                          <InputWithLabel
                            label={t("Pallets")}
                            type="text"
                            name="pallets"
                            value={String(shipmentState.pallets)}
                            handleChange={handleInputChange}
                            error={errors.pallets}
                            handleClick={() => removeError("pallets")}
                            handleBlur={() =>
                              changeEmptyToZero(
                                shipmentState.pallets,
                                "pallets"
                              )
                            }
                          />
                        </div>
                      </div>

                      {/*Cartons*/}
                      <div className="col-3 mb-2 mb-xxl-3">
                        <div>
                          <InputWithLabel
                            label={t("Cartons")}
                            type="text"
                            name="cartons"
                            value={shipmentState.cartons}
                            handleChange={handleInputChange}
                            error={errors.cartons}
                            handleClick={() => removeError("cartons")}
                            handleBlur={() =>
                              changeEmptyToZero(
                                shipmentState.cartons,
                                "cartons"
                              )
                            }
                          />
                        </div>
                      </div>

                      {/* Items */}
                      <div className="col-3 mb-2 mb-xxl-3">
                        <div>
                          <InputWithLabel
                            label={t("Items")}
                            type="text"
                            name="items"
                            value={String(shipmentState.items)}
                            handleChange={handleInputChange}
                            error={errors.items}
                            readOnly={["inactive", "published"].includes(
                              shipmentState?.publish_status
                            )}
                            handleClick={() => removeError("items")}
                            handleBlur={() =>
                              changeEmptyToZero(shipmentState.items, "items")
                            }
                          />
                        </div>
                      </div>

                      {/* Weight */}
                      <div className="col-3 mb-2 mb-xxl-3">
                        <div className="row">
                          <div className="col-6">
                            <InputWithLabel
                              label={t("Weight")}
                              type="text"
                              name="weight"
                              value={String(shipmentState.weight)}
                              handleChange={handleInputChange}
                              error={errors.weight}
                              handleClick={() => removeError("weight")}
                              handleBlur={() =>
                                changeEmptyToZero(
                                  shipmentState.weight,
                                  "weight"
                                )
                              }
                            />
                          </div>
                          <div className="col-6 mt-4 pt-2">
                            <MultiSelect
                              name={"weight_type"}
                              className="border border-0"
                              isClearable={false}
                              isSearchable
                              standards={ShipmentWeightTypes.filter(
                                (option: any) =>
                                  option.value === shipmentState.weight_type
                              )}
                              options={ShipmentWeightTypes}
                              handleChange={(e) =>
                                handleInputChangeWithLabel(e)
                              }
                            />
                          </div>
                        </div>
                      </div>

                      {/* comments */}
                      <div className="col-12 ">
                        <div>
                          <LabelField title={t("Comments")} mandatory={false} />
                          <InputTextAreaField
                            name="comments"
                            value={shipmentState.comments}
                            handleChange={handleInputChange}
                            // error={errors.comments}
                            handleClick={() => removeError("comments")}
                          />
                        </div>
                      </div>
                      {/* end of row */}
                    </>
                  ) : (
                    <GenerateBarcodeMolecule
                      popup={popup}
                      setPopup={setPopup}
                      handlePrint={handlePrint}
                      barcodeUrl={state.barcodeUrl}
                    />
                  )}
                </div>
                {/* end of row */}
              </div>
            </div>
          </div>

          {/* buttons */}
          <div className="d-flex justify-content-between mt-2">
            {submitState ? (
              <>
                <Button
                  handleClick={() =>
                    navigate(
                      localStorage?.getItem("shipment_dashboard_url") ??
                        "/shipment-dashboard"
                    )
                  }
                  className="back-btn shadow-none "
                  title={t(CommonConstants.BACK)}
                />
                <div>
                  {!editId && (
                    <Button
                      className="submit-btn shadow-none mx-2"
                      title={t(CommonConstants.SAVE_AND_GENERATE_BARCODE)}
                      handleClick={(event) => handleSubmit(event, true)}
                    />
                  )}
                  <Button
                    className="submit-btn shadow-none mx-2"
                    title={t(CommonConstants.SAVE)}
                    handleClick={(event) => handleSubmit(event, false)}
                  />
                </div>
              </>
            ) : (
              <>
                <Button
                  className="back-btn shadow-none"
                  title={t(CommonConstants.BACK_TO_DASHBOARD)}
                  handleClick={() =>
                    navigate(
                      localStorage?.getItem("shipment_dashboard_url") ??
                        "/shipment-dashboard"
                    )
                  }
                />
                <AccessControl
                  requiredPermissions={[
                    {
                      permission: "shipment",
                      read: true,
                    },
                  ]}
                >
                  <Button
                    className="submit-btn shadow-none"
                    title={t(ShipmentConstants.MANAGE_SHIPMENT)}
                    handleClick={() => navigate("/shipment/manage")}
                  />
                </AccessControl>
              </>
            )}
          </div>
        </form>
      </Layout>
    </AccessControl>
  );
};
