import React from 'react'

const Createrole: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="43.783" height="41.285" viewBox="0 0 43.783 41.285">
  <g id="Create_role_G_" data-name="Create role (G)" transform="translate(-12680.58 -8354.012)">
    <g id="Group_48682" data-name="Group 48682" transform="translate(12711.756 8382.689)">
      <path id="Create_user" data-name="Create user" d="M35.184,37.447a6.3,6.3,0,1,1,6.3,6.3,6.3,6.3,0,0,1-6.3-6.3Zm3.149.631h2.521V40.6h1.261V38.078h2.527V36.817H42.118V34.3H40.857v2.521H38.336Z" transform="translate(-35.184 -31.144)" fill="#545960"/>
    </g>
    <g id="Group_48559" data-name="Group 48559" transform="translate(12680.58 8354.012)">
      <path id="Subtraction_742" data-name="Subtraction 742" d="M10.9,19.76a9.107,9.107,0,0,1-2.285-.325A9.946,9.946,0,0,1,5.25,17.789a10.1,10.1,0,0,1-2.809-3.308,9.084,9.084,0,0,1-1.094-3.545A9.859,9.859,0,0,1,2.463,5.21c.158-.3.161-.3-.083-.525C1.77,4.121,1.156,3.56.562,3.017L.515,2.974a1.4,1.4,0,0,1-.473-.689A1.058,1.058,0,0,1,.56,1.032a1.128,1.128,0,0,1,.57-.15,1.276,1.276,0,0,1,.856.331C2.587,1.771,3.2,2.335,3.8,2.88l.048.043.015.014c.08.071.12.106.157.106s.074-.036.145-.109l.008-.008A9.253,9.253,0,0,1,7.875.577,10.741,10.741,0,0,1,10.326.041c.292-.028.59-.041.885-.041a9.957,9.957,0,0,1,4.037.859,9.885,9.885,0,0,1,5.336,5.979,9.08,9.08,0,0,1,.31,1.269,7.768,7.768,0,0,0-3.389,9.373,9.9,9.9,0,0,1-3.389,1.84,9.119,9.119,0,0,1-2.838.432h-.092C11.1,19.757,11.007,19.76,10.9,19.76ZM8.918,7.838a.172.172,0,0,0-.076.024c-.07.039-.143.075-.214.11l-.015.007-.142.072a2.981,2.981,0,0,0-1.711,2.662c0,.046,0,.091,0,.136v.011c0,.112-.005.227-.014.34l-.026.372-.007.1c-.05.715-.1,1.453-.164,2.176a1.326,1.326,0,0,0,.394,1.066c.215.233,2.178.337,4.027.337,1.991,0,4.237-.118,4.466-.377A1.243,1.243,0,0,0,15.8,14c-.009-.241-.029-.484-.048-.718v-.01c-.011-.137-.023-.279-.032-.418-.026-.4-.05-.792-.077-1.23q-.025-.41-.051-.82A3.379,3.379,0,0,0,15.3,9.511a3.45,3.45,0,0,0-1.91-1.7h0l-.019.036a.266.266,0,0,0-.028.058,1.96,1.96,0,0,1-.7,1,2.316,2.316,0,0,1-1.444.537,2.486,2.486,0,0,1-.982-.216A2.148,2.148,0,0,1,9.015,7.935C8.989,7.867,8.96,7.838,8.918,7.838Zm2.223-5.715A2.818,2.818,0,0,0,8.366,4.955a2.829,2.829,0,0,0,2.809,2.774h0a2.8,2.8,0,0,0,.081-5.606h-.117Z" transform="translate(12.684 20.117)" fill="#545960"/>
      <path id="Path_56422" data-name="Path 56422" d="M44.407,116.713a1.9,1.9,0,0,0-.937-.572,1.814,1.814,0,0,0-2.266,1.885,1.713,1.713,0,0,0,.558,1.135c.041.04.083.079.123.121.134.135.135.136-.026.249a8.93,8.93,0,0,1-1.874.934,7.036,7.036,0,0,1-2.069.455,8.582,8.582,0,0,1-1.834-.076,8.085,8.085,0,1,1,6.825-13.8c.236.223.447.473.663.716.062.069.107.039.16,0,.434-.307.872-.611,1.3-.923.583-.421,1.16-.85,1.742-1.273a.821.821,0,0,1,.855-.1.767.767,0,0,1,.489.732.852.852,0,0,1-.365.73c-.378.288-.761.568-1.143.848q-.853.623-1.711,1.243c-.089.064-.183.123-.276.182-.066.041-.091.079-.052.162a8.163,8.163,0,0,1,.773,4.108,8.013,8.013,0,0,1-.589,2.449c-.068.169-.137.337-.21.5-.039.09-.085.177-.14.292Zm-10.884-1.089.022,0a1.8,1.8,0,0,1,0,.273.978.978,0,0,0,.806,1c.611.116,5.6.238,6.045-.1a1.232,1.232,0,0,0,.513-1.2c-.07-.6-.084-1.212-.14-1.817a5.784,5.784,0,0,0-.166-1.132,2.565,2.565,0,0,0-1.506-1.584c-.093-.043-.134-.023-.169.071a1.763,1.763,0,0,1-.5.708,1.825,1.825,0,0,1-2.876-.591c-.108-.215-.112-.22-.33-.113a2.436,2.436,0,0,0-1.534,2.136c-.017.3-.02.594-.04.89-.036.487-.081.973-.121,1.46Zm3.684-9.114a2.229,2.229,0,1,0,.029,4.457,2.228,2.228,0,1,0-.029-4.456Z" transform="translate(-29.193 -95.745)" fill="#545960"/>
      <path id="Path_56423" data-name="Path 56423" d="M171.571,38.62c.265-.21.523-.406.775-.614a1.286,1.286,0,0,0,.5-1.07,1.251,1.251,0,0,0-.733-1.149,1.219,1.219,0,0,0-1.3.083c-.168.111-.327.236-.49.352-.058.041-.12.076-.2.13a6.865,6.865,0,0,1-.507-2.973,7.037,7.037,0,0,1,2.662-5.251,7.212,7.212,0,1,1-.7,10.491Zm8.33-2.147c-.008-.12-.015-.239-.023-.358-.033-.5-.055-.995-.1-1.49a3.211,3.211,0,0,0-.161-.8,2.278,2.278,0,0,0-1.181-1.219c-.116-.06-.169-.055-.221.076a1.452,1.452,0,0,1-.779.808,1.543,1.543,0,0,1-2.041-.806c-.045-.1-.084-.135-.189-.079a3.021,3.021,0,0,0-.936.7,1.388,1.388,0,0,0-.363.769c-.076.8-.125,1.593-.2,2.389a.88.88,0,0,0,.368.822c.082.066.169.127.257.184.724.478,4.555.291,5.108-.1a.887.887,0,0,0,.467-.9Zm-3.1-7.715a1.892,1.892,0,0,0-1.862,1.978,1.934,1.934,0,0,0,1.854,1.788,1.883,1.883,0,1,0,.008-3.766Z" transform="translate(-153.435 -26.499)" fill="#545960"/>
    </g>
  </g>
</svg>

  )
}

export default Createrole