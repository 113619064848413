import { useEffect, useState } from 'react'
import { t } from '../../../pages/Translations/TranslationUtils'
import BatchInfo from '../../molecules/LaundryExternal/BatchInfo'
import Layout from '../../../pages/Shipment/Layout'
import Button from '../../atoms/Button'
import { Add } from '../../../static/icons/shipment/Add'
import styles from '../organisms.module.css';
import { useNavigate } from 'react-router-dom'
import { APICALL } from '../../../services/ApiServices'
import { createBatch, fetchBatch, fetchConfigData } from '../../../routes/ApiEndPoints'
import CommonShipmentServices from '../../../services/CommonShipmentServices'
import CommonServices from '../../../services/CommonServices'
import { CommonConstants } from '../../../pages/Shipment/Constants/ShipmentConstants'

interface Data {
    batches: any;
    externalvendor: any;
    activeTab: string;
}
const ExternalBatchOverviewOrganism: React.FC = () => {
    const navigate = useNavigate();
    const localdata = CommonShipmentServices.getPostData();
    const [data, setData] = useState<Data>({ // state object
        batches: null,
        externalvendor: null,
        activeTab: 'ALL'
    });

    useEffect(() => { // fetching the batch info and external vendors when the component loads
        fetchAPI();
    }, []);

    const fetchAPI = async () => {
        try {
            // calling API for fetching the batches info
            const response = await APICALL.service(fetchBatch, 'POST', localdata);
            if (response?.status === 200) {
                let reqdata = {
                    method: 'POST',
                    data: {
                        type: 'model',
                        name: 'ExternalVendor',
                        status: true
                    }
                }
                // calling API for fetching the external vendors
                const config = await APICALL.service(fetchConfigData, 'POST', reqdata, true);
                if (config?.status === 200) {
                    setData({
                        ...data,
                        batches: response?.data,
                        externalvendor: CommonServices.sortAlphabattically(config?.data ?? []),
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleCreate = async () => { // creating the batch on click of create button
        try {
            let postdata = { // making the title to be prefilled by the number of batches (Batch 03)
                ...localdata,
                title: t('Batch') + ' ' + (CommonServices.convertNumberIntoTwoDigits(data?.batches?.length + 1) ?? '01'),
            }

            const response = await APICALL.service(createBatch, 'POST', postdata);

            if (response?.status === 200) { // redirecting after creating the batch
                navigate(`/laundry-external/create-batch?id=${response?.data?.external_laundry_batch_id}`)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const tabSwitch = (tab: string) => {
        setData((prevState: any) => ({
            ...prevState,
            activeTab: tab
        }))
    }

    const filteredData = data.batches?.filter((value: any) => {
        if (data.activeTab === "ARD") {
            return value.batch_status === "ARD";
        } else if (data.activeTab === "ALL") {
            return value.batch_status !== "ARD";
        }
    });

    return (
        <Layout pagename={('Laundry-External')} timer={false} logo={true}>
            <div className="d-flex justify-content-between mt-2">
                <div className="user-tabs">
                    <Button
                        className={`border-0 tab-btn me-4 bg-transparent ${data.activeTab === 'ALL' ? 'active' : ''}`}
                        handleClick={() => tabSwitch('ALL')}
                    >
                        {t("Ongoing")}
                    </Button>
                    <Button
                        className={`border-0 tab-btn bg-transparent ${data.activeTab === 'ARD' ? 'active' : ''}`}
                        handleClick={() => tabSwitch('ARD')}
                    >
                        {t("Arrived")}
                    </Button>
                </div>
                <div className="col-auto">
                    <Button
                        className={`rounded d-flex align-items-center submit-btn ${styles.createbatch}`}
                        handleClick={handleCreate}
                    >
                        <Add />
                        {t("Create new batch")}
                    </Button>
                </div>
            </div>
            <div className="d-flex flex-wrap  flex-1 overflow-auto mt-2">
                { filteredData?.length ? 
                    filteredData?.map((item: any) => (
                        <div key={item.external_laundry_batch_id} className={`${styles.external_laundary_batchinfocard} p-3 position-relative`}>
                            <BatchInfo item={item} fetch={fetchAPI} externalvendor={data.externalvendor} />
                        </div>
                    ))
                :
                    <div>
                        {t("No batches found")}
                    </div>
                }
            </div>
            <div className="mt-2">
                <Button
                    title={t(CommonConstants.BACK)}
                    className="back-btn shadow-none "
                    handleClick={() => navigate(localStorage?.getItem('shipment_dashboard_url') ?? "/shipment-dashboard")}
                />
            </div>
        </Layout>
    )
}

export default ExternalBatchOverviewOrganism;