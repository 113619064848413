import React from 'react'

export const Add = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="74.533" height="74.534" viewBox="0 0 74.533 74.534">
        <path id="Add_widget_v02" data-name="Add widget_v02" d="M-156.4,15270.534h-60.667A6.943,6.943,0,0,1-224,15263.6v-60.666a6.935,6.935,0,0,1,6.933-6.936H-156.4a6.935,6.935,0,0,1,6.933,6.936v60.666A6.943,6.943,0,0,1-156.4,15270.534Zm-51.86-39.962a2.7,2.7,0,0,0-2.692,2.692,2.693,2.693,0,0,0,2.692,2.692h18.84v18.837a2.694,2.694,0,0,0,2.692,2.7,2.694,2.694,0,0,0,2.692-2.7v-18.837H-165.2a2.693,2.693,0,0,0,2.692-2.69,2.693,2.693,0,0,0-2.692-2.691l-18.833,0v-18.834a2.694,2.694,0,0,0-2.692-2.692,2.694,2.694,0,0,0-2.692,2.692v18.834Z" transform="translate(224 -15196)" fill="rgba(84,89,96,0.6)" />
      </svg>
    </>
  )
}


