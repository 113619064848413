import React from 'react'

export const Upload = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26.998 25.678">
        <g id="download" transform="translate(-5.072 -5.998)">
          <path id="Path_56155" data-name="Path 56155" d="M30.386,23.815H24.394a1.682,1.682,0,0,0-1.6,1.166,4.434,4.434,0,0,1-8.441,0,1.683,1.683,0,0,0-1.6-1.166H6.756A1.684,1.684,0,0,0,5.072,25.5v9.6a1.684,1.684,0,0,0,1.684,1.683h23.63A1.684,1.684,0,0,0,32.07,35.1V25.5A1.683,1.683,0,0,0,30.386,23.815Z" transform="translate(0 -5.11)" fill="#545960" />
          <path id="Path_56156" data-name="Path 56156" d="M21.586,6.187A.454.454,0,0,1,21.954,6h0a.451.451,0,0,1,.368.191l4.657,6.55a.453.453,0,0,1-.37.715H24.42v5.884a.453.453,0,0,1-.453.454H19.912a.453.453,0,0,1-.453-.454V13.454H17.267a.453.453,0,0,1-.368-.715Z" transform="translate(-3.367 0)" fill="#545960" />
        </g>
      </svg>
    </>
  )
}
