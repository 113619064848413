import React, { useState } from 'react'
import Button from '../../atoms/Button';
import { t } from '../../../pages/Translations/TranslationUtils';
import { CommonConstants } from '../../../pages/Shipment/Constants/ShipmentConstants';
import styles from '../molecules.module.css';
import CommonServices from '../../../services/CommonServices';
import ErrorMessage from '../../atoms/ErrorMessage';
import FormValidation from '../../../services/FormValidation';
import LabelField from '../../atoms/LabelField';

interface Selected {
    [key: string]: any[];
}
interface Props {
    data: any;
    type: string;
    index: number;
    selecteddata: any;
    length: number;
    handleStep: (index: number, action: string) => void;
    handleClick: (type: string, selected: any) => void;
    timer: boolean;
}

const AddIssueInfo: React.FC<Props> = ({
    data,
    handleStep,
    type,
    index,
    handleClick,
    selecteddata,
    length,
    timer
}) => {
    const [selected, setSelected] = useState<Selected | any>(selecteddata);
    const [error, setError] = useState('');

    const handleSelected = (item: any) => {
        if (timer) {
            setSelected(item);
            handleClick(type, item);
        }
    }

    const handleNextOrBack = (step: number, action: string) => {
        if (timer) {
            let error = '';
            if (step === -1 && action === 'back') {
                handleStep(step, action);
            } else {
                if (action === 'next' && step != 1) {
                    error = FormValidation.selectValidation(selected);
                    setError(error);
                }
                if (error == '') {
                    if (step < length && step >= 0) {
                        handleStep(step, action);
                    } else if (step != -1) {
                        handleStep(-1, action);
                    }
                    setSelected(null);
                }
            }
        }
    }

    return (
        <>
            <div>
                <b>
                    <LabelField
                        title={t(CommonServices.capitalizeLabel(type))}
                        className='text-dark'
                        mandatory={type !== 'issue_size'}
                    />
                </b>
                <div className={`${styles.height_25}`}>
                    <ErrorMessage errorMessage={t(error)} />
                </div>
            </div>
            <div className={`${styles.qaissues_box}`}>
                <div className='d-flex flex-wrap gap-3'>
                    {data?.map((item: any) => (
                        <div key={item[type + '_id']}
                            className={`${styles.color_btn} rounded text-break 
                        ${selected && selected[type + '_id'] === item[type + '_id'] ? styles.activeItem : ''} 
                    `}
                            onClick={() => handleSelected(item)}
                        >
                            {item.title}
                        </div>
                    ))}
                </div>
            </div>
            <div className='d-flex justify-content-between mt-3 mt-xxl-5'>
                <Button
                    title={t(CommonConstants.BACK)}
                    handleClick={() => handleNextOrBack(index - 1, 'back')}
                    className='back-btn'
                />
                <Button
                    title={t(CommonConstants.SAVE_AND_NEXT)}
                    handleClick={() => handleNextOrBack(index + 1, 'next')}
                    className='submit-btn'
                />
            </div>
        </>
    )
}

export default AddIssueInfo
