import React, { ChangeEvent } from "react";
import { Modal, Button } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import styles from "./ImagePopup.module.css";
import InputTextfield from "../../atoms/InputTextField";
import Close from "../../../static/icons/Close";
import { t } from "../../../pages/Translations/TranslationUtils";
interface PopupProps {
  title: string;
  body: React.ReactNode;
  cancel?: () => void;
  submit?: () => void;
  handleRemoveTags?: (id: any) => void;
  handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  selectList?: (title: string) => void;
  change: (event: ChangeEvent<HTMLInputElement>) => void;
  tagInput: string;
  imageUrl: string;
  yestext: string;
  notext: string;
  data: any;
  suggestions: any;
  autoComplete: boolean;
  error?: any;
  highlightSuggestion?: any;
}

class MediaLibraryImagePopup extends React.Component<PopupProps> {
  constructor(props: PopupProps) {
    super(props);
    this.state = {
      status: 0,
    };
  }

  render() {
    const {
      title,
      imageUrl,
      body,
      cancel,
      submit,
      yestext,
      notext,
      change,
      tagInput,
      data,
      suggestions,
      selectList,
      handleKeyDown,
      handleRemoveTags,
      autoComplete,
      error,
      highlightSuggestion
    } = this.props;

    return (
      <Modal size="lg" show={true} centered>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <Button
            variant="link"
            className="btn-close shadow-none mt-1"
            onClick={cancel}
            aria-label={t("Close")}
            title={t("Close")}
          >
            <AiFillCloseCircle />
          </Button>
        </Modal.Header>
        <Modal.Body className={`text-center mb-4`}>
          {/* {body} */}
          <img className={`w-100 ${styles.mediamodalimg}`} src={imageUrl} />
        </Modal.Body>
        <Modal.Footer className={`border-0 mb-3 p-0`}>
          <div className="w-100">
            <InputTextfield
              type="text"
              className="form-control w-100"
              name="inputTags"
              value={tagInput}
              handleChange={change}
              handleKeyDown={handleKeyDown}
              autoComplete={autoComplete}
              error={error}
            />

            {suggestions && suggestions.length > 0 && (
              <ul className={`mt-2 ${styles.tagsuggestions}`}>
                {suggestions.map((item: any) => (
                  <ul className="list-group" key={item.media_tag_id}>
                    <li
                      className={`list-group-item text-break ${item.media_tag_id == highlightSuggestion?.media_tag_id ? 'bg-danger' : 'sdf'}`}
                      onClick={() => selectList && selectList(item)}
                      key={item.media_tag_id}
                      id={item.media_tag_id}
                    >
                      {item.title}
                    </li>
                  </ul>
                ))}
              </ul>
            )}
            <div className="d-flex flex-wrap bg-white mt-1 mb-3">
              {data &&
                data.map((item: any) => {
                  return (
                    <>
                      <div className="position-relative my-3">
                        <div>
                          <span
                            className={`text-gray py-1 px-3 me-3 rounded  bg_color_raven_gray text-truncate ${styles.hashtagestitle}`}
                            title={item.title}
                          >
                            {item.title}
                          </span>
                          <span
                            id={item.media_tag_id}
                            onClick={() =>
                              handleRemoveTags &&
                              handleRemoveTags(item.media_tag_id)
                            }
                            className={`position-absolute ${styles.mediamodaltagclosebtn}`}
                          >
                            <Close />
                          </span>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
            <div className="d-flex justify-content-between">
              {notext != "" && (
                <Button
                  variant="secondary"
                  className="btn reject-btn col-3 shadow-none d-flex align-items-center"
                  onClick={cancel}
                >
                  {" "}
                  {notext}
                </Button>
              )}

              <Button
                variant="primary"
                className="btn accept-btn col-3 shadow-none  d-flex align-items-center"
                onClick={submit}
              >
                {yestext}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default MediaLibraryImagePopup;
