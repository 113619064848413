export const WorkflowStageNames: Record<string, string> = {
  SORT: 'sort',
  CATEGORYSORT: 'category_sort',
  DATAENTRY: 'data_entry',
  QUALITYCONTROL: 'quality_control',
  REPAIR: 'repair',
  MERCHANDISING: 'merchandising',
  FINISHING: 'finishing',
  QUALITYASSURANCE: 'quality_assurance',
  PREPUBLISH: 'publishing',
  PHOTO_STUDIO: 'photo_studio',
  PHOTO_EDITING: 'photo_editing',
  EXTERNALLAUNDRY: 'external_laundry',
  INTERNALLAUNDRY: 'internal_laundry'
}
