import React, { ReactNode, ChangeEvent } from 'react';
import styles from '../../pages/Shipment/QualityControl/Qualitycontrol.module.css';
import Close from '../../static/icons/Close';
interface DivButtonAtomProps {
  id?: string;
  title?: string;
  blockStyle?: string;
  handleTriggerEvent?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleClose?: (id?: string) => void;
}

const DivButtonAtom: React.FC<DivButtonAtomProps> = ({
  id,
  title,
  blockStyle,
  handleTriggerEvent,
  handleClose
}) => {
  //this function is just to trigger the selected button here and update states in parent function  
  const handleInternalClick = (event: any) => {
    //check if the function and id exists otherwise it can crash
    if (handleTriggerEvent) {
      handleTriggerEvent(event);
    }
  }

  return (
    <div className={`${styles.closeblock} position-relative p-2`} title={title}>
      <div id={id}
        className={`${blockStyle} ${styles.blocks} rounded text-center py-2 fs-5 text-break`}
        onClick={handleInternalClick}
      >
        {handleClose != undefined &&
          <div className={`${styles.selected_close_btn} position-absolute cursor-pointer top-2`}
            onClick={() => handleClose(id)}>
            <Close />
          </div>
        }
        {title}
      </div>
    </div>

  );
};

export default DivButtonAtom;
