import React, { useEffect, useState } from "react";
import Managetranslations from "../static/icons/Admindashboard/Managetranslation";
import customAlert from "../components/atoms/CustomAlert";
import { t } from "./Translations/TranslationUtils";
import { ManageEmailTemplate } from "../static/icons/ManageEmailTemplate";
import { AddEmailTemplate } from "../static/icons/AddEmailTemplate";
import AccessControl from "../services/AccessControl";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../routes/Contextlib";
import ActionButtonGroup from "../components/molecules/ActionButtonGroup";
import IconData from "../static/IconData";

ManageEmailTemplate;
export default function ApplicationConfiguration() {
  const navigate = useNavigate();
  const { user, setUser } = useUserContext();
  const [userPermissions, setUserPermissions] = useState<any>()
  localStorage.removeItem("backbuttonURL");
  //To display alert message
  useEffect(() => {
    const storageMessage = localStorage.getItem("successAlertMessage");

    if (storageMessage) {
      localStorage.removeItem("successAlertMessage");
      customAlert("success", t(storageMessage), 6000);
    }
  });

  useEffect(() => {
    if(user){
      const userPerm: any = user.permissions;
      setUserPermissions(userPerm)
    }
  },[user != null])

  const checkStatus = (title: any) => {
    const truePerm = userPermissions?.find((item: any) => item.permission.toLowerCase().trim() === title)
    if(truePerm?.update === true){
      return false
    }else{
      return true
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-md-11 ms-auto px-4 mb-4">
          <div className="py-xxl-4 py-xl-3 py-lg-3 page-title">
            {t("Application configuration")}
          </div>

          <div className="row gx-5 mt-3">
            <AccessControl
              requiredPermissions={[
                {
                  permission: "mail template",
                  read: checkStatus("mail template"),
                },
              ]}
            >
              <div className="col-md-6 pe-5 mb-4">
                <div className="link-border pb-3 ps-4">
                  {" "}
                  <span
                    className="me-2 page-links"
                    onClick={() => navigate("/mail-template/manage")}
                  >
                    <ManageEmailTemplate /> {t("Manage mail template")}
                  </span>
                </div>
              </div>
            </AccessControl>

            <AccessControl
              requiredPermissions={[
                {
                  permission: "translations",
                  read: checkStatus("translations"),
                },
              ]}
            >
              <div className="col-md-6 ps-5 mb-4">
                <div className="link-border pb-3 ps-4">
                  {" "}
                  <span
                    className="me-2 page-links"
                    onClick={() => navigate("/translations/manage")}
                  >
                    <Managetranslations /> {t("Manage translations")}
                  </span>
                </div>
              </div>
            </AccessControl>

            <AccessControl
              requiredPermissions={[
                {
                  permission: "product catalogue",
                  read: checkStatus("product catalogue"),
                },
              ]}
            >
              <div className="col-md-6 pe-5 mb-4">
                <div className="link-border pb-3 ps-4">
                  {" "}
                  <span
                    className="me-2 page-links"
                    onClick={() => navigate("/product-catalogue/manage")}
                  >
                    {IconData.ManageProductCatelog} {t("Manage product catalogue")}
                  </span>
                </div>
              </div>
            </AccessControl>

            <AccessControl
              requiredPermissions={[
                {
                  permission: "recaptcha",
                  read: checkStatus("recaptcha"),
                },
              ]}
            >
              <div className="col-md-6 ps-5 mb-4">
                <div className="link-border pb-3 ps-4">
                  <span
                    className="me-2 page-links"
                    onClick={() => navigate("/captcha/manage")}
                  >
                    {IconData.ManageRecaptcha} {t("Manage recaptcha")}
                  </span>
                </div>
              </div>
            </AccessControl>
            
            <AccessControl
              requiredPermissions={[
                {
                  permission: "inactivity timeout",
                  read: checkStatus("Inactivity timeout"),
                },
              ]}
            >
              <div className="col-md-6 pe-5 mb-4">
                <div className="link-border pb-3 ps-4">
                  <span
                    className="me-2 page-links"
                    onClick={() => navigate("/incactivity-timeout/manage")}
                  >
                    {IconData.ManageInactivityTimeout} {t("Manage inactivity timeout")}
                  </span>
                </div>
              </div>
            </AccessControl>
          </div>
          <ActionButtonGroup
            backTitle={t("Back")}
            handleBackClick={()=> navigate('/')}
          />
        </div>
      </div>
    </>
  );
}
