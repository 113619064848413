import FormValidation from "./FormValidation";

export const RenewalServices = {
    formValidate: function (data: any, errorstate: any, setErrors: any) {
        const error: any = [];
        Object.entries(data).forEach(([key, value]) => {
            if (key == 'style_number') {
                error[key] = FormValidation.nameValidation(value ?? '', 15);
            } else if (!errorstate.hasOwnProperty(key)) {
                return;
            } else {
                error[key] = FormValidation.selectValidation(value ?? []);
            }
            errorstate.hasOwnProperty(key) && setErrors((prevFormData: any) => ({ ...prevFormData, [key]: error[key] }));
        });
        if (data.sku_number?.length > 50) {
            return 'sku';
        }
        if (Object.keys(error).length !== 0) {
            if (Object.values(error).every((errorMessage) => errorMessage === "")) {
                return 'true';
            }
        }
        return 'false';
    },
    generateSkuNumber: function (state: any, issku: boolean = false) {
        return (
            issku ? state.sku_number :
                (state.style_number !== '' && state.colorlabel !== '' && state.sizelabel !== '' &&
                    `${state.style_number}-${state.colorlabel}.${state.sizelabel}`)
        );
    }
};
