import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import customAlret from "../../components/atoms/CustomAlert";
import { t } from "../Translations/TranslationUtils";
import FormValidation from "../../services/FormValidation";
import AccessControl from "../../services/AccessControl";
import { CommonConstants } from "../Shipment/Constants/ShipmentConstants";
import AccessDenied from "../AccessDenied";
import { APICALL } from "../../services/ApiServices";
import { createRole, fetchRolesUrl, managePermissions } from "../../routes/ApiEndPoints";
import LabelField from "../../components/atoms/LabelField";
import { useUserContext } from "../../routes/Contextlib";
import WindowHeightComponent from "../../commonComponents/WindowHeightComponent";
import RadioField from "../../components/atoms/RadioField";

const CreateRole: React.FC = () => {
  type MyState = {
    title: string;
    permissions: object;
    edit: any;
    trackTime: string;
  };

  const initialState: MyState = {
    title: "",
    permissions: {},
    edit: null,
    trackTime: "",
  };

  interface FormErrors {
    title: string;
    permissions: string;
    trackTimeRadio: string;
  }

  type SelectedPermissions = {
    permid: number;
    create: boolean;
    read: boolean;
    update: boolean;
    delete: boolean;
    cid: number;
    type: string;
    [key: string]: boolean | number | string;
  };

  const selectedPermsInitialState: SelectedPermissions = {
    permid: 0,
    create: false,
    read: false,
    update: false,
    delete: false,
    cid: 0,
    type: "",
  };

  type TempArrayy = { [id: number]: SelectedPermissions };
  const [TempArray, setTempArray] = useState<TempArrayy>({});
  const { user, setUser } = useUserContext();

  const [permissionDetails, setPermissionDetails] = useState<object>({});
  const [isSaving, setIsSaving] = useState(false);
  const [existingRoles, setExistingRoles] = useState<string[]>([]);
  const [state, setState] = useState<MyState>(initialState);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const editId = Number(searchParams.get("edit"));
  const [errors, setErrors] = useState<FormErrors>({
    title: "",
    permissions: "",
    trackTimeRadio: "",
  });
  const [oldTitle, setOldTitle] = useState('')

  const [requiredPermissions, setRequiredPermissions] = useState<any>([])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, title: event.target.value }));
  };

  const handleSelectAllCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    category_id: Number
  ) => {
    const { id, checked, name } = event.target;

    let AllPermissions = permissionDetails;
    let selectedCat = Object.values(AllPermissions).filter(
      (item: any) => item[0].cid == id
    );

    Object.values(selectedCat).map((item: any) => {
      item.map((item2: any) => {
        let tempLocal: TempArrayy = { ...TempArray };
        tempLocal = {
          [item2.permission_id]: {
            permid: Number(item2.permission_id),
            create: item2.type === "entity" && checked ? true : false,
            read: checked ? true : false,
            update: item2.type === "entity" && checked ? true : false,
            delete: item2.type === "entity" && checked ? true : false,
            cid: Number(id),
            type: item2.type,
          },
        };
        setTempArray((state) => ({
          ...state,
          ...tempLocal,
        }));
      });
    });
  };

  function areAllCheckboxesChecked(category_id: number): boolean {
    let status: boolean = true;
    let localArray: TempArrayy = Object.values(TempArray).filter(
      (item: any) => item.cid === category_id
    );
    if (Object.keys(localArray).length == 0) {
      status = false;
    }

    for (const key in localArray) {
      const permissions = localArray[key];

      if (permissions.type === "entity") {
        if (
          TempArray &&
          (!permissions.create ||
            !permissions.read ||
            !permissions.update ||
            !permissions.delete)
        ) {
          status = false;
        }
      } else if (permissions.type === "single") {
        if (TempArray && !permissions.read) {
          status = false;
        }
      }
    }
    return status;
  }

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    category_id: Number,
    type: string
  ) => {
    const { id, checked, name } = event.target;

    let data = { ...TempArray };
    let singlePermission = Object.values(data).filter(
      (item: any) => Number(id) === Number(item.permid)
    );

    let permissionArray2 =
      singlePermission.length > 0
        ? singlePermission[0]
        : {
          permid: 0,
          create: false,
          read: false,
          update: false,
          delete: false,
          cid: 0,
          type: type,
        };

    let tempLocal = {
      [id]: {
        permid: Number(id),
        create: permissionArray2.create,
        read: permissionArray2.read,
        update: permissionArray2.update,
        delete: permissionArray2.delete,
        cid: category_id,
        type: type,
      },
    };

    switch (name) {
      case "create_checkbox":
        tempLocal[id].create = checked;
        break;
      case "read_checkbox":
        tempLocal[id].read = checked;
        break;
      case "update_checkbox":
        tempLocal[id].update = checked;
        break;
      case "delete_checkbox":
        tempLocal[id].delete = checked;
        break;
      default:
    }

    setTempArray((state) => ({
      ...state,
      ...tempLocal,
    }));
  };
  useEffect(() => {
    setState((prevState) => ({ ...prevState, permissions: TempArray }));
  }, [TempArray]);

  //To display alert message
  useEffect(() => {
    const storageMessage = localStorage.getItem("errorAlertMessage");
    if (storageMessage) {
      localStorage.removeItem("errorAlertMessage");
      customAlret("error", storageMessage, 6000);
    }
  }, []);

  //Validation rules for each field
  const validateForm = (): FormErrors | undefined => {
    const validationErrors: FormErrors = {
      title: "",
      permissions: "",
      trackTimeRadio: "",
    };

    validationErrors.title = FormValidation.nameValidation(state.title);

    if (Object.keys(state.permissions).length === 0) {
      validationErrors.permissions = t("Permission is required");
    }

    if (existingRoles.includes(state.title.toLowerCase())) {
      validationErrors.title = t("Title already exists");
    }

    if(state.trackTime === ""){
      validationErrors.trackTimeRadio = t("Please select a value")
    }

    setErrors(validationErrors);
    if (validationErrors.title === "" && validationErrors.permissions === "" && validationErrors.trackTimeRadio === "") {
      return undefined;
    } else {
      return validationErrors;
    }
  };

  const removeError = (event: React.MouseEvent<HTMLInputElement>) => {
    // Handle the click event here
    let fieldName = (event.target as HTMLInputElement).name;    
    const permArray = [
      "selectAll_create_checkbox",
      "create_checkbox",
      "read_checkbox",
      "update_checkbox",
      "delete_checkbox",
    ];
    if (permArray.includes(fieldName)) {
      fieldName = "permissions";
    }
    setErrors((prevState) => ({
      ...prevState,
      [fieldName]: "", // Set the value of the field dynamically
    }));
  };

  const hasError = (field: keyof FormErrors): boolean => {
    return Array.isArray(errors[field])
      ? errors[field].length > 0
      : !!errors[field];
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (editId == Number(process.env.REACT_APP_SUPERVISOR_ID)) {
      state.title = oldTitle
    }
    const validationErrors = validateForm();
    if (validationErrors === undefined) {
      storeRole();
    }
  };

  useEffect(() => {
    let status: boolean = true;
    let localArray: TempArrayy = TempArray;

    for (const key in localArray) {
      const permissions = localArray[key];
      if (
        permissions.create ||
        permissions.read ||
        permissions.update ||
        permissions.delete
      ) {
        status = false;
      }
    }
    if (status) {
      setState((prevState) => ({ ...prevState, permissions: {} }));
    }
  }, [TempArray]);

  async function fetchAllRoles(role_id: number | null = null) {
    try {
      const postData = {
        method: "POST",
        data: {},
      };
      const response = await APICALL.service(
        fetchRolesUrl,
        "POST",
        postData,
        true
      );

      let roles: string[] = [];

      if (role_id) {
        roles = Object.values(response?.data)
          .filter((role: any) => role_id !== role.role_id)
          .map((role: any) => {
            return role.title.toLowerCase();
          });
      } else {
        roles = Object.values(response?.data).map((item: any) => {
          return item.title;
        });
      }

      setExistingRoles(roles);
    } catch (error) { }
  }

  async function storeRole() {
    setIsSaving(true);
    try {
      const postData = {
        method: "POST",
        data: state,
      };
      const response = await APICALL.service(
        createRole,
        "POST",
        postData,
        true
      );

      if (response?.status === 200) {
        let message: string = `Role ${editId ? 'updated' : 'created'
          } successfully`;
        localStorage.setItem("successAlertMessage", message);
        setIsSaving(false);
        const userPerm: any = user?.permissions;
        const truePerm: any = userPerm?.find(
          (item: any) => item.permission.toLowerCase().trim() === "roles"
        );
        if (truePerm?.read === true) {
          navigate("/roles/manage");
        } else {
          navigate("/backend");
        }
      }
    } catch (error) {
      localStorage.setItem(
        "errorAlertMessage",
        `${t("Error while")} ${editId ? t("updating") : t("creating")} ${t(
          "role"
        )}`
      );
    }
  }

  async function fetchRoleData(role_id: number) {
    try {
      const postData = {
        method: "POST",
        data: { id: role_id }
      };
      const response = await APICALL.service(
        fetchRolesUrl,
        "POST",
        postData,
        true
      );

      if (response?.status == 200) {
        let responseData: any = response?.data;

        let Arr = {};
        responseData.map((item: any) => {
          let data1 = {
            [item.permission_id]: {
              permid: Number(item.permission_id),
              create: item.create,
              read: item.read,
              update: item.update,
              delete: item.delete,
              cid: item.category_id,
              type: item.type,
            },
          };
          Arr = { ...Arr, ...data1 };
        });
        setTempArray(Arr);

        let role_name: string =
          Object.keys(responseData).length > 0
            ? responseData[0].role_name
            : response?.role_name[0];

        setOldTitle(role_name)
        setState((prevState) => ({
          ...prevState,
          title: role_name,
          permissions: Arr,
          edit: role_id,
          trackTime: Object.keys(responseData).length > 0
          ? responseData[0].trackTime
          : ""
        }));
      }
    } catch (error) {
      console.log(error);

    }
  }

  async function fetchPermissions() {
    try {
      const postData = {
        method: "GET",
        data: {},
      };
      const response = await APICALL.service(
        managePermissions,
        "POST",
        postData,
        true
      );

      let Arr = {};
      Object.values(response?.data).map((item: any) => {
        item.map((item2: any) => {
          let data1 = {
            [item2.permission_id]: {
              permid: Number(item2.permission_id),
              create: item2.create ?? false,
              read: item2.read ?? false,
              update: item2.update ?? false,
              delete: item2.delete ?? false,
              cid: item2.cid,
              type: item2.type,
            },
          };
          Arr = { ...Arr, ...data1 };
        });
      });
      setTempArray(Arr);
      setPermissionDetails(response?.data);

      if (editId != null && editId !== 0) {
        fetchRoleData(editId);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchPermissions();
    fetchAllRoles(editId);
  }, []);

  window.addEventListener("beforeunload", () => {
    const currentURL = new URL(window.location.href);
    const urlExtension = `/roles/create${editId ? "/" + editId : ""}`;
    localStorage.setItem("backbuttonURL", urlExtension);
  });

  const goToPreviousPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    let backButtonURL: any = localStorage.getItem("backbuttonURL");
    localStorage.removeItem("backbuttonURL");
    navigate(backButtonURL ?? "/");
  };

  const handleBackButtonClick = () => {
    if (editId) {
      navigate("/roles/manage");
    } else {
      navigate("/backend");
    }
  };

  useEffect(() => {
    let permissions = [
      {
        permission: "roles",
        create: true,
        update: true
      }
    ]
    if (editId) {
      permissions[0].create = false;
    } else {
      permissions[0].update = false;
    }
    setRequiredPermissions(permissions)
  }, [editId])

  const handleTrackTimerClick = (e: any) => {
    setState((prevState) => ({ ...prevState, trackTime: e.target.value }));
  }

  return (
    <AccessControl
      requiredPermissions={requiredPermissions}
      renderNoAccess={true}
    >
      {editId != undefined &&
        editId !== Number(process.env.REACT_APP_ADMIN_ROLE_ID)
        // editId !== Number(process.env.REACT_APP_SUPERVISOR_ID)
        ? (
          <div className="row">
            <div className="col-md-11 ms-auto px-4">
              {/* Page title */}
              <WindowHeightComponent>
                <div className="d-flex flex-column h-100">
                  <div className="py-xxl-4 py-xl-3 py-lg-3 page-title top-0">
                    {`${state.edit ? t("Update role") : t("Create role")}`}
                  </div>
                  <div className="flex-1 overflow-auto">

                    <form onSubmit={handleSubmit} className="h-100 overflow-auto">


                      <div className="d-flex flex-column h-100">
                        <div className="flex-1 overflow-auto">
                          <div className="d-flex flex-column h-100 overflow-auto">
                            <div className="">
                              <LabelField title={t("Title")} mandatory />
                            </div>
                            <div className="form-group pb-4">
                              <input
                                type="text"
                                name="title"
                                className="shadow-none form-control"
                                value={state.title}
                                onChange={handleInputChange}
                                onClick={removeError}
                                disabled={editId ==
                                  Number(process.env.REACT_APP_SUPERVISOR_ID)}
                              />
                              {hasError("title") && (
                                <div className="error" style={{ color: "red" }}>
                                  {t(errors.title)}
                                </div>
                              )}
                            </div>
                            <div className="mb-3">
                              <LabelField
                                className="pe-5"
                                title={t("Track time in workflow?")}
                                mandatory
                              />
                              <RadioField
                                name="trackTimeRadio"
                                className="pe-5"
                                label={t(CommonConstants.YES)}
                                checked={state.trackTime === "yes"}
                                value={"yes"}
                                handleChange={(e) => handleTrackTimerClick(e)}
                                handleClick={removeError}
                              />
                              <RadioField
                                name="trackTimeRadio"
                                className="pe-5"
                                label={t(CommonConstants.NO)}
                                checked={state.trackTime === "no"}
                                value={"no"}
                                handleChange={(e) => handleTrackTimerClick(e)}
                                handleClick={removeError}
                              />
                              <RadioField
                                name="trackTimeRadio"
                                label={t(CommonConstants.MAYBE)}
                                checked={state.trackTime === "maybe"}
                                value={"maybe"}
                                handleChange={(e) => handleTrackTimerClick(e)}
                                handleClick={removeError}
                              />
                              {hasError("trackTimeRadio") && (
                                <div className="error" style={{ color: "red" }}>
                                  {t(errors.trackTimeRadio)}
                                </div>
                              )}
                            </div>
                            <div className="form-group create-role-tabs flex-1 overflow-auto">
                              <div className="tabs d-flex flex-column h-100">
                                <LabelField title={t("Permissions")} mandatory />
                                {hasError("permissions") && (
                                  <div className="error" style={{ color: "red" }}>
                                    {t(errors.permissions)}
                                  </div>
                                )}
                                <ul
                                  className="nav nav-pills"
                                  id="myTab"
                                  role="tablist"
                                >
                                  {Object.values(permissionDetails).map(
                                    (item: any, index: number) => {
                                      // for tab pills
                                      if (item[0].permission_id !== null) {
                                        return (
                                          <li
                                            className={`nav-item pe-4`}
                                            key={item.cid}
                                            role="presentation"
                                          >
                                            <button
                                              className={`nav-link px-0 ${index == 0 ? "active" : ""
                                                }`}
                                              id={`${item[0].cid}-tab`}
                                              data-bs-toggle="tab"
                                              data-bs-target={`#tab${item[0].cid}`}
                                              type="button"
                                              role="tab"
                                              aria-controls={`$${item[0].cid}`}
                                              aria-selected="false"
                                              onClick={() =>
                                                areAllCheckboxesChecked(
                                                  item[0].cid
                                                )
                                              }
                                            >
                                              {t(item[0].category_name)}
                                            </button>
                                          </li>
                                        );
                                      }
                                    }
                                  )}
                                </ul>
                                <div
                                  className="tab-content mt-3 flex-1 overflow-auto"
                                  id="myTabContent"
                                >
                                  {Object.values(permissionDetails).map(
                                    (item: any, index: number) => {
                                      return (
                                        <div
                                          className={`tab-pane fade h-100 ${index == 0 ? "show active" : ""
                                            }`}
                                          id={`tab${item[0].cid}`}
                                          role="tabpanel"
                                          aria-labelledby={`${item[0].cid}-tab`}
                                        >
                                          <div className="d-flex flex-column h-100">
                                            <div className="mb-3 d-flex align-items-center text-gray">
                                              <label htmlFor="selectAll_create_checkbox">
                                                {t("Select All")}
                                              </label>
                                              <input
                                                type="checkbox"
                                                className="mx-2 mb-1 form-check-input shadow-none"
                                                name="selectAll_create_checkbox"
                                                onClick={removeError}
                                                id={item[0].cid}
                                                onChange={(e) =>
                                                  handleSelectAllCheckboxChange(
                                                    e,
                                                    item[0].cid
                                                  )
                                                }
                                                checked={
                                                  Object.keys(TempArray).length >
                                                  0 &&
                                                  areAllCheckboxesChecked(
                                                    item[0].cid
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="flex-1 overflow-auto">
                                              <table className="table">
                                                <thead className="position-sticky top-0">
                                                  <tr>
                                                    <th
                                                      scope="col"
                                                      className="border-bottom-0"
                                                    >
                                                      {t("Permission")}
                                                    </th>
                                                    <th
                                                      scope="col"
                                                      className="border-bottom-0 text-center"
                                                    >
                                                      {t("Create")}
                                                    </th>
                                                    <th
                                                      scope="col"
                                                      className="border-bottom-0 text-center"
                                                    >
                                                      {t("Read")}/{t("View")}
                                                    </th>
                                                    <th
                                                      scope="col"
                                                      className="border-bottom-0 text-center"
                                                    >
                                                      {t("Update")}
                                                    </th>
                                                    <th
                                                      scope="col"
                                                      className="border-bottom-0 text-center"
                                                    >
                                                      {t("Archive")}
                                                    </th>
                                                  </tr>
                                                </thead>
                                                {item.length > 0 &&
                                                  Object.values(item).map(
                                                    (item2: any, index2: number) => {
                                                      return (
                                                        <tbody>
                                                          {item2.title != null && (
                                                            <tr>
                                                              <td
                                                                className="text-break"
                                                                scope="row"
                                                              >
                                                                {t(item2.title)}
                                                              </td>
                                                              <td className="text-break text-center">
                                                                <input
                                                                  type="checkbox"
                                                                  className="me-2 form-check-input shadow-none"
                                                                  name="create_checkbox"
                                                                  onClick={
                                                                    removeError
                                                                  }
                                                                  id={
                                                                    item2.permission_id
                                                                  }
                                                                  onChange={(e) =>
                                                                    handleCheckboxChange(
                                                                      e,
                                                                      item[0].cid,
                                                                      item2.type
                                                                    )
                                                                  }
                                                                  checked={
                                                                    Object.values(
                                                                      TempArray
                                                                    ).filter(
                                                                      (item: any) =>
                                                                        item2.permission_id ===
                                                                        item.permid
                                                                    ).length > 0
                                                                      ? Object.values(
                                                                        TempArray
                                                                      ).filter(
                                                                        (
                                                                          item: any
                                                                        ) =>
                                                                          item2.permission_id ===
                                                                          item.permid
                                                                      )[0]
                                                                        .create ===
                                                                      true
                                                                      : false
                                                                  }
                                                                  disabled={
                                                                    item2.type ===
                                                                    "single"
                                                                  }
                                                                />
                                                              </td>
                                                              <td className="text-center">
                                                                <input
                                                                  type="checkbox"
                                                                  className="me-2 form-check-input shadow-none"
                                                                  name="read_checkbox"
                                                                  onClick={
                                                                    removeError
                                                                  }
                                                                  id={
                                                                    item2.permission_id
                                                                  }
                                                                  checked={
                                                                    Object.values(
                                                                      TempArray
                                                                    ).filter(
                                                                      (item: any) =>
                                                                        item2.permission_id ===
                                                                        item.permid
                                                                    ).length > 0
                                                                      ? Object.values(
                                                                        TempArray
                                                                      ).filter(
                                                                        (
                                                                          item: any
                                                                        ) =>
                                                                          item2.permission_id ===
                                                                          item.permid
                                                                      )[0].read ===
                                                                      true
                                                                      : false
                                                                  }
                                                                  onChange={(e) =>
                                                                    handleCheckboxChange(
                                                                      e,
                                                                      item[0].cid,
                                                                      item2.type
                                                                    )
                                                                  }
                                                                />
                                                              </td>
                                                              <td className="text-center">
                                                                <input
                                                                  type="checkbox"
                                                                  className="me-2 form-check-input shadow-none"
                                                                  name="update_checkbox"
                                                                  onClick={
                                                                    removeError
                                                                  }
                                                                  id={
                                                                    item2.permission_id
                                                                  }
                                                                  checked={
                                                                    Object.values(
                                                                      TempArray
                                                                    ).filter(
                                                                      (item: any) =>
                                                                        item2.permission_id ===
                                                                        item.permid
                                                                    ).length > 0
                                                                      ? Object.values(
                                                                        TempArray
                                                                      ).filter(
                                                                        (
                                                                          item: any
                                                                        ) =>
                                                                          item2.permission_id ===
                                                                          item.permid
                                                                      )[0]
                                                                        .update ===
                                                                      true
                                                                      : false
                                                                  }
                                                                  onChange={(e) =>
                                                                    handleCheckboxChange(
                                                                      e,
                                                                      item[0].cid,
                                                                      item2.type
                                                                    )
                                                                  }
                                                                  disabled={
                                                                    item2.type ===
                                                                    "single"
                                                                  }
                                                                />
                                                              </td>
                                                              <td className="text-center">
                                                                <input
                                                                  type="checkbox"
                                                                  className="me-2 form-check-input shadow-none"
                                                                  name="delete_checkbox"
                                                                  onClick={
                                                                    removeError
                                                                  }
                                                                  id={
                                                                    item2.permission_id
                                                                  }
                                                                  checked={
                                                                    Object.values(
                                                                      TempArray
                                                                    ).filter(
                                                                      (item: any) =>
                                                                        item2.permission_id ===
                                                                        item.permid
                                                                    ).length > 0
                                                                      ? Object.values(
                                                                        TempArray
                                                                      ).filter(
                                                                        (
                                                                          item: any
                                                                        ) =>
                                                                          item2.permission_id ===
                                                                          item.permid
                                                                      )[0]
                                                                        .delete ===
                                                                      true
                                                                      : false
                                                                  }
                                                                  onChange={(e) =>
                                                                    handleCheckboxChange(
                                                                      e,
                                                                      item[0].cid,
                                                                      item2.type
                                                                    )
                                                                  }
                                                                  disabled={
                                                                    item2.type ===
                                                                    "single"
                                                                  }
                                                                />
                                                              </td>
                                                            </tr>
                                                          )}
                                                        </tbody>
                                                      );
                                                    }
                                                  )}
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        {/* buttons start */}
                        <div className="row mt-1 m-0">
                          <div className="col-md-6 float-start p-0">
                            <button
                              className="back-btn shadow-none float-start text-decoration-none"
                              onClick={() => handleBackButtonClick()}
                            >
                              {t(CommonConstants.BACK)}
                            </button>
                          </div>
                          <div className="col-md-6 float-end p-0">
                            <button
                              type="submit"
                              className="submit-btn shadow-none float-end"
                              disabled={isSaving}
                            >
                              {state.edit
                                ? isSaving
                                  ? t("Saving...")
                                  : t("Update")
                                : isSaving
                                  ? t("Saving...")
                                  : t("Create")}
                            </button>
                          </div>
                        </div>
                        {/* buttons ends*/}
                      </div>
                    </form>
                  </div>
                </div>
              </WindowHeightComponent>
            </div>
          </div>
        ) : (
          <AccessDenied />
        )}
    </AccessControl>
  );
};

export default CreateRole;
