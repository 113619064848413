import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import customAlert from "../components/atoms/CustomAlert";
import { t } from "./Translations/TranslationUtils";
import { CommonConstants } from "./Shipment/Constants/ShipmentConstants";
import { createUserUrl } from "../routes/ApiEndPoints";
import { APICALL } from "../services/ApiServices";

const EditPassword: React.FC = () => {
  type PasswordState = {
    edit: any;
    old_password: string;
    new_password: string;
    cnf_password: string;
  };

  const searchParams = new URLSearchParams(location.search);

  const initialPasswordState: PasswordState = {
    old_password: "",
    new_password: "",
    cnf_password: "",
    edit: Number(searchParams.get("edit")),
  };

  interface FormErrors {
    old_password: string;
    new_password: string;
    cnf_password: string;
  }

  interface ShowIconn {
    old_password: boolean;
    new_password: boolean;
    cnf_password: boolean;
  }

  const [passwords, setPasswords] =
    useState<PasswordState>(initialPasswordState);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState<FormErrors>({
    old_password: "",
    new_password: "",
    cnf_password: "",
  });
  const [alert, setAlert] = useState<boolean>(false);
  const navigate = useNavigate();
  const [showIcon, setShowIcon] = useState<ShowIconn>({
    old_password: false,
    new_password: false,
    cnf_password: false,
  });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const validationErrors = validateForm();
    if (validationErrors === undefined) {
      updatePassword();
    }
  };

  //To display alert message
  useEffect(() => {
    const storageMessage = localStorage.getItem("errorAlertMessage");
    if (storageMessage) {
      localStorage.removeItem("errorAlertMessage");
      customAlert("error", t(storageMessage), 8000);
    }
  }, [alert]);

  //Validation rules for each field
  const validateForm = (): FormErrors | undefined => {
    const validationErrors: FormErrors = {
      old_password: "",
      new_password: "",
      cnf_password: "",
    };

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/;

    // Add validation rules for each field
    if (passwords.old_password.trim() === "") {
      validationErrors.old_password = "Enter current password";
    } else if (passwords.old_password.length > 16) {
      validationErrors.old_password = "Maximum length is 16 characters";
    }

    switch (true) {
      case passwords.new_password.length === 0:
        validationErrors.new_password = "New password is required"; // Assign as an array of strings
        break;
      case passwords.new_password.length > 16:
        validationErrors.new_password = "Maximum length is 16 characters";
        break;
      case passwords.new_password.length < 10:
        validationErrors.new_password = "Password must be at least 10 characters long";
        break;
      case !/[a-z]/.test(passwords.new_password):
        validationErrors.new_password = "Password must contain at least one lowercase";
        break;
      case !/[A-Z]/.test(passwords.new_password):
        validationErrors.new_password = "Password must contain at least one uppercase";
        break;
      case !/\d/.test(passwords.new_password):
        validationErrors.new_password = "Password must contain at least one digit";
        break;
      case !/[!@#$%^&*]/.test(passwords.new_password):
        validationErrors.new_password = "Password must contain at least one special character";
        break;
      default:
        // Handle the default case if none of the conditions match
        break;
    }

    switch (true) {
      case passwords.cnf_password.length === 0:
        validationErrors.cnf_password = "Confirm password is required";
        break;
      case passwords.cnf_password.length > 16:
        validationErrors.cnf_password = "Maximum length is 16 characters";
        break;
      case passwords.cnf_password !== passwords.new_password:
        validationErrors.cnf_password = "Confirm password is not the same as the new password";
        break;
      case passwords.cnf_password.length < 10:
        validationErrors.cnf_password = "Password must be at least 10 characters long";
        break;
      case !/[a-z]/.test(passwords.cnf_password):
        validationErrors.cnf_password = "Password must contain at least one lowercase letter";
        break;
      case !/[A-Z]/.test(passwords.cnf_password):
        validationErrors.cnf_password = "Password must contain at least one uppercase letter";
        break;
      case !/\d/.test(passwords.cnf_password):
        validationErrors.cnf_password = "Password must contain at least one digit";
        break;
      case !/[!@#$%^&*]/.test(passwords.cnf_password):
        validationErrors.cnf_password = "Password must contain at least one special character";
        break;
      default:
        // Default case if none of the conditions match.
        // You can handle this case as needed.
    }
    

    setErrors(validationErrors);
    if (
      validationErrors.old_password === "" &&
      validationErrors.new_password === "" &&
      validationErrors.cnf_password === ""
    ) {
      return undefined;
    } else {
      return validationErrors;
    }
  };

  const hasError = (field: keyof FormErrors): boolean => {
    return Array.isArray(errors[field])
      ? errors[field].length > 0
      : !!errors[field];
  };

  const removeError = (event: React.MouseEvent<HTMLInputElement>) => {
    // Handle the click event here
    const inputField = event.currentTarget as HTMLInputElement;
    const id = inputField.id;
    disableCopyPaste(id);

    const fieldName = (event.target as HTMLInputElement).name;
    setErrors((prevState) => ({
      ...prevState,
      [fieldName]: "", // Set the value of the field dynamically
    }));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let fieldName: string = event.target.name;
    setPasswords((prevState) => ({
      ...prevState,
      [fieldName]: event.target.value,
    }));
  };

  async function updatePassword() {
    setIsSaving(true);
    try {
      const postData = {
        method: "POST",
        data: passwords
      }
      const response = await APICALL.service(createUserUrl, "POST", postData, true);
      if (response?.status === 200) {
        localStorage.setItem("successAlertMessage", response?.message);
        setIsSaving(false);
        navigate("/users/myprofile");
      } else {
        setIsSaving(false);
        localStorage.setItem("errorAlertMessage", response?.message);
        setAlert(!alert);
      }
    } catch (error) {
      localStorage.setItem(
        "errorAlertMessage",
        `Error while updating password`
      );
      setAlert(!alert);
      // console.error(error);
    }
  }
  function togglePasswordVisibility(field: string) {
    const passwordInput = document.getElementById(field) as HTMLInputElement;

    if (passwordInput.type === "password") {
      passwordInput.type = "text";
      // setShowIcon(true);
      setShowIcon((prevState) => ({
        ...prevState,
        [field]: true,
      }));
    } else {
      passwordInput.type = "password";
      // setShowIcon(false);
      setShowIcon((prevState) => ({
        ...prevState,
        [field]: false,
      }));
    }
  }
  const disableCopyPaste = (field: string) => {
    const inputField = document.getElementById(field) as HTMLInputElement;

    inputField.addEventListener("cut", (event) => {
      event.preventDefault();
    });

    inputField.addEventListener("copy", (event) => {
      event.preventDefault();
    });

    inputField.addEventListener("paste", (event) => {
      event.preventDefault();
    });
  };


  return (
    <div className="row">
      <div className="col-md-11 ms-auto px-4 mb-4">
        {/* Page title */}
        <div className="py-xxl-4 py-xl-3 py-lg-3 page-title">
          {t("Change password")}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12">
              <div className="create-form p-4 mb-4">
                <div className="row">
                  <div className="col-md-10 m-auto">
                    <div className="row">
                      <div className="col-12">
                        <label>{t("Current password")}:</label>
                      </div>
                      <div className="form-group col-md-12 mb-2  d-flex align-items-center  position-relative">
                        <div className="position-absolute end-0 me-4">
                          {showIcon.old_password ? (
                            <img
                              src="/images/eye-open.png"
                              alt="open eye"
                              onClick={(e) =>
                                togglePasswordVisibility("old_password")
                              }
                            />
                          ) : (
                            <img
                              src="/images/eye-slash-fill.png"
                              alt="open eye"
                              onClick={(e) =>
                                togglePasswordVisibility("old_password")
                              }
                            />
                          )}
                        </div>
                        <input
                          type="password"
                          name="old_password"
                          id="old_password"
                          className="shadow-none form-control"
                          style={errors.old_password ? { borderColor: 'red' } : {}}
                          value={passwords.old_password}
                          onChange={handleInputChange}
                          onClick={removeError}
                        />
                      </div>
                      {hasError("old_password") && (
                        <div className="error mb-2" style={{ color: "red" }}>
                          {t(errors.old_password)}
                        </div>
                      )}
                    </div>

                    <div className="row mt-3">
                      <div className="col-12">
                        <label>{t("New password")}:</label>
                      </div>
                      <div className="form-group col-md-12 mb-2 d-flex align-items-center  position-relative">
                        <div className="position-absolute end-0 me-4">
                          {showIcon.new_password ? (
                            <img
                              src="/images/eye-open.png"
                              alt="open eye"
                              onClick={(e) =>
                                togglePasswordVisibility("new_password")
                              }
                            />
                          ) : (
                            <img
                              src="/images/eye-slash-fill.png"
                              alt="open eye"
                              onClick={(e) =>
                                togglePasswordVisibility("new_password")
                              }
                            />
                          )}
                        </div>
                        <input
                          type="password"
                          name="new_password"
                          id="new_password"
                          style={errors.new_password ? { borderColor: 'red' } : {}}
                          className="shadow-none form-control"
                          value={passwords.new_password}
                          onChange={handleInputChange}
                          onClick={removeError}
                        />
                      </div>
                      {hasError("new_password") && (
                        <div className="error mb-2" style={{ color: "red" }}>
                          {t(errors.new_password)}
                        </div>
                      )}
                    </div>

                    <div className="row mt-3">
                      <div className="col-12">
                        <label>{t("Confirm password")}:</label>
                      </div>
                      <div className="form-group col-md-12 mb-2 d-flex align-items-center  position-relative">
                        <div className="position-absolute end-0 me-4">
                          {showIcon.cnf_password ? (
                            <img
                              src="/images/eye-open.png"
                              alt="open eye"
                              onClick={(e) =>
                                togglePasswordVisibility("cnf_password")
                              }
                            />
                          ) : (
                            <img
                              src="/images/eye-slash-fill.png"
                              alt="open eye"
                              onClick={(e) =>
                                togglePasswordVisibility("cnf_password")
                              }
                            />
                          )}
                        </div>
                        <input
                          type="password"
                          name="cnf_password"
                          id="cnf_password"
                          style={errors.cnf_password ? { borderColor: 'red' } : {}}
                          className="shadow-none form-control"
                          value={passwords.cnf_password}
                          onChange={handleInputChange}
                          onClick={removeError}
                        />
                      </div>
                      {hasError("cnf_password") && (
                        <div className="error mb-2" style={{ color: "red" }}>
                          {t(errors.cnf_password)}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 float-start">
                  <button
                    onClick={() => navigate('/users/myprofile')}
                    className="back-btn shadow-none float-start text-decoration-none"
                  >
                    {t(CommonConstants.BACK)}
                  </button>
                </div>
                <div className="col-md-6 float-end">
                  <button
                    type="submit"
                    className="submit-btn shadow-none float-end"
                    // disabled={isSaving}
                  >
                    {isSaving ? t("Saving...") : t("Update")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditPassword;
