import React from "react";
import InputTextfield from "../atoms/InputTextField"; // Assuming InputTextfield is a separate component
import styles from "../../pages/BrandPartner/components/utility/colorpicker.module.css";
import FormValidation from "../../services/FormValidation";
import Close from "../../static/icons/Close";
import CommonServices from "../../services/CommonServices";

interface ColorMoleculeProps {
	color: any;
	handleSelectChange?: (e: any, color: any) => void;
	handleTextChange?: (e: any, color: any) => void;
	handleRemoveColor?: (color: any) => void;
	isBorderRequired?: boolean;
	preview?: boolean;
	outerStyle?: any;
	innerStyle?: any;
	select?: boolean;
}

const ColorMolecule: React.FC<ColorMoleculeProps> = ({
	color,
	handleSelectChange,
	handleTextChange,
	handleRemoveColor,
	isBorderRequired = false,
	preview = false,
	select = false,
	outerStyle = { height: "150px", width: "50px", maxWidth: "150px",  marginBottom: "12px" },
  	innerStyle = { height: "70px", width: "100%", maxWidth: "150px" }
}) => {
	const validateColor = (label: string) => {
		let isValid = FormValidation.nameValidation(label);
		return isValid !== "";
	};

	const renderColorBlock = (code: string) => {
		let colorCodes = CommonServices?.splitAndJoin(code, ";");
		const numColors = colorCodes?.length;
		const blockWidth = numColors > 0 ? `${100 / numColors}%` : "100%"; // Calculate width dynamically
		return colorCodes.map((item: any, index: number) => {
			return (
				<span
					key={index}
					className={`h-100 ${color?.squareview ? '' : 'multicolorblocks'} d-block`}
					style={{
						backgroundColor: item,
						width: blockWidth,
					}}
				></span>
			);
		});
  	};

	return (
		<div
			className={`${ color?.squareview ? '' : 'p-1 rounded'}`}
			style={{
				...outerStyle,
				border: isBorderRequired ? "2px solid var(--raven-red)" : "none",
			}}
		>
		<div
			className="rounded d-flex "
			style={{
				...innerStyle,
				cursor: "pointer",
				position: "relative",
			}}
			onClick={(e) =>
				handleSelectChange ? handleSelectChange(e, color) : undefined
			}
		>
			<div className="position-relative d-flex w-100" >
				{handleRemoveColor && (
					<div
						className={`${styles.closebtn} position-absolute`}
						onClick={() => handleRemoveColor(color)}
					>
						<Close />
					</div>
				)}
				<div className="w-100 d-flex">
					{renderColorBlock(color?.color_code)}
				</div>
			</div>

			{ !select &&
				<span
					className={`color_name text-center text-truncate w-100`}
					style={{ position: "absolute", bottom: "-35px" }}
				>
					{preview ? color?.title : color?.color_code}
				</span>
			}
		</div>

		{handleTextChange && (
			<InputTextfield
				className={`border rounded text-gray text-truncate px-2 ${
					validateColor(color?.title) ? "border-danger" : ""
				}`}
				type="text"
				value={color?.title ?? color?.color_code}
				handleChange={(event) => handleTextChange(event, color)}
				handleClick={(event) => event.stopPropagation()}
				customStyle={{
					width: "100%",
					textAlign: "center",
					minHeight: "unset",
					marginTop: "34px",
				}}
			/>
		)}
		</div>
	);
};

export default ColorMolecule;
