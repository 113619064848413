import React from 'react'

const Tier = () => {
  return (
    <>
      <svg id="Group_48463" data-name="Group 48463" xmlns="http://www.w3.org/2000/svg" width="188.3" height="160.655" viewBox="0 0 188.3 160.655">
        <ellipse id="Ellipse_229" data-name="Ellipse 229" cx="94.15" cy="8.716" rx="94.15" ry="8.716" transform="translate(0 143.223)" fill="rgba(84,89,96,0.06)" />
        <g id="Group_48443" data-name="Group 48443" transform="translate(10.664 0)">
          <rect id="Rectangle_10697" data-name="Rectangle 10697" width="137.715" height="98.18" transform="translate(13.906 53.759)" fill="#d3985a" />
          <rect id="Rectangle_10698" data-name="Rectangle 10698" width="137.715" height="10.631" transform="translate(13.906 53.759)" fill="#fff" opacity="0.09" />
          <rect id="Rectangle_10699" data-name="Rectangle 10699" width="122.852" height="53.759" transform="translate(21.329)" fill="rgba(211,152,90,0.9)" />
          <path id="Path_56292" data-name="Path 56292" d="M286.14,243.529l12.243-18.659,9.086,18.659L293.58,270.416Z" transform="translate(-141.959 -216.658)" fill="rgba(211,152,90,0.9)" />
          <path id="Path_56293" data-name="Path 56293" d="M221.619,243.529,209.376,224.87l-9.086,18.659L214.2,270.416Z" transform="translate(-200.29 -216.658)" fill="rgba(211,152,90,0.9)" />
          <path id="Path_56294" data-name="Path 56294" d="M358.079,231.49H203.57l8.4,34.429H349.682Z" transform="translate(-198.061 -212.16)" fill="#d3985a" />
          <path id="Tier_01" d="M161.129,52.5a1.761,1.761,0,0,0-.92.237L140.874,63.9a1.758,1.758,0,0,0,0,3.041l5.963,3.444-5.963,3.444a1.758,1.758,0,0,0,0,3.045l5.963,3.44-5.963,3.444a1.758,1.758,0,0,0,0,3.045l19.334,11.165a1.758,1.758,0,0,0,1.758,0L181.3,86.8a1.758,1.758,0,0,0,0-3.045l-5.963-3.444,5.963-3.44a1.758,1.758,0,0,0,0-3.045l-5.963-3.444,5.963-3.444a1.758,1.758,0,0,0,0-3.041L161.967,52.737a1.761,1.761,0,0,0-.839-.237ZM150.357,72.414l9.852,5.689a1.758,1.758,0,0,0,1.758,0l9.853-5.689,5.087,2.935L161.087,84.48l-15.819-9.131Zm0,9.927,9.855,5.689h0a1.758,1.758,0,0,0,1.758,0l9.855-5.689,5.087,2.935-15.819,9.131-15.819-9.131Z" transform="translate(-78.443 29.046)" fill="#fff" />
        </g>
      </svg>
    </>
  )
}

export default Tier

