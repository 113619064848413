import { fetchLanguageStringsUrl } from "../../routes/ApiEndPoints";
import { APICALL } from "../../services/ApiServices";
import EncryptDecryptService from "../../services/EncryptDecryptService";

const lookupTranslations = (key: string, locale: string, lookup: boolean): boolean | string | undefined => {
  let translations = JSON.parse(localStorage.getItem('translations') || '') || false;
  //check first if the key exists in the json or not
  //return false so a DB lookup can be done from backend else return the pair of key
  try {
    if (lookup) {
      return translations ? key in translations[locale] : false;
    } else {
      //if lookup is not required then return the pair value of translations directly
      return translations ? translations[locale][key] || false : false;
    }
  } catch (e) {
    // console.error(e);
  }
  return undefined; // Add this line as the ending return statement
};

const updateTranslations = (key: string, translation?: any): void => {
  let translations: { [key: string]: { [key: string]: string } } = JSON.parse(localStorage.getItem('translations') || '') || false;
  try {
    let targetkey = get_targetkey(key);
    if (translation && translation != null) {
      //updating the JSON object with new value if found in the DB
        Object.keys(translation)?.forEach(lang => {          
          translations[lang][targetkey] = translation[lang][targetkey];
        });        
        //set the new JSON in localstorage so everytime its not calling the DB search
        localStorage.setItem('translations', JSON.stringify(translations));
    } else {
      let translation = JSON.parse(localStorage.getItem('translations') || '');
      Object.keys(translation)?.forEach(lang => {
        translations[lang][targetkey] = key;
      });
      localStorage.setItem('translations', JSON.stringify(translations));
    }
  } catch (e) {
    console.error(e);
  }
};

const get_targetkey = (key: string): string => {
  return key.split(' ').join('_').toLowerCase();
};

export const t =  (key: string) => {  
  if(!key || key == "" || !(localStorage.getItem('translations'))) return key;  
  const LanguageState = localStorage.getItem('activeLanguage');
  if (!(localStorage.getItem('translations'))) return key;
  if (LanguageState === null) return key;
  let locale = LanguageState || 'en';
  let targetkey = key && get_targetkey(key);

  let translation = lookupTranslations(targetkey, locale, true);
  //checking if the localstorage contains targetkey or not
  //if not then need to check in DB if the string exists there or not.
  if (!translation) {
    updateTranslations(key);
    let postdata = key;
    checkDatabase(postdata, targetkey);
  } else {
    translation = lookupTranslations(targetkey, locale, false) as string;
  }
  return translation ? translation : key;
};

//Check in db and update in local
const checkDatabase = async (postdata:any, targetkey:any) => {
    try {
      let postData =  { // Encrypting the request data
        'method': 'POST',
        'data': EncryptDecryptService.encryptData(JSON.stringify({"development_string": postdata}))
      };
      let response = await APICALL.service(fetchLanguageStringsUrl, 'POST', postData)
      let decrypteddata = JSON.parse(EncryptDecryptService.decryptData(response));
      if (decrypteddata?.status !== 200) {
        throw new Error('Request failed');
      }
      //set the result in the localstorage JSON
      targetkey && updateTranslations(targetkey, decrypteddata.data);
    } catch (e) {
      // console.error(e);
    }
}

const TranslationUtils = {
  t,
};
export default TranslationUtils;
