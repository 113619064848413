import React from 'react'
import { InputWithLabel } from '../InputWithLabel';
import { t } from '../../../pages/Translations/TranslationUtils';
import Button from '../../atoms/Button';
import LabelField from '../../atoms/LabelField';
import styles from "../../organisms/organisms.module.css";
import { APICALL } from '../../../services/ApiServices';
import { createrepairissue } from '../../../routes/ApiEndPoints';
import FileReader from '../../atoms/FileReader';
import { CommonConstants } from '../../../pages/Shipment/Constants/ShipmentConstants';
interface RepairIssueProps {
    issuedetails: any,
    repairid: Number,
    handleRepair: any
}

const RepairIssue: React.FC<RepairIssueProps> = ({ issuedetails, repairid, handleRepair }) => {

    const handleSubmit = async (event: any) => {
        let repairable = event.target.id;
        saveRepairableValue(repairable);
    }

    const saveRepairableValue = async (repairable: string) => {
        let postdata = {
            repair_issue_id: issuedetails.repair_issue_id,
            repairable: repairable,
            serial_number: issuedetails.serial_number,
            repair_id: issuedetails.repair_id,
        }
        const response = await APICALL.service(createrepairissue, 'POST', postdata);
        let data = []
        if (response.status == 200) {
            data = response.data
            handleRepair(data)
        } else {
            handleRepair([])
        }
    }

    return (
        <div className="col-12 mb-3">
            <div className='d-flex align-items-center mb-2'>
                <div className="w-50">
                    <LabelField title={t("Issue type:")} className={`${styles.qclabel}`} /></div>
                <div className="w-50">
                    <InputWithLabel
                        name="title"
                        value={issuedetails?.issuename?.title}
                        handleChange={() => { }}
                        isDisabled={true}
                        className='text-truncate form-control'
                    />
                </div>
            </div>
            <div className='d-flex align-items-center mb-3'>
                <div className="w-50">
                    <LabelField title={t("Repair guidelines:")} className={`${styles.qclabel}`} /></div>
                <div className="w-50">
                    {issuedetails?.issuename?.repair_guidelines == null ?
                        <div className='text-truncate'>{t('No guidelines attached to this issue')}</div> :
                        <FileReader
                            fileurl={issuedetails?.issuename?.repair_guidelines} />
                    }
                </div>
            </div>
            <div>
                <LabelField title={t("Is it repaired")+ '?'} className={`${styles.qclabel}`} />
                <div className='d-flex flex-wrap'>
                    <Button
                        id={"yes"}
                        title={t(CommonConstants.YES)}
                        className="reject-btn-reverse shadow-none rounded mt-3 text-truncate"
                        handleClick={handleSubmit}
                    />
                    <Button
                        title={t(CommonConstants.NO)}
                        className="reject-btn-reverse shadow-none rounded mt-3 text-truncate"
                        handleClick={handleSubmit}
                        id={"no"}
                    />
                </div>
            </div>
        </div>
    )
}

export default RepairIssue;