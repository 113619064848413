import { t } from "../pages/Translations/TranslationUtils";

const token = localStorage.getItem('token');

export const QuickAccessObject = [
  {
    name: t("Reports"),
    subcategories: [
      {
        name: t("Reports"),
        url: "/reports",
        icon: "ReportIcon",
      }
    ],
    requiredPermissions: [
      {permission: "shipment reports",read: true},
      {permission: "published records",read: true},
      {permission: "hold overview",read: true},
      {permission: "time tracker overview",read: true},
    ]
  },
  {
    name: t("Workflow"),
    subcategories: [
      {
        name: t("Workflow"),
        url: `${process.env.REACT_APP_WORKFLOWS_URL}?access_token=${token}`,
        icon: "WorkflowIcon"
      },
    ],
    requiredPermissions: [
      {
        permission: "workflow",
        create: true, read: true, update: true, delete: true
      },
    ]
  },
  {
    name: t("Master data"),
    subcategories: [
      {
        name: "Size",
        url: "/master-data/create?form=size",
        icon: "SizeIcon"
      },
      {
        name: "Repair assessment",
        url: "/master-data/create?form=repair_assessment",
        icon: "RepairIcon"
      },
      {
        name: "Currency",
        url: "/master-data/create?form=currency",
        icon: "CurrencyExchangeIcon"
      },
      {
        name: "Location",
        url: "/master-data/create?form=warehouse_location",
        icon: "LocationIcon"
      },
      {
        name: "Gender list",
        url: "/master-data/create?form=gender",
        icon: "GenderlistIcon"
      },
    ],
    requiredPermissions: [
      {
        permission: "master data",
        create: true
      },
    ],
    strict: true
  },
  {
    name: t("Brand partner"),
    subcategories: [
      {
        name: t("Brand partner"),
        url: "/brand-partner/create",
        icon: "BrandPartnerLogo",
        requiredPermissions: [
          {
            permission: "brand partner",
            create: true
          },
        ]
      },
      {
        name: t("BP Product group"),
        url: "/master-data/create?form=product_group&type=master-data",
        icon: "BpProductGroup",
        requiredPermissions: [
          {
            permission: "brand partner",
            create: true
          },
        ]
      },
      {
        name: t("BP Catalogue"),
        url: "/product-catalogue/manage",
        icon: "BpCatalogueIcon",
        requiredPermissions: [
          {
            permission: "product catalogue",
            read: true,
          },
        ]
      },
    ],
    requiredPermissions: [
      {
        permission: "brand partner",
        create: true, read: true, update: true, delete: true
      },
    ]
  },
  {
    name: t("User management"),
    subcategories: [
      {
        name: t("Create user"),
        url: "/users/create",
        icon: "Createuser",
        requiredPermissions: [
          { permission: "users", create: true },
        ],
      },
      {
        name: t("Create role"),
        url: "/roles/create",
        icon: "Createrole",
        requiredPermissions: [
          { permission: "roles", create: true },
        ],
      },
    ],
    requiredPermissions: [
      { permission: "roles", create: true, read: true, update: true, delete: true },
      { permission: "users", create: true, read: true, update: true, delete: true },
    ],
  },
  {
    name: t("Commonly used"),
    subcategories: [
      {
        name: t("Raise aquestion"),
        url: "/",
        icon: "Createuser"
      },
    ],
  }
];
