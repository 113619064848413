import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "./TranslationUtils";
import { APICALL } from "../../services/ApiServices";
import {
  createLanguageStringsUrl,
  fetchLanguageStringsUrl,
} from "../../routes/ApiEndPoints";
import AccessControl from "../../services/AccessControl";
import { CommonConstants } from "../Shipment/Constants/ShipmentConstants";
import PageNotFound from "../PageNotFound";
import WindowHeightComponent from "../../commonComponents/WindowHeightComponent";

const UpdateTranslations: React.FC = () => {
	const [devString, setDevString] = useState<string>("");
	const [isSaving, setIsSaving] = useState(false);
	const [languages, setLanguages] = useState([]);
	const [langState, setLangState] = useState<any>({});
	const [errors, setErrors] = useState<any>({});
	const navigate = useNavigate();
	const searchParams = new URLSearchParams(location.search);
	const editId = Number(searchParams.get("edit"));

	useEffect(() => {
		fetchTranslations(editId);
	}, []);

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { id, value } = event.target;
		setLangState((prevState: any) => ({ ...prevState, [id]: value }));
	};

	const validateForm = () => {
		const validationErrors: any = {};
		languages.forEach((item: any) => {      
			if (langState[item.language_code]?.length > 250) {
				validationErrors[item.language_code] = t("Maximum length is 250 characters");
			}
		});
		setErrors(validationErrors);
		return Object.keys(validationErrors).length === 0 ? undefined : validationErrors;
	};

	const hasError = (field: string): boolean => {
		return !!errors[field];
	};

	const removeError = (event: React.MouseEvent<HTMLInputElement>) => {
		const fieldName = (event.target as HTMLInputElement).name;
		setErrors((prevState: any) => ({
			...prevState,
			[fieldName]: "",
		}));
	};

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		const validationErrors = validateForm();
		if (!validationErrors) {
			updateTranslations();
		}
	};

	async function updateTranslations() {
		try {
		setIsSaving(true);
		const postData = {
			method: "POST",
			data: langState,
		};
		const response: any = await APICALL.service(
			createLanguageStringsUrl,
			"POST",
			postData,
			true
		);

		if (response.status === 200) {
			let message: string = "Translation updated successfully";
			localStorage.setItem("successAlertMessage", message);
			setIsSaving(false);
			navigate("/translations/manage");
		}
		} catch (error) {
		console.log(error);
		}
	}

	async function fetchTranslations(editId: number) {
		try {
		const postData = {
			method: "POST",
			data: { translation_id: editId },
		};
		const response: any = await APICALL.service(
			fetchLanguageStringsUrl,
			"POST",
			postData,
			true
		);
		if (response.status === 200) {
			const result = response.data[0];
			response?.languageOptions && setLanguages(response?.languageOptions);
			setDevString(result.development_string);
			let lang = response?.languageOptions ?? languages;
			let preparedata: any = {};
			lang.forEach((item: any) => {
				preparedata[item.language_code] = result?.[item.language_code];
			});
			setLangState((prevState: any) => ({
				...prevState,
				...preparedata,
				translation_id: result.translation_id,
			}));
		}
		} catch (error) {
			console.log(error);
		}
	}

	window.addEventListener("beforeunload", () => {
		const currentURL = new URL(window.location.href);
		// const urlExtension = `/translations/manage/${editId ?? editId}`;
		// localStorage.setItem("backbuttonURL", urlExtension);
	});

	const goToPreviousPage = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		let backButtonURL: any = localStorage.getItem("backbuttonURL");
		localStorage.removeItem("backbuttonURL");
		navigate(backButtonURL ?? "/");
	};

	return (
		editId ?
		<AccessControl
			requiredPermissions={[
			{
				permission: "translations",
				update: true,
			},
			]}
			renderNoAccess={true}
		>
			<>
			<div className="row">
				<div className="col-md-11 ms-auto px-4">
				<WindowHeightComponent>
					<div className="d-flex flex-column h-100 overflow-auto">
					<div className="py-xxl-4 py-xl-3 py-lg-3 page-title top-0">
						{t("Update translation")}
					</div>

					<div className="flex-1 overflow-auto">
						<form onSubmit={handleSubmit} className="d-flex flex-column overflow-auto h-100">

						<div className="row m-0 flex-1 overflow-auto">
							<div className="col-12">
							<div className="create-form p-4 mb-4">
								<div className="row">
								<div className="col-10 m-auto">
									<div className="mb-3 ">
									<b className="font20px text-break">
										{t("Translation for")}: {devString}
									</b>
									</div>
									{languages.map((language: any) => (
										<div className="row" key={language.language_code}>
											<div className="col-10">
												<label>{language.language_name}</label> {/* Assuming there's a name property for each language */}
											</div>
											<div className="form-group col-md-12 mb-4">
											<input
												type="text"
												name={language.language_code}
												id={language.language_code}
												className="shadow-none form-control"
												style={
												hasError(language.language_code)
													? { borderColor: "red" }
													: {}
												}
												onClick={removeError}
												value={langState[language.language_code]}
												onChange={handleInputChange}
											/>
											{hasError(language.language_code) && (
												<div
													className="error mb-2"
													style={{ color: "red" }}
												>
												{t(errors[language.language_code])}
												</div>
											)}
											</div>
										</div>
									))}
								</div>
								</div>
							</div>
							</div>
						</div>

						<div className="row m-0 mt-2">
							<div className="col-md-6 float-start">
							<button
								className="back-btn shadow-none float-start text-decoration-none"
								onClick={goToPreviousPage}
							>
								{t(CommonConstants.BACK)}
							</button>
							</div>
							<div className="col-md-6 float-end">
							<button
								type="submit"
								className="submit-btn shadow-none float-end"
							>
								{t("Update")}
							</button>
							</div>
						</div>
						</form>
					</div>
					</div>
				</WindowHeightComponent>
				</div>
			</div>
			</>
		</AccessControl>
		: <PageNotFound />
	);
};

export default UpdateTranslations;
