import React, { useEffect, useReducer, useState } from "react";
import { UTILITYFN } from "../utility/Utility";
import Accordion from "react-bootstrap/Accordion";
import styles from "../../brandpartner.module.css";
import { APICALL } from "../../../../services/ApiServices";
import {
  fetchConfigData,
} from "../../../../routes/ApiEndPoints";
import LabelField from "../../../../components/atoms/LabelField";
import InputTextfield from "../../../../components/atoms/InputTextField";
import { GenerateRefreshToken } from "../../../../services/RefreshTokenService";
import { t } from "../../../Translations/TranslationUtils";
import ActionButtonGroup from "../../../../components/molecules/ActionButtonGroup";
import { useNavigate } from "react-router-dom";
import MultiSelect from "../../../../components/atoms/MultiSelectField";
import CommonServices from "../../../../services/CommonServices";

const initialForm = {
  resaleprice: [],
  resale_price: [],
};

let resalePriceFieldState = {
  resaleprice: {
    errorState: false,
    label: "",
    errorLabel: t("Meter reading is a required field!"),
  },
};

interface FifthTabProps {
  draftSaved: boolean;
  saveAsDraft: string;
  saveAndNext: string;
  backbutton: string;
  arrowIosBackFill?: string;
  arrowIosBackFill2?: string;
  toggleAlertMessage?: () => void;
  setAlertMessage: (message: string) => void;
  formPageState: any;
  dispatchFormPageState: (formData: any) => void;
  setPageDirection: (page: number) => void;
}

const FifthTab: React.FC<FifthTabProps> = (props) => {
  const {
    draftSaved,
    saveAsDraft,
    saveAndNext,
    backbutton,
    arrowIosBackFill,
    arrowIosBackFill2,
    toggleAlertMessage,
    setAlertMessage,
    formPageState,
    dispatchFormPageState,
    setPageDirection,
  } = props;
  type Option = {
    value: string;
    label: any;
  };

  type ResalePrice = {
    [key: number]: {
      discount: string;
      currency?: string;
      tiername: any;
      currencyname: any;
    };
  };
  const currencyvalue = JSON.parse(
    sessionStorage?.getItem("GeneralPropertiesState") ?? ""
  );

  const [state, dispatch] = useReducer(formReducer, initialForm);
  const [statevalues, setStateValues] = useState<{
    [key: number]: {
      discount: string;
      currency?: Option;
      tiername: any;
      currencyname: any;
    };
  }>({});

  const [activePopError, setActivePopError] = useState(false);
  const [errorState, setErrorState] = useState({ ...resalePriceFieldState });

  const [tiers, setTiers] = useState([]);
  const [currency, setCurrency] = useState<Option[]>([]);
  const [activeAccordion, setActiveAccordion] = useState<string | null>(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      let postdata = {
        method: "POST",
        data: {
          type: "model",
          name: "ProductTier",
          status: true,
        },
      };
      const response1 = await APICALL.service(
        fetchConfigData,
        "POST",
        postdata,
        true
      );
      if (response1?.data != null) {
        setTiers(response1?.data);
      }
      // }
      postdata = {
        method: "POST",
        data: {
          type: "model",
          name: "Currency",
          status: true,
        },
      };
      const response2 = await APICALL.service(
        fetchConfigData,
        "POST",
        postdata,
        true
      );

      if (response2?.data != null) {
        const mappedArray: any = response2?.data?.map((item: any) => ({
          value: item?.currency_id,
          label: item?.title,
        }));

        // Sort the mappedArray alphabetically by the 'label' property
        mappedArray?.sort((a: any, b: any) => a?.label.localeCompare(b?.label));
        setCurrency(mappedArray);
      }
    };

    if (tiers.length == 0) {
      fetchData();
    }

    if (sessionStorage?.getItem("resalePriceState")) {
      let data: any = JSON.parse(
        sessionStorage.getItem("resalePriceState") || ""
      );
      if (data != "") {
        setStateValues({ ...data.resale_price });
      }
    } else {
      sessionStorage?.setItem("resalePriceState", JSON.stringify(statevalues));
    }
  }, []);

  useEffect(() => {
    if (tiers.length > 0) {
      // Set the first tab to be open by default
      const firstTier = tiers[0] as { product_tier_id?: string };
      if (firstTier.product_tier_id !== undefined) {
        setActiveAccordion(firstTier?.product_tier_id?.toString());
      }
    }
  }, [tiers]);

  useEffect(() => {
    // Check if the currency condition is met
    if (currencyvalue?.warehouse_location?.currency) {
      const selectedCurrencyValue = currencyvalue.warehouse_location.currency;

      // Iterate through all product tiers and set the currency
      {
        Object.values(tiers).map((item: any) => {
          const tierId = item.product_tier_id;

          // Get the currency label based on the currency ID
          const selectedCurrencyLabel = currency.find(
            (item) => item.value === selectedCurrencyValue
          )?.label;

          if (selectedCurrencyLabel) {
            // Preselect the currency in the state for the current tier
            setStateValues((prevState) => ({
              ...prevState,
              [tierId]: {
                ...prevState[tierId],
                currency: {
                  value: selectedCurrencyValue,
                  label: selectedCurrencyLabel,
                },
                currencyname: selectedCurrencyLabel,
              },
            }));
          }
        });
      }
    }
  }, [currency])

  function formReducer(prevState: any, newsState: any) {
    return { ...prevState, ...newsState };
  }

  function DispatchErrorChanges(newState: any) {
    setErrorState(newState);
  }

  window.addEventListener("beforeunload", () => {
    sessionStorage.clear();
  });

  async function validateFormData(
    event: React.FormEvent<HTMLFormElement>,
    draft?: boolean
  ) {
    event.preventDefault();
    let updatedFormPageState = { ...formPageState };
    let newErrorState = { ...resalePriceFieldState };
    const { requiredStatus, requiredMessage } = validateRequiredField();
    if (!draft && requiredStatus) {
      newErrorState = {
        ...newErrorState,
        resaleprice: { ...newErrorState.resaleprice, errorState: true },
      };
      updatedFormPageState.pageFive.validationStatus = false;
      dispatchFormPageState({ ...formPageState, ...updatedFormPageState });
    } else {
      let stateval: ResalePrice = {};
      Object.entries(statevalues).forEach(([key, value]) => {
        if (value.discount && Number(value.discount) !== 0 && value?.currency) {
          const currencyValue: any = {
            value: value?.currency ? value?.currency.value : undefined,
            label: value?.currency ? value?.currency.label : undefined,
          };
          const currencyLabel = value.currency
            ? value.currency.label
            : undefined;

          stateval[Number(key)] = {
            ...value,
            currency: currencyValue,
            currencyname: currencyLabel,
          };
        }
      });
      sessionStorage.setItem(
        "resalePriceState",
        JSON.stringify({ resale_price: stateval })
      );
      newErrorState = {
        ...newErrorState,
        resaleprice: { ...newErrorState.resaleprice, errorState: false },
      };
      const bp_id = sessionStorage.getItem("brand_partner_id");
      let token = await GenerateRefreshToken();
      const response = await fetch(
        process.env.REACT_APP_MASTER_DATA_URL +
        "api/master/create-brand-partner",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            // Encrypting the request data
            // method: "POST",
            // data: {
            bp_resale_price: stateval,
            brand_partner_id: bp_id,
            // },
          }),
        }
      );
      if (response?.status === 200) {
        if (draft) {
          navigate("/brand-partner/manage");
        }
        updatedFormPageState.pageFive.validationStatus = true;
        dispatchFormPageState({ ...formPageState, ...updatedFormPageState });
        UTILITYFN.setCurrentPageData(props, 6);
      }
    }
    DispatchErrorChanges(newErrorState);
  }

  function validateRequiredField() {
    let emptyResponse = { requiredStatus: false, requiredMessage: "" };
    let errorTierId = null;

    Object.entries(statevalues).forEach(([key, value]) => {
      const errordiv = document.getElementById("error-" + key);

      if (!currencyvalue?.warehouse_location?.currency) {
        if (!value.discount || !value.currency) {
          emptyResponse = { requiredStatus: true, requiredMessage: "" };
          errorTierId = key;

          if (errordiv) {
            errordiv.classList.add("d-flex");
            errordiv.classList.remove("d-none");
          }
        } else {
          hideErrorBlock(errordiv);
        }
      } else {
        // If currency is present, hide the error
        hideErrorBlock(errordiv);
      }
    });

    if (errorTierId) {
      setActiveAccordion(errorTierId);
    }

    return emptyResponse;
  }


  const hideErrorBlock = (errordiv: any) => {
    if (errordiv != null) {
      errordiv.classList.add("d-none");
      errordiv.classList.remove("d-flex");
    }
  };

  function DispatchPriceChanges(
    event: React.ChangeEvent<HTMLInputElement>,
    tiername: string,
    tierid: number
  ) {
    const { name, value } = event.target;
    const discountValue = Number(value);
    // Check if the discount is within the valid range (0 to 100)
    if (!isNaN(discountValue) && discountValue >= 0 && discountValue <= 100) {
      setStateValues((prevState) => ({
        ...prevState,
        [tierid]: {
          ...prevState[tierid],
          [name]: Number(value),
          ["tiername"]: tiername,
        },
      }));
    }
  }

  function DispatchCurrencyChanges(
    value: any,
    tierid: number,
    name: string,
    tiername: string
  ) {
    // Check if the user wants to clear the selection
    if (value === null) {
      setStateValues((prevState) => ({
        ...prevState,
        [tierid]: {
          ...prevState[tierid],
          [name]: null, // Clear the selection
          ["tiername"]: tiername,
          currencyname: null, // Clear the currency name
        },
      }));
    } else {
      // The user selected a currency option
      const result = currency.find(
        (item) => Number(item.value) === Number(value.value)
      );
      let val = {
        value: Number(value.value),
        label: result?.label,
      };
      setStateValues((prevState) => ({
        ...prevState,
        [tierid]: {
          ...prevState[tierid],
          [name]: val,
          ["tiername"]: tiername,
          currencyname: result?.label,
        },
      }));
    }
  }

  // Function to handle accordion item click
  const handleAccordionClick = (item: any) => {
    if (activeAccordion === item?.product_tier_id?.toString()) {
      // If the clicked item is already open, close it
      setActiveAccordion(null);
    } else {
      // If the clicked item is new, open it and close the previously open one
      setActiveAccordion(item?.product_tier_id?.toString());
    }
  };
  return (
    <>
      <span className="custom_astrick_before">
        {" "}
        {t("Discount should be 0 to 100")}{" "}
      </span>

      <form
        className="contract-meter-form mt-2 flex-1 overflow-auto h-100 d-flex flex-column"
        onSubmit={(e) => validateFormData(e)}
      >
        <div className="flex-1 overflow-auto">
          {activePopError ? t("Alert popup code here") : ""}
          {Object.values(tiers).map((item: any) => (
            <Accordion key={item.product_tier_id} activeKey={activeAccordion}>
              <div className="mb-4">
                <Accordion.Item
                  eventKey={item.product_tier_id.toString()}
                  className="border-0"
                  key={item.product_tier_id}
                >
                  <Accordion.Header
                    className={`border-0 ${styles.accheader}`}
                    onClick={() => handleAccordionClick(item)}
                  >
                    {item.title}
                  </Accordion.Header>
                  <Accordion.Body className="my-1 border rounded">
                    <div className="d-flex">
                      <div className="me-5">
                        <div>
                          <LabelField title={t("Discount") + " %"} />
                        </div>
                        <div>
                          <InputTextfield
                            name="discount"
                            id={"discount-" + item.product_tier_id}
                            type="text"
                            value={
                              statevalues[item.product_tier_id]?.discount || ""
                            }
                            handleChange={(event) =>
                              DispatchPriceChanges(
                                event,
                                item.title,
                                item.product_tier_id
                              )
                            }
                            handleClick={(event) =>
                              DispatchPriceChanges(
                                event,
                                item.title,
                                item.product_tier_id
                              )
                            }
                            className={`form-control input-lg `}
                          />
                        </div>
                      </div>

                      <div>
                        <div>
                          <LabelField title={t("Currency")} />
                        </div>
                        <div style={{ minWidth: "180px" }}>
                          <MultiSelect
                            name="currency"
                            options={currency}
                            standards={
                              statevalues[item.product_tier_id]?.currency
                            }
                            handleChange={(event: any) =>
                              DispatchCurrencyChanges(
                                event,
                                item.product_tier_id,
                                "currency",
                                item.title
                              )
                            }
                            isClearable
                            isSearchable
                            disabled={
                              currencyvalue?.warehouse_location?.currency
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      id={"error-" + item.product_tier_id}
                      className={`invalid-feedback d-none text-center me-5`}
                    >
                      {t("Add discount and select currency")}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </div>
            </Accordion>
          ))}
        </div>
        <ActionButtonGroup
          backTitle={t(backbutton)}
          saveAsDraftTitle={draftSaved ? "" : t(saveAsDraft)}
          saveAndNextTitle={t(saveAndNext)}
          handleBackClick={() => UTILITYFN.setCurrentPageData(props, 4)}
          handleSaveAsDraftClick={(e: any) => validateFormData(e, true)}
          handleSaveAndNextClick={(e: any) => validateFormData(e)}
        />
      </form>
    </>
  );
};

export default FifthTab;
