import React from "react";
import ManageHoldItemsOrganism from "../../../../components/organisms/HoldOrganisms/ManageHoldItemsOrganism";
import AccessControl from "../../../../services/AccessControl";
const ManageHoldItems = () => {
  return (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={[
        {
          permission: "hold overview",
          read: true,
        },
      ]}
    >
      <ManageHoldItemsOrganism />
    </AccessControl>
  );
};

export default ManageHoldItems;
