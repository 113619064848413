import React from 'react'

const Search = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32.075 32.048">
        <path id="Path_56142" data-name="Path 56142" d="M409.349,40.344A12.86,12.86,0,0,0,426.58,59.017l8.229,8.229a.891.891,0,0,0,1.266,0l2.321-2.321a.891.891,0,0,0,0-1.266l-8.229-8.229a12.862,12.862,0,0,0-20.783-15.121Zm16.492,14.418a9.1,9.1,0,1,1,0-12.87A9.105,9.105,0,0,1,425.841,54.762Z" transform="translate(-406.584 -35.461)" fill="#54596099" />
      </svg>
    </>
  )
}

export default Search