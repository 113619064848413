import React from 'react'

const Humburgersidebar: React.FC = () => {
  return (
    <><svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 46.608 32.516">
    <g id="Humburger_G_" data-name="Humburger (G)" transform="translate(23593 10153)">
      <path id="Path_55678" data-name="Path 55678" d="M2.168,0H44.44a2.168,2.168,0,0,1,2.168,2.168V4.336A2.168,2.168,0,0,1,44.44,6.5H2.168A2.168,2.168,0,0,1,0,4.336V2.168A2.168,2.168,0,0,1,2.168,0Z" transform="translate(-23593 -10153)" fill="#545960"/>
      <path id="Path_55679" data-name="Path 55679" d="M2.168,0H44.44a2.168,2.168,0,0,1,2.168,2.168V4.336A2.168,2.168,0,0,1,44.44,6.5H2.168A2.168,2.168,0,0,1,0,4.336V2.168A2.168,2.168,0,0,1,2.168,0Z" transform="translate(-23593 -10139.992)" fill="#545960"/>
      <path id="Path_55680" data-name="Path 55680" d="M2.168,0H44.44a2.168,2.168,0,0,1,2.168,2.168V4.336A2.168,2.168,0,0,1,44.44,6.5H2.168A2.168,2.168,0,0,1,0,4.336V2.168A2.168,2.168,0,0,1,2.168,0Z" transform="translate(-23593 -10126.987)" fill="#545960"/>
    </g>
  </svg></>
  )
}

export default Humburgersidebar
