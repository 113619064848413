import React from "react";
import styles from "../../pages/Shipment/shimpment.module.css";
import { t } from "../../pages/Translations/TranslationUtils";

interface WorkflowItemInfoProps {
  serialNumber: string;
  productType: string;
  productDescription: string;
  sku: string;
  color: string;
  size: string;
}

const WorkflowItemInfo: React.FC<WorkflowItemInfoProps> = ({
  serialNumber,
  productType,
  productDescription,
  sku,
  color,
  size,
}) => {
  return (
    <>
      <div className="row m-0 mb-2 pb-2">
        <div className="col">
          <div className="row border shadow m-0 p-3 px-2 pb-2">
            <div className="col-4">
              <div className="bold mb-3 text-truncate" title={serialNumber}>
                <b> {t("Serial number")}:</b> {serialNumber}
              </div>
              <div className="text-truncate" title={sku}>
                <b> {t("SKU")}:</b> {sku}
              </div>
            </div>

            <div className="col-4">
              <div className="mb-3 text-truncate" title={productType}>
                <b> {t("Product type")}:</b> {productType}
              </div>
              <div className="text-truncate" title={color}>
                <b> {t("Color")}: </b>
                {color}
              </div>
            </div>

            <div className="col-4">
              <div className="text-truncate mb-3" title={productDescription}>
                <b> {t("Product description")}:</b> {productDescription}
              </div>
              <div className="text-truncate" title={size}>
                <b> {t("Size")}:</b> {size}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkflowItemInfo;
