import React, { useEffect, useState } from "react";
import customAlert from "../components/atoms/CustomAlert";
import { t } from "./Translations/TranslationUtils";
import { useNavigate } from 'react-router-dom';
import { CreateBpLogo } from "../static/icons/CreateBpLogo";
import { ManageBpLogo } from "../static/icons/ManageBpLogo";
import AccessControl from "../services/AccessControl";
import { useUserContext } from "../routes/Contextlib";
import ActionButtonGroup from "../components/molecules/ActionButtonGroup";

export default function BrandPartnerDashboard() {

    const { user, setUser } = useUserContext();
    const [userPermissions, setUserPermissions] = useState<any>()
    const navigate = useNavigate();

    localStorage.removeItem('backbuttonURL');
    //To display alert message
    useEffect(() => {
        const storageMessage = localStorage.getItem("successAlertMessage");

        if (storageMessage) {
            localStorage.removeItem("successAlertMessage");
            customAlert(
                "success",
                t(storageMessage),
                6000
            )
        }
    });

    const checkStatus = (title: any) => {
        const truePerm = userPermissions?.find((item: any) => item.permission.toLowerCase().trim() === title)
        if(truePerm?.update === true || truePerm?.delete === true){
          return false
        }else{
          return true
        }
      }
    
      useEffect(() => {
        if(user){
          const userPerm: any = user.permissions;
          setUserPermissions(userPerm)
        }
      },[user != null])

    return (
        <>
            <div className="row">
                <div className="col-md-11 ms-auto px-4 mb-4">
                    <div className='page-title'>
                        {t("Brand partner")}
                    </div>

                    <div className="row gx-5 mb-4">
                        <AccessControl
                            renderNoAccess={false}
                            requiredPermissions={[
                                {
                                permission: "brand partner",
                                create: true,
                                },
                            ]}
                        >
                            <div className="col-md-6 pe-5">
                                <div className="link-border py-3 ps-4">
                                    <span onClick={() => navigate("/brand-partner/create")} className="page-links me-2"><CreateBpLogo />{t("Create brand partner")}</span>
                                </div>
                            </div>
                        </AccessControl>
                        <AccessControl
                            renderNoAccess={false}
                            requiredPermissions={[
                                {
                                permission: "brand partner",
                                read: checkStatus("brand partner"),
                                },
                            ]}
                        >
                            <div className="col-md-6 ps-5">
                                <div className="link-border py-3 ps-4">
                                    <span className="me-2 page-links" onClick={() => navigate("/brand-partner/manage")}><ManageBpLogo /> {t("Manage brand partner")}</span>
                                </div>
                            </div>
                        </AccessControl>
                    </div>
                    <ActionButtonGroup
                        backTitle={t("Back")}
                        handleBackClick={()=> navigate('/')}
                    />
                </div>
            </div>
        </>
    )
}
