import React, { useState, useEffect } from 'react'
import Layout from '../../pages/Shipment/Layout';
import { useNavigate } from 'react-router-dom'
import { getqc, fetchList } from '../../routes/ApiEndPoints';
import { APICALL } from '../../services/ApiServices';
import CommonShipmentServices from '../../services/CommonShipmentServices';
import { QCConstants, ConfigConstants } from '../../pages/Shipment/Constants/ShipmentConstants';
import TableStructure from '../atoms/TableStructure';
import { t } from '../../pages/Translations/TranslationUtils';
import Popup from '../../utils/popup';
import { ScannerMolecule } from '../molecules/ScannerMolecule';

const ManageQualityControlOrganism: React.FC = () => {
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const localdata = CommonShipmentServices.getPostData();
    const [qcdata, setQCData] = useState({})
    const user_id = Number(localStorage.getItem("user_id"));
    const [popup, setPopup] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchqcdetails = async () => {
            if (localdata.brand_partner_id == '') {
                navigate(`/shipment`)
            }
            let configreqdata = {
                "method": "POST",
                "data": {
                    "list": [
                        ConfigConstants.PRODUCTTYPE,
                        ConfigConstants.ACTIVE_WAREHOUSELOCATION
                    ]
                }
            }
            const configres = await APICALL.service(fetchList, 'POST', configreqdata, true);
            let configdata: any = [];
            if (configres.status === 200) {
                configdata = configres.data
                let postdata = localdata

                const response = await APICALL.service(getqc, 'POST', postdata);

                if (response.status === 200) {
                    // Filter items with "status" equal to "active"
                    const filteredData = response.data?.filter((item: any) => {
                        return item?.renewal_record?.status === "active";
                    });

                    const mappeddata = filteredData.map((item: any) => ({
                        ...item,
                        'editstatus': !!(item?.renewal_record?.renewal_product_info && item?.renewal_record?.stage_id == response?.stage_id),
                        'style_number': item?.renewal_record?.renewal_product_info?.style,
                        'product_type': item?.renewal_record?.renewal_product_info ? CommonShipmentServices.getFilteredTitle(configdata['ProductType'], 'product_type_id', item?.renewal_record?.renewal_product_info?.product_type) : '',
                        'warehouse_location': item?.renewal_record?.renewal_product_info ? CommonShipmentServices.getFilteredTitle(configdata['WarehouseLocation'], 'warehouse_location_id', item?.renewal_record?.renewal_product_info?.warehouse_location) : '',
                        // 'issue_count': item.quality_control_issues.length
                    }));
                    if (mappeddata.length > 0) {
                        setPopup(true)
                    }
                    setQCData(mappeddata);
                }
            }
        }
        fetchqcdetails()
    }, []);


    const handleEdit = async (value: any) => {
        navigate(`/shipment/quality-control/?edit=${value.quality_control_id}`)
    }

    const handleScan = async (value: any) => {
        const scan_value: any = Object.values(qcdata).find((item: any) => item.serial_number == value.trim())
        if (scan_value) {
            navigate(`/shipment/quality-control/?edit=${scan_value?.quality_control_id}`)
        } else {
            setError(true)
        }
    }

    return (
        <Layout pagename={t('Quality control')} logo={true} timer={false}>
            <div className="flex-1 overflow-auto">
                <TableStructure
                    isAction
                    data={qcdata}
                    actionClass=''
                    headers={QCConstants.TABLE_HEADERS}
                    values={QCConstants.TABLE_VALUES}
                    handleEdit={handleEdit}
                />
            </div>
            {popup && <Popup modalSize="xl" title={t("Scan renewal record")} cancel={() => setPopup(false)} body={<ScannerMolecule handleClick={handleScan} error={error} />} />}
        </Layout >
    )
}

export default ManageQualityControlOrganism