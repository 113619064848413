import React from "react";
import LauntryInternalOrganism from "../../../components/organisms/LaundryInternal/LauntryInternalOrganism";
import AccessControlWorkFlow from "../../../services/AccessControlWorkFlow";
import AccessControl from "../../../services/AccessControl";

const LauntryInternal = () => {
  return (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={[
        {
          permission: "workflow",
          read: true,
        },
      ]}
    >
      <AccessControlWorkFlow
        stage_name="internal_laundry"
        renderNoAccess={true}
      >
          <LauntryInternalOrganism />
      </AccessControlWorkFlow>
    </AccessControl>
  );
};

export default LauntryInternal;
