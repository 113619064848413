import React, { ReactNode } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import styles from "../../pages/BrandPartner/brandpartner.module.css";

type CustomAccordionProps = {
    items: { title: string; content: ReactNode }[];
    className?: string;
    activeKey: string | null; 
    setActiveKey: (key: string | null) => void; 
};

const CustomAccordion: React.FC<CustomAccordionProps> = ({ items, className, activeKey, setActiveKey }) => {
    const handleSelect = (key: any) => {
        if (key !== undefined) {
            setActiveKey(key as string | null); 
        }
    };

    return (
        <Accordion
            className={`workflow-accordion ${className}`}
            activeKey={activeKey}
            onSelect={handleSelect} 
        >
            {items.map((item, index) => (
                <Accordion.Item eventKey={index.toString()} key={index} className='mb-3'>
                    <Accordion.Header className={`${styles.workflowAccordion} shadow-none`}>
                        {item.title}
                    </Accordion.Header>
                    <Accordion.Body>{item.content}</Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
};

export default CustomAccordion;
