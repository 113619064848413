import { display } from '@mui/system'
import React from 'react'
interface LoadingIconProps {
    display:string
}
const LoadingIcon: React.FC<LoadingIconProps> = (props) => {
    return (
        <div id="loading-div-id" style={{ display : props.display}}>
         <div className="loading" id="loading-icon" >
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 0 28 24" width="80" height="80" fill="#D20C14">
                    <line x1="12" y1="0" x2="12" y2="6" stroke="#D20C14" strokeLinecap="round" strokeOpacity="0" strokeWidth="2">
                        <animate attributeName="strokeOpacity" from="1" to="0.1" dur="1s" repeatCount="indefinite" begin="0" />
                    </line>
                    <line transform="rotate(30 12 12)" x1="12" y1="0" x2="12" y2="6" strokeLinecap="round" stroke="#D20C14" strokeOpacity="0.1" strokeWidth="2">
                        <animate attributeName="strokeOpacity" from="1" to="0" dur="1s" repeatCount="indefinite" begin=".083s" />
                    </line>
                    <line transform="rotate(60 12 12)" x1="12" y1="0" x2="12" y2="6" strokeLinecap="round" stroke="#D20C14" strokeOpacity="0.1" strokeWidth="2">
                        <animate attributeName="strokeOpacity" from="1" to="0" dur="1s" repeatCount="indefinite" begin=".167s" />
                    </line>
                    <line transform="rotate(90 12 12)" x1="12" y1="0" x2="12" y2="6" strokeLinecap="round" stroke="#D20C14" strokeOpacity="0.1" strokeWidth="2">
                        <animate attributeName="strokeOpacity" from="1" to="0" dur="1s" repeatCount="indefinite" begin=".25s" />
                    </line>
                    <line transform="rotate(120 12 12)" x1="12" y1="0" x2="12" y2="6" strokeLinecap="round" stroke="#D20C14" strokeOpacity="0.1" strokeWidth="2">
                        <animate attributeName="strokeOpacity" from="1" to="0" dur="1s" repeatCount="indefinite" begin=".333s" />
                    </line>
                    <line transform="rotate(150 12 12)" x1="12" y1="0" x2="12" y2="6" strokeLinecap="round" stroke="#D20C14" strokeOpacity="0.1" strokeWidth="2">
                        <animate attributeName="strokeOpacity" from="1" to="0" dur="1s" repeatCount="indefinite" begin=".417s" />
                    </line>
                    <line transform="rotate(180 12 12)" x1="12" y1="0" x2="12" y2="6" strokeLinecap="round" stroke="#D20C14" strokeOpacity="0.1" strokeWidth="2">
                        <animate attributeName="strokeOpacity" from="1" to="0" dur="1s" repeatCount="indefinite" begin=".5s" />
                    </line>
                    <line transform="rotate(210 12 12)" x1="12" y1="0" x2="12" y2="6" strokeLinecap="round" stroke="#D20C14" strokeOpacity="0.1" strokeWidth="2">
                        <animate attributeName="strokeOpacity" from="1" to="0" dur="1s" repeatCount="indefinite" begin=".583s" />
                    </line>
                    <line transform="rotate(240 12 12)" x1="12" y1="0" x2="12" y2="6" strokeLinecap="round" stroke="#D20C14" strokeOpacity="0.1" strokeWidth="2">
                        <animate attributeName="strokeOpacity" from="1" to="0" dur="1s" repeatCount="indefinite" begin=".667s" />
                    </line>
                    <line transform="rotate(270 12 12)" x1="12" y1="0" x2="12" y2="6" strokeLinecap="round" stroke="#D20C14" strokeOpacity="0.1" strokeWidth="2">
                        <animate attributeName="strokeOpacity" from="1" to="0" dur="1s" repeatCount="indefinite" begin=".75s" />
                    </line>
                    <line transform="rotate(300 12 12)" x1="12" y1="0" x2="12" y2="6" strokeLinecap="round" stroke="#D20C14" strokeOpacity="0.1" strokeWidth="2">
                        <animate attributeName="strokeOpacity" from="1" to="0" dur="1s" repeatCount="indefinite" begin=".833s" />
                    </line>
                    <line transform="rotate(330 12 12)" x1="12" y1="0" x2="12" y2="6" strokeLinecap="round" stroke="#D20C14" strokeOpacity="0.1" strokeWidth="2">
                        <animate attributeName="strokeOpacity" from="1" to="0" dur="1s" repeatCount="indefinite" begin=".917s" />
                    </line>
                </svg>
            </div>
         </div>
         </div>
    )
}

export default LoadingIcon
