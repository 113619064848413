import React, { useEffect, useRef, useState } from "react";
import { t } from "../../pages/Translations/TranslationUtils";

interface Props {
  onChange: (data: string) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  editorLoadedprops: any; // You can specify the type for this prop
  name: string;
  value: string | null;
  defaultHeight?: string;
  type?: string;
  parentclass?: string;
}

const Editor: React.FC<Props> = ({
  onChange,
  onClick = () => { },
  editorLoadedprops,
  name,
  value,
  parentclass = "",
  defaultHeight = '200px',
  type = '',
}) => {
  const editorRef = useRef<any>();
  const { CKEditor, ClassicEditor } = editorRef.current || {};
  const [editorLoaded, setEditorLoaded] = useState(false);
  const config = {
    toolbar: {
      items: [
        'heading',
        'fontFamily',
        'fontSize',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'subScript',
        'superScript',
        '|',
        'highlight',
        'link',
        '|',
        'blockQuote',
        'alignment',
        '|',
        'outdent',
        'indent',
        'bulletedList',
        'numberedList',
        '|',
        'insertTable',
        '|',
        'undo',
        'redo'
      ],
    },
  };

  useEffect(() => {
    editorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor,
      ClassicEditor: require("ckeditor5-build-classic-plus"),
    };
    setEditorLoaded(true);
  }, []);

  return (
    <div className={parentclass}>
      {editorLoaded ? (
        <CKEditor
          type={type}
          name={name}
          editor={ClassicEditor}
          data={value !== null ? value : ''}
          onChange={(event: any, editor: any) => {
            const data = editor.getData();
            onChange(data);
          }}
          onClick={onClick}
          onInit={(editor: any) => {
            editor.editing.view.change((writer: any) => {
              writer.setStyle('height', defaultHeight, editor.editing.view.document.getRoot());
            });
          }}
          config={config}
        />
      ) : (
        <div>{t('Editor loading')}</div>
      )}
    </div>
  );
};

export default Editor;
