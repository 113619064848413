import React from "react";
import ManageFinishingOrganism from "../../../../components/organisms/ManageFinishingOrganism";
import AccessControlWorkFlow from "../../../../services/AccessControlWorkFlow";
import AccessControl from "../../../../services/AccessControl";

const ManageFinishing = () => {
  return (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={[
        {
          permission: "workflow",
          read: true,
        },
      ]}
    >
      <AccessControlWorkFlow stage_name="finishing" renderNoAccess={true}>
        <ManageFinishingOrganism />
      </AccessControlWorkFlow>
    </AccessControl>
  );
};

export default ManageFinishing;
