import React, { useState } from 'react';
import ImageField from '../../atoms/ImageField';
import * as markerjs2 from 'markerjs2';
import styles from "../../molecules/molecules.module.css"
import { t } from '../../../pages/Translations/TranslationUtils';
interface ImageViewProps {
  handleImageEdit?: (url: any, image: string, markedarea:any) => void;
  front_image?: string
  back_image?: string
  disable?: boolean
}

export const ImageView: React.FC<ImageViewProps> = ({ handleImageEdit, front_image, back_image, disable = false }) => {
  const [stat, setStat] = useState(false);

  const initMarkerEditor = (imageElement: any, image: string) => {
    if (disable && stat == false) {
      showMarkerArea(imageElement, image);
      setStat(true)
    }
  }

  const showMarkerArea = (element: any, image: string) => {
    let target = element.target
    // create an instance of MarkerArea and pass the target image reference as a parameter
    let imageElement = document.getElementById(target.id);
    if (imageElement) {
      let markerArea = new markerjs2.MarkerArea(imageElement);
      // register an event listener for when user clicks OK/save in the marker.js UI
      markerArea.availableMarkerTypes = [markerjs2.EllipseMarker];
      markerArea.addEventListener('render', event => {
        target.src = event.dataUrl;
        if (handleImageEdit) {
          setStat(false)
          let markedarea = {
            currentstate: markerArea.getState(),
            imageElement: element
          }          
          handleImageEdit(event.dataUrl, image, markedarea);
        }
      });
      markerArea.addEventListener('close', event => {
        setStat(false)
      });
      // finally, call the show() method and marker.js UI opens
      markerArea.show();
    } else {
      localStorage.setItem("successAlertMessage", 'Error loading the image editor');
    }
  }

  return (
    /*
    define the layout of your molecule here and then call your atomic components inside it
    When you want to handle any function pass it from the parent 
    */
    <div className="d-flex justify-content-between h-100 align-items-center">
      {/* <div className="h-100"> */}
        {/* calling ImageField atom component */}
        <ImageField
          value={(front_image != '' && front_image !== null) ? front_image : (process.env.PUBLIC_URL + '/images/' + 'ProductFront.png')}
          id="front-image-qc"
          altText={t("product-image")}
          className={`${styles.limages}`}
          handleClick={(event: any) => initMarkerEditor(event, 'front_image')}
          crossorigin={disable ? true : undefined}
        />
      {/* </div>
      <div className='h-100'> */}
        {/* calling ImageField atom component */}
        <ImageField
          value={(back_image != '' && back_image !== null) ? back_image : (process.env.PUBLIC_URL + '/images/' + 'ProductBack.png')}
          id="back-image-qc"
          altText={t("product-image")}
          className={`${styles.limages}`}
          handleClick={(event: any) => initMarkerEditor(event, 'back_image')}
          crossorigin={disable ? true : undefined}
        />
      {/* </div> */}
    </div>
  );
};
