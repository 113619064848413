import React from 'react'

const Close = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
        <path id="ios-close-circle" d="M17.375,3.375a14,14,0,1,0,14,14A14,14,0,0,0,17.375,3.375Zm3.547,19.069L17.375,18.9l-3.547,3.547a1.076,1.076,0,1,1-1.521-1.522l3.547-3.547-3.547-3.547a1.076,1.076,0,0,1,1.521-1.522l3.547,3.547,3.547-3.547a1.076,1.076,0,1,1,1.521,1.521L18.9,17.375l3.547,3.547a1.08,1.08,0,0,1,0,1.521,1.068,1.068,0,0,1-1.521,0Z" transform="translate(-3.375 -3.375)" fill="rgba(84,89,96,0.7)"/>
      </svg>
    </>
  )
}

export default Close

