import React, { useEffect, useState } from "react";
import InputRangefield from "../components/atoms/InputRangeField";
import LabelField from "../components/atoms/LabelField";
import { APICALL } from "../services/ApiServices";
import { fetchTimeout, updateTimeout } from "../routes/ApiEndPoints";
import Button from "../components/atoms/Button";
import FormValidation from "../services/FormValidation";
import AccessControl from "../services/AccessControl";
import { t } from "./Translations/TranslationUtils";
import { useNavigate } from "react-router-dom";
import { CommonConstants } from "./Shipment/Constants/ShipmentConstants";
import customAlert from "../components/atoms/CustomAlert";
import { useUserContext } from "../routes/Contextlib";
import WindowHeightComponent from "../commonComponents/WindowHeightComponent";

const ManageInactivityTimeout = () => {
  const { user } = useUserContext();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [requiredPermissions, setRequiredPermissions] = useState<any>([]);
  const [inputValue, setInputValue] = useState("15");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const numericValue = Number(value.trim());
    if (!isNaN(numericValue) && numericValue <= 60) {
      setInputValue(numericValue.toString());
    }
  };

  const handleUpdate = () => {
    let error = FormValidation.isNumberValidation(inputValue, "15", "60");
    setError(error);
    console.log(error);
    console.log(inputValue);

    if (error == "") {
      updateTimeoutTime();
    }
  };

  useEffect(() => {
    manageTimeout();
  }, []);

  useEffect(() => {
    const storageMessage = localStorage.getItem("successAlertMessage");
    localStorage.removeItem("successAlertMessage");
    if (storageMessage) {
      localStorage.removeItem("successAlertMessage");
      customAlert("success", t(storageMessage), 8000);
    }
  }, [refresh]);

  async function updateTimeoutTime() {
    try {
      const postData = {
        method: "POST",
        data: { timeout: inputValue },
      };
      const response = await APICALL.service(
        updateTimeout,
        "POST",
        postData,
        true
      );

      if (response.status === 200) {
        localStorage.setItem(
          "successAlertMessage",
          "Time updated successfully"
        );
        setRefresh(!refresh);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function manageTimeout() {
    try {
      const postData = {
        method: "GET",
        data: {},
      };
      const response = await APICALL.service(
        fetchTimeout,
        "POST",
        postData,
        true
      );

      if (response?.status === 200) {
        setInputValue(response.data.timeout);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const userPerm: any = user?.permissions;
    const truePerm: any = userPerm?.find(
      (item: any) => item.permission.toLowerCase().trim() === "inactivity timeout"
    );
    let permissions = [
      {
        permission: "inactivity timeout",
        read: true,
      },
    ];
    if (truePerm?.update === true) {
      permissions[0].read = false;
      setIsDisabled(false);
    }
    setRequiredPermissions(permissions);
  }, [user != null]);

  return (
    <AccessControl
      requiredPermissions={requiredPermissions}
      renderNoAccess={true}
    >
      <>
        {" "}
        <div className="row">
          <div className="col-md-11 ms-auto px-4">

            <WindowHeightComponent>
              <div className="d-flex flex-column overflow-auto h-100">
                <div className="py-xxl-4 py-xl-3 py-lg-3 page-title top-0">
                  {t("Manage inactivity timeout")}
                </div>

                <div className="flex-1">
                  <LabelField title={t("Set timeout time")} />
                  <div className="row m-0">
                    <div className="col-md-6 d-flex mb-4 mt-3 position-relative align-items-center">
                      <InputRangefield
                        className="border rounded text-gray ps-2 min-height-50"
                        name={"timeout"}
                        handleChange={handleInputChange}
                        handleClick={(event) => event.stopPropagation()}
                        min={"15"}
                        max={"60"}
                        value={String(inputValue)}
                        step={"1"}
                        placeholder="0"
                        error={error}
                        isDisabled={isDisabled}
                      />
                      <div className="ms-2">
                        <LabelField title={t("minutes")} />
                      </div>
                    </div>

                    <div className="col-md-6 pe-5 mb-4 mt-3">
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "inactivity timeout",
                            update: true,
                          },
                        ]}
                      >
                        <Button
                          className="submit-btn"
                          title={t("update")}
                          handleClick={handleUpdate}
                        />
                      </AccessControl>
                    </div>
                  </div>
                </div>

                <div className="row m-0">
                  <div className="col-md-6 float-start p-0">
                    <button
                      className="back-btn shadow-none float-start text-decoration-none"
                      onClick={() => navigate("/application-configuration")}
                    >
                      {t(CommonConstants.BACK)}
                    </button>
                  </div>
                </div>
              </div>
            </WindowHeightComponent>
          </div>
        </div>
      </>
    </AccessControl>
  );
};

export default ManageInactivityTimeout;
