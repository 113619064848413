import PhotoEditingOrganism from "../../../components/organisms/PhotoEditing/PhotoEditingOrganism";
import AccessControl from "../../../services/AccessControl";
import AccessControlWorkFlow from "../../../services/AccessControlWorkFlow";

const PhotoEditing = () => {
  return (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={[
        {
          permission: "workflow",
          read: true,
        },
      ]}
    >
      <AccessControlWorkFlow stage_name="photo_editing" renderNoAccess={true}>
        <PhotoEditingOrganism />
      </AccessControlWorkFlow>
    </AccessControl>
  );
};

export default PhotoEditing;
