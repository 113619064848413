import { fetchLanguageStringsUrl } from "../routes/ApiEndPoints";
import { APICALL } from "./ApiServices";
import EncryptDecryptService from "./EncryptDecryptService";
import { GenerateRefreshToken } from "./RefreshTokenService";
let translation = {
  "en": {},
  "nl": {},
  "fr": {}
}
export async function FetchTranslations(): Promise<any> {

    try {
      let postData =  { // Encrypting the request data
        'method': 'POST',
        'data': {}
      };
      let response = await APICALL.service(fetchLanguageStringsUrl, 'POST', postData, true)
      
      if (response?.status !== 200) {
        throw new Error('Request failed');
      }
      window.localStorage.setItem('translations', (response.data) ? response.data : translation);
        
      } catch(error) {
        console.log(error);
      }
}