import styles from "../../organisms/organisms.module.css";
import ImageCarousel from "../../atoms/ImageCarousel";
import { ProductInfo } from "../../molecules/QualityControlMolecules/ProductInfo";
import { Upload } from "../../../static/icons/Upload";
import { useUserContext } from "../../../routes/Contextlib";
import CommonServices from "../../../services/CommonServices";
import RecordsIssueList from "./RecordsIssuesList";

interface RenewalRecordLeftSideOrganismProps {
  serialnumber: string,
  merchandiseimageurl: any,
  dataentry: any,
  view: boolean,
  rejected: boolean,
  setShowMedia: any,
  timer: boolean,
  configdata: any,
  selectedIssueList: any,
  rejectionReason?: any;
}
const RenewalRecordLeftSideOrganism: React.FC<RenewalRecordLeftSideOrganismProps> = ({
  serialnumber, merchandiseimageurl, dataentry, view, rejected, setShowMedia, timer, selectedIssueList, configdata, rejectionReason
}) => {
  const { user } = useUserContext();

  return (
    <div className="col-5 d-flex flex-column h-100 ps-0">
      <div className="py-4 pb-2 px-5 border rounded shadow flex-1 overflow-auto h-100 d-flex flex-column">
        {/* <h3 className="text-gray text-truncate text-center">
          {serialnumber}
        </h3> */}
        <div
          className={`text-center position-relative merchandising-image-swiper d-flex flex-column h-100 flex-1 overflow-auto ${styles.merchandleftblock}`}>
          <div className="flex-1 overflow-auto">
            <ImageCarousel images={merchandiseimageurl} />
          </div>
          {(dataentry.merchandise_status == null ||
            dataentry.merchandise_status) && !view && !rejected && (
              <span onClick={() => setShowMedia(timer)} className={styles.merchandleftblock_add}>
                <Upload />
              </span>
            )}
          {/* <ProductInfo dataentry={dataentry} /> */}
        </div>
        {(dataentry.merchandise_status == null ||
          dataentry.merchandise_status) && (
            <div className={`text-center ${styles.add_btn}`}>
              <div className="mt-2 d-flex justify-content-between text-center">
                {view && (
                  <div className="w-100">
                    {dataentry.createdAt
                      ? CommonServices.showDateBasedOnFormat(
                        dataentry?.createdAt ?? "",
                        user?.date_format ?? "dd-MM-yyyy",
                        true
                      )
                      : "-"}
                  </div>
                )}
                {dataentry.merch_rack &&
                  Array.isArray(configdata.merch_rack) &&
                  configdata.merch_rack?.map((item: any) => {
                    if (item.value == dataentry.merch_rack) {
                      return (
                        <div className="w-100 text-center">
                          {item.label}
                        </div>
                      );
                    }
                    return "";
                  })}
                {view && (
                  <div className="w-100">
                    {dataentry.updatedAt
                      ? CommonServices.showDateBasedOnFormat(
                        dataentry?.updatedAt ?? "",
                        user?.date_format ?? "dd-MM-yyyy",
                        true
                      )
                      : "-"}
                  </div>
                )}
              </div>
            </div>
          )}
      </div>
      <RecordsIssueList
        selectedIssueList={selectedIssueList}
        configdata={configdata}
        rejected={rejected}
        rejectionReason={rejectionReason}
      />
    </div>
  )
}
export default RenewalRecordLeftSideOrganism;