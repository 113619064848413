import { display } from '@mui/system'
import React from 'react'
interface Props {
    display: string
    // handlePausePlay: () => void;
}
const ScreenDisable: React.FC<Props> = (props) => {
    return (
        <div id="screen-disable-div-id" style={{ display: props.display }}>
            <div className="disable-screen">
            </div>
        </div>
    );
}

export default ScreenDisable;
