import React from 'react'

const Workflow = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="65" height="70" viewBox="0 0 70.765 70.013">
        <path id="Work_flow" data-name="Work flow" d="M53.993,70.02a1.49,1.49,0,0,1-1.49-1.49V62.31a1.854,1.854,0,0,1-.887.223H36.188a1.862,1.862,0,0,1-1.862-1.862V13.039H22.184a11.2,11.2,0,1,1,0-3.724h14a1.862,1.862,0,0,1,1.862,1.862v19.18H51.572a1.853,1.853,0,0,1,.931.249V24.212a1.49,1.49,0,0,1,1.49-1.49H69.262a1.49,1.49,0,0,1,1.49,1.49V40.226a1.49,1.49,0,0,1-1.49,1.49H53.993a1.49,1.49,0,0,1-1.49-1.49V33.832a1.853,1.853,0,0,1-.931.249H38.05V58.81H51.616a1.854,1.854,0,0,1,.887.223V52.516a1.49,1.49,0,0,1,1.49-1.49H69.262a1.49,1.49,0,0,1,1.49,1.49V68.53a1.49,1.49,0,0,1-1.49,1.49Zm2.607-4.1H66.655v-10.8H56.6Zm0-28.3H66.655v-10.8H56.6ZM5.206,11.177a5.959,5.959,0,1,0,5.959-5.959,5.959,5.959,0,0,0-5.959,5.959Z" transform="translate(0.013 -0.006)" fill="#545960" />
      </svg>
    </>
  )
}

export default Workflow

