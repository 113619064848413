import React, { useEffect, useRef } from "react";
import { APICALL } from "../../services/ApiServices";
import { getScannedItem } from "../../routes/ApiEndPoints";
import customAlert from "../atoms/CustomAlert";
import { useNavigate } from "react-router-dom";
import { t } from "../../pages/Translations/TranslationUtils";
import { useUserContext } from "../../routes/Contextlib";
import { ScannerMolecule } from "../molecules/ScannerMolecule";

interface ScanningOrganismProps {
  setShowScanPopup: () => void;
}
const ScanningOrganism: React.FC<ScanningOrganismProps> = ({ setShowScanPopup }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();
  const { user } = useUserContext();
  const hasAdminOrSupervisorRole = user?.roles?.some(
    (role: any) =>
      role.toLowerCase().trim() === process.env.REACT_APP_ADMIN || role.toLowerCase().trim() === process.env.REACT_APP_SUPERVISOR
  );
  const handleScannedData = async (serialnumber: string) => { // Api to check item belongs to which stage    
    if (serialnumber) {
      const postdata: any = {
        serialnumber: serialnumber,
        userid: user?.user_id,
      };
      if (hasAdminOrSupervisorRole) {
        postdata.bypasspermission = true;
      }
      try {
        const response = await APICALL.service(getScannedItem, "POST", postdata);
        if (response.status === 200) {
          let navigateurl = response?.navigateUrl;
          let bpdata = response?.bpdata?.first[0];
          bpdata.filepath = response?.bpdata?.first[2] !== null ? response?.bpdata?.first[2]?.file_path : '';
          localStorage.setItem("bp_details", JSON.stringify(bpdata));
          localStorage.setItem("bp_id", JSON.stringify(bpdata?.brand_partner_id));
          localStorage.setItem("bp_product_group", JSON.stringify(response?.bpdata?.first[1]));
          localStorage.setItem("bp_permissions_details", JSON.stringify(response?.bpdata?.sixth));
          if (localStorage?.getItem("shipment_details")) {
            localStorage.removeItem("shipment_details");
          }
          localStorage.setItem('singleshipment', 'true');
          localStorage.setItem('shipment_dashboard_url', '/shipment-dashboard');
          setShowScanPopup();
          localStorage.removeItem("trackTime")
          navigate(navigateurl);
        } else {
          customAlert("error", t(response.message), 6000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <ScannerMolecule handleClick={handleScannedData} />
    </>
  );
};

export default ScanningOrganism;
